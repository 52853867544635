/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
React Geocode Hook
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th July 2021

*******************************************************************************************/
import React                from 'react';
import Geocode              from "react-geocode";
import { useNetwork }       from '../contexts';
import { useCancelToken }   from './useCancelToken';
import config               from '../config';

const {
    googleMaps : {
        apiKey : GOOGLE_MAPS_API_KEY
    }
} = config;

// Set API Key
Geocode.setApiKey(GOOGLE_MAPS_API_KEY);

// Set Region
Geocode.setRegion("USA");  

// Lookup Component
export const useGeocode = () => {

    const {axios}                   = useNetwork();
    const {cancelToken}             = useCancelToken();

    const lookupLatLng = React.useCallback( (lat,lng) => new Promise((resolve, reject) => {
        axios.post('/api/public/maps/lookup/latlng', {lat,lng}, {cancelToken})
            .then(({data}) => data)
            .then(({lat, lng, address}) => {
                resolve({lat, lng, address})
            })
            .catch(reject)
    }),[axios, cancelToken])

    const lookupAddress = React.useCallback( (address) => new Promise((resolve, reject) => {
        axios.post('/api/public/maps/lookup/address', {address}, {cancelToken})
            .then(({data}) => data)
            .then(({success, lat, lng, address, error}) => {
                resolve({success, lat, lng, address, error})
            })
            .catch(reject)
    }),[axios, cancelToken])

    return {
        Geocode,
        lookupAddress,
        lookupLatLng
    }
};

export default useGeocode;