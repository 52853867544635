
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Logo
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd December 2020

*******************************************************************************************/
import React                            from 'react';
import { Box, CardMedia, useTheme}       from '@mui/material';
import logo_dark                        from 'resources/logos/logo_dark_dot.png';
import logo_light                       from 'resources/logos/logo_light_dot.png';
import {HomeLocation}                   from 'router/locations';
import LinkOptional                     from './LinkOptional';
import { useImageCDN }                  from 'hooks';
import { withTranslation }              from './hoc';

export const Logo = withTranslation( ({
    t, 
    to = HomeLocation.path, 
    p = 1, 
    minWidth    = 100, 
    maxHeight   = 50, 
    width       = 'auto', 
    dark        = 'auto', 
    ...props
}) => {
    const convert   = useImageCDN();
    const theme     = useTheme();
    const src       = React.useMemo(() => {
        const isAuto        = typeof dark === 'string' && dark.toLowerCase() === 'auto';
        const isDarkMode    = (isAuto && theme.palette.mode !== 'dark') || (typeof dark === 'boolean' && dark);
        return isDarkMode ? logo_dark : logo_light;
    }, [dark, theme.palette.mode]);
    
    const converted = React.useMemo(() => convert(src), [convert, src]);

    return (
        <Box p={p} align="center">
            <LinkOptional to={window.location.pathname !== to ? to : undefined}>
                <CardMedia 
                    component   = "img" 
                    src         = {converted} 
                    alt         = {t('common.logo')} 
                    {...props} 
                    sx          = {{
                        width,
                        maxHeight, 
                        minWidth, 
                        ...props.sx
                    }}/>
            </LinkOptional>
        </Box>
    )
});

export default Logo;