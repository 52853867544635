/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Locale Context
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd July 2021

*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';
import {isNil}                  from 'lodash';
import {useAxios}               from '../hooks/services';   
import { useCancelToken }       from 'hooks';    
import formatcoords             from 'formatcoords';      

// The Context Object
export const LocaleContext = React.createContext(undefined);

const   DEFAULT_CURRENCY        = "USD",
        DEFAULT_LANGUAGE        = 'en-US',
        DEFAULT_CURRENCY_FACTOR = 100;

// Voltage Provider
export const LocaleProvider = ({children}) => {
    const {axios}                               = useAxios();
    const {cancelToken}                         = useCancelToken();
    const [currency,        setCurrency]        = React.useState(DEFAULT_CURRENCY.toUpperCase());
    const [currencyFactor,  setCurrencyFactor]  = React.useState(DEFAULT_CURRENCY_FACTOR)
    const [language,        setLanguage]        = React.useState(DEFAULT_LANGUAGE);
    const [dateFormat,      setDateFormat]      = React.useState('YYYY-MM-DD')
    const [timeFormat,      setTimeFormat]      = React.useState('HH:mm:ss');

    const formatNumber                          = React.useCallback( (value) => value.toLocaleString(language), [language]);

    const formatCoords                          = React.useCallback( 
        ({latitude, longitude, decimalPlaces = 3 ,...props}) => {
            return formatcoords(latitude,longitude).format({decimalPlaces,...props})
        },
        []
    )
    const formatLatitude                        = React.useCallback(
        ({latitude, decimalPlaces = 3, ...props}) => {
            const latLonSeparator = '|';
            return formatcoords(latitude,0).format({decimalPlaces, ...props, latLonSeparator}).split(latLonSeparator)[0];
        },
        []
    )
    const formatLongitude                       = React.useCallback(
        ({longitude, decimalPlaces = 3, ...props}) => {
            const latLonSeparator = '|';
            return formatcoords(0,longitude).format({decimalPlaces, ...props, latLonSeparator}).split(latLonSeparator)[1];
        },
        []
    )

    const queryLocale = React.useCallback(() => new Promise((resolve,reject) => {
        axios.get('/api/public/locale', {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(reject)
    }), [axios, cancelToken])

    React.useEffect(() => {
        queryLocale()
            .then(({currency, currencyFactor, language}) => {
                setCurrency(        currency.toUpperCase());
                setCurrencyFactor(  currencyFactor);
                setLanguage(        language);
            })
            .catch(() => {
                setCurrency(        DEFAULT_CURRENCY.toUpperCase());
                setCurrencyFactor(  DEFAULT_CURRENCY_FACTOR);
                setLanguage(        DEFAULT_LANGUAGE);
            })
    },[queryLocale])

    // Helper function to build the datetime format
    const buildDateTimeFormat = React.useCallback(() => `${dateFormat} ${timeFormat} z`, [dateFormat, timeFormat])

    // DateTimeFormat initial state
    const [dateTimeFormat, setDateTimeFormat] = React.useState(buildDateTimeFormat());

    // Update DateTimeFormat when dateFormat or dateTimeFormat changes
    React.useEffect(() => setDateTimeFormat(buildDateTimeFormat), [buildDateTimeFormat])

    // Helper function to format currency
    const formatCurrency = React.useCallback( (value, currencyCode) => {
        if(isNil(value)) 
            return value;
        if(isNaN(value))
            throw new Error('value to convert must be a number');
        return new Intl.NumberFormat(language, {style: 'currency', currency: currencyCode || currency}).format(value)
    }, [currency, language])

    const currencySymbol = React.useCallback( (currencyCode) => {
        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style       : 'currency',
            currency    : currencyCode || currency,
        });

        return currencyFormatter.formatToParts(0).find(part => part.type === 'currency').value;
        
    }, [currency]);

    // Helper function to format currency without symbol
    const formatCurrencyNoSymbol = React.useCallback( (value, currencyCode) => {
        const currencyFormatter = new Intl.NumberFormat('en-US', { 
            style : 'currency', 
            currency : currencyCode || currency
        });

        const currencyOptions = currencyFormatter.resolvedOptions();
    
        // Extract number digits
        let {maximumFractionDigits : digits} = currencyOptions; 
        
        // Ensure Integer
        digits = parseInt(digits);
    
        // Ensure Numeric
        let result = parseFloat(value);
        if(isNaN(result) || isNaN(digits))
            return result;
    
        // To Fixed Digits
        return result.toFixed(digits);

    },[currency])

    // Helper function to format DateTime
    const formatDateTime = React.useCallback((value, format) => {
        if(!isNil(value) && !moment.isMoment(value))
            throw new Error('value must be a moment instance');
        let fmt = format || dateTimeFormat;
        return isNil(value) ? moment().format(fmt) : value.format(fmt);
    },[dateTimeFormat])

    // Helper function to format DateTime
    const formatDate = React.useCallback((value, format) => {
        if(!isNil(value) && !moment.isMoment(value))
            throw new Error('value must be a moment instance');
        let fmt = format || dateFormat;
        return isNil(value) ? moment().format(fmt) : value.format(fmt);
    },[dateFormat])

    // Helper function to format Time
    const formatTime = React.useCallback((value, format) => {
        if(!isNil(value) && !moment.isMoment(value))
            throw new Error('value must be a moment instance');
        let fmt = format || timeFormat;
        return isNil(value) ? moment().format(fmt) : value.format(fmt);
    },[timeFormat])

    // Context values
    const value = React.useMemo(() => ({
        currency,
        currencyFactor,
        currencySymbol,
        language,
        dateFormat,
        setDateFormat,
        timeFormat,
        setTimeFormat,
        dateTimeFormat,
        formatNumber,
        formatCurrency,
        formatCurrencyNoSymbol,
        formatDate,
        formatTime,
        formatDateTime,
        formatCoords,
        formatLatitude,
        formatLongitude
    }), [currency, currencySymbol, currencyFactor, dateFormat, dateTimeFormat, formatCoords, formatCurrency, formatCurrencyNoSymbol, formatDate, formatDateTime, formatLatitude, formatLongitude, formatNumber, formatTime, language, timeFormat]);

    return (
        <LocaleContext.Provider value={value}>
            {children}
        </LocaleContext.Provider>
    )
}

export const LocaleConsumer =  ({children}) => {
    return (
        <LocaleContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('LocaleConsumer must be used within LocaleProvider');
                }
                return children(context)
            }}
        </LocaleContext.Consumer>
    )
}

// useVoltage Hook
export const useLocale = () => {
    const context = React.useContext(LocaleContext);
    if(context === undefined)
        throw new Error('useLocale must be used within LocaleProvider');
    return context;
}