
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Notification Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       2nd August 2021   

*******************************************************************************************/
import React            from 'react';
import {useNetwork}     from './NetworkContext';
import {
    useCanQuery, 
    useCancelToken,
    useStateEphemeral
}                       from 'hooks';

// The Product Context 
const NotificationContext = React.createContext(undefined);

const BASE_URL = '/api/user/notification';

// Notification Provider
const NotificationProvider = ({children}) => {

    const [working, setWorking]                     = React.useState(false);
    const [notifications, setNotifications]         = React.useState([]);
    const { axios, socketUsers : socket }           = useNetwork();
    const { allowQueryForUser }                     = useCanQuery();
    const { cancelToken }                           = useCancelToken();
    const [lastViewed, setLastViewed]               = useStateEphemeral(undefined, 5000);

    // Query the Notification
    const get = React.useCallback(() => new Promise((resolve, reject) => {
        if(!allowQueryForUser)  return reject(new Error('unauthorized'))
        setWorking(true);
        axios.get(BASE_URL, {cancelToken})
            .then(({data})=> data)
            .then(resolve)
            .catch(reject)
            .finally(()=>{
                setWorking(false);
            })
    }),[allowQueryForUser, axios, cancelToken])

    const postById = React.useCallback( ({id, read = true, deleted = undefined}) => new Promise((resolve,reject)=>{
        if(!allowQueryForUser)  return reject(new Error('unauthorized'));
        if(!id)                 return reject(new Error('id is required'));
        setWorking(true);
        axios.post(`${BASE_URL}/${id}`, {read,deleted}, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(reject)
            .finally(()=>{
                setWorking(false);
            })
    }),[allowQueryForUser, axios, cancelToken])

    const deleteById = React.useCallback( ({id}) => new Promise((resolve,reject)=>{
        if(!allowQueryForUser)  return reject(new Error('unauthorized'));
        if(!id)                 return reject(new Error('id is required'));
        setWorking(true);
        axios.delete(`${BASE_URL}/${id}`, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(reject)
            .finally(()=>{
                setWorking(false);
            })
    }),[allowQueryForUser, axios, cancelToken])

    const clear = React.useCallback(() => setNotifications([]), []);

    // Refresh Collection
    const refresh = React.useCallback( () => {
        get()
            .then(setNotifications)
            .catch((err) => {
                console.error(err);
                clear();
            });
    },[clear, get ])

    // Mark as Read
    const markRead  = React.useCallback(({id, read=true, query = true}) => new Promise((resolve,reject) => {
        postById({id,read})
            .then(() => {
                if(query) 
                    refresh();
                resolve(true);
            })
            .catch(reject);
    }),[postById, refresh])

    // Mark as Deleted
    const markDeleted = React.useCallback((id) => {
        deleteById({id})
            .then(refresh)
            .catch(console.error);
    },[deleteById, refresh])

    // Refresh
    React.useEffect(refresh,[refresh]);

    React.useEffect(() => {
        if(!allowQueryForUser)
            clear();
    },[allowQueryForUser, clear])

    // Refresh on SocketIO Instruction
    React.useEffect(()=>{
        if(socket && allowQueryForUser){
            socket.on('refresh_notifications', refresh)
            return () => {
                socket.off('refresh_notifications', refresh);
            }
        }
    },[allowQueryForUser, refresh, socket])

    // Variables
    const quantity          = React.useMemo(() => notifications.length, [notifications.length]);
    const quantityUnread    = React.useMemo(() => notifications.map(x => !x.read).filter(Boolean).length, [notifications]);

    // Context values
    const value = {
        working,
        notifications,
        refresh,
        quantity,
        quantityUnread,
        markRead,
        markDeleted,
        lastViewed, 
        setLastViewed
    };

    return (
        <NotificationContext.Provider value={value}>
            {children}
        </NotificationContext.Provider>
    )
}

// Notification Consumer
const NotificationConsumer = ({children}) => {
    return (
        <NotificationContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('NotificationConsumer must be used within NotificationProvider');
                }
                return children(context)
            }}
        </NotificationContext.Consumer>
    )
}

// useNotification Hook
const useNotification = () => {
    const context = React.useContext(NotificationContext);
    if(context === undefined)
        throw new Error('useNotification must be used within NotificationProvider');
    return context;
}

export {
    NotificationProvider,
    NotificationConsumer,
    useNotification
}