/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Color Chip
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th December 2024

*******************************************************************************************/
import React                            from 'react';
import {
    Chip,
    useTheme,
    darken
}                                       from '@mui/material';

const isValidCSSColor = (color) => {
    const s = new Option().style;
    s.color = color;
    return s.color !== '';
};

// Color Chip
export const ColorChip = ({ color = "default", icon, ...props }) => {
    
    const theme = useTheme();

    // Resolve palette-based colors or directly use provided CSS color
    const resolveColor = () => {
        if (isValidCSSColor(color)) {
            return color;
        }
        const [paletteKey, shade] = color.split('.');
        const paletteColor = theme.palette[paletteKey]?.[shade] || theme.palette[color]?.main;
        return paletteColor || color;
    };

    const background = resolveColor();

    // Fallback to default Chip background if invalid
    const finalBackground = isValidCSSColor(background)
        ? background
        : 'default'; // theme.palette.action.selected //theme.palette.grey[300];

    // Slightly darken the color on hover
    const hoverBackground = isValidCSSColor(finalBackground)
        ? darken(finalBackground, 0.1) 
        : undefined;
        
    // Contrast text color for visibility
    const textColor = isValidCSSColor(finalBackground)
        ? theme.palette.getContrastText(finalBackground)
        : undefined;

    // Clone icon and apply text color
    const iconWithColor = icon
        ? React.cloneElement(icon, { style: { color: textColor || 'inherit' } })
        : null;

    return (
        <Chip
            icon = {iconWithColor}
            {...props}
            sx={{
                background  : finalBackground,
                ...(textColor && {
                    color : textColor
                }),
                ...(hoverBackground && {
                    '&:hover' : {
                        background : hoverBackground,
                    },
                }),
                ...props?.sx
            }}
        />
    );
};

export default ColorChip;