/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
View Order Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       27th April 2022

*******************************************************************************************/

import React                    from 'react';
import { Box,Button }           from '@mui/material';
import InventoryIcon            from '@mui/icons-material/Inventory';
import {
    DeliverySheet,
    DraggableDialog,
    NarrowPage
}                               from '.';


const NarrowPageNoBreadCrumbs = (props) => <NarrowPage {...props} pageContainerProps={{breadcrumbs:false,...props?.pageContainerProps}} />

const noop = () => {}

const DeliveryDialog = ({
    open                        = false,
    deliveryId                  = undefined,
    onClose     : handleClose   = noop,
    onOk        : handleOk      = noop,
    fullScreen                  = false,
    ...props
}) => {
    const Container = React.useMemo(() => fullScreen ? NarrowPageNoBreadCrumbs : Box, [fullScreen])
    return (
        <DraggableDialog 
            title               = "Delivery Sheet"
            subtitle            = {`Delivery ${deliveryId}`}
            open                = {open} 
            onClose             = {handleClose} 
            onOk                = {handleOk}
            showButtonsOk       = {false}
            showButtonsCancel   = {false}
            maxWidth            = "md"
            fullWidth           = {true}
            fullScreen          = {fullScreen}
            disableBackdropClick
            {...props}
        >
            <Container>
                <DeliverySheet 
                    compact             = {true} 
                    showDeliveryLink    = {false}
                    showOrderLink       = {false}
                    deliveryId          = {deliveryId}
                />
            </Container>
        </DraggableDialog>
    )
}

const defaultDialogProps = {

};
const defaultComponentProps = {
    size        : "small",
    variant     : "text",
    color       : "secondary",
    startIcon   : <InventoryIcon/>
}

export const ViewDeliveryButton = ({
    children,
    deliveryId      = undefined, 
    component       : Component = Button,
    componentProps  = defaultComponentProps,
    dialogProps     = defaultDialogProps,
    fullScreen      = false,
    ...props
}) => {

    // Dialog Open or Not
    const [open, setOpen] = React.useState(false); 

    // Event handler for opening/closing dialog
    const handleDialogToggle = React.useCallback((open) => {
        return (event) => {
            if (event) event.stopPropagation();  // Prevent click event propagation
            setOpen(open);
        };
    }, []);

    return (
        <>
            <Component onClick={handleDialogToggle(true)} disabled={!deliveryId} {...componentProps} {...props}>
                {children || 'View Delivery'}
            </Component>
            <DeliveryDialog 
                open        = { open }
                deliveryId  = { deliveryId }
                onOk        = { handleDialogToggle(false) } 
                onClose     = { handleDialogToggle(false) }
                {...{fullScreen,...dialogProps}}
            />
        </>
    )
}

export default ViewDeliveryButton;