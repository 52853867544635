/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
View Product Button -- Sabian Symbols
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th January 2025

*******************************************************************************************/
import React                            from 'react';
import { ViewProductButton }            from 'components';
import { useProduct }                   from 'contexts';
import { withTranslation }              from 'components/hoc';

export const ViewProductButtonSabian = withTranslation(({t, children,...props}) => {
    const {data:products}   = useProduct();
    const symbolsProduct    = React.useMemo(() => (
        products.filter(({deleted = false,name}) => !deleted && /sabian/i.test(name))[0]
    ), [products])
    if(!symbolsProduct)
        return null;
    return (
        <ViewProductButton 
            variant     = "contained"
            color       = "primary"
            size        = "large"
            {...props}
            productId   = {symbolsProduct?._id}
        >
            { children || t('components.library.librarySet.librarySymbolsPreview.button') }
        </ViewProductButton>
    )
})

export default ViewProductButtonSabian;