/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Header
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd December 2020

*******************************************************************************************/
import React                            from 'react';
import { Link, useHistory}              from 'react-router-dom';
import {
    styled,
    useTheme,
    useMediaQuery,
    Avatar as AvatarMUI,
    AppBar,
    Box,
    Toolbar as ToolbarMUI,
    Tooltip,
    Typography,
}                                       from '@mui/material';
import {
    SupervisorAccount   as SupervisorAccountIcon,
    Toll                as TollIcon,
    PersonOff           as PersonOffIcon,
}                                       from '@mui/icons-material';
import {
    Logo, 
    LogoMonogram,
    Login, 
    Logout, 
    HideOnScroll,
    NetworkOnOff,
    SocketOnOff,
    PasswordResetRequestButton,
    Gravatar,
    LoadingData,
    CreditsBalance,
    ColorChip
}                                       from 'components';
import DropDownCart                     from './DropDownCart'
import DropDownNotifications            from './DropDownNotifications';
import UserMenu                         from './UserMenu';
import AdminMenu                        from './AdminMenu';
import PreviewMenu                      from './PreviewMenu';
import HamburgerMenu                    from './HamburgerMenu';
import usePages                         from './usePages';
import MenuButton                       from './MenuButton';
import LanguagesMenu                    from './LanguagesMenu';
import { FONT_FAMILY_FEATURE }          from 'themes';
import { 
    useUser,
    useTranslation,
    useHeader,
    useLocale
}                                       from 'contexts';
import { 
    ExamplesCollectionLocation,
    UserSettingsLocation
}                                       from 'router/locations';
import useSize                          from 'hooks/useSize';

const MenuButtonsContainer = styled(Box)(({theme}) => ({
    display         : 'flex',
    whiteSpace      : 'nowrap',
    overflow        : 'hidden',
    textOverflow    : 'ellipsis',
    '& > * + *' : {
        marginLeft : theme.spacing(1)
    }
}))

const FeatureContainer = styled(Box)(({theme}) => ({
    paddingLeft     : theme.spacing(1),
    paddingRight    : theme.spacing(1)
}))

const ActionButtonsContainer = styled(Box)(({theme}) => ({
    textAlign   : 'right',
    whiteSpace  : 'nowrap',
    [theme.breakpoints.down('lg')]: {
        paddingLeft : theme.spacing(0),
    }
}))

const Avatar = styled(AvatarMUI)(({theme}) => ({
}))

const Toolbar = styled(ToolbarMUI)(({theme}) => ({
    background      : theme.palette.mode ==='light' ? 'white' : 'inherit',
    paddingTop      : theme.spacing(1),
    paddingBottom   : theme.spacing(1),
    minHeight       : 100
}))

const CreditsTypography = styled(Typography)(({theme}) => ({
    fontFamily  : FONT_FAMILY_FEATURE,
    fontWeight  : 600,
    fontSize    : '0.8rem',
    color       : theme.palette.mode ==='light' ? 'black' : 'white',
    cursor      : 'pointer',
    whiteSpace  : 'nowrap'
}))

const Credits = () => {
    const history           = useHistory();
    const {formatNumber}    = useLocale();
    const handleClick       = React.useCallback(() => history.push(ExamplesCollectionLocation.path), [history])

    return (
        <CreditsBalance render = {({balance,working,title}) => {
            if(working) {
                return (
                    <LoadingData label={null}/>
                );
            }
            return (
                <Box display="flex" sx={{mx:0.5,transform:'translatey(-3px)'}}>
                    <Tooltip title={title}>
                        <CreditsTypography onClick={handleClick}>
                            <TollIcon sx={{transform:'translatey(5px)'}}/> {formatNumber(balance || 0)}
                        </CreditsTypography>
                    </Tooltip>
                </Box>
            )
        }} />
    )
}

export const Header = (props) => {
    
    const [t]                       = useTranslation();
    const theme                     = useTheme();
    const history                   = useHistory();
    const {
        mainPages,
        otherPages
    }                               = usePages();
    const {
        isAuthenticated, 
        user, 
        emailMD5, 
        isAdmin, 
        isSuspended
    }                               = useUser();
    const ref                       = React.useRef(null);
    const {width,height}            = useSize(ref);
    const {
        setDims, 
        setShow : handleVisibilityChange
    }                               = useHeader();
    const stripEmail                = React.useCallback( email => email.substring(0, email.lastIndexOf("@")), []);
    const userValid                 = React.useMemo(() => Boolean(isAuthenticated && !isSuspended), [isAuthenticated, isSuspended]);
    const userInvalid               = React.useMemo(() => Boolean(isAuthenticated && isSuspended),  [isAuthenticated, isSuspended]);
    const initials                  = React.useMemo(() => isAuthenticated && user?.name 
        ? user?.name?.match(/(\b\S)?/g).join("").toUpperCase() 
        : undefined, 
        [isAuthenticated, user?.name]
    );
    const isLightTheme              = React.useMemo(() => Boolean(theme.palette.mode === 'light'), [theme?.palette?.mode]);

    React.useEffect(() => {
        setDims({width,height});
    }, [setDims, width, height]);

    const handleNavitageToProfile   = React.useCallback(() => history.push(UserSettingsLocation.path), [history]);
    const handleNavigateToGravitar  = React.useCallback(() => window.open("https://gravatar.com/",'_blank'), []);
    const pointerStyle              = React.useMemo(() => ({ cursor  : 'pointer' }), []);
    const gravitarStyle             = React.useMemo(() => ({ ...pointerStyle, border  : `1px solid ${theme.palette.divider}` }), [pointerStyle,theme.palette.divider]);
    
    const lgUp                      = useMediaQuery(theme => theme.breakpoints.up('lg'));

    return (
        <React.Fragment>
            <HideOnScroll onChange={handleVisibilityChange} {...props}>
                <AppBar id="appbar" ref={ref}>
                    <Toolbar>
                        {
                            lgUp &&
                            <Logo dark={isLightTheme} />
                        }
                        {
                            !lgUp &&
                            <>
                                <HamburgerMenu />
                                <LogoMonogram dark={Boolean(theme.palette.mode !== 'dark')} />
                            </>
                        }

                        <Box display='flex' flexGrow={1}>
                            {
                                lgUp &&
                                <MenuButtonsContainer>
                                    {
                                        mainPages.flat().filter(x => !x.exclude).map(({name,link},ix) => (
                                        <MenuButton key={ix} component={Link} to={link} disableRipple>
                                            {t(`${name}.title`)}
                                        </MenuButton>
                                    ))}

                                    <PreviewMenu />

                                    {
                                        otherPages.flat().map(({name,link},ix) => (
                                        <MenuButton key={ix} component={Link} to={link} disableRipple>
                                            {t(`${name}.title`)}
                                        </MenuButton>
                                    ))}
                                    
                                    <UserMenu/>
                                    <AdminMenu/>  
                                </MenuButtonsContainer>
                            }
                        </Box>

                        {
                            userInvalid &&
                            <FeatureContainer>
                                <ColorChip icon={<PersonOffIcon/>} color="error" label={t('common.userSuspended')}/>
                            </FeatureContainer>
                        }
                        
                        {
                            lgUp && userValid && isAdmin &&
                            <Box sx={{mr:-1,transform:'translatey(2px)'}}>
                                <Tooltip title={t('common.administrator')}>
                                    <SupervisorAccountIcon sx={{
                                        color : theme => theme.palette.mode ==='light' ? 'black' : 'white',
                                    }}/>
                                </Tooltip>
                            </Box>
                        }
                        
                        {
                            lgUp && isAuthenticated && 
                            <SocketOnOff showOn={false} />
                        }

                        {
                            false &&
                            <Typography color="primary">
                                {t(isAuthenticated ? "common.authenticated" : "common.unauthenticated")}
                            </Typography>
                        }

                        {
                            false && <NetworkOnOff />
                        }

                        <DropDownNotifications size="small"/>
                        <DropDownCart />

                        <Credits />
                        
                        {
                            lgUp &&
                            <>
                                {
                                    isAuthenticated && user.picture && !user.email &&
                                    <FeatureContainer>
                                        <Avatar 
                                            alt         = {[t('common.avatar'),user?.name].filter(Boolean).join(' - ')}
                                            src         = { user.picture } 
                                        />
                                    </FeatureContainer>
                                }
                                {
                                    isAuthenticated && user.email &&
                                    <FeatureContainer>
                                        <Gravatar 
                                            size        = { 50 }
                                            alt         = { [t('common.gravatar'),user?.name].filter(Boolean).join(' - ')} 
                                            emailMD5    = { emailMD5 } 
                                            onClick     = { handleNavigateToGravitar }
                                            style       = { gravitarStyle }
                                        />
                                    </FeatureContainer>
                                }

                                {
                                    isAuthenticated && user.name &&
                                    <MenuButton onClick={handleNavitageToProfile} style={pointerStyle}>
                                        {user.name === user.email ? stripEmail(user.name) : user.name}
                                    </MenuButton>
                                }
                                {
                                    isAuthenticated && initials && !user?.name &&
                                    <MenuButton onClick={handleNavitageToProfile} style={pointerStyle}>
                                        {initials}
                                    </MenuButton>
                                }
                            </>
                        }

                        <ActionButtonsContainer>
                            <LanguagesMenu />
                            <PasswordResetRequestButton component={MenuButton}/>
                            <Login  component={MenuButton} />
                            <Logout component={MenuButton} />
                        </ActionButtonsContainer>

                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Toolbar style={{height}}/>
        </React.Fragment>
    );
}

export default Header;