
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
convenience hook to get art styles
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       19th March 2023

*******************************************************************************************/
import React                from 'react';
import { useNetwork }       from 'contexts/NetworkContext';
import { useCancelToken}    from './useCancelToken';

export const useArtstyles = () => {

    const {axios, isNetworkReady}   = useNetwork();
    const {cancelToken}             = useCancelToken();

    const [data, setData]           = React.useState([]);
    const [loading, setLoading]     = React.useState(false);
    const resetData                 = React.useCallback(() => setData([]),[]);
    React.useEffect(() => {
        if(isNetworkReady && axios){
            setLoading(true);
            axios
                .get(`/api/public/artstyles`, {cancelToken})
                .then(({data}) => data)
                .then(setData)
                .catch(err => {
                    console.error(err);
                    resetData();
                })
                .finally(() => {
                    setLoading(false)
                })
        }else{
            resetData();
        }
    },[axios, cancelToken, isNetworkReady, resetData])

    // Done
    return {
        loading,
        data
    }
}

export default useArtstyles;