/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
useUpdateQueryParams
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       26th November 2024

*******************************************************************************************/
import React    from 'react';
import isEqual  from 'lodash/isEqual';

const obj = {};

// Utility to update multiple query parameters
export const useUpdateQueryParams = (setQuery) => (
    React.useCallback(
        (params = obj) => {
            
            if (typeof setQuery !== 'function') {
                console.error('Invalid argument: setQuery is not a function');
                return;
            }
            if (!params || typeof params !== 'object') {
                console.error('Invalid argument: params should be an object');
                return;
            }

            setQuery((prevQuery) => {
                // Construct the new query object by filtering out undefined values
                const newQuery = {
                    ...prevQuery,
                    ...Object.fromEntries(
                        Object.entries(params).filter(([, value]) => value !== undefined) // Prune undefined values
                    ),
                };
                const shouldUpdate = !isEqual(prevQuery,newQuery);

                // If newQuery is the same as prevQuery, return the previous state to avoid unnecessary updates
                if(shouldUpdate){
                    console.log("Update params",params,prevQuery,newQuery);
                    return newQuery;
                }else{
                    return prevQuery;
                }
            });

        },
        [setQuery]
    )
);

export default useUpdateQueryParams;
