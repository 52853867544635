/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
usePublicResources Hook
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       30th October 2021

*******************************************************************************************/
import React                                from 'react';
import { useNetwork }                       from 'contexts';
import { useCancelToken }                   from 'hooks';

const LIMIT = 36

export const usePublicResources = (defaultLimit = LIMIT, search = undefined, title = undefined) => {
    // Defined Limit 36, 
    // 36 is 3 rows of n=12 worst case. 
    // Also (page * limit) % n === 0 for all of n 1, 2, 3, 4 and 12 (grid), where page = 1,2,3 .... etc.
    const { isNetworkReady, axios }     = useNetwork();
    const {cancelToken}                 = useCancelToken();

    // Generic Query
    const getItems                      = React.useCallback(({page = 0, limit = defaultLimit, random = false}) => new Promise((resolve,reject) => {
        
        // Network Not Ready
        if(!isNetworkReady) 
            return resolve([]);

        const queryString = (
            Object
                .entries({page,limit,random,search,title})
                .reduce((acc,[key,value]) => (
                    [...acc, value === undefined ? undefined : `${key}=${value}`]
                ),[])
                .filter(Boolean)
                .join('&')
        )

        // Query
        axios.get(`/api/public/resources?${queryString}`, {cancelToken})
            .then(({data}) => data)
            .then(({totalPages,totalItems,items}) => {
                return {
                    totalItems,
                    totalPages,
                    items
                }
            })
            .then(resolve)
            .catch(reject);

    }),[axios, cancelToken, defaultLimit, isNetworkReady, search, title])

    // Random Query
    const getRandomItems = React.useCallback((quantity = defaultLimit) => (
        getItems({limit : quantity, random : true, page : 0})
    ), [defaultLimit, getItems]);

    return {
        getItems,
        getRandomItems,
        ready : isNetworkReady
    }
}

export default usePublicResources;
