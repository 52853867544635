/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Alert On Behalf Of User
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       27th December 2024

*******************************************************************************************/
import React                            from 'react';
import { FormAlert }                    from 'components';

export const AlertOnBehalfOfUser = ({userId = undefined}) => {
    if(!userId)
        return null;
    return (
        <FormAlert severity="error">
            On behalf of user {userId}
        </FormAlert>
    )
}

export default AlertOnBehalfOfUser;