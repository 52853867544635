/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Public Coupon Alerts
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       26th January 2023

*******************************************************************************************/
import React                        from 'react';
import {Box}                        from '@mui/material';
import {MainAlert}                  from 'components';
import { withTranslation }          from './hoc';
import { 
    useNetwork, 
    useCart
}                                   from 'contexts';
import { 
    useCancelToken, 
    useCopyToClipboard
}                                   from 'hooks';

const CopyCode = withTranslation( ({t, code, children}) => {
    const copy = useCopyToClipboard();
    const handleCopy = React.useCallback( () =>  {
        if(!children) return;
        copy( children, t('components.alertPublicCoupon.codeCopied', { code }))
    }, [children, copy, t, code]);
    return (
        <Box component="span" onClick={handleCopy} sx={{cursor:'pointer',fontWeight:800}}>
            {children}
        </Box>
    )
})

export const AlertPublicCoupon = withTranslation( ({t}) => {

    const { 
        axios, 
        isNetworkReady,
        socketRoot : socket
    }                               = useNetwork();
    const { cancelToken}            = useCancelToken();
    const [data, setData]           = React.useState(undefined);
    const { coupon }                = useCart();

    // Query Function
    const query = React.useCallback(() => {
        if(isNetworkReady){
            axios.get('/api/public/coupon/random', {cancelToken})
                .then(({data}) => data)
                .then(setData)
                .catch(console.error)
        }
    },[axios, cancelToken, isNetworkReady]);

    // Query on Load
    React.useEffect(query,[query]);

    // Listen to Socket Events
    React.useEffect(() => {
        if(socket){
            socket.on('refresh_public_coupon', query);
            return () => {
                socket.off('refresh_public_coupon', query);
            }
        }
    },[query, socket])

    if(coupon) 
        return null;

    if(!data?.description || !data?.code ) 
        return null

    return (
        <MainAlert 
            severity    = "success"
            dismissable = {true} 
            sx          = {{ 
                '& > .MuiAlert-message' : { 
                    // mx : 'auto' 
                } 
            }}
        >
            <strong>{t('components.alertPublicCoupon.salulation')}:</strong> { t('components.alertPublicCoupon.message', {description : data.description}) }{" "}<CopyCode code={data.code}>{data.code}</CopyCode>
        </MainAlert>
    )
});

export default AlertPublicCoupon;