/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
DateTimeField
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       5th June 2021

*******************************************************************************************/

import React                                    from 'react';
import moment                                   from 'moment';
import {styled /*, FormHelperText */ }          from "@mui/material";
import {IconButton}                             from 'components';
import {useTranslation}                         from 'contexts';
import ClearIcon                                from '@mui/icons-material/Clear';

// import TextField                                from '@mui/material/TextField';
// import MomentUtils                              from '@date-io/moment';

// import { useTheme }                             from '@mui/material';
// import { /*ThemeProvider,*/ StyledEngineProvider }  from "@mui/material/styles";

// import 'date-fns';
// import DateFnsUtils                             from '@date-io/date-fns';


// import MomentUtils                              from '@date-io/moment';
// import { MuiPickersUtilsProvider }              from '@material-ui/pickers';
// import DateTimePickerToolbar                    from "@material-ui/pickers/DateTimePicker/DateTimePickerToolbar";
// import PickerToolbar                            from "@material-ui/pickers/_shared/PickerToolbar";
// import ToolbarButton                            from "@material-ui/pickers/_shared/ToolbarButton";

// import AdapterDateFns                           from '@mui/lab/AdapterDateFns';

import { DateTimePicker as DateTimePickerRFF}     from './components';
// import DateTimePickerToolbar                    from "@mui/x-date-pickers/DateTimePicker/DateTimePickerToolbar";";
// import { darkThemeInverted, lightTheme }        from '../../../themes';


const DateTimePicker = styled(DateTimePickerRFF,{
    shouldForwardProps : prop => prop !== 'showButton'
})(({showButton = false}) => ({
    ...(!showButton && {
        "& .MuiInputAdornment-root" : {
            display : 'none'
        }
    })
}))
    

/*
const DatePickerToolbar = ({ 
    date,
    isLandscape,
    openView,
    setOpenView,
    title,
    ...props
}) => {
	const handleChangeViewClick = (view) => (e) => setOpenView(view);
	const classes = useStyles(props);
	return (
		<PickerToolbar className={classes.toolbar} title={title} isLandscape={isLandscape}>
			<ToolbarButton
				onClick     = {handleChangeViewClick("year")}
				variant     = "h6"
				label       = {date.format("YYYY")}
				selected    = {openView === "year"}
			/>
			<ToolbarButton
				onClick     = {handleChangeViewClick("date")}
				variant     = "h4"
				label       = {date.format("MMMM D")}
                selected    = {openView === "date"}
			/>
		</PickerToolbar>
	);
}
*/

const noop                  = () => {};
const noopDateTime          = (dateTime) => {};
const defaultInputProps     = {style:{fontSize:'0.75rem'}};
const isDate                = (value) => value instanceof Date && !isNaN(value);
const DATE_FORMAT           = "DD / MMM / YYYY";
const TIME_FORMAT           = "hh:mm a";

export const DateTimeField = ({
    name                    = 'dateTime',
    label                   = undefined,
    value       : valueIn   = undefined,
    helperText              = undefined,
    disabled                = false,
    viewTime                = true,
    includeSeconds          = false,
    // ampm                    = true,
    disableFuture           = true,
    showTodayButton         = true,
    usetimezone             = true,
    InputProps              = defaultInputProps,
    minYear                 = 0,
    onChange                = noopDateTime,
    onClick                 = noop,
    onClose                 = noop,
    ...props
}) => {
    const {t} = useTranslation();

    // Is Picker Open or Closed
    // const [pickerOpen, setPickerOpen]   = React.useState(false);
    /*
    const [currentError, setCurrentError]   = React.useState(null);
    const [errorDate,       setErrorDate]   = React.useState(false);
    */

    // Ensure is Moment
    const value                         = React.useMemo(() => (
        moment.isMoment(valueIn) 
            ? valueIn 
            : isDate(valueIn) 
                ? moment(valueIn)
                : null
    ), [valueIn])

    const minDate                       = React.useMemo(() => {
        let date = new Date(`1000-01-01`);
        date.setUTCFullYear(parseInt(minYear));
        return moment(date)
    },[minYear])

    // Is Key Valid
    /*
    const validKey                      = React.useCallback( (keyCode) => (
        (keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode === 8)
    ), []);
    */

    // Views depending on viewTime 
    const views                         = React.useMemo(() => (
        ["year", "month", "day"].concat(viewTime ? ["hours", "minutes", includeSeconds ? "seconds" : undefined] : []).filter(Boolean)
    ), [includeSeconds, viewTime]);

    /*
    const castToUTC = value => {
        const newValue = moment.isMoment(value) 
            ? moment().utc(value)
            : value
        return newValue;
    };
    const castToLocal = value => (
        moment.isMoment(value)
            ? value.tz(moment.tz.guess())
            : value
    );

    const castToX = value => {
        value = value || moment();
        usetimezone ? castToLocal(value) : castToUTC(value)
    }
    */

    // Parse Date
    /*
    const parseDateTime = (dateTime) => {
        console.log("ParseDateTime", dateTime)
        let result;
        if(moment.isMoment(dateTime)) 
            result = dateTime;
        else
            result = dateTime ? moment(dateTime) : undefined;
        if(result){
            result = castToX(result);
        }
        return result;
    }
    */

    // Handle Key Entered
    /*
    const handleKeyDown = React.useCallback( (event) => {
        if(disabled) 
            return;
        if([8,46].includes(event.keyCode)) // backspace or delete
            return onChange(undefined);
        if(!validKey(event.keyCode))
            return;
        event.preventDefault(); 
        setPickerOpen(true); 
    }, [disabled, onChange, validKey]);
    */

    // Handle Click
    const handleClick = React.useCallback( (e) => {
        if(disabled) return;
        // setPickerOpen(true);
        onClick();
    }, [disabled, onClick]);

    const handleClearClick = React.useCallback((e) => {
        
        if(disabled) return;
        e && e.stopPropagation();
        onChange(null);
    },[disabled, onChange])

    // Handle Close
    /*
    const handleClose = React.useCallback( () => {
        setPickerOpen(false);
        onClose();
    }, [onClose]);
    */

    // Handle Change Value
    /*
    const handleChange = React.useCallback( dateTime => {
        // console.log("HandleChange", dateTime)
        // let result = parseDateTime(dateTime);
        onChange(dateTime);
    }, [onChange]);
    */
    
    return (
        <>
            <DateTimePicker
                name                = {name}
                label               = {label}
                value               = {value}
                helperText          = {helperText}
                disabled            = {disabled} 
                // open                = {pickerOpen}  
                onClick             = {handleClick}
                // onClose             = {handleClose}
                // onKeyDown           = {handleKeyDown}
                // onChange            = {handleChange}
                onChange            = {onChange}
                // ampm                = {ampm}
                disableFuture       = {disableFuture}
                showTodayButton     = {showTodayButton}
                openTo              = {views[0]} // First View
                views               = {views}
                todayLabel          = {t(!viewTime ? 'components.dateTimeField.today' : 'components.dateTimeField.now')}
                hideTabs            = {!viewTime}
                minDate             = {minDate}
                // format              = {
                inputFormat         = {
                    [
                        DATE_FORMAT,
                        viewTime ? TIME_FORMAT : undefined,
                        viewTime && usetimezone ? '' : undefined
                    ].filter(Boolean).join(' ')
                }
                {...props}
                InputProps      = {{
                    endAdornment: (
                        <IconButton
                            sx      = {{ visibility: value ? "visible" : "hidden" }}
                            onClick = {handleClearClick}
                        >
                            <ClearIcon />
                        </IconButton>
                    ),
                    ...props?.InputProps
                }}
                
                
                /*
                InputProps          = {{
                    ...InputProps,
                    hideTabs    : !viewTime,
                    onClick     : handleClick,
                }}
                */
                
                /*
                onError             = {(reason, value) => {
                    if (reason) {
                        // reason is the error , which it will be displayed as
                        // default error message ,you can also  pass your own error
                        // message depending on the error
                        const errors = {

                            minDate : `Below Min. Date (${minDate.format(DATE_FORMAT)})`,
                            maxDate : "Max. Date Exceeded"
                        }
                        setCurrentError(`Please Check, ${errors[reason] || reason}`);
                        setErrorDate(true);
                    } else {
                        setCurrentError(null);
                        setErrorDate(false);
                    }
                }}
                */
                
                /*
                renderInput={(params) => (
                    <TextField
                        required
                        {...params}
                        // error={errorDate}
                        // helperText={currentError || helperText}
                        inputProps={{
                            ...inputProps.inputProps,
                            onBlur: event => {
                              inputProps.inputProps?.onBlur?.(event);
                              restInput.onBlur(event);
                            },
                            onFocus: event => {
                              inputProps.inputProps?.onFocus?.(event);
                              restInput.onFocus(event);
                            },
                        }}
                    />
                )}
                */
                showButton = {false}
            />
            
            {
                /*
                false && 
                (helperText || errorDate) && 
                <FormHelperText error={errorDate}>
                    {currentError ? currentError : helperText}
                </FormHelperText>
                */
            }
        </>
    );
};

export default DateTimeField;