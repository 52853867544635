
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Coupon Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       12th November 2021

*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';
import {FORM_ERROR}             from 'final-form';
import {useNetwork}             from './NetworkContext';
import {
    useStateEphemeral,
    useCancelToken,
    useCanQuery
}                               from '../hooks';

// The Product Context 
const CouponContext = React.createContext(undefined);

const BASE_API_URL                  = '/api/admin/coupon';
const EPHEMERAL_DELAY               = 1000;
const ERROR_MSG_MISSING_COUPON_TYPE = 'Invalid or Missing Coupon Type';
const ERROR_MSG_COUPON_ID_MISSING   = 'Coupon ID not specified';
const PRODUCT_COUPON_NAME           = 'ProductCoupon';
const ORDER_COUPON_NAME             = 'OrderCoupon';

// Coupon Provider
const CouponProvider = ({children}) => {
    const {
        axios, 
        socketUsers : socket
    }                                           = useNetwork();
    const {allowQueryForAdmin}                  = useCanQuery();
    const {cancelToken }                        = useCancelToken();
    const [messageSuccess,  setMessageSuccess]  = useStateEphemeral(undefined,EPHEMERAL_DELAY);
    const [messageWarning]                      = useStateEphemeral(undefined,EPHEMERAL_DELAY);
    const [messageError,    setMessageError]    = useStateEphemeral(undefined,5000);
    const [deleted,         setDeleted]         = React.useState(false);
    const [enabled,         setEnabled]         = React.useState(false);
    const [working, setWorking]                 = React.useState(false);
    const [queried, setQueried]                 = React.useState(undefined);
    const [dataP,   setDataP]                   = React.useState([]);
    const [dataO,   setDataO]                   = React.useState([]);
    const [data,    setData]                    = React.useState([]);

    const clear                                 = React.useCallback(() => {
        setDataP([]);
        setDataO([]);
    },[]);

    // Concat Order and Product Coupons if they individually change
    React.useEffect(()=>{
        let coupons = dataO.concat(dataP)
        setData(coupons)
    },[dataP,dataO])

    // Query Product Coupons
    const getProductCoupons = React.useCallback( (showLoading = true) => new Promise((resolve,reject) => {
        if(showLoading) setWorking(true);
        axios.get(`${BASE_API_URL}/product?deleted=${deleted.toString()}&enabled=${enabled.toString()}`, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(reject)
            .finally(() => {
                if(showLoading) setWorking(false);
            });
    }),[axios, cancelToken, deleted, enabled])

    // Query Order Coupons
    const getOrderCoupons = React.useCallback((showLoading = true) => new Promise((resolve,reject) => {
        if(showLoading) setWorking(true);
        axios.get(`${BASE_API_URL}/order?deleted=${deleted.toString()}&enabled=${enabled.toString()}`, {cancelToken})
            .then(({data}) => {
                setMessageSuccess("Coupons queried")
                return data
            })
            .then(resolve)
            .catch(err => {
                setMessageError(err.message );
                reject(err)
            })
            .finally(() => {
                if(showLoading) setWorking(false);
            })
    }),[axios, cancelToken, deleted, enabled, setMessageError, setMessageSuccess])

    // Get Product and Order Coupons returns array of arrays [[orderCoupons],[productCoupons]]
    const getCoupons = React.useCallback((showLoading = true) => new Promise((resolve,reject) => {
        let promises = [
            getOrderCoupons(false), 
            getProductCoupons(false)
        ];
        if(showLoading) setWorking(true)
        Promise.all(promises)
            .then(resolve)
            .catch(reject)
            .finally(()=>{
                if(showLoading) setWorking(false);
            })
    }),[getOrderCoupons, getProductCoupons])

    // Enforce couponType and couponID's as part of the form data
    const enforceRequiredIds = (data, couponType = true, couponId = true) => new Promise((resolve,reject) => {
        let {__t = undefined, _id = undefined} = data || {};
        if(couponType && (!__t || ![PRODUCT_COUPON_NAME, ORDER_COUPON_NAME].includes(__t)))
            return reject({errors : {[FORM_ERROR] : ERROR_MSG_MISSING_COUPON_TYPE ,__t : ERROR_MSG_MISSING_COUPON_TYPE}});
        if(couponId && !_id)
            return reject({errors : {[FORM_ERROR] : ERROR_MSG_COUPON_ID_MISSING, _id : ERROR_MSG_COUPON_ID_MISSING}} );
        return resolve({__t,_id})
    })

    // Create a Coupon
    const createCoupon = React.useCallback( formData => new Promise((resolve,reject) => {
        enforceRequiredIds(formData,true,false)
            .then(({__t}) => {

                setWorking(true);
                axios.post(`${BASE_API_URL}/${__t.toLowerCase().replace('coupon','')}`, formData, {cancelToken})
                    .then(({data}) => {
                        setMessageSuccess("Coupon Created");
                        return data;
                    })
                    .then(resolve)
                    .catch(err => {
                        setMessageError(err?.message);
                        reject(err);
                    })
                    .finally(() => {
                        setWorking(false);
                    });
            })
            .catch(reject)
    }),[axios, cancelToken, setMessageError, setMessageSuccess])

    // Edit a coupon
    const editCoupon = React.useCallback(formData => new Promise((resolve,reject) => {
        enforceRequiredIds(formData,true,true)
            .then(({__t,_id})=>{
                setWorking(true);
                axios.post(`${BASE_API_URL}/${__t.toLowerCase().replace('coupon','')}/${_id}`, formData, {cancelToken})
                    .then(({data}) => {
                        setMessageSuccess("Coupon Updated");
                        return data;
                    })
                    .then(resolve)
                    .catch(err => {
                        setMessageError(err.message);
                        reject(err);
                    })
                    .finally(() => {
                        setWorking(false);
                    });
            })
            .catch(reject)
    }),[axios, cancelToken, setMessageError, setMessageSuccess])

    // Delete a Coupon
    const deleteCoupon = React.useCallback(formData => new Promise((resolve,reject) => {
        enforceRequiredIds(formData,true,true)
            .then(({__t,_id})=>{
                setWorking(true);
                axios.delete(`${BASE_API_URL}/${__t.toLowerCase().replace('coupon','')}/${_id}`, {cancelToken})
                    .then(({data}) => {
                        setMessageSuccess("Coupon Deleted");
                        return data;
                    })
                    .then(resolve)
                    .catch(err => {
                        setMessageError(err.message);
                        reject(err);
                    })
                    .finally(() => {
                        setWorking(false);
                    });
            })
            .catch(reject);
    }),[axios, cancelToken, setMessageError, setMessageSuccess])

    // Refreh Coupons List
    const refresh = React.useCallback((reset) => {
        if(allowQueryForAdmin){
            if(reset) clear();
            setWorking(true);
            getCoupons(false)
                .then(([couponsOrder,couponsProduct]) => {
                    setQueried(moment());
                    setDataO(couponsOrder);
                    setDataP(couponsProduct);
                })
                .catch((err) => {
                    setMessageError(err.message);
                    clear();
                })
                .finally(()=>{
                    setWorking(false);
                })
        }else{
            clear();
        }
    },[clear, getCoupons, allowQueryForAdmin, setMessageError])

    // Refresh on authentication
    React.useEffect(() => {
        if(allowQueryForAdmin)
            refresh();
        else 
            clear();
    },[clear, allowQueryForAdmin, refresh])

    // Refresh on SocketIO Instruction
    React.useEffect(()=>{
        if(allowQueryForAdmin && socket){
            socket.on('refresh_coupons', refresh)
            return () => {
                socket.off('refresh_coupons', refresh);
            }
        }
    },[allowQueryForAdmin, refresh, socket])

    // Context values
    const value = React.useMemo(() => ({
        working,
        queried,
        data,
        dataProductCoupons  : dataP,
        dataOrderCoupons    : dataO,
        messageSuccess,
        messageWarning,
        messageError,
        refresh,
        clear,
        createCoupon,
        editCoupon,
        deleteCoupon,
        deleted,
        setDeleted,
        enabled,
        setEnabled,
    }), [clear, createCoupon, data, dataO, dataP, deleteCoupon, deleted, editCoupon, enabled, messageError, messageSuccess, messageWarning, queried, refresh, working]);

    return (
        <CouponContext.Provider value={value}>
            {children}
        </CouponContext.Provider>
    )
}

// Coupon Consumer
const CouponConsumer = ({children}) => {
    return (
        <CouponContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('CouponConsumer must be used within CouponProvider');
                }
                return children(context)
            }}
        </CouponContext.Consumer>
    )
}

// useCoupon Hook
const useCoupon = () => {
    const context = React.useContext(CouponContext);
    if(context === undefined)
        throw new Error('useCoupon must be used within CouponProvider');
    return context;
}

export {
    CouponProvider,
    CouponConsumer,
    useCoupon
}