/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
NatalData Modal
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th May 2021

*******************************************************************************************/

import React                                from 'react';
import { DraggableDialog }                  from 'components';
import { NatalDataForm }                    from 'components/forms';


const defaultFormData = {
    firstName       : undefined,
    lastName        : undefined,
    lat             : undefined,
    lng             : undefined,
    gender          : undefined,
    birthDateTime   : undefined,
    unknownTime     : undefined,
    description     : undefined, 
    isUser          : false, 
    roddenRading    : undefined
}

const obj  = {};
const noop = () => {}
const noopsubmit = (values) => {}

// 
export const NatalDataV2 = ({
    open,
    title,
    disabled                    = false,
    formData                    = defaultFormData,
    onSubmit    : handleSubmit  = noopsubmit,
    onCancel    : handleCancel  = noop,
    onClose     : handleClose   = noop,
    natalDataFormProps          = obj,
    children,
    ...props
}) => {
    return (
        <DraggableDialog maxWidth={'sm'} open={open} onClose={handleClose} title={title} {...props} showButtons={false} readOnly={disabled}>
            
            {children}

            <NatalDataForm
                {...natalDataFormProps}
                disabled            = {disabled}
                formData            = {formData}
                onSubmit            = {handleSubmit}
                onCancel            = {handleCancel}
                showSubmitButton    = {!disabled}
                cancelText          = {
                    disabled 
                        ? "Close" 
                        : undefined
                }
                FormProps           = {{
                    showObjectId    : false,
                    sticky          : true,
                    ...natalDataFormProps?.FormProps
                }}
            />
        </DraggableDialog>
    )
}
export default NatalDataV2;

