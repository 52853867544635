
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
User Context
********************************************************************************************
Listens to changes to authentication, user and pushes update to server

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       21st December 2020

*******************************************************************************************/
import React                    from 'react';
import md5                      from 'md5';
import { useLocation }          from 'react-router-dom';
import { useAuth0 }             from '@auth0/auth0-react';
import { useAPIHealth  }        from 'contexts/APIHealthContext';
import { useAlert }             from 'contexts/AlertContext';
import { useJWT }               from 'hooks/services';
import { useCancelToken }       from 'hooks';
import { useAxios }             from 'hooks/services'
import config                   from '../config';
import { FaRegObjectUngroup } from 'react-icons/fa';

const {
    rootUrl,
    auth0 : {
        namespace   : NAMESPACE,
    }
} = config;

// The User Context 
const UserContext = React.createContext(undefined);

// The Polling Frequency
const POLL_FREQUENCY = 5000;

const DEFAULT_STATE = {
    verified                    : false, 
    emailConfirmationLastSent   : undefined, 
    metadata                    : {}, 
    geolocation                 : {
        countryCode : undefined,
        stateCode   : undefined,
        city        : undefined,
        lat         : undefined, 
        lng         : undefined
    },
    roles                       : [], 
    isAdmin                     : false, 
    isSuspended                 : false,
    isSocial                    : undefined,
    emailMD5                    : undefined
};

// Voltage Provider
const UserProvider = ({children}) => {
    const location                              = useLocation();
    const {axios}                               = useAxios();
    const {alert}                               = useAlert();
    const {isAPIHealthy}                        = useAPIHealth();
    const { 
        error,
        isAuthenticated,
        isLoading,
        user,
        loginWithRedirect   : loginWithRedirectBase,
        loginWithPopup      : loginWithPopupBase,
        logout              : auth0Logout
    }                                           = useAuth0();
    const { token, expires, ready }             = useJWT();
    const { cancelToken }                       = useCancelToken();
    const [isUserCurrent,   setUserCurrent]     = React.useState(false);
    const [userId,          setUserId]          = React.useState(undefined);
    const [updatingServer,  setUpdatingServer]  = React.useState(false);
    const [blocked,         setBlocked]         = React.useState(false);
    const logout                                = React.useCallback( (options) => auth0Logout({ returnTo : rootUrl, ...options}), [auth0Logout]);
    const logoutWithDelay                       = React.useCallback( (delay) => (options) => setTimeout(() => logout(options), delay), [logout]);

    // Force Prompt
    const loginWithRedirect                     = React.useCallback((options = {}) => {
        setBlocked(false);
        return loginWithRedirectBase({
            prompt : 'login', 
            ...options, 
            appState: { 
                returnTo : `${location.pathname}${location.search}${location.hash}`, // https://community.auth0.com/t/redirect-after-login-page/63011
                ...options?.appState
            }
        })
}, [loginWithRedirectBase, location])

    // Force Prompt
    const loginWithPopup                        = React.useCallback((options = {}, config = {}) => {
        setBlocked(false);
        loginWithPopupBase({
            prompt : 'login', 
            ...options
        }, {
            ...config
        })
    }, [loginWithPopupBase])
    
    // State
    const [state, setState] = React.useState(DEFAULT_STATE);

    const isReadyToPoll = React.useMemo(() => (
        Boolean(isAuthenticated && user && token && ready && isAPIHealthy)
    ), [isAPIHealthy, isAuthenticated, ready, token, user]);

    // Function to send email confirmation request
    const sendEmailResetRequest = React.useCallback( () => new Promise((resolve,reject)=>{
        if(isAuthenticated && isAPIHealthy){
            axios.post('/api/auth/management/sendPasswordReset',{},{cancelToken})
                .then(response => {
                    resolve(response)
                })
                .catch(FaRegObjectUngroup)
        }else{
            reject(new Error('User is not authenticated'));
        }
    }),[axios, cancelToken, isAPIHealthy, isAuthenticated ])

    // Function to resend email confirmation
    const resendEmailConfirmation = React.useCallback( () => new Promise((resolve,reject)=>{
        if(isAuthenticated && isAPIHealthy){
            setState(prev => ({...prev, emailConfirmationLastSent : Date.now()})) // Assume it proceeds
            axios.post('/api/auth/management/sendEmailVerification', {}, {cancelToken})
                .then(({data}) => {
                    resolve(data);
                })
                .catch(err => {
                    setState(prev => ({...prev, emailConfirmationLastSent : undefined}))
                    reject(err);
                });
        }else{
            reject(new Error('User is not authenticated'));
        }
    }),[axios, cancelToken, isAPIHealthy, isAuthenticated])

    // Check Verified
    React.useEffect(() => {
        setState(prev => ({
            ...prev, 
            verified : Boolean(isAuthenticated && (user || {}).email_verified)
        }));
    },[isAuthenticated, user])

    // TO MD5
    React.useEffect(() => {
        const toMD5 = email => md5((email || '').trim().toLowerCase())
        const emailMD5 = isAuthenticated 
            ? toMD5((user || {}).email) 
            : undefined;
        setState(prev => ({...prev, emailMD5}));
    },[isAuthenticated, user])

    // Funciton to update user
    const updateUser = React.useCallback( () => new Promise((resolve,reject) => {
        const {nickname, email, email_verified, family_name = nickname, given_name = nickname, sub: openid, picture} = user;
        setBlocked(false);
        setUpdatingServer(true);
        axios.post( '/api/auth/update', { openid, email, email_verified, family_name, given_name, picture } )
            .then(({data : { blocked = false, deleted = false}} = {}) => {
                setBlocked(blocked || deleted);
                setUserCurrent(true);
                resolve(true);
            })
            .catch(err => {
                setUserCurrent(false)
                alert(err.message,'error');
                setTimeout(logout, 3000);
                reject(err)
            })
            .finally(() => {
                setUpdatingServer(false);
            })

    }),[alert, axios, logout, user])

    // Update user
    React.useEffect(() => {
        if(isReadyToPoll){
            updateUser().catch(console.error);
            return () => {
                setUserCurrent(false);
            }
        }else{
            setUserCurrent(false);
        }
    },[updateUser, isReadyToPoll])

    // Poll for user update if ready, and not currently updating
    React.useEffect(() => {
        if(isReadyToPoll && !isUserCurrent && !updatingServer){
            const interval = setInterval( async () => {
                try {
                    await updateUser();    
                } catch (err) {
                    console.error(err);
                }
            }, POLL_FREQUENCY);
            return () => {
                clearInterval(interval);
            }
        }
    },[isReadyToPoll, updateUser, isUserCurrent, updatingServer])

    // If user is current, post to logged in
    React.useEffect(() => {
        if(isReadyToPoll && isUserCurrent && isAPIHealthy){
            setBlocked(false);
            axios.post('/api/auth/login', {}, {cancelToken})
                .then(({user : { _id, blocked = false, deleted = false}}) => {
                    setUserId(_id);
                    setBlocked(blocked || deleted);
                })
                .catch(err => {
                    if(err?.isCancel) 
                        return;
                    alert(err.message, 'error');
                    setTimeout(logout, 3000);
                    setUserId(undefined);
                });

            return () => {
                setUserId(undefined);
            }
        }
    },[alert, axios, cancelToken, isAPIHealthy, isReadyToPoll, isUserCurrent, logout])

    // If user is not authenticated, post to logout
    React.useEffect(() => {
        if(!isAuthenticated && isAPIHealthy){
            axios.post('/api/auth/logout', {}, { cancelToken })
                .catch(console.error);
        }
    },[axios, isAuthenticated, isAPIHealthy, cancelToken])

    // Authentication Error
    React.useEffect(() => {
        if(error){
            alert("Authentication Error: " + error?.message, 'error');
        }
    },[alert, error]);

    // Logout if blocked
    React.useEffect(() => {
        if(isAuthenticated && blocked){
            alert("User Blocked, Logginhg Out", 'error');
            const timeout = setTimeout(()=> {
                logout();
            }, 2500);
            return () => {
                clearTimeout(timeout);
            }
        }
    },[alert, blocked, isAuthenticated, logout])

    // Update 
    React.useEffect(() => {

        // Extract the roles
        const metadata      = isAuthenticated ? ((user || {})[`${NAMESPACE}/metadata`] || {}) : {};
        const roles         = isAuthenticated ? (metadata?.roles || []) : []
        const isAdmin       = roles.includes('admin');
        const isSuspended   = roles.includes('suspended');

        // Check if user is a social user or not
        const openid        = (user || {}).sub;
        const isSocial      = typeof openid === 'string' 
                ? !openid.startsWith('auth0') 
                : false;

        setState(prev => ({
            ...prev,
            metadata,
            roles,
            isAdmin,
            isSuspended,
            isSocial
        }))

    },[isAuthenticated, user])

    // Resolve geolocation from metadata
    React.useEffect(() => {

        if(isAuthenticated && ready && state.metadata){

            // Extract the geo data from user auth metadata
            let {geoip = {}} = state.metadata || {};

            // Function to clear the geoip data
            const eraseGeoIP = () => (
                setState(prev => ({
                    ...prev,
                    geolocation : {
                        countryCode     : undefined, 
                        stateCode       : undefined, 
                        city            : undefined, 
                        lat             : undefined, 
                        lng             : undefined
                    }
                }))
            );

            // if not an object, then destroy and return 
            if(typeof geoip !== 'object') {
                return eraseGeoIP();
            }

            // Deconstruct
            let countryCode = geoip?.country_code   || geoip?.countryCode,
                city        = geoip?.city_name      || geoip?.cityName || geoip?.city,
                stateCode   = geoip?.state_code     || geoip?.stateCode || geoip?.subdivisionCode, // Not provided by google, not sure about facebook etc..
                lat         = geoip?.latitude       || geoip?.lat,
                lng         = geoip?.longitude      || geoip?.lng || geoip?.lon


            // Get cities for country
            if(!stateCode && countryCode && city){

                axios.get(`/api/user/address/lookup/citiesOfCountry/${countryCode}`, {cancelToken})
                    .then(({data = []}) => data)
                    .then(cities => {
                        if(Array.isArray(cities) && cities.length){
                            let stateCodes = cities.filter(item => item.name === city);
                            if(stateCodes.length === 1)
                                stateCode = stateCodes[0].stateCode;
                            // Update the state
                            setState(prev => ({
                                ...prev,
                                geolocation : { countryCode, stateCode, city, lat, lng }
                            }));
                        }
                    })
                    .catch(err => {
                        //
                    })
                    
            }else{
                // Update the state
                setState(prev => ({
                    ...prev,
                    geolocation : {countryCode, stateCode, city, lat, lng}
                }));
            }

            // Cleanup
            return ()=>{
                eraseGeoIP();
            }
        }
    },[axios, cancelToken, isAuthenticated, ready, state.metadata])

    /*
    React.useEffect(() => {
        if(isAuthenticated && user?.blocked)
            logout();
    },[isAuthenticated, logout, user?.blocked])
    */

    const canPurchase = React.useMemo(() => (
        [
            isAuthenticated,  
            state?.verified, 
            ! (state?.isSuspended || false)
        ].every(Boolean)
    ), [isAuthenticated, state.isSuspended, state.verified])

    // Context values
    const value = {
        error,
        ready,
        isLoading,
        user,
        userId,
        token,
        expires,
        isUserCurrent,
        canPurchase,
        updatingServer,
        isAuthenticated             : isUserCurrent && isAuthenticated && ready,
        isValid                     : isUserCurrent && isAuthenticated && ready && !state.isSuspended,
        verified                    : state.verified,
        metadata                    : state.metadata,
        geolocation                 : state.geolocation,
        roles                       : state.roles,
        isAdmin                     : state.isAdmin,
        isSuspended                 : state.isSuspended,
        isSocial                    : state.isSocial,
        emailConfirmationLastSent   : state.emailConfirmationLastSent,
        emailMD5                    : state.emailMD5,
        sendEmailResetRequest,
        resendEmailConfirmation,
        loginWithRedirect,
        loginWithPopup,
        logout,
        logoutWithDelay
    };

    // console.log(value)

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    )
}

// Voltage Consumer
const UserConsumer = ({children}) => {
    return (
        <UserContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('UserConsumer must be used within UserProvider');
                }
                return children(context)
            }}
        </UserContext.Consumer>
    )
}

// useVoltage Hook
const useUser = () => {
    const context = React.useContext(UserContext);
    if(context === undefined)
        throw new Error('useUser must be used within UserProvider');
    return context;
}

export {
    UserProvider,
    UserConsumer,
    useUser
}
