/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Review
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       4th December 2021

*******************************************************************************************/
import React                    from 'react';
import {isEqual}                from 'lodash';
import moment                   from 'moment';
import {titleCase}              from 'title-case';
import config                   from '../config';
import { 
    styled,
    useTheme, 
    useMediaQuery, 
    lighten, 
    Box, 
    Grid, 
    Typography, 
    alpha 
}                               from '@mui/material';
import EditIcon                 from '@mui/icons-material/Edit';
import {
    RootContainer,
    Gravatar,
    StarRating,
    FormAlert as Alert,
    ObjectId,
    PublicChip,
    PrivateChip,
    RefreshIcon,
    Button,
    Like,
    JSONViewer,
    PublicUserName
}                               from 'components';
import {
    useLocale, 
    useUser,
    useNetwork
}                               from 'contexts';
import useSize                  from 'hooks/useSize';

const STAR_STEPS = config.reviews.scoreMax;
const STAR_TOPICS = [
    {key:'quality',         label:'Quality'},
    {key:'relevance',       label:'Relevance'},
    {key:'asDescribed',     label:'As Described'},
    {key:'valueForMoney',   label:'Value'}
];

const noop      = () => {}

const {forcePublicReview = false} = config.reviews;

const CensoredContainer = styled(Box,{
    shouldForwardProp : prop => prop !== 'censored'
})(({theme, censored = false}) => ({
    padding     : theme.spacing(2),
    ...(censored && {
        background  : alpha(theme.palette.error.dark,0.5),
    })
}));

const ContentContainer = styled(Box)(({theme}) => ({
    '& > * + *' : {
        marginTop : theme.spacing(2)
    }
}));

const ReviewDate = styled(Typography)({
    fontSize    : 10,
    fontStyle   : 'italic',
    textAlign   : "right"
});

const Headers = styled(Typography)(({theme}) => ({
    color : theme.palette.mode === 'light' 
        ? theme.palette.primary.dark
        : lighten(theme.palette.primary.light,0.5),
    [theme.breakpoints.down('md')] : {
            fontSize : '0.8rem'
    },
    [theme.breakpoints.down('sm')] : {
        fontSize : '0.75rem'
    }
}));

const Tagline = styled(Typography)({
    fontWeight  : 400,
    fontSize    : '1.3rem',
    textOverflow:'ellipsis',
    whiteSpace  : 'nowrap',
    overflow    :'hidden'
});

const TaglineNoEllipsis = styled(Typography)({
    fontWeight  : 400,
    fontSize    : '1.3rem',
});

const GravatarContainer = styled(Box)({
    margin : 'auto'
});

const QuoteStart = styled(Box)(({theme}) => ({
    fontSize    : 400,
    color       : alpha(theme.palette.divider,0.05),
    fontFamily  : 'Georgia, "Times New Roman", Times, serif', 
    lineHeight  : 0,
    width       : 'fit-content',
    height      : 0,
    userSelect  : 'none', /* Standard */
    position    : 'absolute',
    top         : 0,
    left        : 0,
    zIndex      : 0
}));

const SlimBorder = styled(Box)(({theme}) => ({
    borderBottom    : `1px solid ${theme.palette.divider}`,
    width           : '30%',
    marginBottom    : theme.spacing(1),
    marginTop       : theme.spacing(1),
}));

const ChipsReview = ({review, size="small" ,...props}) => (
    <Box ml={0.5}>
        {
            review?.public 
                ? <PublicChip   label = "Public"    size = {size} {...props} />
                : <PrivateChip  label = "PRIVATE"   size = {size} {...props} />
        }
    </Box>
);

const OwnerAlert = ({review, onEdit=noop, ...props}) => {
    
    const theme                         = useTheme();
    const { userId, isAuthenticated }   = useUser();
    const isOwner                       = isAuthenticated && userId && [review?.user, review?.user?._id].includes(userId);
    // const hasLibrary                    = Boolean(review?.library || review?.delivery?.library);
    const { 
        public : isPublic, 
        censored
    }                                   = review;
    const severity                      = React.useMemo(() => isPublic ? "info" : "error", [isPublic]);
    const color                         = React.useMemo(() => theme.palette[severity].light, [theme, severity]);
    const border                        = React.useMemo(() => `1px solid ${color}`, [color]);
    
    if(!isOwner || censored)
        return null;

    return (
        <Box>
            <Alert severity = {severity} style = {{border}}>
                <Box display="flex">
                    <Box flexGrow={1}>
                        <Typography variant="body2">
                            <strong>This is YOUR review for delivery <ObjectId value={review?.delivery?._id || review?.delivery} /></strong>
                        </Typography>
                    </Box>
                    {false && onEdit !== noop && 
                        <Box flexShrink={1} style={{color,borderColor:color,margin:'auto',transform:'translatey(-4px)'}}>
                            <Button 
                                startIcon   = {<EditIcon/>} 
                                size        = "small"
                                variant     = "text" 
                                onClick     = {onEdit}
                                style       = {{color}}
                            >
                                Edit
                            </Button>
                        </Box>
                    }
                    <Box flexShrink={1} textAlign={'right'} pl={1} style={{margin:'auto', position:'relative',width:'inherit',transform:'translatey(-4px)'}}>            
                        <ChipsReview 
                            size    = "small"
                            review  = {review} 
                        />
                    </Box>
                </Box>
                <Box textAlign="justify">
                    <Typography variant="body2" gutterBottom>
                        Firstly, thankyou. Feedback from customers like yourself will greatly assist us to improve our products. 
                    </Typography>
                    {isPublic &&
                        <Typography variant="body2">
                            This review is <strong>public</strong>, which means your review may be used randomly on our product pages or elsewhere 
                            across this website where appropriate and relevant. {!forcePublicReview && <>If you do not want this to be visible to the general public, please 
                            edit this review and change the privacy settings.</>}
                        </Typography>
                    }
                    {!isPublic &&
                        <Typography variant="body2">
                            This review is <strong>private</strong> and therefore it will be kept confidential. However, if you change your mind, 
                            feel free to edit this review and change the privacy settings.
                        </Typography>
                    }
                </Box>
            </Alert>
        </Box>
    )
}

const Moderate = ({
    review                  = undefined,
    onChange : handleChange = noop,
    ...props
}) => {
    const {style, ...rest}      = props;
    const {
        userId, 
        isAuthenticated, 
        isAdmin
    }                           = useUser();
    const theme                 = useTheme();
    const {axios,cancelToken}   = useNetwork();
    const [working, setWorking] = React.useState(false);
    const censored              = review?.censored;
    const isOwner               = isAuthenticated && userId && [review?.user, review?.user?._id].includes(userId);
    const handleClick           = React.useCallback(() => {
        if(review?._id){
            setWorking(true);
            axios.post(`/api/admin/reviews/${review?._id}`, {censored : !censored}, {cancelToken})
                .then(({data}) => data)
                .then(handleChange)
                .catch(console.log)
                .finally(() => (
                    setWorking(false)
                ))
        }
    },[axios, cancelToken, censored, handleChange, review._id])
    if(!review || isOwner)
        return null;
    if(!isAuthenticated || !isAdmin )
        return null;
    return (
        <Box style={{color:censored ? theme.palette.success.dark : theme.palette.error.dark}}>
            <Button 
                disabled    = {working} 
                startIcon   = {working 
                    ? <RefreshIcon loading={true}/> 
                    : null
                } 
                color       = "inherit"
                variant     = "text" 
                size        = "small" 
                style       = {{textTransform:'initial',...style}}
                {...rest}
                onClick     = {handleClick} 
            >
                {censored ? "Undo Moderation" : "Moderate"}
            </Button>
        </Box>
    )
}

const ReviewFrom = ({city,country}) => {
    if(!city && !country)
        return null;
    return (
        <small>
            <em>
                from {[city,country].filter(Boolean).join(', ')}
            </em>
        </small>
    )
}

export const Review = ({review : reviewIn, view=true, showChips=false, showModerate=true, showAlert = false, 
    onChange    : handleChange      = noop, 
    onLikeClick : handleLikeClick   = noop, 
    onEdit                          = noop, 
    ...props
}) => {
    
    const theme                             = useTheme();
    const {formatDateTime}                  = useLocale();
    // const {axios,cancelToken}               = useNetwork();
    const ref                               = React.useRef(null);
    const {height}                          = useSize(ref);
    const [review,      setReview]          = React.useState(reviewIn)
    // const [lastViewId,  setLastViewId]      = React.useState(undefined)
    const {
        tagline, 
        review : content = '',
        scoresAverage,
        // views,
        censored
    }                                       = React.useMemo(() => review, [review]);
    const paragraphs                        = React.useMemo(() => content.split('\n').filter(Boolean), [content]);

    React.useEffect(() => {
        if(!isEqual(review,reviewIn))
            setReview(reviewIn);
    },[review, reviewIn]);

    /*
    React.useEffect(() => {
        if(view && axios && review && review?._id && review?._id !== lastViewId){
            axios
                .post(`/api/product/review/${review._id}/view`, {}, {cancelToken})
                .catch(console.error)
                .finally(() => {
                    setLastViewId(review?._id)
                })
        }
    },[axios, cancelToken, lastViewId, review, view])
    */

    const {
        emailMD5    = undefined,
        metadata : {
            geoip : {
                city_name       : city          = undefined,
                cityName        : cityAlt       = undefined,
                country_name    : country       = undefined,
                countryName     : countryAlt    = undefined
            } = {}
        } = {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    } = React.useMemo(() => review?.user || {}, [JSON.stringify(review?.user)]);

    const lgUp          = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const smDown        = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <RootContainer id="review" {...props}>
            {
                showAlert && 
                <OwnerAlert review={review} onEdit={onEdit}/>
            }
            <CensoredContainer censored={censored} display="flex">
                {lgUp &&
                    <Box flexShrink={1} pr={2}>
                        <GravatarContainer>
                            <Gravatar emailMD5={emailMD5} size={48}/>
                        </GravatarContainer>
                    </Box>
                }
                <Box flexGrow={1} style={{position:'relative'}}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Box>
                                {
                                    false && 
                                    <Box>
                                        <JSONViewer src={review} />
                                    </Box>
                                }
                                <Box display="flex">
                                    {
                                        !lgUp && 
                                        <Box flexShrink={1} pr={1}>
                                            <GravatarContainer>
                                                <Gravatar emailMD5={emailMD5} size={height - parseInt(theme.spacing(1))}/>
                                            </GravatarContainer>
                                        </Box>
                                    }
                                    <Box ref={ref} flexGrow={1}>
                                        
                                        <Box display={'flex'} style={{width:'100%'}}>
                                            <Box>
                                                <Headers gutterBottom >
                                                    <PublicUserName user={review?.user} sx={{fontWeight:400}}/> <ReviewFrom city={city || cityAlt} country={country || countryAlt}/> {censored ? <strong>(MODERATED)</strong> : null}
                                                </Headers>
                                            </Box>
                                            <Box flexGrow={1}/>
                                            {
                                                !smDown && review?.public && 
                                                <Box flexShrink={1} textAlign="right" sx={{mx:1}}> 
                                                    <Like reference={review?._id} size="small" onClick={handleLikeClick}/>
                                                </Box>
                                            }
                                            <Box flexShrink={1} textAlign="right"> 
                                                <Headers gutterBottom >    
                                                    <strong>{moment.duration(moment().diff(moment(review.createdAt))).humanize()} ago</strong>
                                                </Headers>
                                            </Box>
                                        </Box>
                                        
                                        <Box display="flex" flexWrap="wrap" style={{position:'relative', width:'100%'}}>
                                            {
                                                !smDown &&
                                                <Box flexGrow={1} sx={{position:'relative', mr:1, overflow: 'hidden'}}>
                                                    <Tagline 
                                                        component="div" 
                                                        sx={{
                                                            // width           : '100%',
                                                            position        : 'absolute',
                                                            left            : 0,
                                                            righ            : 0,
                                                            top             : 0,
                                                            bottom          : 0,
                                                            display         : 'flex',
                                                            alignItems      : 'left',
                                                            overflow        : 'hidden',
                                                            whiteSpace      : 'nowrap',
                                                            textOverflow    : 'ellipsis'
                                                        }}
                                                    >
                                                        {tagline}
                                                    </Tagline>
                                                </Box>
                                            }
                                            {
                                                smDown && 
                                                <Box flexGrow={1} />
                                            }
                                            {
                                                smDown && review?.public && 
                                                <Box flexShrink={1} textAlign="right" sx={{mx:1}}> 
                                                    <Like reference={review?._id} size="small" onClick={handleLikeClick}/>
                                                </Box>
                                            }
                                            <Box>
                                                <StarRating 
                                                    steps       = {STAR_STEPS}
                                                    align       = "center" 
                                                    size        = {25} 
                                                    disabled    = {true} 
                                                    showTips    = {false} 
                                                    value       = {review.scoresAverage}
                                                /> 
                                            </Box>
                                            {
                                                smDown && 
                                                <Box flexGrow={1} />
                                            }
                                            {
                                                false &&
                                                <Box flexGrow={1} style={{margin:'auto',marginBottom:2}} pl={1} pr={1}>
                                                    <small><em>{!isNaN(scoresAverage) ? `${scoresAverage.toFixed(1)} out of ${parseFloat(STAR_STEPS).toFixed(1)}` : null}</em></small>
                                                </Box>
                                            }
                                        </Box>
                                    </Box>
                                </Box>

                                <ContentContainer style={{position:'relative'}}>
                                    {
                                        smDown && 
                                        <Box>
                                            <TaglineNoEllipsis>
                                                {tagline}
                                            </TaglineNoEllipsis>
                                        </Box>
                                    }
                                    <Box style={{zIndex:1}}>
                                        {
                                            paragraphs.map((para,ix,arr) => (
                                            <Typography variant="body2" paragraph key={ix} style={{fontStyle:'italic',textAlign:'justify'}}>
                                                {para} 
                                            </Typography>
                                        ))}
                                    </Box>
                                    
                                    {false && 
                                        <QuoteStart>
                                            {'\u201C'}
                                        </QuoteStart>
                                    }
                                </ContentContainer>
                            </Box>
                            <SlimBorder style={{marginRight:'auto'}}/>
                        </Grid>

                        {
                            STAR_TOPICS.map(({key,label},ix)=>(
                                <Grid key={ix} item xs={6} sm={6} md={3} lg={3}>
                                    <StarRating 
                                        steps       = {STAR_STEPS}
                                        align       = "center" 
                                        size        = {15} 
                                        label       = {titleCase(label)} 
                                        disabled    = {true} 
                                        showTips    = {false} 
                                        value       = {review.scores[key]}
                                    />
                                </Grid>
                            ))
                        }
                        <Grid item xs={12}>
                            <SlimBorder style = {{ marginLeft : 'auto' }}/>
                            <Box display="flex">
                                <Box flexGrow={1} textAlign="right" style={{margin:'auto'}}>
                                    {showModerate &&
                                        <Moderate review={review} onChange={handleChange}/>
                                    }
                                </Box>
                                <Box flexShrink={1} style={{margin:'auto'}}>
                                    <ReviewDate>
                                        Review Submitted: {formatDateTime(moment(review.updatedAt))}{/*, Viewed {views + 1}x times*/}
                                    </ReviewDate>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </CensoredContainer>
        </RootContainer>
    );
}

export default Review;