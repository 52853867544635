/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Purchase Alert
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024

*******************************************************************************************/
import React                        from 'react';
import moment                       from 'moment';
import {
    Alert,
    Box,
    Typography
}                                   from '@mui/material';
import TollIcon                     from '@mui/icons-material/Toll';
import CheckCircleOutlineIcon       from '@mui/icons-material/CheckCircleOutline';
import { 
    DraggableDialog,
    ConfirmationButton,
}                                   from 'components';
import { withTranslation }          from 'components/hoc';
import { useAccountCredits }        from 'contexts';

const noop = () => {};

export const ExamplePurchaseAlert = withTranslation(({
    t,
    archiveId                   = undefined,
    durationPaid                = 0,
    durationFree                = 0,
    price                       = 0,
    severity                    = "warning",
    onQuery : handleQuery       = noop,
}) => {
    const { 
        balance, 
        purchaseArchive,
        CREDITS_NAME,
        working : accountBalanceWorking,
    }                               = useAccountCredits();
    const [open, setOpen]           = React.useState(false);
    const handleOpen                = React.useCallback(() => setOpen(true),    [])
    const handleClose               = React.useCallback(() => setOpen(false),   [])

    // Purchase and refresh
    const handlePurchase            = React.useCallback( () => {
        purchaseArchive(archiveId)
            .then(handleOpen)
            .catch(console.error)
    }, [archiveId, handleOpen, purchaseArchive]);

    // close amd passively query
    const handleCloseAndRefresh     = React.useCallback(() => {
        handleClose();
        handleQuery(true);
    }, [handleClose, handleQuery])

    return (
        <React.Fragment>
            <Alert 
                severity    = { severity }
                action      = {
                    <Box sx={{my:'auto'}}>
                        <ConfirmationButton 
                            onClick             = { handlePurchase }
                            disabled            = { accountBalanceWorking || open } 
                            color               = { severity } 
                            size                = "small" 
                            variant             = "contained" 
                            startIcon           = { <TollIcon /> } 
                            sx                  = { { 
                                whiteSpace  : 'nowrap', 
                                minWidth    : 125 
                            } } 
                            confirmationText    = { t('common.confirm') }
                            requireConfirmation = { false }
                        >
                            {false && t('components.example.useCC', { price })}
                            Get Analysis
                        </ConfirmationButton>
                    </Box>
                }
            >
                <Typography variant="body2" fontWeight={600}>
                    {
                        t('components.example.previewOpenedAdditionalContent', {
                            duration    : moment.duration((durationPaid - durationFree),'seconds').format('h [hours] m [minutes]') ,
                            price       : Number(price).toLocaleString('en-US'),
                            balance     : Number(balance).toLocaleString('en-US'),
                            creditsName : CREDITS_NAME
                        })
                    }
                </Typography>
            </Alert>

            <DraggableDialog
                open                = {open}
                title               = {t('common.success')}
                onClose             = {handleCloseAndRefresh}
                onOk                = {handleCloseAndRefresh}
                showButtonsCancel   = {false}
                maxWidth            = "xs"
                fullWidth           = {true}
            >
                <Box display="flex">
                    <Box mr={2}>
                        <CheckCircleOutlineIcon color="success" sx={{fontSize:100}}/>
                    </Box>
                    <Box sx={{my:'auto'}}>
                        <Typography variant="h5" gutterBottom>
                            {t('common.congratulations')}
                        </Typography>
                        <Typography>
                            {t('components.example.openedAdditionalContent',{duration : moment.duration((durationPaid - durationFree),'seconds').format('h [hours] m [minutes]') })}
                        </Typography>
                    </Box>
                </Box>
            </DraggableDialog>
        </React.Fragment>
    )
})

export default ExamplePurchaseAlert;