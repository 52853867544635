/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
AstroVenusian Application -- Axios
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd December 2020

*******************************************************************************************/
import React                            from 'react';
import {omit}                           from 'lodash';
import axiosBase,{isCancel}             from 'axios';
import { FORM_ERROR }                   from 'final-form';

// Defautl Axios
const axios         = axiosBase.create();

// Expose CancelToken and isCancel Methods
axios.CancelToken   = axiosBase.CancelToken;
axios.isCancel      = axiosBase.isCancel;

const scrubErrors = (errors, message) => {
    if(typeof errors !== 'object')
        throw new Error('errors must be an object');

    // Add the message to the FORM_ERROR item
    errors[FORM_ERROR] = errors[FORM_ERROR] || errors['FORM_ERROR'] || message;

    // Remove vanilla 'FORM_ERROR', if necessary and return
    return FORM_ERROR !== 'FORM_ERROR' 
        ? omit(errors, 'FORM_ERROR') 
        : errors;
}

const handleResponse = async (response) => {
    if (!response || typeof response !== 'object') {
        const status    = 500;
        const message   = "Invalid response object";
        const errors    = scrubErrors({}, message);
        return Promise.reject({
            raw         : response,
            status,
            success     : false,
            statusCode  : status,
            message,
            errors
        });
    }

    const { data } = response;

    // Default values for success, status, message, and errors
    const status    = parseInt(response?.status || response?.statusCode) || 500;
    const message   = data?.message || response?.statusText;

    // 4XX series (or missing status) are ERROR results
    if (isNaN(status) || status >= 400) {
        const errorMessage  = message || `Error, Status ${status}`;
        const errors        = scrubErrors(data?.errors || {}, errorMessage);
        return Promise.reject({
            raw         : response,
            status,
            success     : false,
            statusCode  : status,
            message     : errorMessage,
            errors
        });
    }

    // 2XX series are OK results
    const successMessage = message || `Request Successful, Status ${status}`;
    return Promise.resolve({
        raw         : response,
        ...data,
        status,
        success     : true,
        statusCode  : status,
        message     : successMessage,
        errors      : {}
    });
}

// Interceptor to handle the response
axios.interceptors.response.use(
    handleResponse, // Success handler
    async (error) => {
        
        // Handle cancellation errors specifically
        if (isCancel(error)) {
            const message = 'Request cancelled';
            return Promise.reject({
                message,
                errors: {
                    [FORM_ERROR]: message,
                },
                isCancel    : true,
                status      : 0,
                statusCode  : 0
            });
        }

        // Handle errors when error.response is available (server errors)
        if (error.response) {
            return handleResponse(error.response);
        }

        // If error.response is not available, fallback to a general error
        const message = 'An error occurred. Please try again.';
        return Promise.reject({
            message,
            errors: {
                [FORM_ERROR]: message,
            },
            isCancel : false,
            status      : 500,
            statusCode  : 500,
        });
    }
);

const useAxios = () => {
    
    const axiosRef              = React.useRef(axios);
    const [token, setAuthToken] = React.useState(undefined);

    /**
     * Function to set the auth token
     * @param {token} the bearer token
     */
    const setToken = React.useCallback( (token) => {
        axiosRef.current.defaults.headers.common['Authorization'] = `Bearer ${token || "null"}`;
        setAuthToken(token);
    }, [])

    // Revoke the Token
    const revokeToken =  React.useCallback( () => (
        setToken(undefined)
    ), [setToken]);

    // Set Empty if not set yet
    React.useEffect(() => {
        if(!axiosRef.current.defaults.headers.common['Authorization'])
            setToken(undefined);
    }, [setToken])

    return {
        axios: axiosRef.current,
        token,
        setToken,
        revokeToken
    };
}

// Named exports
export {
    axios,
    useAxios
}

// Default export
export default useAxios;