/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Main container
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th November 2020

*******************************************************************************************/

import React                            from 'react';
import { styled, useTheme, Box }        from '@mui/material';
import { usePageHeight }                from 'contexts';
import { useLocation }                  from 'react-router-dom';
import useSize                          from 'hooks/useSize';
import { Seo }                          from 'components';

const Header                    = React.lazy(() => import('components/header/Header'));
const Footer                    = React.lazy(() => import('components/footer/Footer'));
const Alerts                    = React.lazy(() => import('components/Alerts'));
const AlertSnackbar             = React.lazy(() => import('components/AlertSnackbar'));
const ServerBroadcasts          = React.lazy(() => import('components/ServerBroadcasts'));

const Root = styled(Box)(({theme}) => ({
    width           : '100%',
    background      : theme.palette.background.default,
    minHeight       : '100vh',
    display         : "flex",
    flexDirection   : "column",
    position        : "relative"
}))

export const Main = ({ 
    children, 
    ...props
}) => {

    const {setHeight}                           = usePageHeight();
    const theme                                 = useTheme();
    const location                              = useLocation();
    const refHeader                             = React.useRef(null);
    const refBody                               = React.useRef(null);
    const refFooter                             = React.useRef(null);
    
    const {height:heightHeader}                 = useSize(refHeader);
    // const {height:heightBody}                   = useSize(refBody);
    // const {height:heightFooter}                 = useSize(refFooter);

    // Scroll to top if path changes
    React.useEffect(() => (
        window.scrollTo(0, 0)
    ), [location.pathname]);

    React.useLayoutEffect(() => {
        setHeight(`calc(100vh - ${heightHeader}px - ${theme.spacing(2)})`);
    },[heightHeader, setHeight, theme])

    return (
        <Root id="main">
            <Seo />
            <Box id="header" ref={refHeader}>
                <Header />
            </Box>
            <Box ref={refBody} id="body" flexGrow={1} sx={{bgcolor:'inherit',position:'relative'}}>
                <Alerts />
                <Box id="content">
                    { children }
                </Box>
            </Box>
            <Box id="footer" ref={refFooter} style={{zIndex:2}}>
                <Footer />
            </Box>
            <AlertSnackbar />
            <ServerBroadcasts />
        </Root>
    )
}

export default Main;
