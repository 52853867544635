
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Delivery Sheet
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       28th December 2024

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import {Box}                            from '@mui/material';
import { 
    Logo,
    NotFound, 
    SkeletonFancy, 
    Title, 
    Typography,
    OrderDeliveryProgress
}                                       from 'components';
import { 
    useUser,
    useNetwork, 
    TrackIdProvider
}                                       from 'contexts';
import { useCancelToken }               from 'hooks';

export const DeliverySheet = ({
    children, 
    compact             = false, 
    showDeliveryLink    = false,
    showOrderLink       = true,
    deliveryId
} = {}) => {

    const {isNetworkReady}          = useNetwork();
    const { 
        axios,  
        socketUsers : socket 
    }                               = useNetwork()
    const {isAuthenticated}         = useUser();
    const {cancelToken }            = useCancelToken();
    const [delivery, setDelivery]   = React.useState(undefined);
    const [queried,  setQueried]    = React.useState(undefined);

    // Query API
    const queryAPI = React.useCallback(() => {
        if(deliveryId && isNetworkReady && isAuthenticated){
            setQueried(undefined);
            axios.get(`/api/user/delivery/${deliveryId}`,{cancelToken})
                .then(({data}) => data)
                .then(setDelivery)
                .catch(console.error)
                .finally(() => {
                    setQueried(moment());
                })
        }else{
            setQueried(undefined);
            setDelivery(undefined);
        }
    },[axios, cancelToken, deliveryId, isAuthenticated, isNetworkReady])

    // Query on Mount
    React.useEffect(() => { 
        queryAPI(); 
    },[queryAPI]);

    // Refresh delivery on SocketIO Instruction
    React.useEffect(()=>{
        if(socket && queried){
            const refreshOrderById = (orderId) => {
                if(orderId && delivery?.order?._id === orderId)
                    queryAPI();
            };
            const refreshDeliveryById = (deliveryId) => {
                if(deliveryId && delivery?._id === deliveryId)
                    queryAPI();
            }
            socket.on('refresh_order',      refreshOrderById);
            socket.on('refresh_delivery',   refreshDeliveryById);       // Currently not implemented
            return () => {
                socket.off('refresh_order',     refreshOrderById);
                socket.off('refresh_delivery',  refreshDeliveryById);   // Currently not implemented
            }
        }
    },[delivery?._id, delivery?.order?._id, queried, queryAPI, socket])

    return (    
        <TrackIdProvider>
            { 
                !queried && 
                <Box sx={{width:'100%', aspectRatio:'2'}}>
                    <SkeletonFancy />
                </Box>
            }
            {
                queried &&
                <React.Fragment>
                    {
                        (!deliveryId || !delivery) && 
                        <NotFound />
                    }
                    {
                        deliveryId && delivery &&    
                        <Box>

                            {
                                !compact && 
                                <Box display="flex">
                                    <Box>
                                        <Logo />
                                    </Box>
                                    <Box flexGrow={1} sx={{ml:2}}>
                                        <Title>
                                            Delivery Sheet
                                        </Title>
                                        <Typography variant="body2" color="textSecondary" paragraph>
                                            Delivery {deliveryId}
                                        </Typography>
                                    </Box>
                                </Box>
                            }

                            <Box>
                                <OrderDeliveryProgress
                                    delivery            = {delivery}
                                    showDeliveryLink    = {showDeliveryLink}
                                    showOrderLink       = {showOrderLink}
                                />
                            </Box>
                            {
                                children && 
                                <Box sx={{mt:2}}>
                                    {children}
                                </Box>
                            }
                        </Box>
                    }
                </React.Fragment>
            }                
        </TrackIdProvider>
    )
}

export default DeliverySheet;