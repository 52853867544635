/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Share Page
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th July 2022

*******************************************************************************************/
import React                            from 'react';
import { styled, Box, TextField }       from '@mui/material';
import { useHistory }                   from 'react-router-dom';
import { SocialIcon }                   from 'react-social-icons';
import { CheckBoxWithLabel }            from 'components';
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    PinterestShareButton,
    RedditShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    XIcon
    /*
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PocketShareButton,
    TumblrShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
    */
}                                       from "react-share";
import { useTranslation }               from "contexts";

const obj = {};

const Root = styled(Box,{
    shouldForwardProp : prop => prop !== 'width'
})(({theme, width = "100%"}) => ({
    width,
    '& > * + *' : {
        marginTop: theme.spacing(2)
    }
}))

const ButtonsContainer = styled(Box)(({theme}) => ({
    // margin : theme.spacing(0.25),
    '& > *' : {
        margin  : theme.spacing(0.15)
    }
}))

//
export const SocialSharePage = ({
    disabled            = false,
    url : urlIn         = undefined, 
    width               ='100%', 
    iconSize            = 32, 
    textFieldProps      = obj, 
    showUrl             = false, 
    stripParms          = true,
    stripParmsReason    = "Strip Parms",
    ...props
}) => {
    
    const [t]           = useTranslation();
    const history       = useHistory();
    const [url, setUrl] = React.useState(urlIn || window.location.href);
    const args          = React.useMemo(() => ({style:{ height: iconSize, width: iconSize}}), [iconSize]);

    const title         = React.useMemo(() => t('application.title'), [t]);
    const tagline       = React.useMemo(() => t('application.tagline'), [t]);

    const [removeParms, setRemoveParms] = React.useState(Boolean(stripParms));

    React.useEffect(() => {
        const {pathname, search, hash}  = history?.location;
        const shareableLink             = (
            [
                `${window.location.origin}${pathname}`,
                removeParms 
                    ? undefined
                    : `${search}${hash}` 
                    
            ].filter(Boolean).join('')
        )
        setUrl(urlIn || shareableLink)
    }, [urlIn, history?.location, removeParms]); 

    const handleChange = React.useCallback( (event) => {
        setRemoveParms(event.target.checked);
    }, []);

    const buttonArgs = React.useMemo(() => ({
        disabled,
        url,
        ...(disabled && {
            style : {
                cursor : 'default'
            }
        })
    }),[disabled, url])

    return (
        <Root width={width}>
            {
                showUrl &&
                <Box display="flex" style={{width:'100%'}}>
                    <Box flexGrow={1}>
                        <TextField 
                            fullWidth
                            value       = {url}
                            label       = "Address"
                            inputProps  = {{
                                readOnly    : true,
                                style       : { fontSize : '0.65rem' }
                            }}
                            {...textFieldProps}
                        />
                    </Box>
                    {
                        props.renderCopyButtons && 
                        <Box style={{margin:'auto'}}>
                            {props.renderCopyButtons()}
                        </Box>
                    }
                </Box>
            }

            <ButtonsContainer>
                {
                    !stripParms &&
                    <CheckBoxWithLabel
                        checked                 = { removeParms }
                        onChange                = { handleChange }
                        formControlLabelProps   = {{sx : { mr : 1}}}
                    >
                        {stripParmsReason}
                    </CheckBoxWithLabel>
                }
                <EmailShareButton {...buttonArgs} subject={`${title} - ${tagline}`}>
                    <SocialIcon {...args} network="email" />
                </EmailShareButton>
                <FacebookShareButton {...buttonArgs}>
                    <SocialIcon {...args} network="facebook" />
                </FacebookShareButton>
                <TwitterShareButton {...buttonArgs}>
                    <SocialIcon {...args} network={"x"}>
                        <XIcon {...args} round={true} />
                    </SocialIcon>
                    {
                        false && 
                        <SocialIcon {...args} network="twitter" /> 
                    }
                </TwitterShareButton>
                

                {
                    false && 
                    <PinterestShareButton {...buttonArgs}>
                        <SocialIcon {...args} network="pinterest" />
                    </PinterestShareButton>
                }
                
                <RedditShareButton {...buttonArgs}>
                    <SocialIcon {...args} network="reddit" />
                </RedditShareButton>
                <TelegramShareButton {...buttonArgs}>
                    <SocialIcon {...args} network="telegram" />
                </TelegramShareButton>
                <WhatsappShareButton {...buttonArgs}>
                    <SocialIcon {...args} network="whatsapp" />
                </WhatsappShareButton>
            </ButtonsContainer>
        </Root>
    )
};

export default SocialSharePage;