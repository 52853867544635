/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Page Alert and Actions
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       18th June 2021

*******************************************************************************************/
import React                            from 'react';
import { styled, Box }                  from '@mui/material';
import { Title, AlertMessageGroup }     from 'components';

const MIN_HEIGHT = 60;

const AlertContainer = styled(Box)(({theme}) => ({
    minHeight : MIN_HEIGHT,
    '& > * + *' : {
        marginLeft  : theme.spacing(2)
    }
}));

const AlertMessageContainer = styled(Box)(({theme}) => ({
    marginTop       : 'auto',
    marginBottom    : 'audo',
    position        : 'inline-block',
    transform       : `translate(-${theme.spacing(0.5)}, -${theme.spacing(0.5)})`,
    minHeight       : MIN_HEIGHT,
}));

const TitleContainer = styled(Box)({
    position        : 'inline-block',
    marginTop       : 'auto',
    marginBottom    : 'auto',
    minHeight       : MIN_HEIGHT,
});

const ActionsContainer = styled(Box)(({theme}) => ({
    display         : 'flex',
    flexDirection   : 'column',
    marginLeft      : theme.spacing(+1),
    marginRight     : theme.spacing(-1),
    minHeight       : MIN_HEIGHT
}));

const obj = {};

export const PageContainerHeader = ({
    title                   = undefined,
    titleSuffix             = undefined,
    messageSuccess          = undefined, 
    messageWarning          = undefined, 
    messageError            = undefined,
    alertMessageGroupProps  = obj,
    alertOverwriteTitle     = true,
    titleProps              = obj,
    render                  = undefined,
    ...props
}) => {
    
    const hasMessages = React.useMemo(() => (
        [
            messageSuccess, 
            messageWarning, 
            messageError
        ].some(Boolean)
    ), 
    [messageError, messageSuccess, messageWarning]
    )

    return (
        <Box display="flex" sx={{width:'100%',alignItems:'center'}}>
            <AlertContainer display="flex" flexGrow={1}>
                {
                    (!hasMessages || !alertOverwriteTitle) && title &&
                    <TitleContainer display="flex" sx={{my:'auto',flexDirection:{xs:'column',md:'row'}}}>
                        <Box mr={titleSuffix ? 1 : 0}>
                            <Title variant="h3" component="h1" {...titleProps}>
                                {title}
                            </Title>
                        </Box>
                        {
                            titleSuffix && 
                            <Box sx={{mt:'auto',mb:0.25,overflow:'hidden'}}>
                                <Title variant="h5" component="span" sx={{whiteSpace:'nowrap'}}>
                                    {titleSuffix}
                                </Title>
                            </Box>
                        }
                    </TitleContainer>
                }
                {
                    hasMessages && 
                    <AlertMessageContainer flexGrow={1}>
                        <AlertMessageGroup 
                            success = {messageSuccess} 
                            warning = {messageWarning} 
                            error   = {messageError} 
                            {...alertMessageGroupProps}
                        />
                    </AlertMessageContainer>
                }
            </AlertContainer>
            {
                render && 
                <ActionsContainer>
                    <Box flexGrow={1}/>
                        <Box>
                            {
                                typeof render === 'function' 
                                    ? render(props) 
                                    : render
                            }
                        </Box>
                    <Box flexGrow={1}/>
                </ActionsContainer>
            }
        </Box>
    )
};

export default PageContainerHeader