/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Like Hook
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th Feb 2023

*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';
import { 
    useNetwork, 
    useUser, 
    useAlert
}                               from 'contexts';
import { useCancelToken }       from 'hooks';

export const useLike = (reference) => {

    const { isNetworkReady, axios }     = useNetwork();
    const { isAuthenticated }           = useUser();
    const {cancelToken}                 = useCancelToken();
    const [working, setWorking]         = React.useState(false);
    const [data,    setData]            = React.useState(undefined);
    const [queried, setQueried]         = React.useState(undefined);
    const {alert}                       = useAlert();

    // Query the Likes for Reference ID
    const query = React.useCallback(() => new Promise((resolve,reject) => {
        if(!reference)
            return reject(new Error('reference is required'));
        if(!isNetworkReady)
            return reject(new Error('network not ready'));
        setWorking(true);
        axios.get(`/api/like/${reference}`, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(reject)
            .finally(() => {
                setQueried(moment());
                setWorking(false);
            })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }),[axios, cancelToken, isNetworkReady, reference, isAuthenticated])

    React.useEffect(() => {
        query()
            .then(setData)
            .catch(() => {
                setData(undefined)
            })
    },[query]);

    const remove = React.useCallback(() => new Promise((resolve,reject) => {
        console.log("removing");
        if(!isNetworkReady)
            return reject(new Error('network not ready'));
        if(!isAuthenticated)
            return reject(new Error('unauthorized'));
        setWorking(true);
        axios.delete(`/api/like/${reference}`, {cancelToken})
            .then(query)
            .then((data) => {
                setData(data);
                resolve(data);
            })
            .catch(err => {
                alert(err.message,'error');
                reject(err);
            })
            .finally(() => {
                setWorking(false)
            })
    }), [alert, axios, cancelToken, isAuthenticated, isNetworkReady, query, reference]);

    const like = React.useCallback(() => new Promise((resolve,reject) => {
        console.log("liking");
        if(!isNetworkReady)
            return reject(new Error('network not ready'));
        if(!isAuthenticated)
            return reject(new Error('unauthorized'));
        setWorking(true);
        axios.post(`/api/like/${reference}?value=true`, {value : true}, {cancelToken})
            .then(({data}) => {
                setData(data)
                resolve(data);
            })
            .catch(err => {
                alert(err.message,'error');
                reject(err);
            })
            .finally(() => {
                setWorking(false);
            })
    }), [alert, axios, cancelToken, isAuthenticated, isNetworkReady, reference]);

    const dislike = React.useCallback(() => new Promise((resolve,reject) => {
        console.log("disliking");
        if(!isNetworkReady)
            return reject(new Error('network not ready'));
        if(!isAuthenticated)
            return reject(new Error('unauthorized'));
        setWorking(true);
        axios.post(`/api/like/${reference}?value=false`, {value: false}, {cancelToken})
            .then(({data}) => {
                setData(data)
                resolve(data);
            })
            .catch(err => {
                alert(err.message,'error');
                reject(err);
            })
            .finally(() => {
                setWorking(false);
            })
    }), [alert, axios, cancelToken, isAuthenticated, isNetworkReady, reference])

    const value         = React.useMemo(() => data?.value, [data?.value])
    const isLiked       = React.useMemo(() => value === true, [value]);
    const isDisliked    = React.useMemo(() => value === false, [value]);

    return {
        remove,
        like,
        dislike,
        working,
        data,
        queried,
        value,
        isLiked,
        isDisliked
    }
}

export default useLike;