/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Moderation Form
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       26th January 2023

*******************************************************************************************/
import React                            from 'react';
import {
    useTheme,
    Box, 
    InputLabel
}                                       from '@mui/material';
import CheckIcon                        from '@mui/icons-material/Check';
import { Form, ColorChip}               from 'components';
import { useTranslation }               from 'contexts';
import { 
    TextField,
    showErrorOnChange   as showError,
}                                       from 'mui-rff';

const noop  = () => {}
const obj   = {};

const defaultFormData = {
    reason : undefined
}

export const ModerationForm = ({
    formData                = defaultFormData,
    disabled                = false,
    onSubmit : handleSubmit = noop,
    onCancel : handleCancel = noop,
    FormProps               = obj
}) => {
    const {t}       = useTranslation();
    const theme     = useTheme();

    // Validation Function
    const validate  = React.useCallback( (values) => {

        // Empty error object
        let errors = {};
        if(!values.reason){
            errors.values = errors.values || t('components.forms.moderationForm.required')
        }

        // Done
        return errors

    },[t])

    const presets = React.useMemo(() => ([
        'Against TOS','Flaming','Baiting','Offensive',                                          // Custom
        'Toxicity','Identity Attack','Severe Toxicity','Profanity','Threat','Insult','Spam',    // Perspective
        'Reported by User'                                                                      // For Future User Reported Feature
    ]),[]);

    // Initial Values
    const initialValues = React.useMemo(() => ({
        ...formData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [JSON.stringify(formData)]);

    return (
        <Form
            debug                   = {false}
            disabled                = {disabled}
            onSubmit                = {handleSubmit}
            onCancel                = {handleCancel}
            initialValues           = {initialValues}
            validate                = {validate}
            successMessage          = {t('components.forms.moderationForm.successMessage')}
            {...FormProps}
            changeRequiredToSubmit  = {true}
            disabledSubmit          = {false}
            disabledAfterSubmit     = {true}
            sticky                  = {false}
            showResetButton         = {true}
            resetOnSubmit           = {true}
            showObjectId            = {false}
            render                  = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Box>
                            <InputLabel shrink={true}>
                                {t('components.forms.moderationForm.presets')}
                            </InputLabel>
                            {
                                presets.map((preset,ix) => {
                                    const selected = values.reason === preset;
                                    return (
                                        <ColorChip 
                                            color   = {
                                                    selected 
                                                        ? 'warning' 
                                                        : theme.palette.mode === 'dark' 
                                                            ? 'secondary' 
                                                            : 'primary'
                                            } 
                                            size    = "small"
                                            key     = {ix} 
                                            label   = {preset} 
                                            onClick = {() => form.change('reason',preset)} 
                                            sx      = {{m:'1px'}}
                                            icon    = {selected ? <CheckIcon/> : null}
                                        />
                                    )
                                })
                            }
                        </Box>
                        <TextField 
                            multiline
                            label       = {t('components.forms.moderationForm.reason')}
                            name        = {'reason'}
                            disabled    = {disabled}
                            showError   = {showError}
                        />
                    </form>
                )
            }}
        />
    )
}

export default ModerationForm;