/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Orders Table
********************************************************************************************
List of orders in tabular form

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th November 2020

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import {
    styled,
    Box,
    Badge,
    alpha,
    useTheme,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow as TableRowMUI,
    Tooltip,
    Typography,
    useMediaQuery,
    Button
}                                       from '@mui/material';
import ErrorIcon                        from '@mui/icons-material/Error';
import CancelIcon                       from '@mui/icons-material/Cancel';
import AssignmentReturnIcon             from '@mui/icons-material/AssignmentReturn';
import LocalHotelIcon                   from '@mui/icons-material/LocalHotel';
import DoneAllIcon                      from '@mui/icons-material/DoneAll';
import FlightTakeoffIcon                from '@mui/icons-material/FlightTakeoff';
import FileCopyIcon                     from '@mui/icons-material/FileCopy';
import SupervisorAccountIcon            from '@mui/icons-material/SupervisorAccount';
import SearchIcon                       from '@mui/icons-material/Search';
import { useLocation, useHistory}       from 'react-router-dom'
import { 
    useLocale,
    useOrders,
    useUser
}                                       from 'contexts';
import { 
    Title, 
    JSONViewer,
    ObjectId,
    DraggableDialog,
    MakePayment,
    CountDown,
    CompletionBar,
    ItemsCount,
    Currency,
    ColorChip   
}                                       from 'components';
import { withTranslation }              from 'components/hoc';
import Order                            from './Order';
import {
    PayButton
}                                       from 'components/buttons';
import {
    Confirmation
}                                       from 'components/modals';
import {
    ViewIconButton,
    CancelIconButton
}                                       from 'components/iconButtons'
import {
    UserOrderLocation
}                                       from 'router/locations/Locations';
import { withClickHandler }             from 'hooks';

// ['PENDING', 'FAILED', 'PAID', 'FULL_REFUND', 'PARTIAL_REFUND'],
const statusMap = {
    'PENDING'           : {title: "Please Pay",             Icon: undefined},
    'EXPIRED'           : {title: "Expired",                Icon: LocalHotelIcon},
    'CANCELLED_USER'    : {title: "Cancelled by User",      Icon: CancelIcon},
    'CANCELLED_SYSTEM'  : {title: "Cancelled by Webmaster", Icon: CancelIcon},
    'FAILED'            : {title: "Payment Failed",         Icon: ErrorIcon},
    'PAID_FREE'         : {title: "Paid (Free)",            Icon: DoneAllIcon},
    'PAID_FULL'         : {title: "Paid",                   Icon: DoneAllIcon},
    'PAID_PARTIAL'      : {title: "Partially Paid",         Icon: undefined},
    'REFUND_FULL'       : {title: "Full Refund",            Icon: AssignmentReturnIcon},
    'REFUND_PARTIAL'    : {title: "Part Refund",            Icon: AssignmentReturnIcon}
}

const TableRow = styled(TableRowMUI,{
    shouldForwardProp : prop => !['highlighted','pulse','free','paid','cancelled','refunded','paymentRequired','expired','overdue'].includes(prop)
})(({theme,highlighted = false, pulse = false, free = false, paid = false, cancelled = false, refunded = false, paymentRequired = false, expired = false, overdue = false}) => ({
    cursor  : 'pointer',
    ...(highlighted && {
        borderTop       : `1px solid ${theme.palette.primary.main}`,
        borderBottom    : `1px solid ${theme.palette.primary.main}`,
        background      : alpha(theme.palette.primary.light,0.20),
        marginTop       : -1,
        marginBottom    : -1,
    }),
    ...(pulse && {
        animation: `pulse 500ms infinite`,
        "@keyframes pulse": {
            "0%" : {
                transform: 'scale(1)',
                boxShadow: `0 0 0 0 ${alpha(theme.palette.primary.dark,0.7)}`
            },
            "100%" : {
                transform: 'scale(1)',
                boxShadow: `0 0 0 3px ${alpha(theme.palette.primary.dark,0.25)}`
            }
        },
    }),
    ...(free && {
        background      : alpha(theme.palette.success.light,0.10),
        '&:hover'       : {
            background      : [alpha(theme.palette.success.dark,0.10),'!important'],
        }
    }),
    ...(paid && {
        // NOTHING
    }),
    ...(cancelled && {
        background      : alpha(theme.palette.action.disabledBackground,0.15),
        '&:hover'       : {
            background      : [alpha(theme.palette.action.disabled,0.175),'!important'],
        }
    }),
    ...(refunded && {
        background      : alpha(theme.palette.warning.light,0.10),
        '&:hover'       : {
            background      : [alpha(theme.palette.warning.dark,0.10),'!important'],
        }
    }),
    ...(paymentRequired && {
        background      : alpha(theme.palette.info.light,0.20),
        '&:hover'       : {
            background      : [alpha(theme.palette.info.dark,0.20),'!important'],
        }
    }),
    ...(expired && {
        background      : alpha(theme.palette.action.disabled,0.3),
        '&:hover'       : {
            background      : [alpha(theme.palette.action.disabled,0.325),'!important'],
        }
    }),
    ...(overdue && {
        background      : alpha(theme.palette.error.main,0.3),
        '&:hover'       : {
            background      : [alpha(theme.palette.error.main,0.325),'!important'],
        }
    }),
}))

const TableRowMod = withClickHandler(TableRow);

const average = arr => arr.length > 0 
        ? arr.reduce((a,b) => (a + (b || 0)), 0) / arr.length 
        : 0;

export const OrdersTable = withTranslation( ({t, title, showProgress=true, data, page, perPage, totalQuantity, ...props}) => {

    const theme                                 = useTheme();
    const history                               = useHistory();
    const {hash}                                = useLocation()
    const {
        refresh,
        cancelOrderById,
        cancelOrderByIdWorking
    }                                           = useOrders();
    const {isAdmin}                             = useUser();
    const {
        currency, 
        formatDateTime
    }                                           = useLocale();
    const [viewRawData,     setViewRawData]     = React.useState(undefined);
    const [viewOrder,       setViewOrder]       = React.useState(undefined);
    const [cancelOrderId,   setCancelOrderId]   = React.useState(undefined);
    const resetViewRawData                      = React.useCallback( ()    => setViewRawData(undefined), []);
    const resetViewOrder                        = React.useCallback( ()    => setViewOrder(undefined), []);
    const handleCloseOrderCancelConfirmation    = React.useCallback( ()    => setCancelOrderId(undefined), []);
    const handleCancelOrder                     = React.useCallback( ()    => {
        cancelOrderById(cancelOrderId);  
        handleCloseOrderCancelConfirmation();
    }, [cancelOrderById, cancelOrderId, handleCloseOrderCancelConfirmation]);

    const show                                  = useMediaQuery(theme => theme.breakpoints.up('md'))
    const hasPending                            = React.useMemo(() => (
        data
            .map(x => x.paymentStatus === "PENDING")
            .some(Boolean)
    ), [data]);

    return (
        <Box>
            {
                title && 
                <Title variant="h5"> 
                    {title} <ItemsCount count={totalQuantity}/>
                </Title>
            }
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            {hasPending && <TableCell></TableCell>}
                            {
                                show &&
                                <TableCell align="center">
                                    {t('common.order')}
                                </TableCell>
                            }
                            <TableCell align="right"> 
                                {t('common.amount')}
                            </TableCell>
                            <TableCell align="center">
                                {t('common.products')}
                            </TableCell>
                            <TableCell align="center">
                                {t('common.items')}
                            </TableCell>
                            {
                                false && 
                                <TableCell colSpan={2} align="center">
                                    {t('common.type')}
                                </TableCell>
                            }
                            <TableCell align="center"> 
                                {t('common.paymentStatus')}
                            </TableCell>
                            {
                                hasPending && 
                                <TableCell align="center" style={{padding:0}}>
                                    {t('common.expires')}
                                </TableCell>
                            }
                            {
                                showProgress && 
                                <TableCell align="center">
                                    {t('common.complete')}/% 
                                </TableCell>
                            }
                            <TableCell align="center" style={{minWidth:100}}>
                                {t('common.lastModified')}
                            </TableCell>
                            {
                                false && show &&
                                <TableCell align="center" style={{minWidth:100}}>
                                    {t('common.created')}
                                </TableCell>
                            }
                            {
                                isAdmin && 
                                <TableCell align="center" style={{minWidth:0}}>
                                    {t('common.actions')} 
                                </TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map((order,ix) => {
                                let {_id:orderId, total, requiresShipping, isVirtual, paymentStatus, amountRefunded, amountToPay, items, createdAt, updatedAt, expiresAt} = order;
                                let index = page * perPage + ix + 1;
                                const handleViewRawClick        = (e) => {
                                    e.stopPropagation();
                                    setViewRawData(order);
                                }
                                const handleCancelOrderClick    = (e) => {
                                    e.stopPropagation();
                                    setCancelOrderId(orderId); 
                                }
                                const handleViewOrderClick      = (e) => {
                                    e.stopPropagation();
                                    history.push(UserOrderLocation.toUrl({id:orderId}))
                                }
                                const isRequired    = paymentStatus === "PENDING" || paymentStatus === "PAID_PARTIAL";
                                const isExpired     = paymentStatus === "EXPIRED";
                                const isPending     = paymentStatus === "PENDING";
                                const isPartial     = paymentStatus === "PAID_PARTIAL";
                                const isPaidFull    = paymentStatus === "PAID_FULL";
                                const isPaidFree    = paymentStatus === "PAID_FREE";
                                const isPartialRfnd = paymentStatus === "REFUND_PARTIAL";
                                const isCancelled   = paymentStatus.startsWith('CANCELLED');
                                const isRefunded    = paymentStatus.startsWith('REFUND');
                                const {
                                    Icon = CancelIcon 
                                }                   = statusMap[paymentStatus] || {};
                                const title         = t(`components.order.ordersTable.statusMap.${paymentStatus}`)
                                const toPayChip     = Boolean(amountToPay) && (isPending || isPartial);
                                const highlight     = hash && hash.replace('#','') === orderId;
                                const quantityV     = items.filter(x => x.isVirtual).reduce((acc,item) => acc + item.quantity,0);
                                const quantityS     = items.filter(x => x.requiresShipping).reduce((acc,item) => acc + item.quantity,0);
                                const isOverdue     = (
                                    order.shouldProcess && 
                                    (order?.deliveries || [])
                                        .map(del =>   
                                            del?.status === "PROCESSING" && 
                                            del?.releaseAfter && 
                                            moment(del?.releaseAfter) < moment()
                                        )
                                        .some(Boolean)
                                );
                                return (
                                    <TableRowMod 
                                        id                  = {`#${orderId}`} 
                                        onClick             = {handleViewOrderClick} 
                                        key                 = {orderId} 
                                        //hover               = {true}
                                        highlighted         = {highlight}
                                        pulse               = {false}
                                        free                = {isPaidFree}
                                        paid                = {isPartial || isPaidFull}
                                        cancelled           = {isCancelled}
                                        refunded            = {isRefunded}
                                        paymentRequired     = {isRequired}
                                        expired             = {isExpired}
                                        overdue             = {isOverdue}
                                    >
                                        <TableCell>{index}</TableCell>
                                        <TableCell sx={{px:0,minWidth:0}}>
                                            {
                                                !(isPending || isPartial) && 
                                                <Button startIcon={<SearchIcon/>} variant="contained" color="warning" size="small" onClick = {handleViewOrderClick} >
                                                    {t('common.view')}
                                                </Button>
                                            }
                                            {
                                                (isPending || isPartial) && 
                                                <PayButton pulse={true} onClick={handleViewOrderClick} variant="contained" color="primary" size="small" sx={{whiteSpace:'nowrap'}}>
                                                    {t('common.viewAndPay')}
                                                </PayButton>
                                            }
                                        </TableCell>
                                        {
                                            hasPending && 
                                            <TableCell sx={{px:0,minWidth:0,pl:1}}>
                                                {
                                                    isPending && 
                                                    <Button startIcon={<CancelIcon/>} variant="contained" color="error" size="small" onClick = {handleCancelOrderClick}>
                                                        {t('common.cancel')}
                                                    </Button>
                                                }
                                            </TableCell>
                                        }
                                        {
                                            show &&
                                            <TableCell align="center" onClick={e => e.stopPropagation()}>
                                                <ObjectId value={orderId}/>
                                            </TableCell>
                                        }
                                        <TableCell align="right" sx={{minWidth:100}}>
                                            {
                                                toPayChip 
                                                    ?   <ColorChip 
                                                            sx      = {{mr:-1}}
                                                            size    = "small" 
                                                            color   = "error" 
                                                            label   = { <Currency value={amountToPay}/> }
                                                        />
                                                    :   <Currency value={total} />
                                            }
                                        </TableCell>
                                        <TableCell align="center">
                                            {items.filter(x => x.__t === 'PRODUCT').length}x
                                        </TableCell>
                                        <TableCell align="center">
                                            {items.filter(x => x.__t === 'PRODUCT').reduce((acc, item) => acc + (item?.quantity || 0),0)}x
                                        </TableCell>
                                        {
                                            false && 
                                            <TableCell align="center" sx={{p:0}}>
                                                {   isVirtual && 
                                                    <Tooltip 
                                                        title = {t('components.order.ordersTable.electronicVirtualQuantity',{quantity: quantityV})}
                                                    > 
                                                        <Badge overlap="rectangular" badgeContent={quantityV} invisible={quantityV <= 1}>
                                                            <FileCopyIcon/> 
                                                        </Badge>
                                                    </Tooltip>
                                                }
                                            </TableCell>
                                        }

                                        {
                                            false && 
                                            <TableCell align="center" sx={{p:0}}>
                                                {   requiresShipping && 
                                                    <Tooltip 
                                                        title = {t('components.order.ordersTable.requiresShippingQuantity',{quantity: quantityS})}
                                                    > 
                                                        <Badge overlap="rectangular" badgeContent={quantityS} invisible={quantityS <= 1}>
                                                            <FlightTakeoffIcon />
                                                        </Badge>
                                                    </Tooltip>
                                                }
                                            </TableCell>
                                        }
                                        
                                        <TableCell align="center" onClick={e => e.stopPropagation()} sx={{minWidth:125,whiteSpace:'nowrap',px:2,p:0,m:0}}>
                                            {(true || (!isPending && !isPartial)) &&
                                                <Box display={'flex'} style={{width:'100%'}} align="center">
                                                    <Box flexGrow={1}/>
                                                    {
                                                        Icon && 
                                                        <Box sx={{my:'auto',transform:'translatey(2px)'}}>
                                                            <Icon />
                                                        </Box>
                                                    }
                                                    <Box sx={{my:'auto'}}>
                                                        <Typography style={{paddingLeft:theme.spacing(1), fontSize:'inherit'}}>
                                                            {
                                                                false && isPartialRfnd && 
                                                                <span style={{paddingRight:5}}><Currency value={amountRefunded}/></span>
                                                            }
                                                            {title || paymentStatus}
                                                        </Typography>
                                                    </Box>
                                                    <Box flexGrow={1}/>
                                                </Box>
                                            }

                                            {
                                                false && (isPending || isPartial) && 
                                                <PayButton pulse={true} onClick={handleViewOrderClick} variant="contained" color="primary" size="small">
                                                    {t('common.viewOrderAndPay')}
                                                </PayButton>
                                            }

                                            {(isPending || isPartial) && false &&
                                                <MakePayment
                                                    orderId             = {orderId}
                                                    title               = {t('common.makePayment')}
                                                    currency            = {currency}
                                                    amount              = {amountToPay}
                                                    fixed               = {true}
                                                    allowChangeDefault  = {false}
                                                    render              = {({handleMakePayment}) => (
                                                        <PayButton 
                                                            pulse       = {true} 
                                                            onClick     = {handleMakePayment} 
                                                            variant     = "contained" 
                                                            color       = "primary" 
                                                            size        = "small" 
                                                            style       = {{
                                                                marginLeft  : theme.spacing(-1),
                                                                minWidth    : 100
                                                            }}
                                                        >
                                                            {t('common.payNow')}
                                                        </PayButton>
                                                    )}
                                                >
                                                    <CountDown 
                                                        to          = {moment(order.expiresAt)} 
                                                        onExpire    = {refresh} 
                                                        render      = {({countDown, isFinishing})=>(
                                                            <React.Fragment>
                                                                {
                                                                    isFinishing &&
                                                                    <Typography gutterBottom variant = "body2">
                                                                        {t('common.orderExpiresIn', {value : countDown })}
                                                                    </Typography>
                                                                }
                                                            </React.Fragment>
                                                        )}
                                                    />
                                                </MakePayment>
                                            }
                                        </TableCell>

                                        {
                                            hasPending &&
                                            <TableCell align="center" style={{padding:0}}>
                                                {
                                                    isPending && 
                                                    <CountDown to={moment(expiresAt)} onExpire={refresh} allowNegative={false}/>
                                                }
                                            </TableCell>
                                        }
                                        {
                                            showProgress && 
                                            <TableCell align="center">
                                                <CompletionBar cancelled={isCancelled} pcntComplete={average((order?.deliveries || []).map(d => d.percentComplete))} />
                                            </TableCell>
                                        }

                                        <TableCell align="center" sx={{p:0}}>
                                            {formatDateTime(moment(updatedAt))}
                                        </TableCell>
                                        
                                        {
                                            false && show &&
                                            <TableCell align="center" sx={{p:0}}>
                                                {formatDateTime(moment(createdAt))}
                                            </TableCell>
                                        }

                                        {
                                            isAdmin && 
                                            <TableCell align="center" style={{paddingRight:theme.spacing(1),padding:0}}>
                                                {
                                                    false && isPending && 
                                                    <CancelIconButton 
                                                        tooltip         = { t('common.cancelOrder') }
                                                        color           = "secondary" 
                                                        disabled        = {cancelOrderByIdWorking} 
                                                        size            = "small" 
                                                        onClick         = {handleCancelOrderClick}
                                                    />
                                                }
                                                {
                                                    isAdmin && 
                                                    <ViewIconButton 
                                                        Icon            = {SupervisorAccountIcon} 
                                                        TooltipProps    = {{ title : t('common.viewRaw') }} 
                                                        color           = "secondary" 
                                                        size            = "small" 
                                                        onClick         = {handleViewRawClick}
                                                    />
                                                }
                                                {
                                                    false && 
                                                    <ViewIconButton 
                                                        TooltipProps    = {{ title : t('common.viewOrder') }} 
                                                        color           = "secondary" 
                                                        size            = "small" 
                                                        onClick         = {handleViewOrderClick}
                                                    />
                                                }
                                            </TableCell>
                                        }
                                    </TableRowMod>
                                );
                            })
                        }
                    </TableBody>
                </Table>
                {
                    viewRawData && 
                    <DraggableDialog 
                        title               = {t('common.orderRawData')}
                        maxWidth            = {'md'}
                        open                = {Boolean(viewRawData)} 
                        onClose             = {resetViewRawData} 
                        onCancel            = {resetViewRawData} 
                        onOk                = {resetViewRawData}
                        showButtonsCancel   = {false}
                    >
                        <Box minWidth={500}>
                            <Typography paragraph>
                                {t('components.order.ordersTable.rawDetailsForOrder',{orderId : viewRawData._id})}
                            </Typography>
                            <JSONViewer src={viewRawData} />
                        </Box>
                    </DraggableDialog>
                }
                {
                    viewOrder && 
                    <DraggableDialog 
                        title       = {t('common.orderViewer')}
                        fullScreen
                        open        = {Boolean(viewOrder)} 
                        onClose     = {resetViewOrder} 
                        onCancel    = {resetViewOrder} 
                        onOk        = {resetViewOrder}
                        showButtons = {false}
                    >
                        <Order orderId={viewOrder._id}/>
                    </DraggableDialog>
                }

                {
                    cancelOrderId && 
                    <Confirmation title={t('common.pleaseConfirm')} open={cancelOrderId} onOk={handleCancelOrder} onCancel={handleCloseOrderCancelConfirmation}>
                        <Typography>
                            {t('components.order.ordersTable.cancelOrderConfirm')}
                        </Typography>
                    </Confirmation>
                }

            </TableContainer>
        </Box>
    )
});

export default OrdersTable;