/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Seo Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th July 2022

*******************************************************************************************/
import React                            from 'react';
import { Helmet }                       from "react-helmet";
import { useTranslation }               from 'contexts';
import { useImageCDN }                  from 'hooks';
import defaultImage                     from 'resources/logos/og_home_1200x628.png';
import config                           from '../config';

const {
    company : COMPANY
} = config;

const DEFAULT_FAVICON       = "/favicon.ico";
const DEFAULT_LANGUAGE      = "en_US";
const DEFAULT_TYPE          = "website";
const DEFAULT_TWITTER_CARD  = "summary_large_image";

const isString              = val => Boolean(typeof val === 'string');
const removewww             = val => val && isString(val) ? val.replace('www.','') : val;
const secure                = val => val && isString(val) ? val.replace('http:','https:') : val;
const getImageDims          = url => new Promise( (resolve,reject) => {
    try{
        var img     = new Image();
        img.src     = url;
        img.onload  = function() { 
            resolve({
                width   : this.width, 
                height  : this.height
            });
        };
    }catch(err){
        reject(err);
    }
});

const processKeywords       = (keywords) => {
    let result = undefined;
    if(!result)
        return result;
    try {
        result = ( 
            isString(keywords) // Plain String
                ? keywords 
                : (
                    Array.isArray(keywords) && keywords.map(isString).every(Boolean) // Array, and every item is a string, then join
                        ? keywords.join(',') 
                        : undefined
                )
        )
    } catch (err) {
        // Silent
    }
    return result;
}

const useImageDims = (image) => {

    const [dims, setDims]   = React.useState({ width : 0, height : 0})
    const reset             = React.useCallback(() => setDims({ width  : 0, height : 0 }), []);

    // Get Image Details
    React.useEffect(() => {
        if(image){
            getImageDims(image)
                .then(setDims)
                .catch(reset)
        }else{
            reset();
        }
    }, [image, reset]);

    return dims
}

const getMimeTypeFromPath = (path) => {
    const sanitizedPath = path.split(/[?#]/)[0];
    const extension     = sanitizedPath.split('.').pop().toLowerCase();
    switch (extension) {
        case 'png':
            return 'image/png';
        case 'jpg':
        case 'jpeg':
            return 'image/jpeg';
        case 'gif':
            return 'image/gif';
        case 'webp':
            return 'image/webp';
        case 'svg':
            return 'image/svg+xml';
        case 'bmp':
            return 'image/bmp';
        case 'ico':
            return 'image/x-icon';
        default:
            return 'application/octet-stream'; // Fallback for unknown types
    }
}

export const Seo = ({ 
    title                       = undefined, 
    description                 = undefined, 
    image       : imageIn       = undefined, 
    pathname    : pathnameIn    = undefined, 
    canonical                   = undefined,
    follow                      = true, 
    index                       = true,
    keywords                    = undefined,
    favicon                     = undefined,
    author                      = undefined,
    type                        = undefined,
    children
}) => {
    const { t, i18n, ready} = useTranslation();
    const convert           = useImageCDN();
    const updatedTime       = Math.floor(new Date().getTime() / 1000);
    const url               = React.useMemo(() => {
        const  pathname = pathnameIn || `${window.location.pathname}${window.location.search}`
        return new URL(pathname, window.location.origin).href
    }, [pathnameIn]);
    
    // Build the Image
    const image             = React.useMemo(() => {

        // Try and convert
        let pathname = convert(imageIn || defaultImage);

        // Make sure it is relative
        if(pathname && !pathname.startsWith('http')) // Not an absolute url
            pathname = new URL(pathname, window.location.origin).href

        // Just In Case
        pathname = pathname || defaultImage

        // Done
        return pathname;

    }, [imageIn, convert]);
    
    const dims = useImageDims(image);

    // Build the seo data
    const seo = React.useMemo(() => ({
        locale              : i18n?.language || DEFAULT_LANGUAGE,
        author              : author,
        siteName            : COMPANY,
        applicationName     : COMPANY,
        title               : title         || (ready ? t('application.title')   : undefined) || COMPANY,
        description         : description   || (ready ? t('application.tagline') : undefined),
        keywords            : processKeywords(keywords),
        canonical           : removewww(canonical || url),
        favicon             : favicon       || DEFAULT_FAVICON,
        type                : type          || DEFAULT_TYPE,
        url                 : secure(url),
        image               : secure(image),
        imageWidth          : dims.width.toString(),
        imageHeight         : dims.height.toString(),
        imageType           : getMimeTypeFromPath(image),
        twitterCard         : DEFAULT_TWITTER_CARD,
        robots              : [
            index   ? 'index'   : 'noindex', 
            follow  ? 'follow'  : 'nofollow'
        ].join(', '),
        fbid                : "966242223397117"
    }),[
        author, title, ready, t, description, keywords, canonical, url, type, 
        favicon, image, dims.width, dims.height, follow, index, i18n.language
    ])

    // Move title to top
    React.useEffect(() => {
        const title = document.querySelector('title');
        const head  = document.querySelector('head');
        if(title && head){
            head.insertBefore(title, head.firstChild);
        }
    },[])

    return (
        <Helmet placement="top">
            <title> 
                {seo.title} 
            </title>
            <meta charSet = "utf-8" />
            <meta http-equiv="expires"                      content = "0" />
            <meta name = "viewport"                         content = "width=device-width, initial-scale=1" />
            <meta name = "theme-color"                      content = "#000000" />
            <meta name = "application-name"                 content = {seo.siteName} />
            <meta name = "description"                      content = {seo.description} /> 
            {
                seo?.keywords &&
                <meta name="keywords"                       content = {seo.keywords} />
            }
            <link rel = "canonical"                         href    = {seo.canonical} />
            <link id = "favicon-icon" rel="shortcut icon"   href    = {seo.favicon} />
            <meta property = "fb:app_id"                    content = {seo.fbid} />
            <meta property = "og:locale"                    content = {seo.locale} />
            <meta property = "og:site_name"                 content = {seo.siteName} />
            <meta property = "og:title"                     content = {seo.title} />
            <meta property = "og:description"               content = {seo.description} />
            <meta property = "og:type"                      content = {seo.type} />
            <meta property = "og:url"                       content = {seo.url} />
            <meta property = "og:updated_time"              content = {updatedTime} />
            {
                seo?.image &&
                <meta property = "og:image"  itemprop="image"  content = {seo.image} />
            }
            {
                seo?.image &&
                <meta property = "og:image:url"             content = {seo.image} />
            }
            {
                seo?.image && seo.imageWidth &&
                <meta property = "og:image:width"           content = {seo.imageWidth} />
            }
            {
                seo?.image && seo.imageHeight &&
                <meta property = "og:image:height"          content = {seo.imageHeight} />
            }
            {
                seo?.image &&
                <meta property = "og:image:type"            content = {seo.imageType} />
            }
            <meta name = "twitter:card"                     content = {seo.twitterCard} />
            <meta name = "twitter:url"                      content = {seo.url} />
            <meta name = "twitter:title"                    content = {seo.title} />
            <meta name = "twitter:description"              content = {seo.description} />
            {
                seo?.image &&
                <meta name = "twitter:image"                content = {seo.image} />
            }
            {
                seo?.author && 
                <meta name = "author"                       content = {seo.author} />
            }
            <meta name = "robots"                           content={seo.robots} />
            {children}
        </Helmet>
    )
}

export default Seo;