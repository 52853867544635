
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Orders Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       25th July 2021

*******************************************************************************************/
import React                    from 'react';
import {clone}                  from 'lodash';
import moment                   from 'moment';
import {useNetwork}             from './NetworkContext';
import {
    useStateEphemeral,
    useCancelToken,
    useCanQuery
}                               from 'hooks';

// The Product Context 
const OrdersContext = React.createContext(undefined);

const API_BASE_URL = '/api/user/order'

// Orders Provider
const OrdersProvider = ({children}) => {
    const {allowQueryForUser}                                   = useCanQuery();
    const { axios, socketUsers : socket }                       = useNetwork();
    const {cancelToken }                                        = useCancelToken();
    const [orders, setOrders]                                   = React.useState([]);

    // Messages
    const [messageSuccess,          setMessageSuccess]          = useStateEphemeral(undefined);
    const [messageError,            setMessageError]            = useStateEphemeral(undefined,5000);
    const [cancelOrderByIdWorking,  setCancelOrderByIdWorking]      = React.useState(false);
    const [working,                 setWorking]                 = React.useState(false);
    const [queried,                 setQueried]                 = React.useState(undefined);
    const [downloadingInvoice,      setDownloadingInvoice]      = React.useState(false);
    
    const [emailingInvoice,         setEmailingInvoice]         = React.useState(false);
    const [emailingInvoiceAllow,    setEmailingInvoiceAllow]    = React.useState(true);

    // Clear all orders
    const clear = React.useCallback( () => {
        setOrders([]);
        setQueried(undefined);
    }, []);
    
    // Query Database for Orders Collection
    const get = React.useCallback(() => new Promise((resolve,reject) => {
        if(!allowQueryForUser)
            return reject(new Error('unauthorized'))
        setWorking(true);
        axios.get(API_BASE_URL, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(err => {
                setMessageError(err.message);
                reject(err);
            })
            .finally(()=>{
                setWorking(false);
            })
    }),[allowQueryForUser, axios, cancelToken, setMessageError])

    // Refresh the Orders
    const refresh = React.useCallback(() => {
        if(allowQueryForUser){
            get()
                .then(setOrders)
                .catch(({message}) => {
                    setMessageError(message);
                    clear();
                })
                .finally(() => {
                    setQueried(moment());
                })
        }else{
            clear();
        }

    },[allowQueryForUser, clear, get, setMessageError])

    // Query Database for Order by OrderID
    const getOrderById = React.useCallback(orderId => new Promise((resolve, reject) => {
        if(!allowQueryForUser)
            return reject(new Error('unauthorized'))
        if(!orderId)
            return reject(new Error('orderId is required'));
        setWorking(true);
        axios.get(`${API_BASE_URL}/${orderId}`, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(err => {
                setMessageError(err.message);
                reject(err);
            })
            .finally(() => {
                setWorking(false);
            })
    }),[allowQueryForUser, axios, cancelToken, setMessageError])

    const refreshOrderById = React.useCallback((orderId) => {    

        getOrderById(orderId)
            .then(order => {
                setMessageSuccess(`Order ${orderId} queried`);
                let ix = (orders || []).findIndex(x => x.id === order.id)
                if(ix >= 0 && ix < (orders || []).length){
                    let ordersNew = clone(orders);
                    ordersNew[ix] = order;
                    setOrders(ordersNew);
                }
            })
            .catch(({message}) => {
                setMessageError(message);
            })
            .finally(() => {
                setQueried(moment());
            })

    },[getOrderById, setMessageSuccess, orders, setMessageError])

    const disableEmailingInvoice = React.useCallback( () => {
        setEmailingInvoiceAllow(false);
        setTimeout(() => {
            setEmailingInvoiceAllow(true)
        }, 15000);
    },[]);

    const getInvoiceByEmail = React.useCallback(invoiceId => new Promise((resolve,reject) => {
        if(!allowQueryForUser)
            return reject(new Error('unauthorized'))
        if(!invoiceId)
            return reject(new Error('invoiceId is required'))
        setEmailingInvoice(true);
        axios.get(`${API_BASE_URL}/invoice/${invoiceId}/email`, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(err => {
                setMessageError(err.message);
                reject(err);
            })
            .finally(() => {
                setEmailingInvoice(false);
                disableEmailingInvoice();
            })
    }),[allowQueryForUser, axios, cancelToken, disableEmailingInvoice, setMessageError])

    // Get Invoice for OrderID
    // had to add this one here
    // https://stackoverflow.com/a/67210200/1834057
    const getInvoiceByDownload = React.useCallback( (invoiceId, download=true) => new Promise((resolve,reject) => {
        if(!allowQueryForUser)
            return reject(new Error('unauthorized'))
        if(!invoiceId)
            return reject(new Error('invoiceId is required'));
        setDownloadingInvoice(true);
        axios.get(`${API_BASE_URL}/invoice/${invoiceId}/download`, { responseType : 'blob', cancelToken}) 
            .then((response) => {

                let now = moment();

                // Extract the raw and response data
                let {raw, ...rest} = response;

                // Convert the blob and open in a new window
                const blob  = new Blob([raw.data], {type: 'application/pdf'});
                const url   = window.URL.createObjectURL(blob);

                const fileName = raw?.headers['content-disposition']?.split('filename=')[1]?.replaceAll('"','') || `Invoice_Order_${invoiceId.toString().slice(-8).toUpperCase()}_${now.format('MMDDYYYY')}.pdf`

                if(!download){
                    const link  = document.createElement("a");
                    link.href   = url;
                    link.setAttribute( "download", fileName );
                    
                    //link.setAttribute("target","_blank")
                    document.body.appendChild(link);    // Link, Create
                    link.click();                       // Link, Click
                    link.parentNode.removeChild(link);  // Link, Remove
                } 

                /*
                setTimeout(() => {
                    let blob        = new Blob([raw.data], {type: 'application/pdf'});
                    let win         = window.open('', '_blank');
                    let URL         = window.URL || window.webkitURL;
                    let dataUrl     = URL.createObjectURL(blob);
                    win.location    = dataUrl;
                },1000)
                */

                // Done
                resolve({...rest, objectUrl : url, fileName});
            })
            .catch(err => {
                setMessageError(err.message);
                reject(err);
            })
            .finally(()=>{
                setDownloadingInvoice(false);
            })
    }),[allowQueryForUser, axios, cancelToken, setMessageError])

    // Get order payment timeline
    const getTimeLineById = React.useCallback( orderId => new Promise((resolve,reject)=>{
        if(!allowQueryForUser)
            return reject(new Error('unauthorized'))
        if(!orderId)
            return reject(new Error('orderId is required'));
        axios.get(`${API_BASE_URL}/${orderId}/timeline`, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(err => {
                setMessageError(err.message);
                reject(err);
            })
    }),[allowQueryForUser, axios, cancelToken, setMessageError])

    // Cancel an Order by ID
    const cancelOrderById = React.useCallback( orderId => new Promise((resolve,reject) => {
        if(!allowQueryForUser)
            return reject(new Error('unauthorized'))
        if(!orderId)
            return reject(new Error('orderId is required'));
        setCancelOrderByIdWorking(true);
        axios.post(`${API_BASE_URL}/${orderId}/cancel`, {}, {cancelToken})
            .then(({data}) => data)
            .then(data => {
                setMessageSuccess(`Order ${orderId} cancelled`);
                resolve(data);
            })
            .catch(err => {
                setMessageError(err.message);
                reject(err);
            })
            .finally(()=>{
                setCancelOrderByIdWorking(false);
            })
    }),[allowQueryForUser, axios, cancelToken, setMessageError, setMessageSuccess]);

    // Get an order by ID, will use memoized copy first, else if unavailable, will query server
    const getOrderFromMemoryOrById = React.useCallback( orderId => new Promise((resolve,reject) => {

        if(!allowQueryForUser)
            return reject(new Error('unauthorized'))
        if(!orderId)
            return reject(new Error('orderId is required'));

        // See if the data is already part of our dataset
        let inMemoryData = orders.find(ord => ord._id === orderId);
        if(inMemoryData) 
            return resolve(inMemoryData)

        // Not in memory, Query the actual database
        getOrderById(orderId)
            .then(data => {
                setMessageSuccess(`Order ${orderId} queried`);
                return data;
            })
            .then(resolve)
            .catch(err => {
                setMessageError(err.message);
                reject(err);
            })

    }),[allowQueryForUser, orders, getOrderById, setMessageSuccess, setMessageError])

    // When refresh changes, execute
    React.useEffect(refresh,[refresh])

    // Refresh all orders on SocketIO Instruction
    React.useEffect(()=>{
        if(socket && allowQueryForUser){
            socket.on('refresh_orders', refresh)
            socket.on('refresh_order', refreshOrderById);
            return () => {
                socket.off('refresh_orders', refresh);
                socket.off('refresh_order', refreshOrderById);
            }
        }
    },[allowQueryForUser, refresh, refreshOrderById, socket])

    const ordersUnpaid      = orders.filter(o => ['PENDING','PAID_PARTIAL'].includes(o.paymentStatus))
    const quantityUnpaid    = ordersUnpaid.length;
    const amountToPay       = ordersUnpaid.reduce((acc,cur) => acc + cur.amountToPay,0);
    const hasUnpaid         = quantityUnpaid > 0;
    const hasOrders         = orders.length > 0;

    // Context values
    const value = {
        queried,
        refresh,
        working,
        loading : working,
        orders,

        getInvoiceByEmail,
        emailingInvoice,
        emailingInvoiceAllow,

        getInvoiceByDownload,
        downloadingInvoice,

        getTimeLineById,
        getOrderFromMemoryOrById,
        hasOrders,
        ordersUnpaid,
        quantityUnpaid,
        amountToPay,
        hasUnpaid, 
        cancelOrderById,
        cancelOrderByIdWorking,
        messageSuccess,
        messageError
    };

    return (
        <OrdersContext.Provider value={value}>
            {children}
        </OrdersContext.Provider>
    )
}

// Orders Consumer
const OrdersConsumer = ({children}) => {
    return (
        <OrdersContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('OrdersConsumer must be used within OrdersProvider');
                }
                return children(context)
            }}
        </OrdersContext.Consumer>
    )
}

// useOrders Hook
const useOrders = () => {
    const context = React.useContext(OrdersContext);
    if(context === undefined)
        throw new Error('useOrders must be used within OrdersProvider');
    return context;
}

export {
    OrdersProvider,
    OrdersConsumer,
    useOrders,
}