
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Examples Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       7th January 2022

*******************************************************************************************/
import React            from 'react';
import moment           from 'moment';
import {useNetwork}     from 'contexts';
import {
    useCancelToken,
    useCanQuery
}                       from 'hooks';

// The Product Context 
const ExamplesContext = React.createContext(undefined);

// Examples Provider
const ExamplesProvider = ({children}) => {

    const {allowQueryForAnonymous}      = useCanQuery();
    const { axios }                     = useNetwork();
    const {cancelToken}                 = useCancelToken();

    // State Variables
    const [enabled,     setEnabled]     = React.useState(false);
    const [queried,     setQueried]     = React.useState(undefined);
    const [loading,     setLoading]     = React.useState(true);
    const [quantity,    setQuantity]    = React.useState(3);
    const [productId,   setProductId]   = React.useState(undefined);
    const [data,        setData]        = React.useState([]);

    // Reset Examplexs
    const resetData                     = React.useCallback(() => setData([]),[]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(resetData,[productId]);

    const queryDatabase = React.useCallback( () => new Promise((resolve,reject) => {
        if(enabled){
            setLoading(true);
            axios.get(`/api/public/library/examples?quantity=${quantity}` + (productId ? `&productId=${productId}` : ''), {cancelToken})
                .then(({data}) => data)
                .then(resolve)
                .catch(reject)
                .finally(() => {
                    setLoading(false);
                    setQueried(moment())
                })
        }else{
            resetData();
        }
    }),[axios, cancelToken, enabled, productId, quantity, resetData])

    const refresh = React.useCallback(() => {
        if(enabled && allowQueryForAnonymous){
            queryDatabase().then(setData).catch(resetData);
        }else{
            resetData();
        }
    },[enabled, queryDatabase, allowQueryForAnonymous, resetData])

    // Query the server for examples
    React.useEffect(refresh,[refresh])

    // Has data or not
    const hasExamples = React.useMemo(() => Array.isArray(data) && data.length > 0, [data])

    // Context values
    const value = React.useMemo(() => ({
        setEnabled,
        refresh,
        loading,
        working : loading,
        queried,
        data,
        hasExamples,
        quantity,
        setQuantity,
        productId,
        setProductId
    }), [data, hasExamples, loading, productId, quantity, queried, refresh]);

    return (
        <ExamplesContext.Provider value={value}>
            {children}
        </ExamplesContext.Provider>
    )
}

// Examples Consumer
const ExamplesConsumer = ({children}) => {
    return (
        <ExamplesContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('ExamplesConsumer must be used within ExamplesProvider');
                }
                return children(context)
            }}
        </ExamplesContext.Consumer>
    )
}

// useExamples Hook
const useExamples = () => {
    const context = React.useContext(ExamplesContext);
    if(context === undefined)
        throw new Error('useExamples must be used within ExamplesProvider');
    return context;
}

export {
    ExamplesProvider,
    ExamplesConsumer,
    useExamples
}