/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Create Natal Data
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd February 2022

*******************************************************************************************/
import React                            from 'react';
import { useNatalData, useUser }        from 'contexts';
import { AlertOnBehalfOfUser, Button }                       from 'components';
import { NatalDataV2 }                  from 'components/modals';
import { withTranslation }              from './hoc';

const obj = {};
const noop = () => {};

export const CreateNatalData = withTranslation( ({
    t,
    userId,
    open        : openIn    = false, 
    component   : Component = Button, 
    disabled                = false, 
    onOpenChange            = noop,
    natalDataFormProps      = obj,
    children,
    ...props
}) => {
    
    const {
        geolocation,
        userId : userIdAuth, 
        isAuthenticated, 
        isAdmin
    }                                   = useUser();
    const {
        createNatalData,
        apiPostForUserId
    }                                   = useNatalData();
    const [open, setOpen]               = React.useState(openIn);
    
    const handleOpen                    = React.useCallback( () => {
        setOpen(true);
        onOpenChange(true);
    }, [onOpenChange]);

    const handleClose                   = React.useCallback( () => {
        setOpen(false)
        onOpenChange(false);
    }, [onOpenChange]);

    React.useEffect(() => {
        setOpen(openIn);
    }, [openIn])

    // Create
    const handleCreate = React.useCallback( ({lat,lng, address, birthDateTime, unknownTime, localTime, gender, isUser, photo, description, roddenRating, ...rest} = {}) => new Promise( resolve => {
        const args = {lat, lng, address, gender, birthDateTime, localTime, unknownTime, isUser, photo, description, roddenRating, ...rest};
        if(isAuthenticated && isAdmin && userId && userId !== userIdAuth){
            apiPostForUserId(userId,args)
                .then(handleClose)
                .then(() => {
                    resolve({})
                })
                .catch(({errors = {}} = {}) => {
                    resolve(errors);
                });
        }else{
            createNatalData(args)
                .then(handleClose)
                .then(() => {
                    resolve({})
                })
                .catch(({errors = {}} = {}) => {
                    resolve(errors);
                });
        }
    }), [apiPostForUserId, createNatalData, handleClose, isAdmin, isAuthenticated, userId, userIdAuth])
    
    const formData = React.useMemo(() => ({
        lat             : geolocation.lat, 
        lng             : geolocation.lng, 
        address         : undefined, 
        gender          : undefined, 
        birthDateTime   : undefined, 
        unknownTime     : false,
        photo           : undefined,
        description     : undefined,
        roddenRating    : undefined
    }), [geolocation.lat, geolocation.lng])

    return (
        <>
            <Component
                {...props}
                disabled    = {disabled || open}
                onClick     = {handleOpen} 
            >
                {children}
            </Component> 
            <NatalDataV2     
                title               = { t('components.createNatalData.createNatalData') } 
                formData            = {formData}
                open                = {open}    
                onSubmit            = {handleCreate}   
                onClose             = {handleClose}  
                onCancel            = {handleClose}
                natalDataFormProps  = {natalDataFormProps}
            >
                <AlertOnBehalfOfUser userId={userId}/>
            </NatalDataV2>
        </>
    )
})

export default CreateNatalData;