
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
EmailSubscription Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       30th November 2021

*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';
import {useNetwork}             from './NetworkContext';
import {
    useStateEphemeral,
    useCanQuery,
    useCancelToken
}                               from '../hooks';

// The Product Context 
const EmailSubscriptionContext  = React.createContext(undefined);
const BASE_API_URL              = '/api/user/subscription'

// EmailSubscription Provider
const EmailSubscriptionProvider = ({children}) => {

    const {
        axios, 
        socketUsers : socket
    }                                               = useNetwork();
    const {allowQueryForUser}                       = useCanQuery();
    const {cancelToken}                             = useCancelToken();
    const [enabled,         setEnabled]             = React.useState(undefined);
    const [data,            setData]                = React.useState({});
    const [rawData,         setRawData]             = React.useState({});
    const [loading,         setLoading]             = React.useState();
    const [queried,         setQueried]             = React.useState(undefined);
    const [messageSuccess,  setMessageSuccess]      = useStateEphemeral(undefined);
    const [messageError,    setMessageError]        = useStateEphemeral(undefined,5000);

    // Query the Notification
    const getEmailSubscriptions = React.useCallback(() => new Promise((resolve, reject) => {
        setLoading(true);
        axios.get(BASE_API_URL, {cancelToken})
            .then(({data,rawData}) => {
                setMessageSuccess("Successfully retrieved subscriptions");
                resolve({data,rawData})
            })
            .catch(err => {
                setMessageError(err.message);
                reject(err);
            })
            .finally(()=>{
                setLoading(false);
                setQueried(moment());
            })
    }),[axios, cancelToken, setMessageError, setMessageSuccess])

    const updateEmailSubscriptions = React.useCallback( (formData) => new Promise((resolve, reject) => {
        setLoading(true);
        axios.post(`${BASE_API_URL}/${data.id}`, formData, {cancelToken})
            .then(({data}) => data)
            .then(data => {
                setMessageSuccess("Email subscriptions updated");
                resolve(data);
            })
            .catch(err => {
                setMessageError(err?.message);
                reject(err);
            })
            .finally(()=>{
                setLoading(false);
            })
    }), [axios, cancelToken, data.id, setMessageError, setMessageSuccess])

    const clear = React.useCallback(() => {
        setData({});
        setRawData({});
    }, [])

    const refresh = React.useCallback(() => {
        if(enabled && allowQueryForUser){
            getEmailSubscriptions()
                .then(({data,rawData}) => {
                    setData(data);
                    setRawData(rawData);
                })
                .catch(clear);
        }else{
            clear();
        }
    },[clear, enabled, getEmailSubscriptions, allowQueryForUser])

    React.useEffect(()=>{
        if(enabled && allowQueryForUser){
            refresh();
        }else{
            clear();
        }
    },[clear, enabled, allowQueryForUser, refresh])

     // Refresh on SocketIO Instruction
     React.useEffect(() => {
        if(enabled && allowQueryForUser && socket){
            socket.on('refresh_subscriptions', refresh)
            return () => {
                socket.off('refresh_subscriptions', refresh);
            }
        }
    },[enabled, allowQueryForUser, refresh, socket])

    // Context values
    const value = React.useMemo(() => ({
        data,
        rawData,
        loading,
        setEnabled,
        messageSuccess,
        messageError,
        refresh,
        queried,
        getEmailSubscriptions,
        updateEmailSubscriptions
    }), [data, rawData, getEmailSubscriptions, loading, messageError, messageSuccess, queried, refresh, updateEmailSubscriptions]);

    return (
        <EmailSubscriptionContext.Provider value={value}>
            {children}
        </EmailSubscriptionContext.Provider>
    )
}

// EmailSubscription Consumer
const EmailSubscriptionConsumer = ({children}) => {
    return (
        <EmailSubscriptionContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('EmailSubscriptionConsumer must be used within EmailSubscriptionProvider');
                }
                return children(context)
            }}
        </EmailSubscriptionContext.Consumer>
    )
}

// useEmailSubscription Hook
const useEmailSubscription = () => {
    const context = React.useContext(EmailSubscriptionContext);
    if(context === undefined)
        throw new Error('useEmailSubscription must be used within EmailSubscriptionProvider');
    return context;
}

export {
    EmailSubscriptionProvider,
    EmailSubscriptionConsumer,
    useEmailSubscription
}