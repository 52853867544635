/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
AstroVenusian Application -- App
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th November 2020

*******************************************************************************************/
import './wdyr'

import React, {Suspense}                  from 'react';
import moment                             from 'moment-timezone';
import { parse, stringify }               from 'query-string';
import { ErrorBoundary }                  from 'react-error-boundary';
import { 
  BrowserRouter
}                                         from 'react-router-dom';
import { CookiesProvider }                from 'react-cookie';
import { CacheProvider }                  from "@emotion/react";
import createCache                        from "@emotion/cache";

import { 
  CssBaseline,
  ThemeProvider, 
}                                         from '@mui/material';
import { AdapterMoment }                  from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider }           from '@mui/x-date-pickers';

import { Elements }                       from '@stripe/react-stripe-js';
import { loadStripe }                     from '@stripe/stripe-js';
import { AuthInterceptor }                from 'auth';

import {
  CacheLoader, 
  Seo, 
  Analytics,
  HeaderScripts,
  ScrollToTop,
  ExitIntent,
  AutoTheme, 
  ErrorFallback,
  HelmetTagOrganizer,
  CookieConsent
}                                         from './components';
import Main                               from './containers/layouts/Main';
import { 
  CacheBusterProvider,
  ContextWrapper,
}                                         from './contexts';
import theme                              from './themes/lightTheme';
import Router                             from './router';
import AuthProvider                       from './auth/AuthProviderWithHistory';
import { 
  QueryParamProvider as BaseQueryParamProvider, 
  Adapter
}                                         from './hooks/useQueryParams';
import config                             from './config'

// Base css overrides
import "./index.css"

const {
  stripe : {
    publishableKey
  }
} = config;

const emotionCache = createCache({
  key     : "emotion-cache-no-speedy",
  speedy  : false,
});

moment.tz.setDefault(moment.tz.guess());

// https://momentjscom.readthedocs.io/en/latest/moment/07-customization/13-relative-time-threshold/
moment.relativeTimeThreshold('ss', 44)

const stripePromise = loadStripe(publishableKey);

const QueryParamProvider = ({children}) => (
  <BaseQueryParamProvider 
    adapter = {Adapter}
    options = {{
      // updateType              : 'replace',
      // updateType              : 'pushIn',
      // includeAllParams        : false,
      // removeDefaultsFromUrl   : true,
      // enableBatching          : true,
      // skipUpdateWhenNoChange  : true,
      // includeAllParams        : false,
      searchStringToObject    : parse,
      objectToSearchString    : stringify
    }}
  >
    {children}
  </BaseQueryParamProvider>
)
/**
 * The App
 */
export const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <CacheProvider value={emotionCache}>
        <CssBaseline />
        <Elements stripe={stripePromise}>
          <Seo />
          <Analytics />
          <HeaderScripts />
          <CookiesProvider>
            <BrowserRouter> 
              <QueryParamProvider>
                <ScrollToTop />
                <AuthProvider>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <CacheBusterProvider isEnabled = {true} isVerboseMode = {false}>
                      <ErrorBoundary FallbackComponent = {ErrorFallback}>
                        <ContextWrapper>
                          <AutoTheme>
                            <Suspense fallback = { <CacheLoader/> } >
                              <AuthInterceptor />
                              <Main>
                                <Router />
                              </Main>
                            </Suspense>
                            <CookieConsent />
                            <ExitIntent />
                          </AutoTheme>
                        </ContextWrapper>
                      </ErrorBoundary>
                    </CacheBusterProvider>
                  </LocalizationProvider>
                </AuthProvider>
                <HelmetTagOrganizer />
              </QueryParamProvider>
            </BrowserRouter>
          </CookiesProvider>
        </Elements>
      </CacheProvider>
    </ThemeProvider>
  );
}

export default App;
