
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
StatusMap Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       28th November 2024

*******************************************************************************************/
import React                from 'react';
import { useOrderViewer }   from './OrderViewerContext';

// The Product Context 
const StatusMapContext = React.createContext(undefined);

const emptyArray = [];

const STATUS_CONSTANTS = {
    DISPATCHED          : "DISPATCHED",
    USER_INPUT          : "USER_INPUT",
    USER_INPUT_PENDING  : "USER_INPUT_PENDING",
    PROCESSING          : "PROCESSING",
    COMPLETE_PENDING    : "COMPLETE_PENDING",
    COMPLETE            : "COMPLETE",
    CANCELLED           : "CANCELLED"
};

export const STATUS_PROP_MAP = {
    [STATUS_CONSTANTS.DISPATCHED] : {
        userInputRequired   : false,
        userInputAvailable  : false,
        userInputConfirm    : false,
        hideProgress        : false
    },
    [STATUS_CONSTANTS.USER_INPUT] : {
        userInputRequired   : true,
        userInputAvailable  : true,
        userInputConfirm    : false,
        hideProgress        : false
    },
    [STATUS_CONSTANTS.USER_INPUT_PENDING] : {
        userInputRequired   : false,
        userInputAvailable  : true,
        userInputConfirm    : true,
        hideProgress        : false
    },
    [STATUS_CONSTANTS.PROCESSING] : {
        userInputRequired   : false,
        userInputAvailable  : false,
        userInputConfirm    : false,
        hideProgress        : false
    },
    [STATUS_CONSTANTS.COMPLETE_PENDING] : {
        userInputRequired   : false,
        userInputAvailable  : false,
        userInputConfirm    : false,
        hideProgress        : false
    },
    [STATUS_CONSTANTS.COMPLETE] : {
        userInputRequired   : false,
        userInputAvailable  : false,
        userInputConfirm    : false,
        hideProgress        : false
    },
    [STATUS_CONSTANTS.CANCELLED] : {
        userInputRequired   : false,
        userInputAvailable  : false,
        userInputConfirm    : false,
        hideProgress        : true
    },
}

const enforceTruthyArray = arrayOrValue => ( Array.isArray(arrayOrValue) ? arrayOrValue : [arrayOrValue] ).filter(Boolean); 

// StatusMap Provider
const StatusMapProvider = ({children}) => {

    const { deliveries } = useOrderViewer();

    /* 
        Schema of statusMap:
        [
            {
                id: string;                     // Identifier for the status (e.g., "userInputPending")
                status: string | undefined;     // Current status, may be undefined for certain entries
                statusExclude: string[];        // List of statuses to exclude
                userInputRequired: boolean;     // Whether userInput is required for this status
                userInputAvailable: boolean;    // Whether userInput is available for this status
                userInputConfirm: boolean;      // Whether userInput confirmation is required
                hideProgress?: boolean;         // (Optional) Whether progress is hidden
                deliveriesSubset: object[];     // Subset of deliveries matching this status
                empty: boolean;                 // Whether the subset is empty
                quantity: number;               // Number of deliveries in the subset
            }
        ]
    */
    const statusMap = React.useMemo(() => ([
        {
            id              : 'inputPending',
            status          : STATUS_CONSTANTS.USER_INPUT_PENDING,
            statusExclude   : emptyArray,
        },
        {
            id              : 'input',
            status          : STATUS_CONSTANTS.USER_INPUT, 
            statusExclude   : emptyArray, 
        },
        {
            id              : 'processing',
            status          : undefined, 
            statusExclude   : [ 
                STATUS_CONSTANTS.USER_INPUT,  
                STATUS_CONSTANTS.USER_INPUT_PENDING,  
                STATUS_CONSTANTS.COMPLETE, 
                STATUS_CONSTANTS.DISPATCHED,
                STATUS_CONSTANTS.CANCELLED
            ], //PROCESSING & COMPLETE_PENDING OK
        },
        {
            id              : 'cancelled',
            status          : STATUS_CONSTANTS.CANCELLED, 
            statusExclude   : emptyArray,
        },
        {
            id              : 'dispatched',
            status          : STATUS_CONSTANTS.DISPATCHED, 
            statusExclude   : emptyArray,
        },
        {
            id              : 'complete',
            status          : STATUS_CONSTANTS.COMPLETE, 
            statusExclude   : emptyArray,
        },
    ].map( item => {
        const include = enforceTruthyArray(item.status);
        const exclude = enforceTruthyArray(item.statusExclude);
        const deliveriesSubset = (
            deliveries
                .filter(d => !include.length ||  include.includes(d.status))
                .filter(d => !exclude.length || !exclude.includes(d.status))
        );
        const quantity = deliveriesSubset?.length;
        const empty    = Boolean(quantity <= 0);
        return {
            ...item,
            deliveriesSubset,
            empty,
            quantity,
        };
    })), [deliveries]);

    /* 
    Schema of statusMapDict:
    {
        [id: string]: {
            id: string;                 // Identifier for the status (e.g., "inputPending")
            status: string | undefined; // Current status, may be undefined for certain entries
            statusExclude: string[];    // List of statuses to exclude
            inputRequired: boolean;     // Whether input is required for this status
            inputAvailable: boolean;    // Whether input is available for this status
            inputConfirm: boolean;      // Whether input confirmation is required
            hideProgress?: boolean;     // (Optional) Whether progress is hidden
            deliveriesSubset: object[]; // Subset of deliveries matching this status
            empty: boolean;             // Whether the subset is empty
            quantity: number;           // Number of deliveries in the subset
        }
    }
    */
    const statusMapDict = React.useMemo(
        () => (
            Object
                .fromEntries(
                    statusMap.map((cur) => [cur?.id, cur])
                    .filter(([id]) => id)
                )
        ),
        [statusMap]
    );

    const statusMapNonEmpty     = React.useMemo(() => statusMap.filter(x => !x.empty), [statusMap]);
    const hasNonEmpty           = React.useMemo(() => statusMapNonEmpty.length > 0, [statusMapNonEmpty.length]);

    // Context values
    const value = {
        statusMap,
        statusMapDict,
        statusMapNonEmpty,
        hasNonEmpty,
        STATUS_CONSTANTS,
        STATUS_PROP_MAP
    };

    return (
        <StatusMapContext.Provider value={value}>
            {children}
        </StatusMapContext.Provider>
    )
}

// StatusMap Consumer
const StatusMapConsumer = ({children}) => {
    return (
        <StatusMapContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('StatusMapConsumer must be used within StatusMapProvider');
                }
                return children(context)
            }}
        </StatusMapContext.Consumer>
    )
}

// useStatusMap Hook
const useStatusMap = () => {
    const context = React.useContext(StatusMapContext);
    if(context === undefined)
        throw new Error('useStatusMap must be used within StatusMapProvider');
    return context;
}

export {
    StatusMapProvider,
    StatusMapConsumer,
    useStatusMap
}