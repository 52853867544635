
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Nakshatra Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       11th October 2024

*******************************************************************************************/
import React from 'react';
import moment                   from 'moment';
import {useNetwork}             from './NetworkContext';
import { 
    useStateEphemeral, 
    useCancelToken
}                               from '../hooks';
import {debounce}               from '../functions';

// The Product Context 
const NakshatraContext = React.createContext(undefined);

const makeDefaultData = (name, description) => ({ name, description });

const DEFAULT_DATA = [
    makeDefaultData(
        'ashwini',        
        "Ashwini Nakshatra is known for its speed and agility. Those born under this sign are often energetic, enthusiastic, and natural leaders. They have a pioneering spirit, but can also be impulsive."
    ),
    makeDefaultData(
        'bharani',        
        "Bharani Nakshatra represents creativity and nurturing. Individuals are often passionate and dedicated, but they may also struggle with control and possessiveness."
    ),
    makeDefaultData(
        'krittika',        
        "Krittika Nakshatra symbolizes strength and determination. People born under this sign are often ambitious and influential, but can also be assertive and aggressive."
    ),
    makeDefaultData(
        'rohini',        
        "Rohini Nakshatra is associated with beauty and fertility. Those born under this sign tend to be creative and nurturing, often drawn to the arts."
    ),
    makeDefaultData(
        'mrigashira',        
        "Mrigashira Nakshatra signifies curiosity and exploration. Individuals are often adaptable and inquisitive, but may struggle with commitment and indecisiveness."
    ),
    makeDefaultData(
        'ardra',        
        "Ardra Nakshatra represents change and transformation. Those born under this sign are often innovative and insightful but can also be restless and emotional."
    ),
    makeDefaultData(
        'punarvasu',        
        "Punarvasu Nakshatra symbolizes renewal and optimism. Individuals are often adaptable and resourceful, but may sometimes struggle with focus and consistency."
    ),
    makeDefaultData(
        'pushya',        
        "Pushya Nakshatra is associated with nourishment and support. People born under this sign tend to be caring and protective, often valuing family and community."
    ),
    makeDefaultData(
        'ashlesha',        
        "Ashlesha Nakshatra signifies intuition and depth. Individuals are often perceptive and strategic, but may also be secretive and manipulative."
    ),
    makeDefaultData(
        'magha',        
        "Magha Nakshatra represents leadership and authority. Those born under this sign tend to be confident and powerful but may also struggle with ego and dominance."
    ),
    makeDefaultData(
        'purva phalguni',        
        "Purva Phalguni Nakshatra is associated with creativity and pleasure. Individuals are often artistic and sociable but may also be indulgent."
    ),
    makeDefaultData(
        'uttara phalguni',        
        "Uttara Phalguni Nakshatra symbolizes stability and loyalty. People born under this sign are often dependable and supportive, but may also be rigid."
    ),
    makeDefaultData(
        'hasta',        
        "Hasta Nakshatra signifies skill and dexterity. Individuals are often talented and resourceful but may struggle with self-doubt and anxiety."
    ),
    makeDefaultData(
        'chitra',        
        "Chitra Nakshatra represents beauty and aesthetics. Those born under this sign are often creative and innovative but can be prone to superficiality."
    ),
    makeDefaultData(
        'swati',        
        "Swati Nakshatra symbolizes independence and flexibility. Individuals are often free-spirited and adaptable but may struggle with commitment."
    ),
    makeDefaultData(
        'vishakha',        
        "Vishakha Nakshatra signifies ambition and determination. People born under this sign are often driven and goal-oriented but can also be intense and competitive."
    ),
    makeDefaultData(
        'anuradha',        
        "Anuradha Nakshatra represents loyalty and friendship. Those born under this sign tend to be supportive and nurturing but may struggle with boundaries."
    ),
    makeDefaultData(
        'jyeshtha',        
        "Jyeshtha Nakshatra symbolizes wisdom and leadership. Individuals are often perceptive and authoritative but can also be prone to jealousy."
    ),
    makeDefaultData(
        'mula',        
        "Mula Nakshatra signifies exploration and discovery. Those born under this sign are often adventurous and transformative but may struggle with stability."
    ),
    makeDefaultData(
        'purva ashadha',        
        "Purva Ashadha Nakshatra represents victory and success. Individuals are often optimistic and enthusiastic but can also be overly confident."
    ),
    makeDefaultData(
        'uttara ashadha',        
        "Uttara Ashadha Nakshatra symbolizes perseverance and determination. People born under this sign are often resilient and strong-willed but may be stubborn."
    ),
    makeDefaultData(
        'shravana',        
        "Shravana Nakshatra signifies learning and communication. Individuals are often wise and articulate but may struggle with indecision."
    ),
    makeDefaultData(
        'dhanishta',        
        "Dhanishta Nakshatra represents wealth and abundance. Those born under this sign are often resourceful and industrious but can also be materialistic."
    ),
    makeDefaultData(
        'shatabhisha',        
        "Shatabhisha Nakshatra symbolizes healing and compassion. Individuals are often nurturing and caring but may struggle with their own emotional health."
    ),
    makeDefaultData(
        'purva bhadrapada',        
        "Purva Bhadrapada Nakshatra represents spirituality and mysticism. Those born under this sign are often insightful and intuitive but may also be elusive."
    ),
    makeDefaultData(
        'uttara bhadrapada',        
        "Uttara Bhadrapada Nakshatra signifies introspection and depth. Individuals are often philosophical and wise but may also be withdrawn."
    ),
    makeDefaultData(
        'revati',        
        "Revati Nakshatra symbolizes abundance and prosperity. People born under this sign tend to be caring and compassionate but may struggle with boundaries."
    )
];

// Export or use DEFAULT_DATA as needed


const BASE_API_URL = '/api/astrology/nakshatra';

// Nakshatra Provider
const NakshatraProvider = ({children}) => {
    const {
        axios, 
        socketUsers : socket
    }                                               = useNetwork();
    const {cancelToken }                            = useCancelToken();

    const [enabled, setEnabled]                     = React.useState(false);
    const [data,    setData]                        = React.useState([]);
    const [working, setWorking]                     = React.useState(false);
    const [queried, setQueried]                     = React.useState(undefined);
   
    // Error/Success Messages
    const   [messageSuccess,    setMessageSuccess]  = useStateEphemeral(undefined),
            [messageError,      setMessageError]    = useStateEphemeral(undefined,5000);

    // Clear Records
    const clear = React.useCallback(() => setData([]),[]);

    // GET
    const get = React.useCallback(() => new Promise((resolve,reject) => {
        setWorking(true);
        axios.get(BASE_API_URL, {cancelToken})
            .then(({data}) => {
                setMessageSuccess("Nakshatras queried")
                return data
            })
            .then(resolve)
            .catch(err => {
                setMessageError(err.message);
                reject(err)
            })
            .finally(() => {
                setWorking(false);
            })
    }),[axios, cancelToken, setMessageError, setMessageSuccess]);

    const refresh = React.useCallback( (reset) => {
        setWorking(true);
        if(reset) 
            clear();
        get()
            .then(setData)
            .catch(clear)
            .finally(() => {
                setWorking(false);
                setQueried(moment());
            })
    },[clear, get])

    const create = React.useCallback( ({name, description, rulers=[]}, query=true) => new Promise((resolve,reject) => {
        setWorking(true);
        axios.post(BASE_API_URL, {name, description, rulers}, {cancelToken})
            .then(() => {
                setMessageSuccess('Created nakshatra');
                resolve({})
            })
            .catch(err => {
                setMessageError(err?.message);
                reject(err);
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }),[axios, cancelToken, refresh, setMessageError, setMessageSuccess])

    const deleteById = React.useCallback( (id,query=true) => new Promise((resolve,reject) => {            
        setWorking(true);
        axios.delete(`${BASE_API_URL}/${id}`, {cancelToken})
            .then(data => {
                setMessageSuccess(`Deleted nakshatra ${id}`);
                resolve({})
            })
            .catch(err =>{
                setMessageError(err?.message);
                reject(err);
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }),[axios, cancelToken, refresh, setMessageError, setMessageSuccess])

    const postById = React.useCallback( ({_id, name, description, rulers=[]}, query=true) => new Promise((resolve,reject) => {
        setWorking(true);
        axios.post(`${BASE_API_URL}/${_id}`,{name, description, rulers}, {cancelToken})
            .then(() => {
                setMessageSuccess(`Updated nakshatra ${_id}`);
                resolve({})
            })
            .catch(err =>{
                setMessageError(err?.message);
                reject(err)
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }),[axios, cancelToken, refresh, setMessageError, setMessageSuccess])

    // Refresh on authentication
    React.useEffect(() => {
        if(enabled)
            refresh();
    },[enabled, refresh])

    // Refresh on SocketIO Instruction
    React.useEffect(() => {
        if(enabled && socket){
            socket.on('refresh_nakshatras', refresh)
            return () => {
                socket.off('refresh_nakshatras', refresh);
            }
        }
    },[enabled, refresh, socket]);

    const enable = React.useCallback( () => setEnabled(true) , []);

    // Context values
    const value = {
        data,
        setEnabled,
        enable,
        working,
        queried,
        refresh : debounce(refresh),
        create,
        postById,
        deleteById,
        messageSuccess,
        setMessageSuccess,
        messageError,
        setMessageError,
        DEFAULT_DATA,
        BASE_API_URL
    };

    return (
        <NakshatraContext.Provider value={value}>
            {children}
        </NakshatraContext.Provider>
    )
}

// Nakshatra Consumer
const NakshatraConsumer = ({children}) => {
    return (
        <NakshatraContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('NakshatraConsumer must be used within NakshatraProvider');
                }
                return children(context)
            }}
        </NakshatraContext.Consumer>
    )
}

// useNakshatra Hook
const useNakshatra = () => {
    const context = React.useContext(NakshatraContext);
    if(context === undefined)
        throw new Error('useNakshatra must be used within NakshatraProvider');
    return context;
}

export {
    NakshatraProvider,
    NakshatraConsumer,
    useNakshatra
}