
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Invoices Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       XXX

*******************************************************************************************/
import React                            from 'react';
import { 
    useNetwork,
    useUser
}                                       from 'contexts';
import { useCancelToken }               from 'hooks';
import { useOrderViewer }               from './OrderViewerContext';

const API_BASE_URL                      = '/api/user/order'

// The Product Context 
const InvoicesContext = React.createContext({});

const STATES = {
    UNCOLLECTIBLE   : "Abandoned",
    PAID            : 'Paid',
    OPEN            : 'Open',
    DRAFT           : 'Draft',
    VOID            : 'Void'      
}

// Invoices Provider
const InvoicesProvider = ({children}) => {

    const {orderId}                 = useOrderViewer();
    const {
        axios,
        isNetworkReady,
        socketUsers : socket
    }                               = useNetwork();
    const {cancelToken}             = useCancelToken();
    const {isAuthenticated }        = useUser();

    const [working, setWorking]     = React.useState(false);
    const [invoices, setInvoices]   = React.useState([]);
    const hasInvoices               = React.useMemo(() => (invoices || []).length > 0, [invoices]);
    const resetInvoices             = React.useCallback(() => setInvoices([]),[]);

    const refresh = React.useCallback(() => {
        if(orderId && isNetworkReady && isAuthenticated){
            setWorking(true);
            axios.get(`${API_BASE_URL}/${orderId.toString()}/invoice`, {cancelToken})
                .then(({data}) => data)
                .then(setInvoices)
                .catch((err) => {
                    console.error(err);
                    resetInvoices();
                })
                .finally(() => {
                    setWorking(false);
                })
        }
    }, [orderId, isNetworkReady, isAuthenticated, axios, cancelToken, resetInvoices])

    React.useEffect(refresh,[refresh])

    // Refresh all orders on SocketIO Instruction
    React.useEffect(()=>{
        const refreshById = (oid) => {
            if(!oid || oid !== orderId)
                return;
            refresh();
        }
        if(socket){
            socket.on('refresh_orders',     refresh)
            socket.on('refresh_order',      refreshById);
            return () => {
                socket.off('refresh_orders',    refresh);
                socket.on('refresh_order',      refreshById);
            }
        }
    },[orderId, refresh, socket])

    // Context values
    const value = {
        orderId,
        working,
        hasInvoices,
        resetInvoices,
        refresh,
        invoices,
        STATES
    };

    return (
        <InvoicesContext.Provider value={value}>
            {children}
        </InvoicesContext.Provider>
    )
}

// Invoices Consumer
const InvoicesConsumer = ({children}) => {
    return (
        <InvoicesContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('InvoicesConsumer must be used within InvoicesProvider');
                }
                return children(context)
            }}
        </InvoicesContext.Consumer>
    )
}

// useInvoices Hook
const useInvoices = () => {
    const context = React.useContext(InvoicesContext);
    if(context === undefined)
        throw new Error('useInvoices must be used within InvoicesProvider');
    return context;
}

export {
    InvoicesProvider,
    InvoicesConsumer,
    useInvoices
}