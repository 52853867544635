/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Delivery Progress
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       19th February 2022

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import pick                             from 'lodash/pick';
import isEmpty                          from 'lodash/isEmpty';
import { titleCase }                    from 'title-case';
import { useHistory }                   from 'react-router-dom';
import { FORM_ERROR }                   from 'final-form';
import {
    styled,
    useTheme,
    useMediaQuery,
    lighten,
    alpha,
    Box,
    Button      as ButtonMUI,
    Grid,
    Typography,
    Stepper     as StepperMUI,
    Step,
    StepLabel   as StepLabelMUI,
    StepIcon    as StepIconMUI,
    TableContainer,
    Table,
    TableRow,
    TableCell   as TableCellMUI,
    TableHead,
    TableBody,
    Tooltip,
    Paper,
    Button
}                                       from '@mui/material';
import EditIcon                         from '@mui/icons-material/Edit';
import InputIcon                        from '@mui/icons-material/Edit';
import SubmitIcon                       from '@mui/icons-material/Backup';
import CancelIcon                       from '@mui/icons-material/Cancel';
import CheckCircleIconMUI               from '@mui/icons-material/CheckCircle';
import ManageHistoryIcon                from '@mui/icons-material/ManageHistory';
import RateReviewIcon                   from '@mui/icons-material/RateReview';
import InputIconAlt                     from '@mui/icons-material/Input';
import SettingsIcon                     from '@mui/icons-material/Settings';
import { BiLibrary }                    from "react-icons/bi";
import { FaFilePdf }                    from "react-icons/fa";
import {
    Title,
    ItemPaper,
    FormAlert,
    DraggableDialog,
    CountDown,
    ConfirmationButton,
    RefreshIcon,
    DigitalFont,
    CompletionBar,
    LoadingData,
    ObjectId,
    NatalSummary,
    FatButton,
    JSONViewer,
    SpaceBox,
    FileIcon,
    Thumbnail,
    TabProvider,
    Tabs,
    TabPanel,
    ColorChip,
    VerticalSpaceContainer,
    AlertOnBehalfOfUser,
    AdminDeliveryPanel
}                                       from 'components';
import {
    DeliveryReview,
    DeliveryReviewSummary,
    DeliveryReviewAlert,
}                                       from 'components/order';
import {EditButton}                     from 'components/buttons';
import {
    DeliveryIconButton, 
    EditIconButton, 
    OrderIconButton
}                                       from 'components/iconButtons';
import DeliveryUserInputForm            from 'components/forms/DeliveryUserInputForm';
import { STATUS_PROP_MAP }              from 'components/order/context/StatusMapContext';
import {
    useOrders, 
    useLocale, 
    useProduct,
    useNetwork,
    useTrackId,
    useUser
}                                       from 'contexts';
import {
    useMoment,
    useCancelToken,
    useUserFieldOptions,
    useImageCDN,
    useStateEphemeral
}                                       from 'hooks';
import { 
    UserLibraryCollectionLocation,
    UserOrderLocation,
    UserDeliveryLocation
}                                       from "router/locations";
import { withTranslation }              from 'components/hoc';
import { IconModal } from './modals';

const DeliveryProgressLabel = withTranslation(({t : tBase, status, fallback = undefined}) => {

    const t = React.useCallback((key) => tBase(`components.deliveryProgress.deliveryProgressLabel.${key}`),[tBase]);

    const labels = React.useMemo(() => ({
        CREATED : (
            <Typography sx={{fontSize:'inherit', color : theme => theme.palette.info.main}}>
                {t("CREATED")}
            </Typography>
        ),
        CANCELLED : (
            <Typography sx={{fontSize:'inherit', color : theme => theme.palette.error.main}}>
                {t("CANCELLED")}
            </Typography>
        ),
        USER_INPUT : (
            <Typography sx={{fontSize:'inherit', color : theme => theme.palette.warning.main}}>
                {t("USER_INPUT")}
            </Typography>
        ),
        USER_INPUT_PENDING : (
            <Typography sx={{fontSize:'inherit', color : theme => theme.palette.success.main}}>
                {t("USER_INPUT_PENDING")}
            </Typography>
        ),
        USER_INPUT_CANCELLED : (
            <Typography sx={{fontSize:'inherit', color : theme => theme.palette.error.main}}>
                {t("USER_INPUT_CANCELLED")}
            </Typography>
        ),
        PROCESSING : (
            <Typography sx={{fontSize:'inherit', color : theme => theme.palette.info.main}}>
                {t("PROCESSING")}
            </Typography>
        ),
        COMPLETE_PENDING : (
            <Typography sx={{fontSize:'inherit', color : theme => theme.palette.info.main}}>
                {t("COMPLETE_PENDING")}
            </Typography>
        ),
        COMPLETE : (
            <Typography sx={{fontSize:'inherit', color : theme => theme.palette.success.main}}>
                {t("COMPLETE")}
            </Typography>
        ),
        EXPECTED_RELEASE : (
            <Typography sx={{fontSize:'inherit', color : theme => theme.palette.info.main}}>
                {t("EXPECTED_RELEASE")}
            </Typography>
        )
    }),[t]);

    return (
        <>
            { labels[status] || fallback || status }
        </>
    )
})

const cancelledStatus           = "CANCELLED";
const userInputStatus           = "USER_INPUT";
const userInputPendingStatus    = "USER_INPUT_PENDING";
const processingStatus          = "PROCESSING";
const completePendingStatus     = "COMPLETE_PENDING";
const completeStatus            = "COMPLETE";
// const dispatchedStatus       = "DISPATCHED";

const SPACING_CONTAINER         = 1;

const DeliveryPaper = styled(Paper,{
    shouldForwardProp : prop => !['userInputRequired', 'userInputConfirm','completed','highlighted'].includes(prop)
})(({theme, userInputRequired=false, userInputConfirm=false, completed=false, highlighted=false}) => ({
    position                    : 'relative',
    width                       :  '100%',
    padding                     : theme.spacing(2),
    transitionTimingFunction    : 'ease-out',
    overflow                    : 'hidden',
    background                  : 'inherit',
    borderTop                   : `5px solid ${theme.palette.divider}`,
    ...((userInputRequired && !userInputConfirm) && {
        border : `1px dashed ${theme.palette.warning.light}`,
        background : theme.palette.mode === 'dark' 
            ? alpha(theme.palette.warning.light,0.05)
            : lighten(theme.palette.warning.light,0.95)
    }),
    ...((userInputConfirm) && {
        border : `1px dashed ${theme.palette.error.light}`,
        background : theme.palette.mode === 'dark' 
            ? alpha(theme.palette.error.light,0.025)
            : lighten(theme.palette.error.light,0.975)
    }),
    ...(completed && {
        background : theme.palette.mode === 'dark' 
            ? alpha(theme.palette.success.light,0.05)
            : lighten(theme.palette.success.light,0.95)
    }),
    ...(highlighted && {
        background : [alpha(theme.palette.info.dark,0.30),'!important'],
            '&:hover' : {
                background : [alpha(theme.palette.info.dark,0.20),'!important']
            },
            border : [`1px solid ${theme.palette.info.dark}`,'!important'],
            margin : -1,
            width : `calc(100% + 2px)`
    }),
}))

const Image = styled('img',{
    shouldForwardProp : prop => prop !== 'large'
})(({large = false}) => ({
    transitionTimingFunction : 'ease',
    transition      : '1s',
    width           : 75,
    ...(large && {
        width       : 150
    })
}));

const TableCell = styled(TableCellMUI,{
    shouldForwardProp : prop => !['attribute','dashed','lastRow','subCell'].includes(prop)
})(({theme,attribute=false,dashed=false,lastRow=false,subCell=false}) => ({
    borderColor     : 'inherit',
    color           : 'inherit',
    padding         : theme.spacing(0.5),
    verticalAlign   : 'top',
    transform       : 'translatey(-1px)',
    ...(attribute && {
        width       : 100
    }),
    ...(dashed && {
        borderBottomSize    : '1px',
        borderBottomStyle   : 'dashed',
        borderBottomColor   : 'inherit'
    }),
    ...(lastRow && {
        borderBottom : 'none!important',
    }),
    ...(subCell && {
        padding         : 0,
    }),
}))

const VirtualDeliveryContainer = styled(ItemPaper)(({theme}) => ({
    padding         : theme.spacing(0.5),
    background      : 'transparent',
    border          : `1px dashed ${theme.palette.divider}`,
    borderRadius    : theme.spacing(0.5)
}))

const StepIcon = styled(StepIconMUI,{
    shouldForwardProp : prop => prop !== 'userInputRequired' && prop !== 'userInputAvailable'
})(({userInputAvailable = false, userInputRequired = false}) => ({
    '& > .MuiStepIcon-text' : {
        ...(userInputRequired && {
        }),
        ...(userInputAvailable && {
        })
    }
}));

const StepLabel = styled(StepLabelMUI,{
    shouldForwardProp : prop => prop !== 'userInputRequired' && prop !== 'userInputAvailable'
})(({userInputAvailable = false, userInputRequired = false}) => ({
    "& > .MuiStepLabel-label" : {
        ...(userInputRequired && {
        }),
        ...(userInputAvailable && {
        })
    },
    "& > .MuiStepLabel-active" : {
        fontWeight : [400,'!important'],
        ...(userInputRequired && {
        }),
        ...(userInputAvailable && {
        })
    },
    "& > .muiStepLabel-completed" : {
        ...(userInputRequired && {
        }),
        ...(userInputAvailable && {
        })
    }
}));

const Details = styled(Box,{
    shouldForwardProp : prop => prop !== 'expanded'
})(({theme,expanded = false}) => ({
    position        : 'relative', 
    width           : '100%',
    padding         : theme.spacing(0),
    // EXPANDED
    ...(expanded && {

    }),
    // COLLAPSED
    ...(!expanded && { 
        height          : 0,
        overflow        :'hidden'
    })
}));

const noop = () => {};

const DetailsContainer = styled(Box,{
    shouldForwardProp : prop => prop !== 'itemContent' && prop !== 'userInput'
})(({theme, itemContent = false, userInput = false}) => ({
    textAlign       : 'justify',
    borderRadius    : 2,
    padding         : theme.spacing(SPACING_CONTAINER), 
    ...(itemContent && {
        '& > * + *' : {
            marginTop   : theme.spacing(2),
        },
    }),
    ...(userInput && {

    })
}));

const Stepper = styled(StepperMUI)(({theme}) => ({
    '&.MuiStepper-root' : {
        background : 'transparent'
    }
}));

const ItemContainer = styled(Box)(({theme}) => ({
    display : "flex",
    width   :'100%',
    "& > * + *" : {
        marginLeft  : theme.spacing(2),
    },
}));

const CheckCircleIcon = styled(CheckCircleIconMUI)(({theme}) => ({
    fontSize    : '1rem',
    marginTop   : 'auto',
    marginBottom: 'auto',
    color       : theme.palette.success.light,
    '&:hover' : {
        color : [theme.palette.success.main,'!important']
    }
}));

const TimerContainer = styled(Box)(({theme}) => ({
    fontSize    : '2em',
    overflow    : 'hidden',
    background  : theme.palette.action.hover,
    // position    : 'absolute',
    // top         : 0,
    // right       : 0,
    padding     : theme.spacing(1),
    lineHeight  : 'normal',
    [theme.breakpoints.down('xl')] : {
        fontSize : '1.75em'
    },
    [theme.breakpoints.down('lg')] : {
        fontSize : '1.5em'
    },
    [theme.breakpoints.down('md')] : {
        fontSize : '1.25rem'
    },
    [theme.breakpoints.down('sm')] : {
        fontSize : '1rem'
    }
}));

const ClickBox = styled(Box)(({ onClick }) => ({
    cursor: onClick ? 'pointer' : 'default',
    zIndex: 2,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
}));

const ChipArray = ({values, ...props}) => values.map((label,ix) => (
    <ColorChip 
        key     = {ix} 
        label   = {titleCase(label)} 
        color   = "secondary" 
        size    = "small" 
        {...props}
        sx      = {{
            ...props.sx,
            p               : 0,
            marginRight     : '2px',
            marginBottom    : '2px',
            height          : 20
        }}
    /> 
));

const CompleteIcon = withTranslation( ({t, tooltip = undefined, ...props}) => (
    <Tooltip title={tooltip || t('common.complete')}>
        <CheckCircleIcon {...props}/>
    </Tooltip>
));

const DeliveryStatusHistory = withTranslation( ({t, delivery, maxrows = 6, showTitle = true, ...props}) => {
    const {formatDateTime}          = useLocale();
    const theme                     = useTheme();
    const now                       = useMoment();
    const [expanded, setExpanded]   = React.useState(false);

    const handleToggle              = React.useCallback( (e) => {
        e.stopPropagation();
        setExpanded(prev => !prev);
    }, []);

    if(!delivery?.statusHistory)
        return null;

    // Inject CREATED into the status history
    let statusHistory = [];
    if(Array.isArray(delivery?.statusHistory)){

        // Default
        statusHistory = [
            ...delivery?.statusHistory
        ];

        // Inject Created
        statusHistory = [
            {status:'CREATED', createdAt : delivery?.createdAt},
            ...statusHistory
        ]

        // Inject Input Cancelled
        statusHistory = statusHistory.reduce((a,c,ix) => {
            if(ix > 0 && c.status === userInputStatus && statusHistory[ix-1].status === userInputPendingStatus)
                    return [ ...a, { status : 'USER_INPUT_CANCELLED', createdAt   : c.createdAt }, c]
            return [...a, c];
        },[])

        // Inject Expected Release
        if(statusHistory.length > 0 && statusHistory[statusHistory.length - 1].status === completePendingStatus){
            statusHistory = [
                ...statusHistory,
                {status:'EXPECTED_RELEASE', createdAt : delivery?.releaseAfter}
            ]
        }
    }

    const sliceRows             = Math.abs(maxrows)
    const exceedsMaxRows        = statusHistory.length > maxrows;
    const statusHistoryTrim     = ((exceedsMaxRows && !expanded) ? statusHistory.slice(-sliceRows) : statusHistory)
    const expandRowCount        = statusHistory.length - statusHistoryTrim.length;
    const collapseRowCount      = statusHistory.length - sliceRows;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {
                    showTitle &&
                    <Typography gutterBottom variant="h5">
                        { t('components.deliveryProgress.deliveryStatusHistory.statusHistory') }
                    </Typography>
                }
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                <TableCell>
                                    {t('common.when')}
                                </TableCell>
                                <TableCell>
                                    {t('common.status')}
                                </TableCell>
                                <TableCell>
                                    {t('common.date')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <React.Fragment>
                                {
                                    exceedsMaxRows &&
                                    <TableRow hover={true}>
                                        <TableCell onClick={handleToggle} colSpan={4} align="center" style={{padding:0}}>
                                            <ButtonMUI onClick={handleToggle} size="small" color="secondary" style={{textTransform:'none'}}>
                                                {
                                                    !expanded &&
                                                    <>
                                                        {
                                                            expandRowCount > 0 
                                                                ? t('components.deliveryProgress.deliveryStatusHistory.expandRowsQuantity', {quantity : expandRowCount})
                                                                : t('components.deliveryProgress.deliveryStatusHistory.expandRows')
                                                        }
                                                    </>
                                                }
                                                {
                                                    expanded && 
                                                    <>
                                                        {
                                                            collapseRowCount > 0 
                                                                ? t('components.deliveryProgress.deliveryStatusHistory.collapseRowsQuantity', {quantity : collapseRowCount})
                                                                : t('components.deliveryProgress.deliveryStatusHistory.collapseRows')
                                                        }
                                                    </>
                                                }
                                            </ButtonMUI>
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    statusHistoryTrim.map((s,ix) => {
                                        const createdAt = s?.createdAt ? moment(s?.createdAt) : undefined
                                        const humanized = moment.isMoment(now) && moment.isMoment(createdAt) ? moment.duration( createdAt.diff(now) ).humanize(true) : null
                                        return (
                                            <TableRow hover={true} key={ix}>
                                                <TableCell>
                                                    {ix + 1 + (!expanded && Math.max(collapseRowCount,0))}       
                                                </TableCell>
                                                <TableCell 
                                                    style = {{
                                                        whiteSpace      : 'nowrap',
                                                        overflow        : 'hidden',
                                                        textOverflow    : 'ellipsis',
                                                        fontStyle       : 'italic',
                                                        paddingRight    : theme.spacing(1)
                                                    }}
                                                >
                                                    {humanized}
                                                </TableCell>
                                                <TableCell width="100%">
                                                    <DeliveryProgressLabel status={s?.status} />
                                                </TableCell>
                                                <TableCell 
                                                    style={{
                                                        whiteSpace      : 'nowrap',
                                                        overflow        : 'hidden',
                                                        textOverflow    : 'ellipsis'
                                                    }}
                                                >
                                                    {moment.isMoment(createdAt) ? formatDateTime(createdAt) : t('common.unknown') }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </React.Fragment>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
});

const DynamicUserDataCell = withTranslation( ({t, type, data, options, ...props}) => {

    const convert = useImageCDN();
    
    // No Data
    if(!data)
        return null;

    // Handle different type
    switch(type){
        case 'natal':
            return <NatalSummary {...data} localTime  = {typeof data?.localTime === 'boolean' ? data?.localTime : true } />;
        case 'enum':
            const option = options.find(ex => data && ex.value === data);
            const thumbnail     = convert(option?.image, { operation : 'width', width : 50  });
            const highres       = convert(option?.image, { operation : 'width', width : 500 });
            return (
                <Box display="flex">
                    {
                        option?.image && 
                        <Box>
                            <Thumbnail title="Preview" thumbnail={thumbnail} src={highres} size={500} thumbnailSize={16}>
                                {t('components.deliveryProgress.dynamicUserDataCell.previewOnlyOrderMayDiffer')}
                            </Thumbnail>
                        </Box>
                    }
                    <Box flexGrow={1} ml={1}>
                        {option?.label || data}
                    </Box>
                </Box>
            )
        default:
            return (
                <div>
                   {typeof data === 'object' ? JSON.stringify(data) : titleCase(data)}
                </div>
            )
    }
})

const Progress = ({activeStep, steps, delivery, userInputRequired, userInputAvailable, ...props}) => {
    
    if(!delivery)
        return null;
    return (
        <DetailsContainer>
            <Stepper activeStep={activeStep} alternativeLabel>
                {
                    (steps || []).filter(s => s !== cancelledStatus).map((s,ix)=>(
                        <Step key={ix} completed={ix < activeStep || delivery?.status === completeStatus}>
                            <StepLabel 
                                StepIconComponent = {StepIcon} 
                                StepIconProps = {{
                                    userInputRequired   : userInputRequired,
                                    userInputAvailable  : userInputAvailable
                                }}
                                userInputRequired   = {userInputRequired}
                                userInputAvailable  = {userInputAvailable}
                            >
                                <DeliveryProgressLabel 
                                    status      = {s} 
                                    fallback    = {titleCase((s || '').toLowerCase().replace(/_/g, ' '))} 
                                />
                            </StepLabel>
                        </Step>
                    ))
                }
            </Stepper>
        </DetailsContainer>
    );
}

const ProductImage = withTranslation( ({t, delivery = undefined, ...props}) => {
    const convert                   = useImageCDN();
    const {DEFAULT_PRODUCT_IMAGE}   = useProduct();
    if(!delivery) 
        return null;
    return (
        <Image 
            src         = {convert(delivery?.product?.cover || DEFAULT_PRODUCT_IMAGE)} 
            alt         = {t('components.deliveryProgress.productImage.productIdCover', {id : delivery?.product?._id})}
            large       = {false}
            {...props}
        />
    )
})

const DeliveryHeader = withTranslation( ({
    t, 
    delivery                = undefined, 
    order                   = undefined,
    userInputRequired       = false, 
    userInputAvailable      = false, 
    userPendingRelease      = false,
    onClick : handleClick   = noop
}) => {
    
    const quantity                                  = (order?.items || []).find(i => i?.product && i?.product === delivery?.product?.id)?.quantity;
    const now                                       = useMoment();
    const [releaseAfter,        setReleaseAfter]    = React.useState(undefined);
    const [isReleaseOverdue,    setReleaseOverdue]  = React.useState(false);

    React.useEffect(()=>{
        setReleaseAfter(delivery?.releaseAfter ? moment(delivery?.releaseAfter) : undefined)
    },[delivery?.releaseAfter])

    React.useEffect(() => {
        setReleaseOverdue(delivery?.status === processingStatus && moment.isMoment(releaseAfter) && releaseAfter < now);
    },[delivery.status, releaseAfter, now, isReleaseOverdue])

    const loading = !delivery || !order;
    return (
        <>

            {
                (
                    !loading 
                        ? delivery?.product?.name 
                        : undefined
                ) || "Loading ...."
            }
            {
                loading &&
                <Typography component="div" sx={{fontStyle:'italic'}}>
                    Loading ...
                </Typography>
            }
            {
                !loading &&
                <>
                    {
                        quantity && quantity > 1 &&
                        <sup style={{paddingRight:5}}>
                            <Typography component="span">
                                {delivery.ix + 1}{quantity && `/${quantity}`}
                            </Typography>
                        </sup>
                    }
                    {
                        userInputRequired && 
                        <div onClick={handleClick}>
                            <Typography component="span" sx={{fontStyle:'italic'}}>
                                {t('components.deliveryProgress.deliveryHeader.userInputRequired')}
                            </Typography>
                        </div>
                    }
                    {
                        userInputAvailable && !userInputRequired &&
                        <div onClick={handleClick}>
                            <Typography component="span" sx={{fontStyle:'italic'}}>
                                {t('components.deliveryProgress.deliveryHeader.awaitingConfirmation')}
                            </Typography>
                        </div>
                    }
                    {
                        userPendingRelease && !(userInputAvailable && !userInputRequired) &&
                        <div onClick={handleClick}>
                            <Typography component="span" sx={{fontStyle:'italic'}}>
                                {t('components.deliveryProgress.deliveryHeader.pendingRelease')}
                            </Typography>
                        </div>
                    }
                    {
                        isReleaseOverdue && !(userPendingRelease && !(userInputAvailable && !userInputRequired)) &&
                        <div onClick={handleClick}>
                            <Typography color="error" component="span" sx={{fontStyle:'italic'}}>
                                {t('components.deliveryProgress.deliveryHeader.deliveryDue')}
                            </Typography>
                        </div>
                    }
                </>
            }
        </>
    )
});

const DeliveryTable = withTranslation( ({
    t,
    disabled                    = false,
    working                     = false,
    delivery                    = undefined,
    order                       = undefined,
    userFields : userFieldsIn   = undefined,
    userInputRequired           = false, 
    userInputAvailable          = false,
    userInputSubmitted          = false,
    onClick  : handleClick      = noop
}) => {

    const theme                                 = useTheme();
    const {formatDateTime}                      = useLocale();
    const userFields                            = useUserFieldOptions(userFieldsIn, delivery?.product?._id);

    const {isNetworkReady}                      = useNetwork();
    const lgUp                                  = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const smDown                                = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const createdAt                             = React.useMemo(() => delivery?.createdAt ? moment(delivery?.createdAt) : undefined, [delivery?.createdAt]);
    const showAction                            = React.useMemo(() => (userInputRequired || userInputAvailable), [userInputAvailable, userInputRequired]); // && canEdit;

    const fields                                = React.useMemo(() => userFields.reduce((acc,cur) => ([...acc,cur.name]),[]), [userFields]);
    const handleClickEdit                       = React.useCallback( () => {
        const formData = {
            ...userFields.reduce((acc,cur) => ({...acc,[cur.name] : undefined}),{}), 
            ...pick(delivery?.userInput?.values, fields)
        };
        const types = {
            ...userFields.reduce((acc,cur) => ({...acc,[cur.name]:cur.type}),{})
        }; 
        handleClick(formData, types, userFields);
    }, [delivery?.userInput?.values, fields, handleClick, userFields]);

    if(!delivery || !order || !userFields)
        return null;

    return (
        <>
            {
                showAction && 
                <Box display="flex">
                    <Box flexGrow={1}/>
                    <Box sx={{mb:2}}>
                        <EditButton 
                            startIcon   = {working ? <RefreshIcon loading={working}/> : <EditIcon/>}
                            disabled    = {disabled || (!userInputRequired && !userInputAvailable) || working}
                            onClick     = {handleClickEdit} 
                            color       = "primary"
                            variant     = "contained" 
                            size        = "large"
                        >
                            {
                                userInputSubmitted 
                                    ? t('components.deliveryProgress.deliveryTable.updateRequiredData') 
                                    : t('components.deliveryProgress.deliveryTable.provideRequiredData')
                            }
                        </EditButton>
                    </Box>
                    <Box flexGrow={1}/>
                </Box>
            }
            <TableContainer style={{width:'100%'}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell/>

                            {
                                !smDown && 
                                <TableCell>
                                    {t('common.name')}
                                </TableCell>
                            }
                            
                            <TableCell style={{minWidth:150}} align="left">
                                {t('common.attributes')}
                            </TableCell>

                            {
                                false && showAction && 
                                <TableCell align="center">
                                    {t('common.action')}
                                </TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        userFields.map((field,ix)=>{
                            const data      = delivery?.userInput?.values[field.name];
                            const lastRow   = ix >= userFields.length - 1;
                            return (
                                <TableRow key={ix} hover>
                                    <TableCell lastRow={true}>
                                        {ix + 1}
                                    </TableCell>
                                    {
                                        !smDown &&
                                        <TableCell lastRow={true} style={{whiteSpace:'nowrap'}}>
                                            <strong>{field.label}</strong>
                                        </TableCell>
                                    }
                                    <TableCell subCell={true} lastRow={lastRow} align="left">
                                        <Table>
                                            <TableBody>
                                                {
                                                    smDown && 
                                                    <TableRow>
                                                        <TableCell dashed={true} colSpan={2}>
                                                            <strong>{field.label}</strong>
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                                <TableRow>
                                                    <TableCell attribute={true} dashed={true}>
                                                        {t('common.type')}
                                                    </TableCell>
                                                    <TableCell dashed={true}>
                                                        { field?.typeLabel}
                                                        {!field?.typeLabel && 
                                                            <>
                                                                {
                                                                    field?.enum 
                                                                        ? t('components.deliveryProgress.deliveryTable.selectionOptions') 
                                                                        : titleCase(field.type)
                                                                }
                                                                {
                                                                    userInputRequired && Array.isArray(field?.enum) && 
                                                                    <Box component="span" sx={{ml:0.5}}>
                                                                        <ChipArray values={field.enum} />
                                                                    </Box>
                                                                }
                                                            </>
                                                        }
                                                        
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell attribute={true} dashed={true}>
                                                        {t('common.description')}
                                                    </TableCell>
                                                    <TableCell dashed={true}>
                                                        {field.description}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell attribute={true} dashed={true}>
                                                        {t('common.value')}
                                                    </TableCell>
                                                    <TableCell dashed={true}>
                                                        {
                                                            data &&
                                                            <DynamicUserDataCell description={field.description} type={field.type} data={data} options={field?.options}/>
                                                        }
                                                        {
                                                            false && !data && 
                                                            <ColorChip disabled={!isNetworkReady || working} icon={<InputIcon/>} onClick={handleClickEdit} size="small" color="primary" label={t('common.notSubmitted')}/>
                                                        }
                                                        {
                                                            !data && 
                                                            "Awaiting User Input"
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                {
                                                    false && 
                                                    <TableRow>
                                                        <TableCell attribute={true} dashed={true}>
                                                            {t('common.valid')}
                                                        </TableCell>
                                                        <TableCell dashed={true}>
                                                            { typeof delivery?.userInput?.valid === 'boolean'
                                                                ? delivery?.userInput?.valid 
                                                                    ? t('common.yes') 
                                                                    : t('common.no')
                                                                : t('common.notSubmitted')
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                                <TableRow>
                                                    <TableCell attribute={true} dashed={true} lastRow={true}>
                                                        {t('common.submitted')}
                                                    </TableCell>
                                                    <TableCell dashed={true} lastRow={true}>
                                                        {(createdAt && delivery?.userInput?.valid)
                                                            ? formatDateTime(moment(createdAt)) 
                                                            : t('common.notYet')
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>

                                    </TableCell>
                                    {
                                        false && showAction && 
                                        <TableCell lastRow={true} align="center">
                                            {lgUp && 
                                                <EditButton 
                                                    startIcon   = {working ? <RefreshIcon loading={working}/> : <EditIcon/>}
                                                    disabled    = {(!userInputRequired && !userInputAvailable) || working}
                                                    onClick     = {handleClickEdit} 
                                                    color       = "primary"
                                                    variant     = "contained" 
                                                    size        = "small"
                                                />
                                            }
                                            {!lgUp && 
                                                <EditIconButton
                                                    Icon        = {working ? RefreshIcon : EditIcon}
                                                    disabled    = {(!userInputRequired && !userInputAvailable) || working}
                                                    onClick     = {handleClickEdit} 
                                                    size        = "small"
                                                    IconProps   = {{
                                                        style : { color:theme.palette.primary.main },
                                                        ...(working ? {loading:true} : {})
                                                    }}
                                                />
                                            }
                                        </TableCell>
                                    }
                                </TableRow>
                            );
                        })
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
});

const Review = withTranslation( ({t, deliveryId = undefined, title = undefined, render = null}) => {
    
    if(!deliveryId)
        return null;

    return (
        <DeliveryReview 
            title           = {title || t('common.review')}
            deliveryId      = {deliveryId} 
            itemPaperProps  = {{sx : {background : 'inherit', padding : 0 }}}
            render          = {render}
        />
    )
});

const TAB_PANEL_USER_INPUT      = 'input'  ;    //0x001;
const TAB_PANEL_STATUS_HISTORY  = 'history' ;   //0x002;
const TAB_PANEL_REVIEW          = 'review';     //0x004;

export const OrderDeliveryProgress = withTranslation( ({
    t,
    delivery : deliveryIn, 
    showDeliveryLink            = true,
    showOrderLink               = false,    
    ...props
}) => {
    const [order,setOrder]                  = React.useState(undefined);
    const {getOrderFromMemoryOrById}        = useOrders();

    // THeme
    const history                           = useHistory();

    // Locale
    const {formatDateTime}                  = useLocale();

    // Network
    const {axios}                           = useNetwork();
    const {cancelToken}                     = useCancelToken();
    const {isAuthenticated,userId,isAdmin}  = useUser();

    // Orders Working
    const {working:workingOrders}           = useOrders();

    // Get products and processors
    const {data:products, processors}       = useProduct();

    const {trackId, setTrackId}             = useTrackId();

    const tabProviderRef                    = React.useRef(null);

    // Delivery State
    const [delivery,    setDelivery]        = React.useState(deliveryIn);
    React.useEffect(() => (
        setDelivery(deliveryIn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [JSON.stringify(deliveryIn || {})]);

    const {
        userInputConfirm    = false,
        userInputRequired   = false,
        userInputAvailable  = false,
        hideProgress        = false
    } = React.useMemo(() => STATUS_PROP_MAP[delivery?.status] || {}, [delivery?.status]);

    React.useEffect(() => {
        if(delivery?.order){
            getOrderFromMemoryOrById(delivery?.order?.id || delivery?.order)
                .then(setOrder)
                .catch(console.error)
        }else{
            setOrder(undefined);
        }
    },[delivery?.order, getOrderFromMemoryOrById])

    // Order State
    /*
    const [order,       setOrder]           = React.useState(orderIn);
    React.useEffect(() => (
        setOrder(orderIn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [JSON.stringify(orderIn || {})]);
    */

    // Product State
    const [product,     setProduct]         = React.useState(undefined);

    // Processor State
    const [processor,   setProcessor]       = React.useState({});

    // Working
    const [workingUpdate, setWorkingUpdate] = React.useState(false);
    const [userInputData, setUserInputData] = React.useState(undefined);
    const [
        userInputDisabled,      
        setUserInputDisabled
    ]                                       = useStateEphemeral(false,5000,false)
    const handleClose                       = React.useCallback( () => setUserInputData(undefined), []);
    const handleOpen                        = React.useCallback( (data, types, fields) => setUserInputData({data, types, fields}), []);

    // Update Product
    React.useEffect(()=>{
        setProduct(
            products.find(item => item?._id && item?._id === delivery?.product?.id)
        );
    },[delivery?.product?.id, products])

    // Extract Processor
    React.useEffect(()=>{
        if(product && product?.type){
            let type = product?.type;
            setProcessor(processors[type]?.find(p => p.className === product.processor))
        }else{
            setProcessor({});
        }
    },[product,processors])

    // State expanded or contracted
    const expanded = React.useMemo(() => true, []);
    /*
    const [expanded, setExpanded] = React.useState( [userInputRequired, userInputAvailable, userInputConfirm].some(Boolean));

    React.useEffect(() => {
        if(trackId && trackId === delivery?.id)
            setExpanded(true);
    },[delivery?.id, trackId])

    // Function to expand content
    const expand = React.useCallback( () => setExpanded(true), []);

    // Function to collapse content
    const collapse = React.useCallback( () => setExpanded(false), []);
    */
    // const [expanded, setExpanded] = React.useState( true );

    // Reduced Fields
    const userFields = Object
        .keys((processor?.userFields || {}))
        .reduce((acc,cur) => ([...acc, {name:cur,...processor.userFields[cur]}]),[])
        .filter(f => userInputRequired || delivery?.userInput?.values[f.name]);

    const hasUserFields = Array.isArray(userFields) && userFields.length > 0;

    // Steps and current Step
    const steps         = React.useMemo(() => (
        hasUserFields 
            ? delivery.statusOptions
            : delivery.statusOptions.filter(x => ![userInputStatus,userInputPendingStatus].includes(x))
    ), [delivery.statusOptions, hasUserFields]);

    // Step
    const step          = React.useMemo(() => delivery.status, [delivery.status]);

    // Determine the active step index
    const activeStep    = React.useMemo(() => steps.indexOf(step), [step, steps]);

    const orderId = React.useMemo(() => {
        if (typeof delivery?.order === 'string') return delivery.order;
        if (typeof delivery?.order === 'object' && delivery.order !== null) return delivery.order.id;
        return undefined;
    }, [delivery?.order]);

    const confirmDelivery = React.useCallback(() => new Promise((resolve,reject) => {
        if(delivery && orderId){
            setWorkingUpdate(true);
            setTrackId(delivery.id);
            axios.post(`/api/user/order/${orderId}/delivery/${delivery.id}/confirm`,{},{cancelToken})
                .then(({data}) => data)
                .then(resolve)
                .catch(reject)
                .finally(() => {
                    setWorkingUpdate(false);
                })
        }else{
            reject(new Error(t('components.deliveryProgress.orderAndDeliveryRequired')))
        }
    }),[delivery, orderId, setTrackId, axios, cancelToken, t])

    // Handle Confirm User Input
    const handleConfirm = React.useCallback(() => new Promise(resolve => {
        confirmDelivery()
            .then(delivery => {
                setTrackId(delivery.id);
                setDelivery(delivery);
                resolve({})
            })
            .catch(({errors}) => resolve(errors));
    }),[confirmDelivery, setTrackId])

    const submitDelivery = React.useCallback((formData) => new Promise((resolve,reject) => {
        if(delivery?.id && orderId){
            setWorkingUpdate(true);
            setTrackId(delivery.id);
            axios.post(`/api/user/order/${orderId}/delivery/${delivery.id}`, {userInput : formData}, {cancelToken})
                .then(({data}) => resolve(data))
                .catch(reject)
                .finally(() => setWorkingUpdate(false) );
        }else{
            reject({
                errors : { 
                    [FORM_ERROR]: t('components.deliveryProgress.orderAndDeliveryRequired')
                }
            });
        }
    }),[delivery, orderId, setTrackId, axios, cancelToken, t])

    // Handle Submit User Input
    const handleSubmit = React.useCallback((formData) => new Promise(resolve => {
        submitDelivery(formData)
            .then(delivery => {
                setTrackId(delivery.id)
                setDelivery(delivery);
                setUserInputDisabled(true); // ephemeral
                handleClose();
                resolve({})
            })
            .catch(({errors}) => resolve(errors))
    }),[handleClose, setTrackId, setUserInputDisabled, submitDelivery])

    const cancelDeliverySubmission = React.useCallback(() => new Promise((resolve,reject) => {
        if(delivery && orderId){
            setWorkingUpdate(true);
            axios.post(`/api/user/order/${orderId}/delivery/${delivery.id}/cancel`, {}, {cancelToken})
                .then(({data}) => data)
                .then(resolve)
                .catch(reject)
                .finally(()=>{
                    setWorkingUpdate(false);
                });
        }else{
            reject(new Error(t('components.deliveryProgress.orderAndDeliveryRequired')))
        }
    }),[delivery, orderId, axios, cancelToken, t])

    // Handle Cancel User Input
    const handleCancel = React.useCallback(() => new Promise(resolve => {
        cancelDeliverySubmission()
            .then(delivery => {
                setDelivery(delivery);
                setTrackId(delivery.id);
                resolve({})
            })
            .catch(({errors}) => {
                resolve(errors);
            })
    }),[cancelDeliverySubmission, setTrackId])

    const handleNavToLib    = React.useCallback(() => {
        const url = `${UserLibraryCollectionLocation.path}?id=${delivery?.library?._id || delivery?.library}`;
        history.push( url )
    },[delivery?.library,history]);

    const handleNavToDelivery = React.useCallback((deliveryId) => () => {
        const url = UserDeliveryLocation.toUrl({id:deliveryId});
        history.push( url )
    },[history]);

    const handleNavToOrder = React.useCallback((orderId,deliveryId) => () => {
        const url = `${UserOrderLocation.toUrl({id:orderId})}?deliveryId=${deliveryId}`;
        history.push( url );
    },[history]);

    // Handle scroll to review tab
    const handleScrollToReviewTab = React.useCallback(() => {
        if (tabProviderRef.current)
            tabProviderRef.current.setTabValue(TAB_PANEL_REVIEW);
        if(beforeTabsRef.current)
            setTimeout(() => beforeTabsRef.current.scrollIntoView({ block: 'start',  behavior: 'smooth' }), 100);
    },[])

    const mdUp                  = useMediaQuery(theme => theme.breakpoints.up('md'));

    // Immediately before tab panels
    const beforeTabsRef         = React.useRef();
    const releaseAfterRaw       = React.useMemo(() => delivery?.releaseAfter, [delivery?.releaseAfter]);

    const working               = React.useMemo(() => workingUpdate || workingOrders, [workingOrders, workingUpdate]);
    const releaseAfter          = React.useMemo(() => releaseAfterRaw ? moment(releaseAfterRaw) : undefined, [releaseAfterRaw]);
    const processAfter          = React.useMemo(() => delivery?.userInput?.processAfter ? moment(delivery?.userInput?.processAfter) : undefined, [delivery?.userInput?.processAfter]);
    const showDetail            = React.useMemo(() => true, []);
    const showUserFields        = React.useMemo(() => userFields.length > 0  || userInputRequired || userInputAvailable, [userFields.length, userInputAvailable, userInputRequired]);
    const scheduledProcess      = React.useMemo(() => [userInputPendingStatus].includes(delivery.status)  && Boolean(processAfter), [delivery.status, processAfter]);
    
    const estimatingRelease     = React.useMemo(() => [processingStatus,completePendingStatus].includes(delivery.status) && !Boolean(releaseAfter),  [delivery.status, releaseAfter]);
    const scheduledRelease      = React.useMemo(() => [processingStatus,completePendingStatus].includes(delivery.status) && Boolean(releaseAfter),   [delivery.status, releaseAfter]);
    const isComplete            = React.useMemo(() => delivery?.status === completeStatus, [delivery?.status]);
    const isHighlighted         = React.useMemo(() => trackId && trackId === delivery?.id && !userInputRequired && !userInputConfirm, [delivery?.id, trackId, userInputConfirm, userInputRequired]);
    const orderQueried          = React.useMemo(() => Boolean(order), [order]);
    const isOwner               = React.useMemo(() => isAuthenticated && orderQueried && order?.user === userId, [isAuthenticated, order?.user, orderQueried, userId]);
    const canReview             = React.useMemo(() => isComplete && delivery?.product?.canReview,[isComplete, delivery?.product?.canReview])
    const hasReviewed           = React.useMemo(() => Boolean(delivery?.productReview), [delivery?.productReview])

    const tabData               = React.useMemo(() => ([
        {
            label           : t('components.deliveryProgress.userInput'),
            hidden          : false,
            value           : TAB_PANEL_USER_INPUT,
            icon            : <InputIconAlt />,
            iconPosition    : "start"
        },{
            label           : t('components.deliveryProgress.statusHistory'),
            hidden          : !Boolean(delivery && delivery.statusHistory ),
            value           : TAB_PANEL_STATUS_HISTORY,
            icon            : <ManageHistoryIcon />,
            iconPosition    : "start"
        },{
            label           : <>{ t('components.deliveryProgress.review')} {hasReviewed ? <CheckCircleIcon color="success" sx={{ml:1,fontSize:20}}/> : null}</>,
            hidden          : !Boolean(delivery && canReview),
            value           : TAB_PANEL_REVIEW,
            icon            : <RateReviewIcon />,
            iconPosition    : "start"
        }
    ]),[delivery, canReview, hasReviewed, t])

    return (
        <DeliveryPaper 
            id                  = {`DeliveryPaper-${delivery?.id}`}
            key                 = {props.key}
            userInputRequired   = {userInputRequired}
            userInputConfirm    = {userInputConfirm}
            completed           = {isComplete}
            highlighted         = {isHighlighted}
            sx                  = {{ "& > * + *" : { mt : 2 } }}
        >
            {
                orderQueried &&
                <>
                    { 
                        !isOwner && isAdmin &&
                        <FormAlert severity="error">
                            You are <strong>NOT THE OWNER</strong> of this delivery. This has been made accessible since you are an administrator.
                        </FormAlert>
                    }
                    {  
                        isOwner && isComplete && !hasReviewed &&
                        <DeliveryReviewAlert 
                            disabled    = { !isComplete } 
                            deliveryId  = { delivery?.id }   
                            onClick     = { handleScrollToReviewTab } 
                        />
                    }
                </>
            }

            <ItemContainer>
                {
                    mdUp && 
                    <Box flexShrink={1}>
                        <ProductImage delivery={delivery} large={true}/>
                    </Box>
                }
                <Box flexGrow={1} sx={{"& > * + *" : { mt:2}}}>

                    <Box display="flex">
                        {!mdUp && 
                            <Box flexShrink={1} pr={1}>
                                <ProductImage delivery={delivery} large={false}/>
                            </Box>
                        }
                        <Box flexGrow={1} sx={{"& > * + *" : { mt:2}}}>
                            <Box >
                                
                                <Title component="div" variant="h5" gutterBottom style={{color:'inherit'}}>
                                    <Box display="flex" >

                                        <Box flexShrink={1}>        
                                            <DeliveryHeader 
                                                delivery            = {delivery}
                                                order               = {order}
                                                userInputRequired   = {userInputRequired} 
                                                userInputAvailable  = {userInputAvailable}
                                                userPendingRelease  = {scheduledRelease && releaseAfter}
                                            />
                                        </Box>

                                        <Box flexGrow={1} />
                                        
                                        {
                                            scheduledProcess && processAfter && 
                                            <Box flexShrink={1} align="right" style={{whiteSpace:'nowrap'}}>
                                                <TimerContainer>
                                                    <DigitalFont>
                                                        <CountDown 
                                                            to              = {moment(processAfter)} 
                                                            finishedValue   = {t('common.processing')} 
                                                        />
                                                    </DigitalFont>
                                                </TimerContainer>
                                            </Box>
                                        }
                                        
                                        {
                                            (scheduledRelease || estimatingRelease) &&
                                            <Box flexShrink={1} align="right" style={{whiteSpace:'nowrap'}}>
                                                <TimerContainer>
                                                    <DigitalFont>
                                                        {
                                                            scheduledRelease 
                                                                ? (
                                                                    <CountDown 
                                                                        to              = {moment(releaseAfter)} 
                                                                        finishedValue   = {
                                                                            delivery?.releaseManual 
                                                                                ? t('common.availableSoon')
                                                                                : undefined
                                                                        }
                                                                    />
                                                                )
                                                                :   t('common.queued')
                                                        }
                                                    </DigitalFont>
                                                </TimerContainer>
                                            </Box>
                                        }

                                        {
                                            delivery && canReview &&
                                            <Box position="relative">
                                                <DeliveryReviewSummary deliveryId = {delivery._id} compact = {true || !mdUp} />
                                                {
                                                    isOwner &&
                                                    <ClickBox onClick = {handleScrollToReviewTab} />
                                                }
                                            </Box>
                                        }
                                    </Box>
                                </Title>

                                
                            </Box>

                            <Box display='flex' sx={{"& > * + *" : {ml:0.25}}}>

                                <Box display='flex' style={{margin:'auto'}}>
                                    <Typography align="left" style={{fontSize:'0.75rem'}}>
                                        {t('components.deliveryProgress.deliveryId')}: <ObjectId value={delivery.id} />
                                    </Typography>
                                    {
                                        isComplete && //TODO
                                        <Box component="span" sx={{my:'auto',ml:1}}>
                                            <CompleteIcon tooltip={t('components.deliveryProgress.deliveryComplete')} />
                                        </Box>
                                    }
                                </Box>

                                {
                                    showDeliveryLink && delivery?.id &&
                                    <Box>
                                        <DeliveryIconButton 
                                            color   = "primary" 
                                            size    = "small" 
                                            onClick = {handleNavToDelivery(delivery._id)}
                                            TooltipProps = {{
                                                title : "Open as Delivery Sheet"
                                            }}
                                        />
                                    </Box>
                                }

                                {
                                    showOrderLink && delivery?.order?.id &&
                                    <Box>
                                        <OrderIconButton 
                                            color   = "primary" 
                                            size    = "small" 
                                            onClick = {handleNavToOrder(delivery?.order?.id,delivery?._id)}
                                            TooltipProps = {{
                                                title : "View Original Order"
                                            }}
                                        />
                                    </Box>
                                }

                                {
                                    isAuthenticated && isAdmin &&
                                    <Box>
                                        <AdminDeliveryPanel 
                                            component       = {IconModal} 
                                            deliveryId      = {delivery?.id} 
                                            title           = "Administration"
                                            Icon            = {SettingsIcon}
                                            iconButtonProps = {{color:'primary',size:"small"}}
                                        />
                                    </Box>
                                }

                                <Box flexGrow={1} />
                                    {
                                        !hideProgress && 
                                        <Box flexGrow={1} style={{maxWidth:300}}>
                                            <CompletionBar pcntComplete={delivery.percentComplete} suffix={t('common.complete')} sx={{my:0}}/>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                    </Box>

                    <Box>

                        {
                            delivery?.product?.abstract &&
                            <Box mt={2}>
                                <Typography variant="h5" component="h3">
                                    {t('components.deliveryProgress.productSummary')}
                                </Typography>
                                <Typography>
                                    {
                                        delivery?.product?.abstract
                                    }
                                </Typography>
                            </Box>
                        }

                        {
                            !hideProgress &&
                            <Box mt={2}>
                                <Box>
                                    <Typography variant="h5" component="h3">
                                        {t('components.deliveryProgress.progressOfDelivery')}
                                    </Typography>
                                </Box>
                                <Box sx={{mt:2}}>
                                    <Progress 
                                        delivery            = {delivery}
                                        activeStep          = {activeStep} 
                                        steps               = {steps} 
                                        userInputRequried   = {userInputRequired} 
                                        userInputAvailable  = {userInputAvailable}
                                    /> 
                                </Box>
                            </Box>
                        }

                        {
                            delivery?.product?.isVirtual && 
                            (delivery?.library || !isEmpty(delivery?.files)) && 
                            [processingStatus,completePendingStatus,completeStatus].includes(delivery?.status) && 
                            <Box mt={2}>
                                <Typography variant="h5" component="h3" gutterBottom>
                                    {t('components.deliveryProgress.virtualItemsAttached')}
                                </Typography>
                                <VirtualDeliveryContainer>
                                    <Grid container spacing={1}>
                                        {
                                            delivery?.library &&
                                            Array(1).fill(undefined).map((_,ix) => (
                                            <Grid item xs={12} sm={6} md={4} lg={3} key={ix}>
                                                <FatButton
                                                    icon    = {ix === 0 ? BiLibrary : FaFilePdf}
                                                    onClick = {handleNavToLib}
                                                    sx      = {{width:'100%'}}
                                                >
                                                    <Box sx={{fontWeight:600}}>
                                                        {t('components.deliveryProgress.libraryRecord')}
                                                    </Box>
                                                    <Box sx={{fontStyle:'italic'}}>
                                                        <DeliveryProgressLabel status={delivery?.status} />
                                                    </Box>
                                                </FatButton>  
                                            </Grid>
                                        ))}

                                        {
                                            (delivery?.files || []).map(({fileName, type, metadata, url, urlSigned},ix) => (
                                                <Grid item xs={12} sm={6} md={4} lg={3} key={ix}>
                                                    <FatButton
                                                        disabled            = { !isComplete }
                                                        icon                = { FileIcon }
                                                        onClick             = {
                                                            isComplete 
                                                                ? () => {
                                                                    const anchor    = document.createElement("a");
                                                                    anchor.href     = urlSigned || url;
                                                                    anchor.download = fileName;
                                                                    anchor.target   = "_blank" 
                                                                    document.body.appendChild(anchor);
                                                                    anchor.click();
                                                                    document.body.removeChild(anchor);
                                                                }
                                                                : null
                                                        }
                                                        iconProps           = {{
                                                            extension: type
                                                        }}
                                                        iconContainerProps  = {{
                                                            flexGrow        : 1,
                                                            sx : {
                                                                maxWidth    : 75,
                                                                mx          : 'auto'
                                                            }
                                                        }}
                                                        sx                  = {{
                                                            width   : '100%',
                                                            height  : '100%'
                                                        }}
                                                    >
                                                        <Box sx={{fontWeight:600}}>
                                                            {metadata?.name || fileName}
                                                        </Box>
                                                        <Box sx={{fontStyle:'italic'}}>
                                                            <DeliveryProgressLabel status={delivery?.status} />
                                                        </Box>
                                                    </FatButton>  
                                                </Grid>
                                            ))
                                        }
                                        
                                    </Grid>
                                </VirtualDeliveryContainer>
                            </Box>  
                        }

                        {
                            !hideProgress && 
                            <Details expanded = {expanded}>
                                
                                {   
                                    userInputAvailable && !userInputRequired && processAfter &&
                                    <Box mt={2}>
                                        <FormAlert severity="error">
                                            {/* PROMPT SUBMISSION CONFIRMATION */}
                                            <CountDown 
                                                to      = {processAfter} 
                                                render  = {({countDown}) => (
                                                    <Typography variant="body2" fontWeight={400}>
                                                        {t('components.deliveryProgress.modifySubmissionPrompt', {countDown})}
                                                    </Typography>
                                                )} 
                                            />
                                        </FormAlert>
                                    </Box>
                                }

                                {
                                    showDetail &&
                                    <DetailsContainer userInput={userInputRequired} itemContent={true} sx={{mx:-1}}>
                                        
                                        {
                                            (scheduledRelease || estimatingRelease) &&
                                            <Box>
                                                <Typography variant="h5">
                                                    {t('components.deliveryProgress.scheduledDelivery')}
                                                </Typography>
                                                {
                                                    /* PROMPT RELEASE */
                                                    scheduledRelease && 
                                                    <CountDown 
                                                        to      = {releaseAfter} 
                                                        render  = {({countDown,finished}) => (
                                                            <Typography>
                                                                {
                                                                    !finished &&
                                                                    t('components.deliveryProgress.scheduledReleasePrompt.working', {timeStamp : formatDateTime(moment(releaseAfter)), countDown})
                                                                }
                                                                {
                                                                    finished && 
                                                                    <>
                                                                        {
                                                                            delivery?.releaseManual 
                                                                            ? t('components.deliveryProgress.scheduledReleasePrompt.finished.releaseManual')
                                                                            : t('components.deliveryProgress.scheduledReleasePrompt.finished.release')
                                                                        }
                                                                    </>
                                                                }
                                                            </Typography>
                                                        )} 
                                                    />
                                                }
                                                {
                                                    estimatingRelease &&
                                                    <Typography>
                                                        {t('components.deliveryProgress.deliveryScheduleBeingEstimated')}
                                                    </Typography>
                                                }
                                            </Box>
                                        }

                                        {
                                            showUserFields && userInputAvailable && !userInputRequired &&
                                            <Box>
                                                <Typography variant="h5">
                                                    {t('components.deliveryProgress.confirmationActions')}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {t('components.deliveryProgress.confirmationActionsPrompt')}
                                                </Typography>
                                            </Box>
                                        }

                                        {
                                            showUserFields && (hasUserFields || delivery?.userInput?.valid) && userInputConfirm &&
                                            <Box>
                                                <Grid container>
                                                    <Grid item xs={12} sm={6} md={7}>
                                                        <ConfirmationButton 
                                                            pulse       = {!(!userInputConfirm || working)}
                                                            onClick     = {handleConfirm} 
                                                            disabled    = {!userInputConfirm || working} 
                                                            startIcon   = {
                                                                working 
                                                                    ? <RefreshIcon loading={true} /> 
                                                                    : <SubmitIcon />
                                                            } 
                                                            size        = "large" 
                                                            color       = "secondary" 
                                                            variant     = "contained"
                                                            style       = {{
                                                                width   : '100%',
                                                                height  : '100%'
                                                            }}
                                                        >
                                                            {t('components.deliveryProgress.submitForProcessing')}
                                                        </ConfirmationButton>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={5}>
                                                        <Button 
                                                            onClick     = {handleCancel} 
                                                            disabled    = {!userInputConfirm || working} 
                                                            startIcon   = {
                                                                working 
                                                                    ? <RefreshIcon loading={true} /> 
                                                                    : <CancelIcon/>
                                                            } 
                                                            size        = "large" 
                                                            color       = "error" 
                                                            variant     = "contained"
                                                            style       = {{
                                                                width   : '100%',
                                                                height  : '100%'
                                                            }}
                                                        >
                                                            {t('common.cancel')}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        }

                                        <Box ref={beforeTabsRef} />   

                                        <TabProvider
                                            ref             = {tabProviderRef}
                                            data            = {tabData}
                                            syncWithQuery   = {true}
                                            queryParamName  = {`tab${delivery?.ix}`}
                                        >
                                            
                                            <Tabs />

                                            <TabPanel index={TAB_PANEL_USER_INPUT}>
                                                <Box>
                                                    {
                                                        userInputAvailable && !userInputRequired &&
                                                        <Typography variant="body2" gutterBottom>
                                                            {t('components.deliveryProgress.awaitingFieldsConfirmation')}
                                                        </Typography>
                                                    }
                                                    {
                                                        userInputRequired &&
                                                        <Typography variant="body2" gutterBottom>
                                                            {t('components.deliveryProgress.awaitingFieldsConfirmationPrompt')}
                                                        </Typography>
                                                    }
                                                </Box>

                                                {   
                                                    hasUserFields && 
                                                    <Box>
                                                        <DeliveryTable 
                                                            disabled                = {userInputDisabled}
                                                            working                 = {working}
                                                            delivery                = {delivery}
                                                            order                   = {order}
                                                            userFields              = {userFields}
                                                            userInputRequired       = {userInputRequired}
                                                            userInputAvailable      = {userInputAvailable}
                                                            userInputSubmitted      = {(hasUserFields || delivery?.userInput?.valid) && userInputConfirm}
                                                            onClick                 = {handleOpen}
                                                        />
                                                    </Box>
                                                }

                                                {   
                                                    !hasUserFields && 
                                                    <SpaceBox align="center">
                                                        {
                                                            working 
                                                                ? (
                                                                    <LoadingData />
                                                                ) : (
                                                                    <Typography>
                                                                        {t('components.deliveryProgress.deliveryDoesntRequireInput')}
                                                                    </Typography>
                                                                )
                                                        }
                                                    </SpaceBox>
                                                }

                                                {   
                                                    userInputData && 
                                                    <DraggableDialog 
                                                        title       = {t('components.deliveryProgress.userInputRequired')}
                                                        showButtons = {false} 
                                                        open        = {Boolean(userInputData)} 
                                                        onClose     = {handleClose} 
                                                        onCancel    = {handleClose}
                                                        maxWidth    = "xs"
                                                        fullWidth   = {true}
                                                    >
                                                        <VerticalSpaceContainer spacing={1} sx={{mb:1}}>
                                                            {
                                                                isAuthenticated && isAdmin && order?.user && order?.user !== userId &&
                                                                <AlertOnBehalfOfUser userId={order?.user} />
                                                            }
                                                            <FormAlert severity="info">
                                                                {t('components.deliveryProgress.userInputRequiredPrompt')}
                                                            </FormAlert>
                                                        </VerticalSpaceContainer>
                                                        <DeliveryUserInputForm 
                                                            userId      = {order?.user}
                                                            disabled    = {!([userInputStatus,userInputPendingStatus].includes(delivery?.status))}
                                                            formData    = {userInputData.data} 
                                                            types       = {userInputData.types} 
                                                            fields      = {userInputData.fields}
                                                            delivery    = {delivery}
                                                            onCancel    = {handleClose} 
                                                            onSubmit    = {handleSubmit}
                                                        />
                                                    </DraggableDialog>
                                                }
                                            </TabPanel>
                                            {   
                                                delivery && delivery.statusHistory && 
                                                <TabPanel index={TAB_PANEL_STATUS_HISTORY}>
                                                    <DeliveryStatusHistory delivery={delivery} showTitle={false}/>
                                                </TabPanel>
                                            }
                                            { 
                                                delivery && canReview &&
                                                <TabPanel index={TAB_PANEL_REVIEW}>
                                                    <Box id="review" mt={2}>
                                                        <Review deliveryId={delivery?.id} title={null}/>
                                                    </Box>
                                                </TabPanel>
                                            }
                                        </TabProvider>
                                    </DetailsContainer>
                                }
                            </Details>
                        }
                        {
                            false && 
                            <JSONViewer src={delivery}/>
                        }
                    </Box>
                </Box>
            </ItemContainer>
        </DeliveryPaper>
    );
})

export default OrderDeliveryProgress;
