/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Profile
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       2nd June 2024

*******************************************************************************************/
import React                            from 'react';
import { useHistory }                   from 'react-router-dom';
import {
    styled,
    Box,
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableRow as TableRowMUI,
    TableCell,
    Typography
}                                       from '@mui/material';
import { 
    useUser,
    useTranslation,
    useUserProfile,
    useNetwork
}                                       from 'contexts';
import {
    LoadingData,
    SkeletonFancyWaitingForNetwork,
    ContainerWithFooter,
    PageContainerHeader,
    RootContainer,
    JSONViewer,
    PublicPrivateChip,
    LastQueried,
    DraggableDialog,
    SocialSharePage
}                                       from 'components'
import {
    CopyIconButton,
    ViewIconButton
}                                       from 'components/iconButtons';
import {UserProfileLocation}            from 'router/locations'
import {
    UserProfileForm
}                                       from 'components/forms'
import {
    useCopyToClipboard
}                                       from 'hooks';
import {
    FabEdit,
    FabRefresh,
}                                       from 'components/fabs';

const obj = {};
const noop = () => {};

const TableCellParagraph = styled(TableCell)({
    textAlign       : 'justify'
    // whiteSpace  :  'normal', 
    // wordWrap    : 'break-word',
    // minHeight   : 250,
    // display     : 'table-cell'
});

const TableCellLHS = styled(TableCell)({
    width : 200
});

const TableCellRHS = ({loading = false, children, ...props}) => {
    return (
        <TableCell 
            children={
                loading 
                    ? <LoadingData label="Loading ..."/> 
                    : children
            } 
            {...props}
        />
    )
};

const TableRow = ({loading = false, title = undefined, children, tableCellLHSProps = obj, tableCellRHSProps = obj}) => (
    <TableRowMUI>
        <TableCellLHS {...tableCellLHSProps}>
            {title}
        </TableCellLHS>
        <TableCellRHS {...tableCellRHSProps} loading={loading}>
            {children}
        </TableCellRHS>
    </TableRowMUI>
)

// Display table of user natal data
export const Profile = ({ children, render = noop, ...props }) => {
    const {t}                                   = useTranslation();
    const copy                                  = useCopyToClipboard();
    const history                               = useHistory();
    const {isNetworkReady}                      = useNetwork();

    const {
        loading,
        queried,
        data,
        update,
        refresh : handleRefresh,
        messageSuccess,
        messageError
    }                                           = useUserProfile();
    
    const {
        userId, 
        isAuthenticated, 
        isLoading : isUserLoading
    }                                           = useUser();

    const [openEdit,    setOpenEdit]            = React.useState(false);
    const handleOpenEdit                        = () => setOpenEdit(true);
    const handleCloseEdit                       = () => setOpenEdit(false);

    const handleSubmitProfile = React.useCallback(({_id, ...formData}) => new Promise(resolve => (
        update({_id, ...formData})
            .then(handleCloseEdit)
            .catch(({errors}) => resolve(errors))
    )),[update])

    const publicPath = React.useMemo(() => (
        data?.public ? UserProfileLocation.toUrl({handle:data?.handle}) : undefined
    ), [data?.public, data?.handle]);

    const publicUrl = React.useMemo(() => (
        publicPath ? `${window.location.origin}${publicPath}` : undefined
    ), [publicPath]);

    const isRender = render && typeof render === 'function' && render !== noop;
    const isLoaded = isAuthenticated && !isUserLoading && queried;

    return (    
        <>  
            {
                isRender && 
                render({
                    profile : data, 
                    loading, 
                    queried,
                    editing     : openEdit,
                    openEdit    : handleOpenEdit,
                    closeEdit   : handleCloseEdit,
                    isLoaded
                })
            }
            {
                !isRender && 
                <>
                    {   
                        (!isLoaded || !isNetworkReady) && 
                        <SkeletonFancyWaitingForNetwork />
                    }
                    {
                        isLoaded && isNetworkReady &&
                        <ContainerWithFooter 
                            renderHeader = {
                                <PageContainerHeader 
                                    title           = {t('pages.user.settings.profile.title')}
                                    messageSuccess  = { messageSuccess } 
                                    messageError    = { messageError } 
                                    render          = {
                                        <React.Fragment>
                                            {
                                                queried && data &&
                                                <FabEdit
                                                    disabled    = {!isAuthenticated || !userId} 
                                                    color       = "primary"
                                                    onClick     = {handleOpenEdit}
                                                    size        = "small"
                                                />
                                            }
                                            <FabRefresh 
                                                disabled    = {!isAuthenticated || !userId} 
                                                loading     = {loading} pulse={false} 
                                                color       = "primary" 
                                                onClick     = {handleRefresh} 
                                                size        = "small"
                                            />
                                        </React.Fragment>
                                    }
                                />
                            }
                            renderFooter = { <LastQueried quantity={data ? 1 : 0} timestamp={queried}/> }
                        >
                            <RootContainer sx={{position : 'relative'}}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableBody>
                                            <TableRow loading = {loading} title={"Handle"}>
                                                { data?.handle ? `@${data?.handle}` : "N/A" }
                                            </TableRow>
                                            <TableRow loading = {loading} title={"Display Name"}>
                                                { data?.displayName || "N/A" }
                                            </TableRow>
                                            <TableRow loading = {loading} title={"First Name"}>
                                                { data?.firstName || "N/A" }
                                            </TableRow>
                                            <TableRow loading = {loading} title={"Last Name"}>
                                                { data?.lastName || "N/A" }
                                            </TableRow>
                                            <TableRow loading = {loading} title={"Profile Visibility"}>
                                                <PublicPrivateChip isPublic={data?.public} publicChipProps={{color:'success.light'}} size="small"/> 
                                            </TableRow>
                                            {
                                                data?.public && data?.handle &&
                                                <TableRow loading = {loading} title={"Public URL"}>
                                                    <Box display="flex">
                                                        <ViewIconButton onClick={() => history.push(publicPath) } />
                                                        <CopyIconButton onClick={() => copy(publicUrl)} />
                                                        <SocialSharePage url={publicUrl} />
                                                    </Box>
                                                </TableRow>
                                            }
                                            <TableRow loading = {loading} title={"Bio."} tableCellLHSProps={{sx:{verticalAlign:'top'}}}  tableCellRHSProps={{component:TableCellParagraph}}>
                                                {
                                                    (data?.bio || "N/A").split(/\r?\n/).map((line,ix) => (
                                                        <Typography paragraph key={ix} sx={{textAlign:'justify',fontSize:'inherit'}}>
                                                            {line}
                                                        </Typography>
                                                    ))
                                                }
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {
                                    false && 
                                    <JSONViewer src={data}/>
                                }
                            </RootContainer>
                        </ContainerWithFooter>
                    }
                </>
            }

            <DraggableDialog 
                title       = "Edit User Profile"
                maxWidth    = {"sm"}
                open        = {openEdit} 
                showButtons = {false}
                onClose     = {handleCloseEdit} 
                onCancel    = {handleCloseEdit}
            >
                <UserProfileForm 
                    formData    = {data}
                    onSubmit    = {handleSubmitProfile}
                    onCancel    = {handleCloseEdit} 
                />
            </DraggableDialog>
        </>    
    )
};

// Default Export
export default Profile;