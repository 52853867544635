
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Angle Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       21st August 2021

*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';
import {useNetwork}             from './NetworkContext';
import {
    useStateEphemeral,
    useCancelToken
}                               from 'hooks';
import {debounce}               from '../functions';

// The Product Context 
const AngleContext = React.createContext(undefined);

const makeDefaultData = (name,description) => ({name,description});
const DEFAULT_DATA = [
    makeDefaultData('ascendant', "The ascendant (Asc or As) is the astrological sign (and degree of that sign) that is ascending on the eastern horizon at the specific time and location of an event."),
    makeDefaultData('midheaven', "The midheaven (MC; from Latin Medium Coeli, literally 'middle of the sky') is a point of definition in the ecliptic coordinate system. It aims to find the part of the ecliptic that corresponds to the highest point in a celestial object's apparent daily traverse of the visible sky, midway between its ascension on the eastern horizon and descension on the western horizon."),
]

const BASE_API_URL = '/api/astrology/angle';

// Angle Provider
const AngleProvider = ({children}) => {

    const {
        axios, 
        socketUsers : socket
    }                                               = useNetwork();
    const {cancelToken }                            = useCancelToken();

    const [enabled, setEnabled]                     = React.useState();
    const [data,    setData]                        = React.useState([]);
    const [working, setWorking]                     = React.useState(false);
    const [queried, setQueried]                     = React.useState(undefined);
   
    // Error/Success Messages
    const   [messageSuccess,    setMessageSuccess]  = useStateEphemeral(undefined),
            [messageError,      setMessageError]    = useStateEphemeral(undefined,5000);

    // Clear Records
    const clear = React.useCallback(() => setData([]),[]);

    // GET
    const get = React.useCallback(() => new Promise((resolve,reject) => {
        setWorking(true);
        axios.get(BASE_API_URL,{cancelToken})
            .then(({data}) => {
                setMessageSuccess("Angles queried")
                return data
            })
            .then(resolve)
            .catch(err => {
                setMessageError(err.message);
                reject(err)
            })
            .finally(() => {
                setWorking(false);
            })
    }),[axios, cancelToken, setMessageError, setMessageSuccess]);

    const refresh = React.useCallback( (reset) => {
        setWorking(true);
        if(reset) 
            clear();
        get()
            .then(setData)
            .catch(clear)
            .finally(() => {
                setWorking(false);
                setQueried(moment());
            })
    },[clear, get]);

    const create = React.useCallback( ({name, description}, query=true) => new Promise((resolve,reject) => {
        setWorking(true);
        axios.post(BASE_API_URL, {name, description}, {cancelToken})
            .then(() => {
                setMessageSuccess('Created angle');
                resolve({})
            })
            .catch(err => {
                setMessageError(err?.message);
                reject(err);
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }),[axios, cancelToken, refresh, setMessageError, setMessageSuccess])

    const deleteById = React.useCallback( (id,query=true) => new Promise((resolve,reject) => {            
        setWorking(true);
        axios.delete(`${BASE_API_URL}/${id}`, {cancelToken})
            .then(data => {
                setMessageSuccess(`Deleted angle ${id}`);
                resolve({})
            })
            .catch((err) =>{
                setMessageError(err.message);
                reject(err);
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }),[axios, cancelToken, refresh, setMessageError, setMessageSuccess])

    const postById = React.useCallback( ({_id, name, description}, query=true) => new Promise((resolve,reject) => {
        setWorking(true);
        axios.post(`${BASE_API_URL}/${_id}`, {name, description}, {cancelToken})
            .then(() => {
                setMessageSuccess(`Updated angle ${_id}`);
                resolve({})
            })
            .catch((err) => {
                setMessageError(err.message);
                reject(err)
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }), [axios, cancelToken, refresh, setMessageError, setMessageSuccess])

    // Refresh on authentication
    React.useEffect(() => {
        if(enabled)
            refresh();
    },[enabled, refresh])

    // Refresh on SocketIO Instruction
    React.useEffect(() => {
        if(enabled && socket){
            socket.on('refresh_angles', refresh)
            return () => {
                socket.off('refresh_angles', refresh);
            }
        }
    },[refresh, enabled, socket]);

    const enable = React.useCallback( () => setEnabled(true) , []);

    // Context values
    const value = React.useMemo(() => ({
        data,
        enabled,
        setEnabled,
        enable,
        working,
        queried,
        refresh : debounce(refresh),
        create,
        postById,
        deleteById,
        messageSuccess,
        setMessageSuccess,
        messageError,
        setMessageError,
        DEFAULT_DATA,
        BASE_API_URL
    }), [create, data, deleteById, enable, enabled, messageError, messageSuccess, postById, queried, refresh, setMessageError, setMessageSuccess, working])

    return (
        <AngleContext.Provider value={value}>
            {children}
        </AngleContext.Provider>
    )
}

// Angle Consumer
const AngleConsumer = ({children}) => {
    return (
        <AngleContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('AngleConsumer must be used within AngleProvider');
                }
                return children(context)
            }}
        </AngleContext.Consumer>
    )
}

// useAngle Hook
const useAngle = () => {
    const context = React.useContext(AngleContext);
    if(context === undefined)
        throw new Error('useAngle must be used within AngleProvider');
    return context;
}

export {
    AngleProvider,
    AngleConsumer,
    useAngle
}