/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
useComments Hook
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       5th May 2022

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import {isEmpty}                        from 'lodash';
import { 
    useNetwork, 
    useAlert,
    useCommentSubscription,
    useCommentsThread,
    useComment as useCommentContext
}                                       from 'contexts';
import { 
    useCancelToken, 
}                                       from 'hooks'
import {FORM_ERROR}                     from 'final-form';

/*
const COLORS = [
    '#a6cee3',
    '#1f78b4',
    '#33a02c',
    '#fb9a99',
    '#e31a1c',
    '#fdbf6f',
    '#ff7f00',
    '#cab2d6',
    '#6a3d9a',
    '#ffff99',
    '#b15928',
    '#b2df8a'
];
*/

const noop              = () => {};
const calcGravatarSize  = level => {
    let rootValue   = 50;
    let result      = Math.max( rootValue / (1 + Math.log(level + 1)), 15)
    return isNaN(result) ? rootValue : result;
}

const   DELETED     = false, 
        CENSORED    = false;

const   API_BASE_URL = '/api/user/commentGroup';

export const useComments = ({ parentId : parentIdIn = undefined, onChange = noop, level : levelIn = 0}) => {
    const {
        axios, 
        isNetworkReady, 
        socketUsers : socket
    }                                           = useNetwork();
    const {cancelToken}                         = useCancelToken();
    const {refreshChildCounts}                  = useCommentsThread();
    const {alert}                               = useAlert();
    const {join, leave}                         = useCommentSubscription();
    const {
        markPosted, 
        semaphore,
        maxQueries
    }                                           = useCommentContext();

    // Memoised ParentId and Level
    const parentId                              = React.useMemo( () => parentIdIn,  [parentIdIn]);
    const level                                 = React.useMemo( () => levelIn,     [levelIn]);

    const [data,            setData]            = React.useState({});
    const [childCount,      setChildCount]      = React.useState(0);
    const [queriedParent,   setQueriedParent]   = React.useState(false);
    const [queried,         setQueried]         = React.useState(false);
    const [runQuery,        setRunQuery]        = React.useState(false);
    const [comments,        setComments]        = React.useState([]);

    const [loadingParent,   setLoadingParent]   = React.useState(false);
    const [loading,         setLoading]         = React.useState(false);
    const [counting,        setCounting]        = React.useState(false);
    const [deleting,        setDeleting]        = React.useState(false);
    const [hasMore,         setHasMore]         = React.useState(true);
    const [canEdit,         setCanEdit]         = React.useState(false);
    const [viewChildren,    setViewChildren]    = React.useState(false);
    const [before,          setBefore]          = React.useState(moment().unix());
    
    const isRoot                                = React.useMemo( () => Boolean(level <= 0), [level]);
    const commentsCount                         = React.useMemo( () => Array.isArray(comments) ? comments.length : 0, [comments])
    const hasComments                           = React.useMemo( () => Boolean(commentsCount > 0), [commentsCount]);
    const gravatarSize                          = React.useMemo( () => calcGravatarSize(level), [level])
    const gravatarSizeParent                    = React.useMemo( () => calcGravatarSize(Math.max(level-1,0)), [level])

    const resetComments                         = React.useCallback( () => setComments([]),[]);  

    // Determine the child count for the parent
    const queryChildCount = React.useCallback(() => {
        if(parentId){
            setCounting(true)
            semaphore
                .acquire()
                .then(([ semaphoreValue, release]) => {
                    axios.get(`${API_BASE_URL}/comment/${parentId}/childCount?deleted=${DELETED}&censored=${CENSORED}&sID=${semaphoreValue}&sMax=${maxQueries}`, {cancelToken})
                        .then(({data}) => data)
                        .then(({childCount}) => {
                            setChildCount(childCount);
                        })
                        .catch(resetComments)
                        .finally(() => {
                            setCounting(false);
                            release();
                        })
                })
        }
    },[axios, cancelToken, maxQueries, parentId, resetComments, semaphore]);

    // Get comment by Id
    const getById = React.useCallback((id) => new Promise((resolve,reject) => {
        semaphore
            .acquire()
            .then(([ semaphoreValue, release]) => {
                axios.get(`${API_BASE_URL}/comment/${id}?sID=${semaphoreValue}&sMax=${maxQueries}`, {cancelToken})
                    .then(({data}) => data)
                    .then(resolve)
                    .catch(reject)
                    .finally(release)
            })
    }),[axios, cancelToken, maxQueries, semaphore])

    const handleSubmitComment = React.useCallback(({parentId, comment}) => new Promise( resolve => {

        if(!parentId) 
            return resolve({[FORM_ERROR]:'parentId is required'});
        if(parentId !== data?.id) 
            return resolve({[FORM_ERROR]:'invalid parentId'});

        semaphore
            .acquire()
            .then(([ semaphoreValue, release]) => {
                axios.post(`${API_BASE_URL}/comment?sID=${semaphoreValue}&sMax=${maxQueries}`, {parentId, comment}, {cancelToken})
                    .then(({data : newData}) => {
                        setComments(prev => [{...newData,newComment:true}, ...prev])
                        setChildCount(prev => prev + 1);
                    })
                    .then(() => {
                        markPosted();
                        alert(`${isRoot ? 'Comment' : 'Reply'} Submitted`);
                        refreshChildCounts();
                        resolve({})
                    })
                    .catch(({errors}) =>{
                        resolve(errors)
                    })
                    .finally(release)
            })

    }),[data?.id, semaphore, axios, maxQueries, cancelToken, markPosted, alert, isRoot, refreshChildCounts])

    const handleEditComment = React.useCallback(({parentId,id,comment}) => new Promise( resolve => {

        if(!id || !parentId) 
            return resolve({[FORM_ERROR]:'parentId and id are required'});
        if(parentId !== data?.parent)   
            return resolve({[FORM_ERROR]:'invalid parentId'});

        semaphore
            .acquire()
            .then(([ semaphoreValue, release]) => {
                axios.post(`${API_BASE_URL}/comment/${id}?sID=${semaphoreValue}&sMax=${maxQueries}`, {parentId, id, comment}, {cancelToken})
                    .then(({data : newData}) => {
                        if(newData.id === data?.id){
                            setData(newData);
                        }
                        setComments(prev => prev.map(p => p.id === newData.id ? newData : p));
                    })
                    .then(() => {
                        alert(`${isRoot ? 'Comment' : 'Reply'} Updated`)
                        resolve({})
                    })
                    .catch(({errors}) =>{
                        resolve(errors)
                    })
                    .finally(release)
            })
    }),[alert, axios, cancelToken, data?.id, data?.parent, isRoot, maxQueries, semaphore]);

    const handleDeleteComment = React.useCallback((id) => new Promise( resolve => {
        
        if(!id) 
            return resolve({[FORM_ERROR]:'id is required'});

        setDeleting(true);
        semaphore
            .acquire()
            .then(([ semaphoreValue, release]) => {
                axios.delete(`${API_BASE_URL}/comment/${id}?sID=${semaphoreValue}&sMax=${maxQueries}`, {}, {cancelToken})
                    .then(({data : newData}) => {
                        if(newData.id === data?.id){
                            setData(newData);
                        }
                        setComments(prev => prev.filter(p => p.id !== newData.id ));
                    })
                    .then(() => {
                        alert(`${isRoot ? 'Comment' : 'Reply'} Deleted`, 'error')
                        refreshChildCounts();
                        resolve({})
                    })
                    .catch(({errors}) =>{
                        resolve(errors)
                    })
                    .finally(() => {
                        setDeleting(false);
                        release();
                    })
            })
    }),[alert, axios, cancelToken, data?.id, isRoot, maxQueries, refreshChildCounts, semaphore]);

    const reset = React.useCallback(() => {
        setHasMore(false);
        setQueried(false);
        setComments([]);
        onChange([],0);
        setBefore(moment().unix());
    },[onChange])

    const queryParent = React.useCallback(() => new Promise((resolve,reject) => {
        if(parentId){
            setLoadingParent(true);
            semaphore
                .acquire()
                .then(([ semaphoreValue, release]) => {
                    axios.get(`${API_BASE_URL}/comment/${parentId}?origin=queryParent&sID=${semaphoreValue}&sMax=${maxQueries}`, {cancelToken})
                        .then(({data}) => {
                            setData({...data, parentData:true});
                            setChildCount(data?.childCount || 0)
                            resolve({});
                        })
                        .catch(err => {
                            setData({});
                            reject(err)
                        })
                        .finally(() => {
                            release();
                            setLoadingParent(false);
                        })
                })
        }else{
            setData({});
            resolve({})
        }
    }),[parentId, semaphore, axios, maxQueries, cancelToken])

    // const sleep = React.useCallback( (ms) => (data) => new Promise(resolve => setTimeout(() => resolve(data), ms)), []);

    const query = React.useCallback(() => new Promise((resolve,reject) => {
        if(parentId){
            setLoading(true);
            semaphore
                .acquire()
                .then(([semaphoreValue, release]) => {
                    axios.get(`${API_BASE_URL}/comment?parentId=${parentId}&level=${level}&limit=${level === 0 ? 10 : 5}&deleted=${DELETED}&censored=${CENSORED}&origin=query&sID=${semaphoreValue}&sMax=${maxQueries}`, {cancelToken})
                        .then(({data}) => data)
                        .then(({items, totalItems, hasMore}) => {
                            setBefore(items.length ? items[items.length - 1].id : moment().unix());
                            setHasMore(hasMore);
                            setComments(items);
                            setQueried(true);
                            resolve({});
                        })
                        .catch((err) => {
                            resetComments();
                            reject(err)
                        })
                        .finally(() => {
                            setLoading(false)
                            release();
                        })
                })
        }else{
            reset();
            resolve({});
        }
    }),[parentId, semaphore, axios, level, maxQueries, cancelToken, resetComments, reset])

    const queryMore = React.useCallback(() => new Promise((resolve,reject) => {
        if(parentId){
            setLoading(true);
            semaphore
                .acquire()
                .then(([value, release]) => {
                    // axios.get(`${API_BASE_URL}/comment/collection/${parentId}?before=${before}&level=${level}&limit=${3}&deleted=${DELETED}&censored=${CENSORED}&origin=queryMore&q=${value}`, {cancelToken})
                    axios.get(`${API_BASE_URL}/comment/?parentId=${parentId}&before=${before}&level=${level}&limit=${3}&deleted=${DELETED}&censored=${CENSORED}&origin=queryMore&q=${value}`, {cancelToken})
                        .then(({data}) => data)
                        .then(({items, totalItems, hasMore}) => {
                            setBefore(items.length ? items[items.length - 1].id : moment().unix());
                            setHasMore(hasMore);
                            setComments(prev => [...prev, ...items]);
                            setQueried(true);
                            resolve({});
                        })
                        .catch(err => {
                            resetComments();
                            reject(err);
                        })
                        .finally(() => {
                            setLoading(false);
                            release();
                        })
                    });
        }else{
            reset();
            resolve({});
        }
    }),[parentId, semaphore, axios, before, level, cancelToken, resetComments, reset]);

    // Update Child Count
    React.useEffect(() => {
        onChange({childCount})
    },[childCount, onChange])

    // Get the Parent Data
    React.useEffect(() => {
        queryParent()
            .catch(console.error);
    },[queryParent]);

    // Parent Queried
    React.useEffect(() => {
        const queried = !isEmpty(data);
        setQueriedParent(queried);
        setCanEdit(queried);
    },[data,isRoot])
        
    React.useEffect(() => {
        if(isNetworkReady && runQuery){
            query();
        }
    },[isNetworkReady, query, runQuery]);

    React.useEffect(() => {
        if(viewChildren) 
            setRunQuery(true) 
    }, [viewChildren]);

    React.useEffect(() => {
        if(isRoot && queriedParent){
            setRunQuery(true);
        }
    },[isRoot,queriedParent])

    const refresh = React.useCallback(() => new Promise((resolve) => {
        Promise
            .all([
                queryParent(),
                query()
            ])
            .then(resolve)
            .catch(resolve);
    }), [query, queryParent]);

    // If parentID and successfully queried Parent, Join the room to listen for updates
    React.useEffect(() => {
        if(parentId && queriedParent){
            join(parentId);
            return () => {
                leave(parentId)
            }
        }
    },[join, leave, parentId, queriedParent]);

    React.useEffect(() => {
        if(parentId && queriedParent && socket){

            const handleCreated = (args) => {
                if(args?.parent === parentId){
                    if(!comments.find(x => x.id === args.id)){
                        getById(args.id)
                            .then(data => {
                                setComments(prev => {
                                    if(!prev.find(x => x.id === args.id))
                                        return [{...data,newComment:true}, ...prev]
                                    return prev
                                })
                            })
                            .catch(console.log)    
                        queryChildCount()
                    }
                    
                }
            }

            const handleUpdated = (args) => {
                if(args?.id === parentId){
                    getById(args.id).then(setData).catch(console.log)
                }
            }

            const handleDeleted = (args) => {
                if(args?.parent === parentId){
                    setComments(prev => prev.filter(c => c.id !== args.id));
                    queryChildCount();
                }
            }

            const handleVoted = ({id,voteUp,voteDown}) => {
                if(id && id === parentId)
                    setData(prev => ({...prev, voteUp, voteDown}))
            }

            socket.on('comment_created',    handleCreated);
            socket.on('comment_updated',    handleUpdated);
            socket.on('comment_deleted',    handleDeleted);
            socket.on('comment_voted',      handleVoted);

            return () => {
                socket.off('comment_created',   handleCreated);
                socket.off('comment_updated',   handleUpdated);
                socket.off('comment_deleted',   handleDeleted);
                socket.off('comment_voted',     handleVoted);
            }
        }
    }, [comments, getById, parentId, queriedParent, queryChildCount, socket])

    return {
        isRoot,
        level,
        parentId,

        queried,
        queriedParent,
        
        loading,
        loadingParent,
        counting,
        deleting,

        data,
        canEdit,

        comments,
        hasComments,
        commentsCount,
        setComments,
        resetComments,

        hasMore,
        setHasMore,

        childCount,
        // queryChildCount,

        handleSubmitComment,
        handleEditComment,
        handleDeleteComment,

        query,
        queryMore,
        queryParent,
        
        viewChildren,
        setViewChildren,

        gravatarSize,
        gravatarSizeParent,

        calcGravatarSize, 

        before, 
        // beforeDate,

        refresh
    }
}