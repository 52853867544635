/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Deliveries Components
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th February 2022

*******************************************************************************************/
import React                        from 'react';
import {
    styled,
    alpha,
    Typography,
    TableRow    as TableRowMUI,
    TableCell   as TableCellMUI,
}                                   from '@mui/material';
import { purple }                   from '@mui/material/colors';
import RotateLeftIcon               from '@mui/icons-material/RotateLeft';
import CancelIcon                   from '@mui/icons-material/Cancel';
import UpdateIcon                   from '@mui/icons-material/Update';
import HistoryIcon                  from '@mui/icons-material/History';  
import {
    useNetwork
}                                   from 'contexts';
import {
    Product as ProductComponent, 
    DraggableDialog,
    ObjectId,
    JSONViewer
}                                   from 'components';
import {
    LibrarySet
}                                   from 'components/library/LibrarySet';
import {
    Confirmation,
}                                   from 'components/modals';
import {
    UpdateIconButton,
}                                   from 'components/iconButtons';

const noop = () => {};

export const TableCell = styled(TableCellMUI,{
    shouldForwardProp : prop => prop !== 'header' && prop !== 'lastRow' && prop !== 'description'
})(({theme,lastRow = false, header = false, description = false}) => ({
    padding         : theme.spacing(0.2),
    paddingLeft     : theme.spacing(0.5),
    paddingRight    : theme.spacing(0.5),
    fontSize        : '0.6rem',
    fontWeight      : 'inherit',
    ...(lastRow && {
        borderBottom : 'none'
    }),
    ...(header && {
        whiteSpace      : 'nowrap',
        fontWeight      : 800
    }),
    ...(description && {
        maxWidth        : 200,
        whiteSpace      : 'nowrap',
        overflow        : 'hidden',
        textOverflow    : 'ellipsis'
    })
}));

export const TableRow = styled(TableRowMUI,{
    shouldForwardProp : prop => prop !== 'expired' && prop !== 'releaseSoon' && prop !== 'cancelled' && prop !== 'complete' && prop !== 'expanded'
})(({theme, expired = false, releaseSoon = false, cancelled = false, complete = false, expanded = false}) => ({
    ...(expanded && {
        background  : alpha(theme.palette.info.main,0.15),
    }),
    ...(cancelled && {
        background  : alpha(theme.palette.error.dark,0.15),
        "&:hover" : {
            background  : alpha(theme.palette.error.dark,0.25) + '!important'
        }
    }),
    ...(!cancelled && expired && {
        background  : alpha(purple[400],0.15),
        "&:hover" : {
            background  : alpha(purple[400],0.25) + '!important'
        }
    }),
    ...(!cancelled && !expired && releaseSoon && {
        background  : alpha(theme.palette.info.light,0.15),
        "&:hover" : {
            background  : alpha(theme.palette.info.light,0.25) + '!important'
        }
    }),
    ...(!cancelled && !expired && !releaseSoon && complete && {
        background  : alpha(theme.palette.success.light,0.15),
        "&:hover" : {
            background  : alpha(theme.palette.success.light,0.25) + '!important'
        }
    })
}));

const ConfirmationButton = ({
    children,
    deliveryId, 
    disabled        = false,
    onClick         = noop,
    render          = undefined,
    IconButton      = UpdateIconButton,
    IconButtonProps = {}
}) => {
    const [open, setOpen]   = React.useState(false);
    const {isNetworkReady}  = useNetwork();
    
    const handleOpen        = React.useCallback( (e) => {
        if(e) e.stopPropagation();
        setOpen(true);
    }, []);

    const handleClose       = React.useCallback( (e) => {
        if(e) e.stopPropagation();
        setOpen(false);
    }, []);
    
    const handleConfirm     = React.useCallback( (e) => {
        if(e) e.stopPropagation();
        handleClose();
        onClick(deliveryId);
    }, [deliveryId, handleClose, onClick]);

    return (
        <React.Fragment>
            {
                render && 
                render({onClick:handleOpen, disabled : open || disabled || !isNetworkReady})
            }
            {
                !render &&
                <IconButton 
                    color           = "secondary" 
                    size            = "small" 
                    {...IconButtonProps}
                    onClick         = {handleOpen} 
                    disabled        = {open || disabled || !isNetworkReady}
                />
            }
            <Confirmation
                open            = {open}
                onClose         = {handleClose}
                onOk            = {handleConfirm}
            >
                {children}
            </Confirmation>
        </React.Fragment>
    )
}

export const ReleaseDeliveryButton = ({
    deliveryId, 
    disabled    = false,
    onClick     = noop,
    render      = undefined
}) => {
    return (
        <ConfirmationButton 
            deliveryId      = {deliveryId} 
            disabled        = {disabled} 
            onClick         = {onClick} 
            render          = {render}
            IconButtonProps = {{
                Icon: React.forwardRef((props, ref) => (
                    <UpdateIcon ref={ref} color={disabled ? 'disabled' : "info" } {...props} />
                )),
                TooltipProps : {
                    title:'Early Release'
                }
            }}
        >
            <Typography gutterBottom>
                Early Reliease of Delivery: <ObjectId value={deliveryId}/>
            </Typography>
            <Typography gutterBottom>
                Are you sure that you want to release this delivery early?
            </Typography>
            <Typography style={{fontWeight:800}}>
                WARNING, this cannot be undone. 
            </Typography>
        </ConfirmationButton>
    )
}

export const ReprocessDeliveryButton = ({
    deliveryId, 
    disabled    = false,
    onClick     = noop,
    render      = undefined
}) => {
    return (
        <ConfirmationButton 
            deliveryId      = {deliveryId} 
            disabled        = {disabled} 
            onClick         = {onClick} 
            render          = {render}
            IconButtonProps = {{
                Icon        : React.forwardRef((props, ref) => (
                    <HistoryIcon ref={ref} color={disabled ? 'disabled' : "warning"} {...props} />
                )),
                TooltipProps : {
                    title:'Reprocess Delivery'
                }
            }}
        >
            <Typography gutterBottom>
               Reprocess Delivery: <ObjectId value={deliveryId}/>
            </Typography>
            <Typography gutterBottom>
                Are you sure that you want to reprocess this delivery?
            </Typography>
            <Typography style={{fontWeight:800}}>
                WARNING, this cannot be undone, all existing material will be discarded and regenerated.
            </Typography>
        </ConfirmationButton>
    )
}

export  const ResetDeliveryButton = ({
    deliveryId, 
    disabled    = false,
    onClick     = noop,
    render      = undefined
}) => {
    return (
        <ConfirmationButton 
            deliveryId      = {deliveryId} 
            disabled        = {disabled} 
            onClick         = {onClick} 
            render          = {render}
            IconButtonProps = {{
                Icon: React.forwardRef((props, ref) => (
                    <RotateLeftIcon ref={ref} color={ disabled ? 'disabled' :"succes"} {...props} />
                )),
                TooltipProps : {
                    title:'Reset Delivery'
                }
            }}
        >
            <Typography gutterBottom>
                Reset Delivery: <ObjectId value={deliveryId}/>
            </Typography>
            <Typography gutterBottom>
                Are you sure that you want to reset this delivery?
            </Typography>
        </ConfirmationButton>
    )
}

export const CancelDeliveryButton = ({
    deliveryId, 
    disabled    = false,
    onClick     = noop,
    render      = undefined
}) => {
    return (
        <ConfirmationButton 
            deliveryId      = {deliveryId} 
            disabled        = {disabled} 
            onClick         = {onClick} 
            render          = {render}
            IconButtonProps = {{
                Icon: React.forwardRef((props, ref) => (
                    <CancelIcon ref={ref} color={disabled ? 'disabled' : "error"} {...props} />
                )),
                TooltipProps : {
                    title : 'Cancel Delivery'
                }
            }}
        >
            <Typography gutterBottom>
                Cancel Delivery: <ObjectId value={deliveryId}/>
            </Typography>
            <Typography gutterBottom>
                Are you sure that you want to cancel this delivery?
            </Typography>
        </ConfirmationButton>
    )
}

export const ProductDialog = ({
    productId = undefined,
    onClose     : handleClose   = noop,
    onOk        : handleOk      = noop,
}) => {
    if(!productId)
        return null;
    return (
        <DraggableDialog 
            title               = "View Product"
            open                = {Boolean(productId)} 
            onClose             = {handleClose} 
            onOk                = {handleOk}
            readOnly            = {true}
            fullScreen          = {true}
            showButtonsCancel   = {false}
            disableBackdropClick
        >
            <ProductComponent 
                id                  = {productId} 
                sticky              = {false}
                disabled            = {true}
                showImages          = {true} 
                showPricing         = {true} 
                showRawData         = {false} 
                showCrossSell       = {false}
            />
        </DraggableDialog>
    )
}

export const UserLibraryDialog = ({
    libraryId = undefined,
    onClose     : handleClose   = noop,
    onOk        : handleOk      = noop,
}) => {
    if(!libraryId)
        return null;
    return (
        <DraggableDialog 
            title               = "Library Viewer"
            open                = {Boolean(libraryId)} 
            onClose             = {handleClose} 
            onOk                = {handleOk}
            readOnly            = {true}
            fullScreen          = {true}
            showButtonsCancel   = {false}
        >
            <LibrarySet 
                sensitive   = {false} 
                showTitle   = {!false} 
                force       = {true}
            />
        </DraggableDialog>
    )
}

export const JSONDataDialog = ({
    title = "Data",
    data = undefined,
    onClose     : handleClose   = noop,
    onOk        : handleOk      = noop,
    width = 500,
    ...props
}) => {
    if(!data)
        return null;
    return (
        <DraggableDialog 
            title               = {title}
            open                = {Boolean(data)} 
            onClose             = {handleClose} 
            onOk                = {handleOk}
            readOnly            = {true}
            showButtonsCancel   = {false}   
        >
            <div style={{minWidth:width,maxWidth:width}}>
                <JSONViewer src={data}/>
            </div>
        </DraggableDialog>
    )
}