/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Natal Summary
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       26th September 2022

*******************************************************************************************/
import React                                from 'react';
import moment                               from 'moment';
import formatcoords                         from 'formatcoords';
import { styled, Typography, Grid, Box}     from '@mui/material';
import { 
    GoogleMapsStatic, 
    Skeleton as SkeletonOriginal
}                                           from 'components';
import { withTranslation }                  from './hoc';
import { useLocale }                        from 'contexts';
import { withFeatureTypography }            from 'components/hoc'

const Skeleton = withFeatureTypography(SkeletonOriginal);

const InvalidDataPrompt = withTranslation( ({t}) => (
    <Typography textAlign={"center"}>
        {t('components.natalSummary.invalidOrMissing')}
    </Typography>
));

const GeoData = styled(Typography)({
    fontSize : '0.75rem',
});

const MapsContainer = styled(Box)({
    width:'100%',
    height:'100%',
    position:'relative'
});

const MapsWrapper = styled(Box)({
    width:'100%', 
    borderRadius:5, 
    overflow:'hidden', 
    position:'absolute',
    top:0,left:0,bottom:0,right:0
});

// const roundChunk = (num,by=200) => Math.ceil(num / by) * by;
const obj = {};

export const NatalSummary = withTranslation( ({
    t, 
    lat, 
    lng, 
    address, 
    unknownTime, 
    localTime,
    birthDateTime, 
    approxAddress   = false, 
    typographyProps = obj,
    description     = undefined
}) => {
    
    const {formatDate,formatTime}   = useLocale();
    const ref                       = React.useRef();
    // const {width,height}            = useSize(ref);

    const dt                        = React.useMemo(() => moment.utc(birthDateTime), [birthDateTime]);
    const valid                     = React.useMemo(() => !isNaN(lat) && !isNaN(lng), [lat, lng])
    const coords                    = React.useMemo(() => {
        // console.log(lat, lng)
        // console.log(typeof lat, typeof lng)
        return valid 
            ? formatcoords(lat, lng).format({decimalPlaces:1}) 
            : "N/A"
    }, [lat, lng, valid])
    const dob                       = React.useMemo(() => (
        `${formatDate(dt)} ${!unknownTime ? formatTime(dt) : t('common.unknownTime')}`
    ), [t, dt, formatDate, formatTime, unknownTime])

    return (
        <Grid container spacing={1} display="flex">
            {   
                valid && 
                <>
                    <Grid item xs={4} sm={4} md={4} lg={3} pt={1}>
                        <MapsContainer ref={ref}>
                            <MapsWrapper>
                                <GoogleMapsStatic zoom={15} lat={lat} lng={lng} width={ 100 } height={ 100 }/>
                            </MapsWrapper>
                        </MapsContainer>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={9} textAlign='left'>
                        {
                            description &&
                            <GeoData {...typographyProps} sx={{fontWeight:800}}> 
                                {description}
                            </GeoData>
                        }
                        <GeoData {...typographyProps} sx={{fontWeight:description ? 400 : 800}}> 
                            {dob} {localTime ? t('common.lmt') : t('common.gmt')}
                        </GeoData>
                        <GeoData {...typographyProps}> 
                            { coords }
                        </GeoData>
                        <GeoData {...typographyProps}> 
                            { !approxAddress
                                ? address 
                                : (address || '').split(',').slice(1).join(', ')
                            }
                        </GeoData>
                    </Grid>
                </>
            }
            {
                !valid && 
                <Grid item xs={12} sx={{minHeight:110}}>
                    <Skeleton typographyComponent={InvalidDataPrompt}/>
                </Grid>
            }
        </Grid>
    )
});

export default NatalSummary;