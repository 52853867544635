/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Book Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd January 2022

*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';
import {
    useMediaQuery,
    Box,
    Grid
}                               from '@mui/material';
import AddShoppingCartIcon      from '@mui/icons-material/AddShoppingCart';
import { 
    Title,
    PageContainer,
    LastQueried,
    ContainerWithFooter,
    JSONViewer,
    Typography,
    CommentsThread,
    NotFound,
    SocialSharePage,
    Seo,
    SkeletonFancy,
    Button,
    PublicBooksAdvertisement,
    ItemPaper,
    ImageLinkWithOverlay,
    Like,
    LikeDisplay,
    ColorChip
}                               from 'components';
import { withTranslation }      from './hoc';
import { 
    BooksLocation
}                               from 'router/locations/Locations';
import { 
    useNetwork,
    CommentsThreadProvider 
}                               from 'contexts';
import {
    useCancelToken,
    // useStateEphemeral
}                               from 'hooks';    

const obj = {};

const toUrl = ({id, absolute = true} = {}) => {
    const {origin} = window.location;
    if(!id) return origin;
    const rel = BooksLocation.toUrl({id})
    return absolute ? `${origin}${rel}` : rel;
}

const OrderBookButton = withTranslation( ({t, ...props}) => (
    <Button startIcon={<AddShoppingCartIcon/>} size="large" variant={"contained"} color="warning" {...props}>
        {t('common.order')}
    </Button>
))

export const Book = withTranslation( ({
    t, 
    resourceId, 
    page                = true, 
    pageContainerProps  = obj 
}) => {
    
    const {axios, isNetworkReady}                       = useNetwork();
    const {cancelToken }                                = useCancelToken();
    // const [messageSuccess,  setMessageSuccess]          = useStateEphemeral(undefined)
    // const [messageError,    setMessageError]            = useStateEphemeral(undefined)
    const [data,            setData]                    = React.useState({});
    const [loading,         setLoading]                 = React.useState(false);
    const [queried,         setQueried]                 = React.useState(undefined);
    const [status,          setStatus]                  = React.useState(undefined);

    const query = React.useCallback(() => {
        if(isNetworkReady && resourceId){
            axios.get(`/api/public/resources/${resourceId}`, {cancelToken})
                .then(({data,...rest}) => {
                    setData(data);
                    setStatus(rest.status);
                    // setMessageSuccess("Queried");
                })
                .catch(err => {
                    console.error(err);
                    setStatus(err.status);
                })
                .finally(() => {
                    setQueried(moment());
                    setLoading(false)
                })
        }
    },[axios, cancelToken, isNetworkReady, resourceId])

    React.useEffect(query,[query]);

    const mdUp                      = useMediaQuery(theme => theme.breakpoints.up('md'));
    const smDown                    = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const lgUp                      = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const href                      = React.useMemo(() => toUrl({id : data?.id, absolute: true}), [data?.id]);
    const authors                   = React.useMemo(() => (data?.data?.authors || []).map(({author}) => author).filter(Boolean).join(', '), [data?.data?.authors])
    const description               = React.useMemo(() => data?.description || data?.data?.description, [data?.data?.description, data?.description]);
    const review                    = React.useMemo(() => data?.review, [data?.review]);

    const handleAffilliateClick     = React.useCallback(() => window.open(data?.affiliate || data?.url, "_blank"), [data?.affiliate, data?.url]);

    return (
        <CommentsThreadProvider>

            {
                page &&
                <Seo
                    title           = { 
                        [
                            'Book', 
                            data?.data?.title || `ID: ${resourceId}`
                        ]
                            .filter(Boolean)
                            .join(' - ') 
                    }
                    description     = { 
                        data?.data?.title
                            ? t('components.book.titleWithName', {name : data?.data?.title})
                            : t('components.book.titleDefault' )
                        }
                    keywords        = { 
                        ["Books", ...(data?.data?.categories || []).filter(Boolean).join(", ") ]
                    }
                    canonical       = { href }
                    image           = { data?.data?.main_image }
                    type            = "book"
                />
            }

            <PageContainer loading={loading} breadcrumbs={Boolean(page)} id="pageContainer" {...pageContainerProps}>

                {
                    status && (status >= 400 || !data?.valid) &&
                    <Box display="flex" flexGrow={1} flexDirection="column">
                        <Box flexGrow={1}/>
                        <Box>
                            <NotFound/>
                        </Box>
                        <Box flexGrow={1}/>
                    </Box>
                }

                {
                    !queried && 
                    <Box sx={{height : page ? 'calc(100vh - 300px)' : '100vh', position:'relative'}}>
                        <SkeletonFancy showCircle={true} circleDiameter={100}/>
                    </Box>
                }

                {   queried && (!status || status < 400) && data?.valid &&
                    <ContainerWithFooter
                        renderFooter = {
                            <LastQueried quantity={data?.id ? 1 : 0} timestamp={queried}/> 
                        }
                    >
                        <ItemPaper>
                            <Grid container spacing={2}>
                                {
                                    !smDown &&
                                    <Grid item xs={3}>
                                        <Box sx = {{ position : 'sticky', top : 0}}>
                                            <ImageLinkWithOverlay 
                                                to              = {'#'}
                                                photo           = {data?.data?.main_image}
                                                onClick         = {handleAffilliateClick}
                                                name            = { t('components.book.orderNow') }
                                                aspectRatio     = 'unset'
                                            />
                                            <LikeDisplay likes={data?.likeGroup?.likeCount} dislikes={data?.likeGroup?.dislikeCount} />
                                        </Box>
                                    </Grid>
                                }
                                <Grid item xs={!smDown ? 9 : 12}>
                                    <Box display="flex">
                                        {
                                            smDown &&
                                            <Box flexBasis = "30%" mr={2} sx={{maxWidth:100}}>
                                                <Box sx={{position:'relative'}}>
                                                    <ImageLinkWithOverlay 
                                                        to              = {'#'}
                                                        photo           = {data?.data?.main_image}
                                                        onClick         = {handleAffilliateClick}
                                                        name            = { t('components.book.orderNow') }
                                                        aspectRatio     = 'unset'
                                                    />
                                                    <LikeDisplay likes={data?.likeGroup?.likeCount} dislikes={data?.likeGroup?.dislikeCount} />
                                                </Box>
                                            </Box>
                                        }
                                        <Box flexGrow={1} flexBasis={smDown ? "70%" : '100%'}>
                                            <Box display="flex">
                                                <Box flexGrow={1} sx={{'& > * + *' : {mt:2}}}>
                                                    {
                                                        (data?.title || data?.data?.title) &&
                                                        <Title component="h1">
                                                            {data?.title || data?.data?.title}
                                                        </Title>
                                                    }
                                                    
                                                    {
                                                        authors && 
                                                        <Typography component="div" variant="body2" align="justify" sx={{fontStyle:'italic'}}>
                                                            { authors }
                                                        </Typography>
                                                    }
                                                </Box>

                                                {
                                                    mdUp &&
                                                    <Box display="flex" sx={{ml:2}}>
                                                        <Box sx={{my:'auto'}}>
                                                            <OrderBookButton onClick={handleAffilliateClick} />
                                                        </Box>
                                                    </Box>
                                                }
                                            </Box>

                                            {
                                                !mdUp &&
                                                <Box display="flex" sx={{mt:2}}>
                                                    <Box flexGrow={1}/>
                                                    <Box>
                                                        <OrderBookButton onClick={handleAffilliateClick}/>
                                                    </Box>
                                                </Box>
                                            }
                                        </Box>
                                    </Box>
                                    
                                    <Box mt={2}>
                                        {
                                            description &&
                                            description
                                                .split("\n")
                                                .filter(Boolean)
                                                .map((paragraph,ix) => (
                                                    <Typography key={ix} paragraph sx={{textAlign:'justify'}}>
                                                        {paragraph}
                                                    </Typography>
                                                ))
                                        }
                                        {
                                            !description &&
                                            <Typography paragraph>
                                                { t('components.book.noDescription') }
                                            </Typography>
                                        }
                                    </Box>

                                    {
                                        review && 
                                        <Box mt={2}>
                                            <Title variant="h6" component="div">
                                                { t('components.book.review') }
                                            </Title>
                                            <Box>
                                                {
                                                    review
                                                        .split("\n")
                                                        .filter(Boolean)
                                                        .map((paragraph,ix) => (
                                                            <Typography key={ix} paragraph sx={{textAlign:'justify'}}>
                                                                {paragraph}
                                                            </Typography>
                                                        ))
                                                }
                                            </Box>
                                        </Box>
                                    }

                                    <Box mt={2} display={"flex"} width={'100%'}>
                                        <Box flexGrow={1}>
                                            {
                                                (data?.data?.categories || []).map(({category},ix) => (
                                                    <ColorChip label={category} key={ix} color="primary" sx={{m:0.25}}/>
                                                ))
                                            }
                                        </Box>
                                        <Box ml={2}>
                                            <Like reference={data?.id} size={"small"} onClick={query}/>
                                        </Box>
                                        {
                                            lgUp &&
                                            <Box ml={2}>
                                                <SocialSharePage url = {href} />
                                            </Box>
                                        }
                                    </Box>

                                    {
                                        !lgUp &&
                                        <Box align="right">
                                            <SocialSharePage url = {href} />
                                        </Box>
                                    }

                                    {
                                        false && 
                                        <JSONViewer src={data}/> 
                                    }

                                    {
                                        data?.id &&
                                        <Box mt={2}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} lg={7}>
                                                    <CommentsThread reference = {data?.id} privateThread = {false} />
                                                </Grid>
                                                <Grid item xs={12} lg={5}>
                                                    <PublicBooksAdvertisement 
                                                        title           = { t('components.book.otherBooks') }
                                                        auto            = {false} 
                                                        excludeId       = {data?._id} 
                                                        quantity        = {5}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    }
                                </Grid>
                            </Grid>
                        </ItemPaper>
                    </ContainerWithFooter>
                }
            </PageContainer>
        </CommentsThreadProvider>
    )
})

export default Book;