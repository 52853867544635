
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Celestial Body Context
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       21st August 2021

*******************************************************************************************/
import React from 'react';
import moment                   from 'moment';
import {useNetwork}             from './NetworkContext';
import {
    useStateEphemeral,
    useCancelToken,
}                               from '../hooks';
import {debounce}               from '../functions';

// The Product Context 
const CelestialPointContext = React.createContext(undefined);

const makeDefaultData = (name,description) => ({name,description});
const DEFAULT_DATA = [
    makeDefaultData('northnode',    "Your north node is your true north or your North Star, says intuitive astrologer and healer Rachel Lang. 'It's the point of destiny you continue to follow throughout your life'"),
    makeDefaultData('southnode',    "Your south node is an aspect of your birth chart that directly correlates with your north node. These two aspects are not planets, but the points where the moon's orbit pierced through the plane of ecliptic when you were born."),
    makeDefaultData('lilith',       "The Black Moon Lilith is the geometric point in the sky that marks the furthest point of the moon's orbit around the earth."),
]

const BASE_API_URL = '/api/astrology/celestialPoint';

// CelestialPoint Provider
const CelestialPointProvider = ({children}) => {

    const {
        axios, 
        socketUsers : socket
    }                                               = useNetwork();
    const {cancelToken }                            = useCancelToken();

    const [enabled, setEnabled]                     = React.useState();
    const [data,    setData]                        = React.useState([]);
    const [working, setWorking]                     = React.useState(false);
    const [queried, setQueried]                     = React.useState(undefined);
   
    // Error/Success Messages
    const   [messageSuccess,    setMessageSuccess]  = useStateEphemeral(undefined),
            [messageError,      setMessageError]    = useStateEphemeral(undefined,5000);

    // Clear Records
    const clear = React.useCallback(() => setData([]),[]);

    // GET
    const get = React.useCallback(() => new Promise((resolve,reject) => {
        setWorking(true);
        axios.get(BASE_API_URL,{cancelToken})
            .then(({data}) => {
                setMessageSuccess("Celestial points queried")
                return data
            })
            .then(resolve)
            .catch(err => {
                setMessageError(err.message);
                reject(err)
            })
            .finally(() => {
                setWorking(false);
            })
    }),[axios, cancelToken, setMessageError, setMessageSuccess]);

    const refresh = React.useCallback( (reset) => {
        setWorking(true);
        if(reset) 
            clear();
        get()
            .then(setData)
            .catch(clear)
            .finally(() => {
                setWorking(false);
                setQueried(moment());
            })
    },[clear, get])

    const create = React.useCallback( ({name, description}, query=true) => new Promise((resolve,reject) => {
        setWorking(true);
        axios.post(BASE_API_URL, {name, description}, {cancelToken})
            .then(() => {
                setMessageSuccess('Created celestial body');
                resolve({})
            })
            .catch(err => {
                setMessageError(err.message);
                reject(err);
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }),[axios, cancelToken, refresh, setMessageError, setMessageSuccess]);

    const deleteById = React.useCallback( (id,query=true) => new Promise((resolve,reject) => {            
        setWorking(true);
        axios.delete(`${BASE_API_URL}/${id}`,{cancelToken})
            .then(data => {
                setMessageSuccess(`Deleted celestial body ${id}`);
                resolve({})
            })
            .catch((err) => {
                setMessageError(err.message);
                reject(err);
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }),[axios, cancelToken, refresh, setMessageError, setMessageSuccess])

    const postById = React.useCallback( ({_id, name, description},query=true) => new Promise((resolve,reject) => {
        setWorking(true);
        axios.post(`${BASE_API_URL}/${_id}`,{name, description}, {cancelToken})
            .then(() => {
                setMessageSuccess(`Updated celestial body ${_id}`);
                resolve({})
            })
            .catch((err) => {
                setMessageError(err.message);
                reject(err);
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }),[axios, cancelToken, refresh, setMessageError, setMessageSuccess])

    // Refresh on authentication
    React.useEffect(() => {
        if(enabled)
            refresh();
    },[enabled, refresh])

    // Refresh on SocketIO Instruction
    React.useEffect(() => {
        if(enabled && socket){
            socket.on('refresh_celestial_bodies', refresh)
            return () => {
                socket.off('refresh_celestial_bodies', refresh);
            }
        }
    },[enabled, refresh, socket]);

    const enable = React.useCallback( () => setEnabled(true) , []);

    // Context values
    const value = React.useMemo(() => ({
        data,
        setEnabled,
        enable,
        working,
        queried,
        refresh : debounce(refresh),
        create,
        postById,
        deleteById,
        messageSuccess,
        setMessageSuccess,
        messageError,
        setMessageError,
        DEFAULT_DATA,
        BASE_API_URL
    }), [create, data, deleteById, enable, messageError, messageSuccess, postById, queried, refresh, setMessageError, setMessageSuccess, working]);

    return (
        <CelestialPointContext.Provider value={value}>
            {children}
        </CelestialPointContext.Provider>
    )
}

// CelestialPoint Consumer
const CelestialPointConsumer = ({children}) => {
    return (
        <CelestialPointContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('CelestialPointConsumer must be used within CelestialPointProvider');
                }
                return children(context)
            }}
        </CelestialPointContext.Consumer>
    )
}

// useCelestialPoint Hook
const useCelestialPoint = () => {
    const context = React.useContext(CelestialPointContext);
    if(context === undefined)
        throw new Error('useCelestialPoint must be used within CelestialPointProvider');
    return context;
}

export {
    CelestialPointProvider,
    CelestialPointConsumer,
    useCelestialPoint
}