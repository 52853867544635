/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Archive Form
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       21st August 2021

*******************************************************************************************/
import React                            from 'react';
import pick                             from 'lodash/pick';
import {titleCase}                      from 'title-case';
import isEmpty                          from 'lodash/isEmpty';
import { 
    styled,
    Box, 
    Grid,
    Typography,
    InputLabel
}                                       from '@mui/material';
import Portal                           from '@mui/material/Portal';
import EditLocationAltIcon              from '@mui/icons-material/EditLocationAlt';
import BackupIcon                       from '@mui/icons-material/Backup';
import {
    Form,
    Button,
    JSONViewer,
    Title,
    DraggableDialog,
    GenerateReading,
    ProductCard,
    FeatureBox,
    NatalSummary,
    RemoveWithConfirmationButton,
    NoDataPrompt,
    Radix,
    CommentsThread,
    SpaceBox,
    HoroscopeComponent,
    ColorChip
}                                       from 'components';
import { EditButton }                   from 'components/buttons'
import {
    FileUploader
}                                       from 'components/modals';
import {
    HouseSystemField,
    ZodiacField,
    ProductField,
    NoopFields
}                                       from './fields';
import {
    NatalDataForm
}                                       from './NatalDataForm'
import {
    SkeletonProductCard,
}                                       from 'components/skeletons'
import { useSize }                      from 'hooks';
import { 
    TextField,
    showErrorOnChange   as showError,
    Autocomplete,
    Switches,
}                                       from 'mui-rff';
import { FormSpy }                      from 'react-final-form';
import {
    CommentsThreadProvider,
    useNetwork,
    useUser,
    useTranslation
}                                       from 'contexts';
import {
    useCancelToken
}                                       from 'hooks';

const ImageContainer = styled(Box)({
    display         : 'flex',
    justifyContent  : 'center',
    alignItems      : 'center',
    overflow        : 'hidden',
    position        : 'relative',
    width           : '100%',
    height          : '100%'
})

const Image = styled("img")({
    width           : '100%',
    minWidth        : '100%',
    minHeight       : '100%',
    transition      : 'all .2s ease-in-out',
    transform       : 'scale(1.00)',
    "&:hover" : {
        transform   : 'scale(0.99)',
    }
})

const noop = () => {}
const obj = {};
const arr = [];

const defaultNatalData = {
    lat             : undefined,
    lng             : undefined,
    address         : undefined,
    birthDateTime   : undefined,
    localTime       : undefined,
    unknownTime     : undefined,
    gender          : undefined,
    roddenRating    : undefined,
}

const defaultFormData = {
    id              : undefined,
    photo           : undefined,
    photoAttribution: '',
    tags            : arr,
    name            : '',
    firstName       : '',
    middleName      : '',
    lastName        : '',
    slug            : '',
    biography       : '',
    ...defaultNatalData,
    metadata        : obj,
    active          : false,
    enabled         : false,
    comments        : true
}

const BASE_API_URL = '/api/admin/examples';

export const ArchiveForm = ({
    formData    = defaultFormData,
    isEditing   = false,
    disabled    = false,
    onSubmit : handleSubmit = noop,
    onCancel : handleCancel = noop,
    sticky      = true,
    FormProps   = {},
    ...rest
}) => {

    const {t}                                           = useTranslation();
    const {axios, isNetworkReady}                       = useNetwork();
    const { isAuthenticated, isAdmin}                   = useUser();
    const {cancelToken  }                               = useCancelToken();
    const ref                                           = React.useRef();
    const {width}                                       = useSize(ref);
    const initialValues                                 = React.useMemo(() => ({...formData}), [formData]);
    const [open, setOpen]                               = React.useState(false);
    const handleOpen                                    = React.useCallback( () => setOpen(true), []);
    const handleClose                                   = React.useCallback( () => setOpen(false), []);
    const [readingData,     setReadingData]             = React.useState({});
    const [readingDataTemp, setReadingDataTemp]         = React.useState(undefined);
    const handleChangeReadingData                       = React.useCallback( ({result,formData}) => setReadingDataTemp({result,formData}), []);
    const portalRef                                     = React.useRef(null);
    const [tags, setTags]                               = React.useState([]);
    const [comments, setComments]                       = React.useState(formData.comments);
    
    const [natalDataSubmitted, setNatalDataSubmitted]   = React.useState(false);
    const [openEditNatalData, setOpenEditNatalData]     = React.useState(false);
    const handleOpenEditNatalData                       = React.useCallback( () => setOpenEditNatalData(true), []);
    const handleCloseEditNatalData                      = React.useCallback( () => setOpenEditNatalData(false), []);   

    React.useEffect(() => {
        if(isNetworkReady && isAuthenticated && isAdmin){
            axios.get(`${BASE_API_URL}/tags`, {cancelToken})
                .then(({data}) => data)
                .then(setTags)
                .catch(console.error)
        }
    },[axios, cancelToken, isAdmin, isAuthenticated, isNetworkReady]);


    const [openPhotoUploader,   setOpenPhotoUploader]   = React.useState(false);

    const validate                                      = React.useCallback( (values) => {

        let errors          = {};

        // Required Fields
        let requiredFields  = ['name','biography', ...Object.keys(defaultNatalData), 'houseSystem', 'product', 'zodiac'];
        requiredFields.forEach(item=>{
            if(values[item] === undefined || values[item] === '')
                errors[item] = errors[item] || t('components.forms.archiveForm.required');
        })

        // Errors
        return errors;

    }, [t]);

    return (
        <>
            <Form
                debug           = {false}
                disabled        = {disabled}
                onSubmit        = {handleSubmit}
                onCancel        = {handleCancel}
                initialValues   = {initialValues}
                validate        = {validate}
                sticky          = {sticky}
                {...FormProps}
                render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                    const handleCoverUploaderSubmit = (files, allFiles) => {
                        setOpenPhotoUploader(false);
                        let uploads = files.map(f => f?.meta?.fileUrl).filter(Boolean);
                        form.change('photo', uploads[0]);
                    };
                    const {horoscopeData} = values?.metadata?.result || {};
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container>

                                <Grid item xs={4} sm={3}>

                                    <Box style={{position:'sticky',top:0}}>
                                        
                                        <Box display="flex">
                                            <Box flexGrow={1}>
                                                <Title variant="h5" component="h2">
                                                    {t('components.forms.archiveForm.photo')}
                                                </Title>
                                            </Box>
                                            <Box>
                                                <Button disabled = {disabled} startIcon={<BackupIcon/>} size="small" color="secondary" onClick={() => setOpenPhotoUploader(true)}> 
                                                    {t('components.forms.archiveForm.upload')}
                                                </Button>
                                                { 
                                                    values.photo &&
                                                    <RemoveWithConfirmationButton 
                                                        disabled    = {disabled} 
                                                        size        = "small" 
                                                        color       = "secondary" 
                                                        onClick     = {() => {
                                                            form.change('photo', null)
                                                        }}
                                                    > 
                                                        {t('common.clear')}
                                                    </RemoveWithConfirmationButton>
                                                }
                                            </Box>
                                        </Box>
                                        <FeatureBox sx={{borderRadius:2}}>
                                            {
                                                values.photo &&
                                                <ImageContainer>
                                                    <Image
                                                        src         = {values.photo}
                                                        alt         = {""}
                                                        loading     = "lazy"
                                                    />
                                                </ImageContainer>
                                            }
                                            {
                                                !values.photo &&
                                                <Box sx={{pt:2}}>
                                                    <NoDataPrompt disabled={disabled} onClick={()=>setOpenPhotoUploader(true)}>
                                                        {t('components.forms.archiveForm.noPhotoUploaded')}
                                                    </NoDataPrompt>
                                                </Box>
                                            }
                                        </FeatureBox>

                                        <Box mt={2}>
                                            <TextField 
                                                disabled    = {disabled} 
                                                name        = {'photoAttribution'} 
                                                label       = {t('components.forms.archiveForm.photoAttribution')}
                                                {...{disabled : isEditing || disabled, type : "text" }}
                                                showError   = {showError}
                                                onChange    = {(e) => {
								                    const val = e.target.value;
								                    form.change('photoAttribution', val === "" ? null : val);
							                    }}
                                            />
                                        </Box>

                                        {
                                            false &&
                                            <Box mt={2}>
                                                <Title variant="h5" component="h2">
                                                    {t('components.forms.archiveForm.productUsed')}
                                                </Title>
                                                <Box>
                                                    { values?.metadata?.formData?.productData?.id && 
                                                        <ProductCard editable={false} productId={values?.metadata?.formData?.productData?.id} actionsHeight={0} showDescription={!true} showAddToCart={false}/>
                                                    }
                                                    { !values?.metadata?.formData?.productData?.id && 
                                                        <SkeletonProductCard />
                                                    }
                                                </Box>
                                            </Box>
                                        }
                                    </Box>
                                </Grid>
                                
                                <Grid item xs={8} sm={9}>
                                    <Grid container>
                                        <Grid item xs={12} md={8}>
                                            <Title variant="h5" component="h2">
                                                {t('components.forms.archiveForm.archiveDetails')}
                                            </Title>
                                            <Box sx={{'& > * + *' : {mt:2}}}>
                                                <Box>
                                                    <TextField 
                                                        name        = {'name'} 
                                                        label       = {t('components.forms.archiveForm.commonName')}
                                                        {...{disabled : isEditing || disabled, type : "text" }}
                                                        showError   = {showError}
                                                    />
                                                </Box>

                                                <Box>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={4}>
                                                            <TextField 
                                                                name        = {'firstName'} 
                                                                label       = {t('components.forms.archiveForm.firstName')}
                                                                {...{disabled : isEditing || disabled, type : "text" }}
                                                                showError   = {showError}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <TextField 
                                                                name        = {'middleName'} 
                                                                label       = {t('components.forms.archiveForm.middleName')}
                                                                {...{disabled : isEditing || disabled, type : "text" }}
                                                                showError   = {showError}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <TextField 
                                                                name        = {'lastName'} 
                                                                label       = {t('components.forms.archiveForm.lastName')}
                                                                {...{disabled : isEditing || disabled, type : "text" }}
                                                                showError   = {showError}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                                <Box>
                                                    <TextField 
                                                        name        = {'slug'} 
                                                        label       = {t('components.forms.archiveForm.slug')}
                                                        {...{disabled : isEditing || disabled, type : "text" }}
                                                        showError   = {showError}
                                                    />
                                                </Box>

                                                <Box>
                                                    <Autocomplete
                                                        disabled                = {disabled} 
                                                        label                   = {t('components.forms.archiveForm.tags')}
                                                        name                    = "tags"
                                                        freeSolo
                                                        options                 = {tags}
                                                        value                   = {values.tags}
                                                        getOptionValue          = {option => option}
                                                        getOptionLabel          = {option => titleCase(option)}
                                                        isOptionEqualToValue    = {(option,value) => option === value }
                                                        disableCloseOnSelect    = {false}
                                                        helperText              = {t('components.forms.archiveForm.tagsHelperText')}
                                                        showError               = {showError}
                                                        textFieldProps          = {{
                                                            InputLabelProps : {shrink :true}
                                                        }}
                                                        renderTags={(value, getTagProps) =>
                                                            value.map((option, index) => (
                                                                <ColorChip {...getTagProps({ index })} color="primary" size="small" label={titleCase(option)} />
                                                            ))
                                                        }
                                                        multiple
                                                    />
                                                </Box>


                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <Title variant="h5" component="h2">
                                                            {t('components.forms.archiveForm.flags')}
                                                        </Title>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <Switches
                                                            disabled    = {disabled} 
                                                            name        = "active"
                                                            data        = {{
                                                                label   : values.active 
                                                                    ? t('components.forms.archiveForm.archiveVisible') 
                                                                    : t('components.forms.archiveForm.archiveNotVisible'), 
                                                                value   : true
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <Switches
                                                            disabled    = {disabled} 
                                                            name        = "enabled"
                                                            data        = {{
                                                                label   : values.enabled
                                                                    ? t('components.forms.archiveForm.playlistEnabled') 
                                                                    : t('components.forms.archiveForm.playlistDisabled'), 
                                                                value   : true
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <Switches
                                                            disabled    = {disabled} 
                                                            name        = "comments"
                                                            data        = {{
                                                                label   : values.comments
                                                                    ? t('components.forms.archiveForm.commentsEnabled') 
                                                                    : t('components.forms.archiveForm.commentsDisabled'), 
                                                                value   : true
                                                            }}
                                                        />
                                                        <FormSpy
                                                            subscription    = {{ values: true }}
                                                            onChange        = { props => {
                                                                setComments(props.values.comments);
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Box>
                                                    <TextField 
                                                        name        = {'biography'} 
                                                        label       = {t('components.forms.archiveForm.biography')}
                                                        {...{multiline:true, minRows:5, disabled : isEditing || disabled, type:"text"}}
                                                        showError   = {showError}
                                                    />
                                                </Box>
                                                {
                                                    <Box ref={portalRef}/>
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Title variant="h5" component="h2">
                                                {t('components.forms.archiveForm.readingData')}
                                            </Title>
                                            <Box style={{position:'sticky',top:0, width:"100%"}} sx={{'& > * + *' : {mt:2}}}>
                                                <Box>
                                                    <ProductField 
                                                        useVirtual  = {true} 
                                                        usePhysical = {false} 
                                                        processor   = "NatalAnalysisCP" 
                                                        disabled    = {disabled} 
                                                        showError   = {showError}
                                                    />
                                                </Box>
                                                <Box display="flex" sx={{"& > * + *" : {ml : 1}}}>
                                                    <Box flexGrow={1}>
                                                        <ZodiacField 
                                                            disabled    = {disabled} 
                                                            showError   = {showError}
                                                        />
                                                    </Box>
                                                    <Box flexGrow={1}>
                                                        <HouseSystemField 
                                                            disabled    = {disabled} 
                                                            showError   = {showError}
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    
                                                    <Box display="flex">
                                                        <Box flexGrow={1}>
                                                            <InputLabel shrink={true}>
                                                                { t('components.forms.archiveForm.natalData') }
                                                            </InputLabel>
                                                        </Box>
                                                        <Box>
                                                            <EditButton disabled={disabled} size="small" onClick={handleOpenEditNatalData} style={{padding:2}}>
                                                                {t('common.edit')}
                                                            </EditButton>
                                                        </Box>
                                                    </Box>
                                                    <Box onClick={disabled ? null : handleOpenEditNatalData} sx={{cursor:disabled ? 'default' : 'pointer'}}>
                                                        <NatalSummary {...values} />
                                                        <NoopFields names={Object.keys(defaultNatalData)}/>
                                                    </Box>
                                                    <DraggableDialog
                                                        title       = {t('components.forms.archiveForm.editNatalData')}
                                                        open        = {openEditNatalData}
                                                        showButtons = {false}
                                                        onClose     = {handleCloseEditNatalData}
                                                    >
                                                        <NatalDataForm 
                                                            usePhoto        = {false}
                                                            useDescription  = {false}
                                                            useIsUser       = {false}
                                                            formData        = {pick(values,Object.keys(defaultNatalData))} 
                                                            onCancel        = {handleCloseEditNatalData}
                                                            onSubmit        = {(formData) => {
                                                                Object.keys(defaultNatalData).forEach((key) => {
                                                                    form.change(key,formData[key]);
                                                                })
                                                                setNatalDataSubmitted(true);
                                                                handleCloseEditNatalData();
                                                            }}
                                                        />
                                                    </DraggableDialog>
                                                </Box>

                                                {
                                                    <Box sx={{mt:0}} width="100%">
                                                        
                                                        <Box 
                                                            ref     = {ref} 
                                                            style   = {{width:'100%', maxWidth:400,margin:'auto'}}
                                                            sx      = {{
                                                                p    : 2,
                                                                px   : 0,
                                                                zoom : {xs:'0.6',sm:'0.7',md:'0.8',lg:'0.9',xl:'1.0'}
                                                            }}
                                                        >
                                                            {
                                                                horoscopeData && !natalDataSubmitted && width > 0 &&
                                                                <Box sx={{mx:'auto', width:'fit-content'}}>
                                                                    <Radix 
                                                                        width   = {0.99 * width}
                                                                        data    = {horoscopeData}
                                                                    />
                                                                </Box>
                                                            }
                                                            
                                                            {
                                                                (!horoscopeData || natalDataSubmitted) && width > 0 && 
                                                                <Box sx={{mx:'auto'}}>
                                                                    <HoroscopeComponent {...values} render = {({horoscopeData}) => (
                                                                        <Radix 
                                                                            width   = {0.99 * width}
                                                                            data    = {horoscopeData}
                                                                        />
                                                                    )}/>
                                                                </Box>
                                                            }
                                                        </Box>
                                                    </Box>
                                                }

                                                {
                                                    false &&
                                                    <Box textAlign="center">
                                                        <Button disabled={disabled} startIcon={<EditLocationAltIcon/>} size="large" variant="contained" color="primary" onClick={handleOpen} style={{width:'100%'}}>
                                                            { t('components.forms.archiveForm.modifyReading') }
                                                        </Button>
                                                    </Box>
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            

                            {false && 
                                <Box>
                                    <JSONViewer src={readingData || []}/>
                                </Box>
                            }

                            <DraggableDialog 
                                title       = {t('components.forms.archiveForm.generateReading')}
                                open        = {open}
                                fullScreen  = {true}
                                onClose     = {handleClose}
                                onOk        = {() => {
                                    if(readingDataTemp){
                                        let {natalRecord} = readingDataTemp.formData;
                                        Object.keys(natalRecord).forEach((key,ix) => {
                                            form.change(key,natalRecord[key]);
                                        })
                                        form.change('metadata',readingDataTemp)
                                        setReadingData({...readingDataTemp})
                                    }
                                    setReadingDataTemp(undefined);
                                    handleClose();
                                }}
                                okenabled   = {!isEmpty(readingDataTemp?.result) && (readingDataTemp?.result?.files || []).length > 0}
                            >
                                <GenerateReading 
                                    formData        = {values?.metadata?.formData}
                                    result          = {values?.metadata?.result}
                                    allowStitching  = {false} 
                                    auto            = {!true} 
                                    onChange        = {handleChangeReadingData} 
                                    stickySpacing   = {0}
                                />
                            </DraggableDialog>


                            <FileUploader 
                                title       = {t('components.forms.archiveForm.uploadPhoto')}
                                open        = {openPhotoUploader} 
                                multiple    = {false}
                                onClose     = {() => setOpenPhotoUploader(false)} 
                                onSubmit    = {handleCoverUploaderSubmit}
                            />
                        </form>
                    );
                }}
            />

            <Portal container={portalRef.current}>
                {
                    formData.id && comments && 
                    <CommentsThreadProvider>
                        <CommentsThread reference = {formData.id} privateThread = {false} />
                    </CommentsThreadProvider>
                }
                {
                    formData.id && !comments && 
                    <Box>
                        <Title variant="h5" component="h2" gutterBottom>
                            {t('components.forms.archiveForm.comments')}
                        </Title>
                        <FeatureBox>
                            <SpaceBox>
                                <Typography align="center">
                                    {t('components.forms.archiveForm.commentsDisabled')}
                                </Typography>
                            </SpaceBox>
                        </FeatureBox>
                    </Box>
                }

            </Portal>
        </>
    );
}

export default ArchiveForm;