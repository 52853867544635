/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Cart Addresses
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       19th July 2021

*******************************************************************************************/
import React                            from 'react';
import {isEmpty}                        from 'lodash';
import { 
    styled, 
    Box, 
    Grid, 
    Typography 
}                                       from '@mui/material';
import {
    Edit as EditIcon
}                                       from '@mui/icons-material';
import { 
    FormAlert,
    Title,
    ItemPaper,
    FeatureBox,
    GoogleMapsStaticWithGeolocation,
    AddressBlock,
    Loading,
    Button
}                                       from 'components';
import { withTranslation }              from './hoc';
import {
    Address,
    Confirmation
}                                       from 'components/modals';
import {
    useCart,
    useUser,
    useAddress
}                                       from 'contexts';

const BORDER_RADIUS = 3;


const EditButton = withTranslation( ({t, ...props}) => (
    <Button {...props} startIcon={<EditIcon/>} color="secondary" variant="contained" size="small"> 
        {props.children || t('components.cartAddresses.modifyOrder')}
    </Button>
));

const MapsContainer = styled(Box)(({theme}) => ({
    position    : 'relative',
    width       : '100%',
    height      : 100,
    marginTop   : theme.spacing(2)
}))

const MapsContainerSameAddress = styled(Box)({
    position    : 'relative',
    width       : '100%',
    height      : 200,
    marginTop   : 0
})

// Display Alert addressess differ from what is on file with the user.
const PromptAddressesDiffer = withTranslation( ({t}) => {

    const {
        isEqual         : isEqualAddress,
        billingAddress  : userBillingAddress,
        shippingAddress : userShippingAddress
    }                                           = useAddress();
    const { 
        resetAddresses,
        requiresShipping,
        shippingAddress,    shippingAddressWorking,
        billingAddress,     billingAddressWorking,
    }                                           = useCart();

    const showBilling   = React.useMemo(() => (
        !isEmpty(userBillingAddress)  && !isEqualAddress(billingAddress,   userBillingAddress)
    ), [billingAddress, isEqualAddress, userBillingAddress]);
    
    const showShipping  = React.useMemo(() => (
        requiresShipping && !isEmpty(userShippingAddress) && !isEqualAddress(shippingAddress,  userShippingAddress)
    ), [isEqualAddress, requiresShipping, shippingAddress, userShippingAddress]);
    
    if(!showBilling && !showShipping) 
        return null;

    if(billingAddressWorking || shippingAddressWorking) 
        return null;

    return (
        <Box sx={{mb:2}}>
            <FormAlert 
                severity    = "info" 
                action      = {
                    <Button onClick={() => resetAddresses(true)} size="small" variant="contained" color="primary" style={{minWidth:100}}>
                        {t('components.cartAddresses.yesPlease')}
                    </Button>
                }
            >
                {t('components.cartAddresses.addressesDifferFromAccount')}
            </FormAlert>
        </Box>
    )
})

export const CartAddresses = withTranslation( ({t, disabled = false }) => {

    const { geolocation,user }                  = useUser();
    const { isEqual : isEqualAddress }          = useAddress();
    const { 
        requiresShipping,
        shippingAddress,
        shippingAddressWorking,
        setShippingAddress,
        billingAddress,
        billingAddressWorking,
        setBillingAddress
    }                                           = useCart();
    const [openBilling,     setOpenBilling]     = React.useState(false);
    const [openShipping,    setOpenShipping]    = React.useState(false);
    const [openCopy,            setOpenCopy]    = React.useState(false);

    React.useEffect(() => setOpenCopy(false), []);

    // Extract the user geoip if it is available
    const {countryCode, stateCode, city}        = React.useMemo(() => geolocation || {}, [geolocation]);

    // Extract the user name if it is available
    const {
        family_name : lastName, 
        given_name  : firstName
    }                                           = React.useMemo(() => user || {}, [user]);

    // Are Addresses Equal
    const isAddressesEqual  = React.useMemo(() => (
        isEqualAddress(shippingAddress, billingAddress, false)
    ), [billingAddress, isEqualAddress, shippingAddress]);

    // Prompt for Addresses
    const promptAddresses = React.useMemo(() => ({
        "billing"   : Boolean(!billingAddress && !billingAddressWorking),
        "shipping"  : Boolean(!shippingAddress && !shippingAddressWorking && requiresShipping)
    }),[billingAddress, billingAddressWorking, requiresShipping, shippingAddress, shippingAddressWorking])
    
    return (
        <ItemPaper>
            <Title>
                {
                    requiresShipping 
                        ? t('components.cartAddresses.billingShippingAddress')
                        : t('components.cartAddresses.billingAddress')
                }
            </Title>
            {
                !disabled && 
                <PromptAddressesDiffer />
            }
            {
                Object.values(promptAddresses).some(Boolean) && 
                <Box mb={1}>
                    <FormAlert severity="error">
                        { Boolean(promptAddresses.billing && promptAddresses.shipping) 
                            && t('components.cartAddresses.requiresBillingShippingAddress') 
                        }
                        { Boolean(promptAddresses.billing && !promptAddresses.shipping) 
                            && t('components.cartAddresses.requiresBillingAddress') 
                        }
                        { Boolean(!promptAddresses.billing && promptAddresses.shipping)
                            && t('components.cartAddresses.requiresShippingAddress') 
                        }
                    </FormAlert>
                </Box>
            }

            <Grid container display="flex">
                <Grid item xs={12} md={6}>
                    <FeatureBox 
                        component   = {ItemPaper}
                        title       = {t('components.cartAddresses.billingAddress')}
                        style       = {{borderRadius:BORDER_RADIUS}}
                        actions     = {
                            <EditButton disabled={disabled || billingAddressWorking} onClick={()=>setOpenBilling(true)}>
                                {t('common.edit')}
                            </EditButton>
                        }
                    >
                        <AddressBlock {...billingAddress}/>
                        <Address 
                            open        = {openBilling} 
                            title       = {t('components.cartAddresses.billingAddress')} 
                            onSubmit    = {(values) => {setBillingAddress(values); setOpenBilling(false)}}
                            onClose     = {() => setOpenBilling(false)}
                            onCancel    = {() => setOpenBilling(false)}
                            formData    = {{
                                firstName,
                                lastName,
                                countryCode,
                                stateCode,
                                city,
                                ...billingAddress,
                                kind : "BILLING",
                            }}
                        >
                            <Typography paragraph>
                                {t('components.cartAddresses.pleaseEditBillingAddress')} 
                            </Typography>
                        </Address>
                    </FeatureBox>
                    {
                        billingAddress?.geolocation  && !isAddressesEqual &&
                        <MapsContainer>
                            <GoogleMapsStaticWithGeolocation 
                                borderRadius    = {BORDER_RADIUS} 
                                lat             = {billingAddress?.geolocation?.lat} 
                                lng             = {billingAddress?.geolocation?.lng} 
                                width           = {400}
                            />
                        </MapsContainer>
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    <FeatureBox 
                        component   = {ItemPaper}
                        title       = {t('components.cartAddresses.shippingAddress')}
                        style       = {{borderRadius:BORDER_RADIUS}}
                        actions     = {
                            <React.Fragment>
                                {
                                    requiresShipping &&
                                    <>
                                        {!disabled && !isEmpty(billingAddress) && !isEqualAddress({...billingAddress, kind : "SHIPPING"}, shippingAddress) &&
                                            <EditButton disabled={disabled || billingAddressWorking || shippingAddressWorking} onClick={() => setOpenCopy(true)}>
                                                {t('common.copy')}
                                            </EditButton>
                                        }
                                        <EditButton disabled={disabled || shippingAddressWorking} style={{marginLeft:2}}  onClick={()=>setOpenShipping(true)}>
                                            {t('common.edit')}
                                        </EditButton>
                                        <Confirmation 
                                            title       = {t('common.confirmationRequired')}
                                            maxWidth     = {'xs'} 
                                            open        = {openCopy}
                                            onClose     = {() => setOpenCopy(false)} 
                                            onCancel    = {() => setOpenCopy(false)} 
                                            onOk        = {() => { 
                                                setShippingAddress({...billingAddress, kind : "SHIPPING"}); 
                                                setOpenCopy(false);
                                            }}
                                        >
                                            <Typography>
                                                {t('components.cartAddresses.confirmReplaceShippingWithBilling')} 
                                            </Typography>
                                        </Confirmation>
                                    </>
                                }
                            </React.Fragment>
                        }>
                            
                            {   
                                requiresShipping &&
                                <React.Fragment>
                                    <AddressBlock {...shippingAddress}/>
                                    <Address 
                                        open        = {openShipping} 
                                        title       = {t('components.cartAddresses.shippingAddress')}
                                        onSubmit    = {(values) => {setShippingAddress(values); setOpenShipping(false)}}
                                        onClose     = {() => setOpenShipping(false)}
                                        onCancel    = {() => setOpenShipping(false)}
                                        formData    = {{
                                            firstName,
                                            lastName,
                                            countryCode,
                                            stateCode,
                                            city,
                                            ...shippingAddress,
                                            kind : "SHIPPING",
                                        }}
                                    >
                                        <Typography paragraph>
                                            {t('components.cartAddresses.pleaseEditShippingAddress')} 
                                        </Typography>
                                    </Address>
                                </React.Fragment>
                            }
                            {   
                                !requiresShipping &&
                                <AddressBlock>
                                    <Typography variant="body2" color="textSecondary">
                                        {t('components.cartAddresses.noItemsRequireShipping')} 
                                    </Typography>
                                </AddressBlock>
                            }
                    </FeatureBox>
                    {
                        requiresShipping && shippingAddress?.geolocation && !isAddressesEqual &&
                        <MapsContainer>
                            <GoogleMapsStaticWithGeolocation 
                                borderRadius    = {BORDER_RADIUS} 
                                lat             = {shippingAddress?.geolocation?.lat} 
                                lng             = {shippingAddress?.geolocation?.lng} 
                                width           = {400}
                            />
                        </MapsContainer>
                    }
                </Grid>
                <Grid item xs={12}>
                    {
                        billingAddress?.geolocation && isAddressesEqual && 
                        <MapsContainerSameAddress>
                            <GoogleMapsStaticWithGeolocation 
                                borderRadius    = {BORDER_RADIUS} 
                                lat             = {billingAddress?.geolocation?.lat} 
                                lng             = {billingAddress?.geolocation?.lng} 
                                width           = {800}
                            />
                        </MapsContainerSameAddress>
                    }
                </Grid>
            </Grid>
            {
                (shippingAddressWorking || billingAddressWorking) &&
                <Loading >
                    {t('components.cartAddresses.loadingAddresses')} 
                </Loading>
            }
        </ItemPaper>
    )
});

export default CartAddresses;