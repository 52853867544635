
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Product Context
********************************************************************************************
Listens to changes to authentication, user and pushes update to server

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       18th Jun 2021

*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';
import { useNetwork }           from './NetworkContext';
import { useContentful }        from './ContentfulContext';
import {
    useStateEphemeral,
    useCancelToken
}                               from 'hooks';
import { debounce }             from 'functions';
import { ProductLocation }      from 'router/locations/Locations';
import DEFAULT_PRODUCT_IMAGE    from 'resources/universe/universe.gif';

// The Product Context 
const ProductContext = React.createContext(undefined);

const BASE_API_URL                  = '/api/product';
const GRAYSCALE_WHEN_UNAVAILABLE    = false;

// Voltage Provider
const ProductProvider = ({children}) => {
    
    const {isNetworkReady, axios, socketRoot : socket}      = useNetwork();
    const {cancelToken }                                    = useCancelToken();
    const {getProducts : getContentful}                     = useContentful();

    // Messages
    const [messageSuccess,  setMessageSuccess]              = useStateEphemeral(undefined);
    const [messageWarning]                                  = useStateEphemeral(undefined);
    const [messageError,    setMessageError]                = useStateEphemeral(undefined,5000);

    const [data,        setData]                            = React.useState([]);
    const [contentful,  setContentful]                      = React.useState([]);
    const [contentfulLoading,  setContentfulLoading]        = React.useState(false);
    const [isRetrieved, setRetrieved]                       = React.useState(false);
    const [queried,     setQueried]                         = React.useState(undefined);
    const [loading,     setLoading]                         = React.useState(false);
    const [compiling,   setCompiling]                       = React.useState(false);

    const [processors,  setProcessors]                      = React.useState({});

    const toUrl = React.useCallback(({id,absolute=true} = {}) => {
        const {origin} = window.location;
        if(!id) return origin;
        const rel = ProductLocation.toUrl({slug : id})
        return absolute ? `${origin}${rel}` : rel;
    },[])

    React.useEffect(() => {
        setContentfulLoading(true)
        getContentful()
            .then(data => {
                setContentful(data);
            })
            .catch(err => {
                setContentful([]);
            })
            .finally(() => {
                setContentfulLoading(false);
            })
    },[getContentful])

    const getProcessors = React.useCallback(()=> new Promise((resolve,reject) => {
        setLoading(true);
        axios.get(`${BASE_API_URL}/processors`, {cancelToken})
            .then(({data}) => {
                // console.log(data)
                return data
            })
            .then(resolve)
            .catch(reject)
            .finally(()=>{
                setLoading(false);
            })
    }),[axios, cancelToken ])

    const getProductById = React.useCallback((productId) => {
        return (data || []).find(x => x._id === productId)
    },[data])

    const getProducts = React.useCallback( () => new Promise((resolve, reject) => {
        setLoading(true);
        axios.get(BASE_API_URL, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(err => {
                setMessageError(err?.message);
                reject(err);
            })
            .finally(() => {
                setQueried(moment())
                setLoading(false);
                setRetrieved(true);
            });
    }),[axios, cancelToken, setMessageError])

    const createProduct = React.useCallback( ({
        type, name, slug, abstract, description, listPrice, 
        discountValue, discountType, listed, available, 
        /*taxExempt,*/ tags, categories, cover, images, processor, deleted = false, productInput = {}, 
        leadTimeSecondsOverride,
        priority = 0, 
        canReview, canExample
    }) => new Promise((resolve, reject) => {
        setLoading(true);
        const args = {
            type, name, slug, abstract, description, listPrice, discountValue, discountType, listed, available, 
            /*taxExempt,*/ tags, categories, cover, images, processor, deleted, productInput, leadTimeSecondsOverride, priority,
            canReview, canExample
        };
        axios.post(BASE_API_URL, args, {cancelToken})
            .then(({data}) => data)
            .then(data => {
                setMessageSuccess('Product created');
                return data;
            })
            .then(resolve)
            .catch(err => {
                setMessageError(err?.message);
                reject(err);
            })
            .finally(()=>{
                setLoading(false);
            })
    }), [axios, cancelToken, setMessageError, setMessageSuccess])

    // Delete Natal Data by Id
    const deleteProduct = React.useCallback( ({id}) => new Promise((resolve, reject) => {
        setLoading(true);
        axios.delete(`${BASE_API_URL}/${id}`, {cancelToken})
            .then(({data}) => data)
            .then(data => {
                setMessageSuccess("Product deleted");
                resolve(data);
            })
            .catch(err => {
                setMessageError(err?.message);
                reject(err);
            })
            .finally(()=>{
                setLoading(false);
            })
    }),[axios, cancelToken, setMessageError, setMessageSuccess])

    const updateProduct = React.useCallback( ({
        id, 
        type, name, slug, abstract, description, listPrice, 
        discountValue, discountType, listed, available, 
        /*taxExempt,*/ tags, categories, cover, images, processor, deleted = false, productInput = {}, leadTimeSecondsOverride, 
        priority = 0,
        canReview, canExample, 
        ...rest
    }) => new Promise((resolve, reject) => {

        setLoading(true);
        const args = {
            type, name, slug, abstract, description, listPrice, 
            discountValue, discountType, listed, available, 
            /*taxExempt,*/ tags, categories, cover, images, processor, deleted,
            productInput, leadTimeSecondsOverride, priority, 
            canReview, canExample, 
            ...rest
        }
        axios.post(`${BASE_API_URL}/${id}`, args, {cancelToken})
            .then(({data}) => data)
            .then(data => {
                setMessageSuccess("Product updated");
                resolve(data);
            })
            .catch(err => {
                setMessageError(err?.message);
                reject(err);
            })
            .finally(()=>{
                setLoading(false);
            })
    }), [axios, cancelToken, setMessageError, setMessageSuccess])

    const clear                         = () => { 
        setData([]); 
        setRetrieved(false);
        setQueried(undefined);
    }

    const clearProcessors               = () => {
        setProcessors({});
    }
    
    const refresh = React.useCallback( (reset) => {
        if(reset) clear();
        getProducts().then(setData).catch(clear)
    },[getProducts])

    const refreshProductById = React.useCallback((productId) => {
        refresh(); // TODO fix to refresh only productId
    },[refresh])

    // Refresh on initial load
    React.useEffect(() => {
        if(isNetworkReady) refresh();
    },[isNetworkReady, refresh]);

    // Refresh on SocketIO Instruction
    React.useEffect(() => {
        if(socket){
            socket.on('refresh_products',   refresh);
            socket.on('refresh_product',    refreshProductById)
            return () => {
                socket.off('refresh_products',  refresh);
                socket.off('refresh_product',   refreshProductById);
            }
        }
    },[refresh, refreshProductById, socket])

    // Refresh the processors
    React.useEffect(()=>{
        if(isNetworkReady)
            getProcessors().then(setProcessors).catch(clearProcessors)
    },[isNetworkReady, getProcessors])

    // Compile Product Method
    const compileProduct = React.useCallback( (id) => new Promise((resolve,reject) => {
        setCompiling(true);
        axios.post(`${BASE_API_URL}/${id}/compile`, {}, {cancelToken})
            .then(({data}) => data)
            .then((data) => {
                refresh();
                setMessageSuccess("Product compiled");
                resolve(data);
            })
            .catch(err => {
                setMessageError(err?.message);
                reject(err);
            })
            .finally(()=>{
                setTimeout(() => setCompiling(false), 5000);
            })
    }), [axios, cancelToken, refresh, setMessageError, setMessageSuccess])

    // Quantity of items
    const quantity  = React.useMemo(() => (data || []).map(p => p?.listed).filter(Boolean).length, [data]);
    const hasData   = React.useMemo(() => quantity > 0, [quantity]);

    // Context values
    const value = {
        messageSuccess,
        messageWarning,
        messageError,
        isRetrieved,
        data,
        contentful,
        contentfulLoading,
        processors,
        quantity, 
        hasData,
        hasProducts : hasData,
        refresh : debounce(refresh),
        getProductById,
        createProduct,
        deleteProduct,
        updateProduct,
        compileProduct,
        queried,
        loading,
        compiling,
        toUrl,
        DEFAULT_PRODUCT_IMAGE,
        GRAYSCALE_WHEN_UNAVAILABLE
    };

    return (
        <ProductContext.Provider value={value}>
            {children}
        </ProductContext.Provider>
    )
}

// Voltage Consumer
const ProductConsumer = ({children}) => {
    return (
        <ProductContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('ProductConsumer must be used within ProductProvider');
                }
                return children(context)
            }}
        </ProductContext.Consumer>
    )
}

// useVoltage Hook
const useProduct = () => {
    const context = React.useContext(ProductContext);
    if(context === undefined)
        throw new Error('useProduct must be used within ProductProvider');
    return context;
}

export {
    ProductProvider,
    ProductConsumer,
    useProduct
}
