/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Public Private Chip
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6rh April 2022

*******************************************************************************************/
import React                            from 'react';
import {
    VisibilityOff       as PrivateIcon,
    Visibility          as PublicIcon,
}                                       from '@mui/icons-material';
import ColorChip                        from "./ColorChip";

const obj = {};

// Private Chip
const PrivateChip = ({
    label   = "Private", 
    color   = "error.light",    
    icon    = <PrivateIcon/>, 
    ...props
}) => (
    <ColorChip
        icon    = {icon}
        label   = {label}
        color   = {color}
        {...props}
    />
)

// Public Chip
const PublicChip = ({
    label   = "Public",  
    color   = "primary.light",      
    icon    = <PublicIcon/>, 
    ...props
}) => (
    <ColorChip
        icon    = {icon}
        label   = {label}
        color   = {color}
        {...props}
    />
)

// Public Private Chip
const PublicPrivateChip = ({
    isPublic            = true, 
    publicChipProps     = obj,
    privateChipProps    = obj, 
     ...props
}) => {
    return isPublic 
        ? <PublicChip   {...props} {...publicChipProps}     />
        : <PrivateChip  {...props} {...privateChipProps}    />
}

// Named Exports
export {
    PrivateChip,
    PublicChip,
    PublicPrivateChip
}

// Default Export
export default PublicPrivateChip;
