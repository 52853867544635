/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Chips Socials
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024

*******************************************************************************************/
import React                    from 'react';
import {titleCase}              from 'title-case';
import { useHistory}            from "react-router-dom";
import {
    Box,
}                               from '@mui/material';
import { 
    SocialSharePage,
    Like,
    ColorChip
}                               from 'components';
import { 
    ExamplesCollectionLocation,
}                               from 'router/locations/Locations';
import {
    useBreakpointQueries
}                               from 'hooks';     
import { useExample }           from './ExampleContext';

export const ExampleChipsSocials = () => {
    const history   = useHistory();
    const {lgUp}    = useBreakpointQueries();
    const { 
        data,
        allowSharing
    }  = useExample();

    const chipData  = React.useMemo( () => {
        const gender    = data?.gender;
        const tags      = data?.tags || [];    
        return [
            ...(tags || [])
                .map(tag => ({ key: tag, label: titleCase(tag), color: 'primary', param: 'tags' })),
            gender && 
                { key: gender, label: titleCase(gender), color: 'warning', param: 'gender' }
        ].filter(Boolean)
    },[data?.gender, data?.tags]);

    return (
        <Box sx={{"& > * + *" : { mt :2}}} >
            <Box display="flex" width="100%" alignItems="center" sx={{"& > * + *" : { ml :2}}} >
                {/* Chips Section */}
                <Box flexGrow={1} display="flex" flexWrap="wrap">
                    {
                        chipData.reduce((acc, {label, color, param, key}, ix) => {
                        acc.push(
                            <ColorChip
                                key     = {ix}
                                label   = {label}
                                sx      = {{ m: 0.15 }}
                                color   = {color}
                                onClick={() => {
                                    history.push(`${ExamplesCollectionLocation.path}?${param}=${key}`);
                                }}
                            />
                        );
                        return acc;
                    }, [])}
                </Box>
                {/* Like Button */}
                <Box>
                    <Like reference={data?.id} size="small" />
                </Box>
                {/* SocialSharePage only visible on larger viewports */}
                {
                    lgUp && (
                    <Box>
                        <SocialSharePage disabled={!allowSharing}/>
                    </Box>
                )}
            </Box>
            {/* SocialSharePage for smaller viewports */}
            {
                !lgUp && (
                <Box textAlign="right">
                    <SocialSharePage disabled={!allowSharing}/>
                </Box>
            )}
        </Box>
    );
};

export default ExampleChipsSocials;