
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
UserProfile Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       29th September 2024
*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import {useNetwork}                     from './NetworkContext';
import {useUser}                        from './UserContext';
import {
    useCancelToken,
    useStateEphemeral,
}                                       from 'hooks';

// The Product Context 
const UserProfileContext = React.createContext({});

// Base API URL
const BASE_API_URL = '/api/user/profile';

// Filter Collection Function
const filterProfiles = userId => profiles => (profiles || []).filter(({user}) => userId && user === userId)[0];

// UserProfile Provider
const UserProfileProvider = ({children}) => {

    const {userId, isAuthenticated}             = useUser();
    const {axios}                               = useNetwork();
    const {cancelToken }                        = useCancelToken();
    const [loading,         setLoading]         = React.useState(false);
    const [queried,         setQueried]         = React.useState(undefined);
    const [data,            setData]            = React.useState(undefined);

    const [messageSuccess,  setMessageSuccess]  = useStateEphemeral(undefined);
    const [messageError,    setMessageError]    = useStateEphemeral(undefined);

    const query = React.useCallback(() => new Promise((resolve,reject) => {
        if(userId && isAuthenticated){
            setLoading(true);
            axios.get(BASE_API_URL, { cancelToken })
                .then(({data}) => data)
                .then(filterProfiles(userId))
                .then(resolve)
                .catch(reject)
                .finally(() => {
                    setLoading(false);
                })
        }else{
            resolve(undefined);
        }
    }),[axios, cancelToken, isAuthenticated, userId])

    const update = React.useCallback(({_id : id, ...data}) => new Promise((resolve, reject) => {
        try {
            if(!id)
                throw new Error('id is required')
            setLoading(true);
            axios.post(`${BASE_API_URL}/${id}`, {...data}, {cancelToken})
                .then(({data}) => data)
                .then((profile) => {
                    setMessageSuccess("Successfully updated user profile");
                    setData(profile)
                    setQueried(moment());
                })
                .then(resolve)
                .catch(err => {
                    setMessageError(err?.message)
                    reject(err);
                })
                .finally(() => {
                    setLoading(false)
                })
        } catch (err) {
            reject(err);
        }
    }), [axios, cancelToken, setMessageSuccess, setMessageError])

    const refresh = React.useCallback(() => {
        query()
            .then(
                profile => {
                    setMessageSuccess("Successfully queried user profile");
                    setData(profile);
                    setQueried(moment());
                }
            )
            .catch(err => {
                setMessageError(err?.message || "Error querying user profile")
                setData(undefined);
            })
    },[query, setMessageError, setMessageSuccess])

    React.useEffect(() => {
        refresh();
        return () => {
            setData(undefined);
        }
    },[refresh])

    // Context values
    const value = {
        loading,
        queried,
        data,
        query,
        update,
        refresh,
        messageSuccess,
        messageError
    };

    return (
        <UserProfileContext.Provider value={value}>
            {children}
        </UserProfileContext.Provider>
    )
}

// UserProfile Consumer
const UserProfileConsumer = ({children}) => {
    return (
        <UserProfileContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('UserProfileConsumer must be used within UserProfileProvider');
                }
                return children(context)
            }}
        </UserProfileContext.Consumer>
    )
}

// useUserProfile Hook
const useUserProfile = () => {
    const context = React.useContext(UserProfileContext);
    if(context === undefined)
        throw new Error('useUserProfile must be used within UserProfileProvider');
    return context;
}

export {
    UserProfileProvider,
    UserProfileConsumer,
    useUserProfile
}