
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Sign Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       21st August 2021

*******************************************************************************************/
import React from 'react';
import moment                   from 'moment';
import {useNetwork}             from './NetworkContext';
import { 
    useStateEphemeral, 
    useCancelToken
}                               from '../hooks';
import {debounce}               from '../functions';

// The Product Context 
const SignContext = React.createContext(undefined);

const makeDefaultData = (name,description,rulers) => ({name,description,rulers});
const DEFAULT_DATA = [
    makeDefaultData(
        'aries',        
        "The Sun in such high dignity gives them excellent organizational skills, so you'll rarely meet an Aries who isn't capable of finishing several things at once, often before lunch break! Their challenges show when they get impatient, aggressive and vent anger pointing it to other people. Strong personalities born under this sign have a task to fight for their goals, embracing togetherness and teamwork through this incarnation.",
        ['mars']
    ),    
    makeDefaultData(
        'taurus',       
        "Taurus is an Earth sign, just like Virgo and Capricorn, and has the ability to see things from a grounded, practical and realistic perspective. They find it easy to make money and stay on same projects for years, or until they are completed. What we often see as stubbornness can be interpreted as commitment, and their ability to complete tasks whatever it takes is uncanny. This makes them excellent employees, great long-term friends and partners, always being there for people they love. Earthly note makes them overprotective, conservative, or materialistic at times, with views of the world founded on their love of money and wealth.",
        ['venus']
    ),
    makeDefaultData(
        'gemini',       
        "The sign of Gemini belongs to the element of Air, accompanying Libra and Aquarius, and this connects it to all aspects of the mind. It is ruled by Mercury, the planet that represents communication, writing, and movement. People born under this Sun sign often have a feeling that their other half is missing, so they are forever seeking new friends, mentors, colleagues and people to talk to.",
        ['mercury']
    ),
    makeDefaultData(
        'cancer',       
        "The sign of Cancer belongs to the element of Water, just like Scorpio and Pisces. Guided by emotion and their heart, they could have a hard time blending into the world around them. Being ruled by the Moon, phases of the lunar cycle deepen their internal mysteries and create fleeting emotional patterns that are beyond their control. As children, they don't have enough coping and defensive mechanisms for the outer world, and have to be approached with care and understanding, for that is what they give in return.",
        ['moon']
    ),
    makeDefaultData(
        'leo',          
        "Leo belongs to the element of Fire, just like Aries and Sagittarius. This makes them warmhearted, in love with life, trying to laugh and have a good time. Able to use their mind to solve even the most difficult problems, they will easily take initiative in resolving various complicated situations. Ruled by the Sun, Leo worships this fiery entity in the sky, quite literally as well as metaphorically. They are in search for self-awareness and in constant growth of ego. Aware of their desires and personality, they can easily ask for everything they need, but could just as easily unconsciously neglect the needs of other people in their chase for personal gain or status. When a Leo representative becomes too fond and attached to their achievements and the way other people see them, they become an easy target, ready to be taken down.",
        ['sun']
    ),
    makeDefaultData(
        'virgo',        
        "Virgo is an Earth sign, fitting perfectly between Taurus and Capricorn. This will lead to a strong character, but one that prefers conservative, well-organized things and a lot of practicality in their everyday life. These individuals have an organized life, and even when they let go to chaos, their goals and dreams still have strictly defined borders in their mind. Constantly worried that they missed a detail that will be impossible to fix, they can get stuck in details, becoming overly critical and concerned about matters that nobody else seems to care much about.",
        ['mercury']
    ),
    makeDefaultData(
        'libra',        
        "The sign of Libra is an Air sign, set between Gemini and Aquarius, giving these individuals constant mental stimuli, strong intellect and a keen mind. They will be inspired by good books, insurmountable discussions and people who have a lot to say. Each Libra representative has to be careful when talking to other people, for when they are forced to decide about something that is coming their way, or to choose sides, they suddenly realize that they might be in the wrong place and surrounded by wrong people. No partner should make them forget that they have their own opinion.",
        ['venus']
    ),
    makeDefaultData(
        'scorpio',     
        "This is a Water sign, just as Cancer and Pisces, and its representatives have to experience, express and face emotions. Always fighting dishonesty of any kind, they have to learn not to manipulate or turn people to look their way by methods that aren’t straightforward and clean. These inner tendencies sometimes make them distrustful and jealous, suspicion leading them to strange behavioral patterns they have to unveil.",
        ['mars','pluto']
    ),
    makeDefaultData(
        'sagittarius',  
        "Just like Aries and Leo, Sagittarius is a Fire sign, in search of ways to creatively color the world in their colors. Clear about their intentions and honest by moral imperative, its representatives are often honest to the point of absurd, with no regard for tact and emotions of other people whatsoever. It is important for them to learn how to express their opinions in a tolerant and socially acceptable way.",
        ['jupiter']
    ),
    makeDefaultData(
        'capricorn',    
        "Belonging to the element of Earth , like Taurus and Virgo, this is the last sign in the trio of practicality and grounding. Not only do they focus on the material world, but they have the ability to use the most out of it. Unfortunately, this element also makes them stiff and sometimes too stubborn to move from one perspective or point in a relationship. They have a hard time accepting differences of other people that are too far from their character, and out of fear might try to impose their traditional values aggressively.",
        ['saturn']
    ),
    makeDefaultData(
        'aquarius',     
        "The sign of Aquarius belongs to the element of Air, finishing the story of Gemini and Libra and connecting them in a way through opposition of freedom and relationships. They will rely on their mind and spoken words most of the time, and without mental stimulation might lose interest even in the things that made their heart jump at first. They see the world as a place full of possibilities and have the need to be spontaneous, follow the moment, and live it to the fullest in an attempt not to waste any time.",
        ['uranus','saturn']
    ),
    makeDefaultData(
        'pisces',       
        "The sign of Pisces is a Water sign, ending the cycle of Cancer and Scorpio, being the one to disperse everything that happened in the past and change anyone’s relative view on forgiveness. They are characterized by empathy and incredible emotional capacity, but only if they keep their boundaries strong and don’t let outer emotions overwhelm them.",
        ['jupiter','neptune']
    ),   
]

const BASE_API_URL = '/api/astrology/sign';

// Sign Provider
const SignProvider = ({children}) => {
    const {
        axios, 
        socketUsers : socket
    }                                               = useNetwork();
    const {cancelToken }                            = useCancelToken();

    const [enabled, setEnabled]                     = React.useState(false);
    const [data,    setData]                        = React.useState([]);
    const [working, setWorking]                     = React.useState(false);
    const [queried, setQueried]                     = React.useState(undefined);
   
    // Error/Success Messages
    const   [messageSuccess,    setMessageSuccess]  = useStateEphemeral(undefined),
            [messageError,      setMessageError]    = useStateEphemeral(undefined,5000);

    // Clear Records
    const clear = React.useCallback(() => setData([]),[]);

    // GET
    const get = React.useCallback(() => new Promise((resolve,reject) => {
        setWorking(true);
        axios.get(BASE_API_URL, {cancelToken})
            .then(({data}) => {
                setMessageSuccess("Signs queried")
                return data
            })
            .then(resolve)
            .catch(err => {
                setMessageError(err.message);
                reject(err)
            })
            .finally(() => {
                setWorking(false);
            })
    }),[axios, cancelToken, setMessageError, setMessageSuccess]);

    const refresh = React.useCallback( (reset) => {
        setWorking(true);
        if(reset) 
            clear();
        get()
            .then(setData)
            .catch(clear)
            .finally(() => {
                setWorking(false);
                setQueried(moment());
            })
    },[clear, get])

    const create = React.useCallback( ({name, description, rulers=[]}, query=true) => new Promise((resolve,reject) => {
        setWorking(true);
        axios.post(BASE_API_URL, {name, description, rulers}, {cancelToken})
            .then(() => {
                setMessageSuccess('Created sign');
                resolve({})
            })
            .catch(err => {
                setMessageError(err?.message);
                reject(err);
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }),[axios, cancelToken, refresh, setMessageError, setMessageSuccess])

    const deleteById = React.useCallback( (id,query=true) => new Promise((resolve,reject) => {            
        setWorking(true);
        axios.delete(`${BASE_API_URL}/${id}`, {cancelToken})
            .then(data => {
                setMessageSuccess(`Deleted sign ${id}`);
                resolve({})
            })
            .catch(err =>{
                setMessageError(err?.message);
                reject(err);
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }),[axios, cancelToken, refresh, setMessageError, setMessageSuccess])

    const postById = React.useCallback( ({_id, name, description, rulers=[]}, query=true) => new Promise((resolve,reject) => {
        setWorking(true);
        axios.post(`${BASE_API_URL}/${_id}`,{name, description, rulers}, {cancelToken})
            .then(() => {
                setMessageSuccess(`Updated sign ${_id}`);
                resolve({})
            })
            .catch(err =>{
                setMessageError(err?.message);
                reject(err)
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }),[axios, cancelToken, refresh, setMessageError, setMessageSuccess])

    // Refresh on authentication
    React.useEffect(() => {
        if(enabled)
            refresh();
    },[enabled, refresh])

    // Refresh on SocketIO Instruction
    React.useEffect(() => {
        if(enabled && socket){
            socket.on('refresh_signs', refresh)
            return () => {
                socket.off('refresh_signs', refresh);
            }
        }
    },[enabled, refresh, socket]);

    const enable = React.useCallback( () => setEnabled(true) , []);

    // Context values
    const value = {
        data,
        setEnabled,
        enable,
        working,
        queried,
        refresh : debounce(refresh),
        create,
        postById,
        deleteById,
        messageSuccess,
        setMessageSuccess,
        messageError,
        setMessageError,
        DEFAULT_DATA,
        BASE_API_URL
    };

    return (
        <SignContext.Provider value={value}>
            {children}
        </SignContext.Provider>
    )
}

// Sign Consumer
const SignConsumer = ({children}) => {
    return (
        <SignContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('SignConsumer must be used within SignProvider');
                }
                return children(context)
            }}
        </SignContext.Consumer>
    )
}

// useSign Hook
const useSign = () => {
    const context = React.useContext(SignContext);
    if(context === undefined)
        throw new Error('useSign must be used within SignProvider');
    return context;
}

export {
    SignProvider,
    SignConsumer,
    useSign
}