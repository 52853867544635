/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Library Entries Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       16th October 2021

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import {
    styled,
    alpha,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow    as TableRowMUI,
    TableCell   as TableCellMUI,
    Typography,
    Checkbox,
}                                       from '@mui/material';
import {
    Visibility  as PublicIcon,
    CheckCircle as CheckCircleIcon,
}                                       from '@mui/icons-material';
import {
    LoadingData,
    GoogleMapsStatic,
    SkeletonFancyNoLibrariesSelected,
    RootContainer,
    ColorChip as ColorChipBase
}                                       from 'components';
import { 
    useLibrary,
    useLibraryViewer,
    useLocale,
    useTranslation
}                                       from 'contexts';
import { withClickHandler }             from 'hooks';
import LibraryAvatar                    from './LibraryAvatar';

// Noop function
const noop = () => {}

const TableCell = styled(TableCellMUI)(({theme}) => ({
    fontSize    : '0.6rem',
    padding     : theme.spacing(0.5)
}))

const ColorChip = (props) => <ColorChipBase {...props} sx={{fontSize : '0.6rem', p:0, mt : 0.5,...props?.sx}} />

const TableRow = withClickHandler(TableRowMUI);

const TableRowMod = styled(TableRow, {
    shouldForwardProp : (prop) => !['isselected','ispublic','ispublicSelected','isunavailable'].includes(prop)
})(({theme, isselected = false, ispublic = false, ispublicSelected = false, isunavailable = false}) => {
    let colors = {}
    if(isunavailable){
        colors = { 
            background : [alpha(theme.palette.action.active,0.10),'!important'],
            '&:hover' : {
                background : [alpha(theme.palette.action.active,0.25),'!important'],
            },
        }
    }
    else if(ispublicSelected || isselected){
        colors = {
            background : alpha(theme.palette.secondary.light,0.25),
            '&:hover' : {
                background : [alpha(theme.palette.secondary.light,0.35),'!important'],
            },
        }
    }
    else if(ispublic){
        colors = {
            background : alpha(theme.palette.primary.light,0.15),
            '&:hover' : {
                background : [alpha(theme.palette.primary.light,0.25),'!important'],
            }
        }
    }
    return {
        ...colors,
        scrollPaddingTop    : '100px', 
        cursor              : 'pointer'
    }
});

// Library Collection List
export const LibraryEntries = ({
    available               = true,
    viewPublic              = true,
    viewPrivate             = true,
    showLoading             = false,
    title                   = undefined,
    onChange                = noop
}) => {
    const {t}                                   = useTranslation();
    const {
        library : libraryComplete, 
        horoscopeData, 
        loading
    }                                           = useLibrary();
    const {libraryId,setLibraryId}              = useLibraryViewer();
    const {formatDateTime}                      = useLocale();
    const [checked, setChecked]                 = React.useState(undefined);
    const [library, setLibrary]                 = React.useState([]);   // Filter the Library

    // Set Library
    React.useEffect(() => {
        const filterFun = lib => {
            let a = Boolean(available) === Boolean(lib?.available);
            let b = !available;
            let c = viewPublic  && lib.public;
            let d = viewPrivate && !lib.public
            return a && [b,c,d].some(Boolean);
        }
        let lib = (libraryComplete || []).filter(filterFun);
        
        if(!available) // Pending Orders ort closest to release
            lib.sort((a,b) => moment(a.releaseAfter).isAfter(moment(b.releaseAfter)) ? 1 : -1);
        
        setLibrary(lib)
    },[available, viewPrivate, viewPublic, libraryComplete])
    
    // Update libraryId arg change
    React.useEffect(() => setChecked(libraryId), [libraryId])

    // Is there items in library
    const hasLibrary = React.useMemo(() => Boolean(library && library.length), [library]);

    // No library records, return null
    if(!hasLibrary) 
        return <SkeletonFancyNoLibrariesSelected showCircle={false}/>

    return (
        <RootContainer id='libraryEntries'>
            {title && 
                <Typography variant="body2">
                    {title} ({library.length} items)
                </Typography>
            }
            <Box id="tableContainer" sx = {{mx:-2}}>
                <TableContainer style={{width:'100%'}} >
                    <Table>
                        <TableHead>
                            <TableRowMod>
                                <TableCell/>
                                <TableCell align="center">
                                    { t('components.library.libraryEntries.table.photo') }
                                </TableCell>
                                <TableCell>
                                    { t('components.library.libraryEntries.table.parameters') }
                                </TableCell>
                                <TableCell align="center">
                                    { t('components.library.libraryEntries.table.dateTime') }
                                </TableCell>
                                {
                                    false &&
                                    <TableCell align="center" style={{width:125}}>
                                        { t('components.library.libraryEntries.table.created') }
                                    </TableCell>
                                }
                            </TableRowMod>
                        </TableHead>
                        <TableBody>
                            {
                                hasLibrary &&
                                library.map((data,ix)=>{
                                    let {_id:id} = data;
                                    let {natalRecord = undefined} = data?.metadata.userFieldsData;
                                    const handleSelect = () => {

                                        // Is this item already checked ? 
                                        const alreadyChecked = checked && checked === id;

                                        // Only set if not current checked item.
                                        // This stops unchecking an already checked item, causing UI to be filled with skeletons
                                        // if(!alreadyChecked){
                                            const selected = alreadyChecked ? undefined : id
                                            setLibraryId(selected)
                                            onChange(selected);
                                        // }

                                    };
                                    const selected = id && id === checked;
                                    const seekRef = x => {
                                        if(x && id === libraryId){
                                            const position = window.pageYOffset;
                                            x.scrollIntoView({block:'nearest'}); //, behavior: "smooth"});
                                            window.scrollTo(0, position);
                                        }
                                    }
                                    // let [lat,lng] = formatcoords(natalRecord.lat,natalRecord.lng).format({latLonSeparator: '|',decimalPlaces:3}).split('|')
                                    return (
                                        <TableRowMod 
                                            ref                 = { seekRef } 
                                            id                  = { id } 
                                            key                 = { ix } 
                                            onClick             = { handleSelect } 
                                            isselected          = { !data?.public && selected}
                                            ispublicSelected    = {  data?.public && selected}
                                            ispublic            = {  data?.available && data?.public && !selected}
                                            isunavailable       = { !data?.available && !selected}
                                        >
                                            <TableCell style={{width:'fit-content'}}>
                                                <Checkbox checked={selected} style={{fontSize:16}}/>
                                            </TableCell>
                                            <TableCell>
                                                <Box>
                                                    <LibraryAvatar src={data.photoGetSignedUrl}/>
                                                </Box>
                                                {   false && 
                                                    <div style={{width:60,height:60}}>
                                                        <GoogleMapsStatic lat={natalRecord.lat} lng={natalRecord.lng} zoom={12} width={60} height={60}/>
                                                    </div>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                    <div>
                                                        <div style={{display:'inline'}}>
                                                            <strong>
                                                                { natalRecord?.description}
                                                                { !natalRecord?.description && 
                                                                    <>
                                                                        {data?.available ? t('components.library.libraryEntries.libraryRecord') : t('components.library.libraryEntries.pending') } {available ? library.length - ix : (ix + 1)}
                                                                    </>
                                                                } 
                                                            </strong>
                                                        </div>
                                                        <div style={{display:'inline'}}>
                                                            {
                                                                data?.public && 
                                                                <PublicIcon ccolor="secondary" sx={{fontSize:10}}/>
                                                            } 
                                                            {
                                                                horoscopeData[data._id] &&
                                                                <CheckCircleIcon color="secondary" sx={{fontSize:10}}/>
                                                            } 
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {natalRecord.address}
                                                    </div>
                                            </TableCell>
                                            <TableCell style={{minWidth:100}} align="center">
                                                <div>
                                                    {moment.utc(natalRecord.birthDateTime).format('ll')}
                                                </div>
                                                <div>
                                                    {
                                                        !natalRecord.unknownTime && 
                                                        moment.utc(natalRecord.birthDateTime).format('LT')
                                                    }
                                                    {   
                                                        natalRecord.unknownTime && 
                                                        <ColorChip label={ t('components.library.libraryEntries.timeUnknown') } size="small" color="secondary"/>
                                                    }
                                                </div>
                                            </TableCell>
                                            {
                                                false &&
                                                <TableCell align="center" sx={{pr:1}}>
                                                    {formatDateTime(moment(data.createdAt))}
                                                </TableCell>
                                            }
                                        </TableRowMod>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {   
                !hasLibrary && !loading &&
                <Box align="center">
                    <Typography>
                        { t('components.library.libraryEntries.noDeliveries') }
                    </Typography>
                </Box>
            }
            {
                showLoading && loading && 
                <Box align="center">
                    <LoadingData/>
                </Box>
            }
        </RootContainer>
    )
}

export default LibraryEntries;