/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
NatalDataForm
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       5th June 2021

*******************************************************************************************/

import React                                from 'react';
import {clone /*,isEmpty*/ }                from 'lodash';
import moment                               from 'moment';
import { 
    styled, useTheme, Box,
    Grid, Typography, Tooltip, Avatar as AvatarMUI
}                                           from '@mui/material';
import HelpOutlineIcon                      from '@mui/icons-material/HelpOutline';
import BackupIcon                           from '@mui/icons-material/Backup';
import MapIcon                              from '@mui/icons-material/Map';
import { 
    Select, 
    TextField,
    Checkboxes,
    showErrorOnChange
}                                           from 'mui-rff';
import {
    FormSpy
}                                       from 'react-final-form';
import { 
    Form,
    GoogleMapsStaticWithGeolocation as GoogleMapsStatic,
    DraggableDialog,
    Button,
    IconButton,
    RemoveWithConfirmationButton,
    JSONViewer,
    Overlay
}                                           from 'components';
import {
    FabLookupMap 
}                                           from 'components/fabs';
import useSize                              from 'hooks/useSize';
import useBreakpointQueries                 from 'hooks/useBreakpointQueries';
import {
    GeoLocate,
    FileUploader
}                                           from 'components/modals';
import { useTranslation, useUser}           from 'contexts'; 
import { DateTimeField }                    from 'components/forms/fields';
import {
    RODDEN_CLASSIFICATION_DATA,
    RoddenRatingDialogButton
}                                           from 'components/RoddenRatingDialogButton';

const ContentContainer = styled(Box)(({theme}) => ({
    '& > * + *' : {
        marginTop : theme.spacing(2)
    }
}));

const Verbiage = styled(Typography)({
    textAlign   : 'justify'
})

const ChildContainer = styled(Box)(({theme}) => ({
    paddingTop : 0.5 * theme.spacing(2),
    [theme.breakpoints.down('md')] : {
        paddingTop : theme.spacing(0),
    }
}));

const PhotoMapContainer = styled(Box)(({theme}) => ({
    display : "flex",
    width   : "100%",
    [theme.breakpoints.down('md')] : {
        '& > * + *' : {
            marginLeft : theme.spacing(1)
        }
    }
}))

const SmallMapContainer = styled(Box)(({theme}) => ({
    position    : 'relative',
    minHeight   : `100px!important`,
    paddingTop : 0, //theme.spacing(2),
    [theme.breakpoints.down('sm')] : {
        height      : '100%',
        minHeight   : `100px!important`,
        overflow    : 'hidden',
    }
}))

const Avatar = styled(AvatarMUI)({
    width       : '100%',
    height      : '100%',
})

const noop          = () => {}
const noopsubmit    = (values) => {}

const About = ({
    tooltip                 = undefined, 
    size                    = "small", 
    color                   = 'default', 
    onClick : handleClick   = noop
}) => {
    const {t} = useTranslation();
    return (
        <Tooltip title={tooltip || t('common.about')}>
            <span>
                <IconButton onClick={handleClick} size={size}> 
                    <HelpOutlineIcon color={color}/> 
                </IconButton>
            </span>
        </Tooltip>
    )
}

const SubTitle = ({ children, disabled, ...props }) => {
    return (
        <Box pb={0} style={{width:'100%', position:'relative'}}>
            <Typography {...props}>
                {children}
            </Typography>
        </Box>
    )
}

const obj               = {};
const defaultFormData   = {
    // firstName       : undefined,
    // lastName        : undefined,
    lat             : undefined,
    lng             : undefined,
    gender          : undefined,
    birthDateTime   : undefined,
    unknownTime     : undefined,
    localTime       : true,
    roddenRating    : undefined,
    description     : undefined, 
    photo           : undefined,
    isUser          : false, 
};

// Natal Data Form 
export const NatalDataForm = ({
    disabled                    = false,
    formData                    = defaultFormData,
    usePhoto                    = true,
    useDescription              = true,
    useIsUser                   = true,
    useGender                   = true,
    useRoddenRating             = true,
    useMap                      = true,
    useAddress                  = true,
    onSubmit                    = noopsubmit,
    onCancel    : handleCancel  = noop,
    onReset     : handleReset   = noop,
    debug                       = false,
    forceGeolookup              = true,
    useGeolocateButton          = true,
    FormProps                   = obj
}) => {
    const {t}                                           = useTranslation();
    const theme                                         = useTheme();
    const mapRef                                        = React.useRef(null);
    const {width, height}                               = useSize(mapRef);
    const {isAuthenticated, isAdmin }                   = useUser();
    const  allowGMTDifference                           = React.useMemo(() => (
        isAuthenticated && isAdmin
    ), [isAuthenticated, isAdmin]);

    // Initial Values
    const [initialValues,       setInititialValues]     = React.useState({});

    // Date Time Picker
    const [geoLocateOpen,       setGeoLocateOpen]       = React.useState(false);
    const [openAbout,           setOpenAbout]           = React.useState(false);
    const [openPhotoUploader,   setOpenPhotoUploader]   = React.useState(false);

    const dataRoddenRating = React.useMemo(() => (
        RODDEN_CLASSIFICATION_DATA
            .map(({code,summary,unknownTime,unknownDate}) => ({
                value   : code,
                label   : `${code} - ${t(summary)}`,
                unknownTime, 
                unknownDate
            }))
            .filter(({unknownDate}) => !unknownDate) // Minimum Date is Required
    ), [t]);

    // Genders
    const dataGenders = React.useMemo(() => ([
        {value : 'male',         label : t('components.forms.natalDataForm.male') },
        {value : 'female',       label : t('components.forms.natalDataForm.female') },
        {value : 'other',        label : t('components.forms.natalDataForm.other') },
        {value : 'ratherNotSay', label : t('components.forms.natalDataForm.ratherNotSay') }
    ]), [t]);

    // Genders
    const dataIsUser = React.useMemo(() => ([
        {value : true,           label : t('components.forms.natalDataForm.isUserYes') },
        {value : false,          label : t('components.forms.natalDataForm.isUserNo') },
    ]), [t]);

    React.useEffect(() => {
        if(typeof formData === 'object'){
            let initialValues = {
                ...defaultFormData,
                ...formData, 
                birthDateTime   : moment.utc(formData.birthDateTime || moment().format('YYYY-MM-DD HH:mm')), 
            }

            setInititialValues(prev => ({
                ...prev,
                ...initialValues,
                unknownTime : initialValues?.unknownTime ? ['unknownTime']   : [],
                localTime   : initialValues?.localTime   ? ['localTime']     : [] 
            }))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ JSON.stringify(formData) ])

    // Validation
    const validate = React.useCallback( values => new Promise( resolve => {
        
        let errors = {}
        const required      = ['lat','lng','address', useGender ? 'gender' : undefined ,'birthDateTime', useIsUser ? 'isUser' : undefined, useRoddenRating ? 'roddenRating' : undefined].filter(Boolean)
        const isRequired    = name => required.includes(name);
        required.forEach(fieldName => {
            if(values[fieldName] === undefined)
                errors[fieldName] = errors[fieldName] || t('components.forms.natalDataForm.required');
        })
        if(isRequired('lat')){
            let [lower,upper] = [-90,90];
            if(isNaN(parseFloat(values.lat)))
                errors.lat = errors.lat || t('components.forms.natalDataForm.mustBeNumeric');
            if(parseFloat(values.lat) > upper || parseFloat(values.lat) < lower)
                errors.lat = errors.lat || t('components.forms.natalDataForm.mustBeInRange', { minimum : lower, maximum : upper });
        }
        if(isRequired('lng')){
            let [lower,upper] = [-180,180];
            if(isNaN(parseFloat(values.lng)))
                errors.lng = errors.lng || t('components.forms.natalDataForm.mustBeNumeric');
            if(parseFloat(values.lng) > upper || parseFloat(values.lng) < lower)
                errors.lng = errors.lng || t('components.forms.natalDataForm.mustBeInRange', { minimum : lower, maximum : upper });
        }
        if(isRequired('gender')){
            if(!dataGenders.map(item => item.value).includes(values.gender))
                errors.gender = errors.gender || t('components.forms.natalDataForm.mustBeOneOf', { values : dataGenders.map(item => item.label).filter(Boolean).join(', ') });
        }
        if(isRequired('roddenRating')){
            const unknownTime   = (values.unknownTime || []).includes('unknownTime') ? true : false;  
            const subset        = dataRoddenRating.filter(x => !x.unknownDate && x.unknownTime === unknownTime);
            if(!subset.map(item => item.value).includes(values.roddenRating))
                errors.roddenRating = errors.roddenRating || t('components.forms.natalDataForm.mustBeOneOf', { values : subset.map(item => item.label).filter(Boolean).join(', ') });
        }
        if(isRequired('birthDateTime')){
            let tmp = values.birthDateTime; 
            tmp = moment.isMoment(tmp) 
                ? tmp 
                : moment(tmp);
            if(!tmp._isValid)
                errors.birthDateTime = errors.birthDateTime || t('components.forms.natalDataForm.invalidDateTime');
        }
        if(isRequired('description')){
            let maxLength = 25;
            if((values.description || '').length > maxLength)
                errors.description = errors.description || t('components.forms.natalDataForm.lengthMax', {maxLength} );
        }
        resolve(errors);
    }), [useGender, useIsUser, useRoddenRating, t, dataGenders, dataRoddenRating]);

    // Submit Handler
    const handleSubmit = React.useCallback( (values) => {

        // Local Copy
        let result = clone(values);

        // Ensure Description is present
        result.description = result.description || '';

        // Unknown Time
        result.unknownTime = (result.unknownTime || []).includes('unknownTime') ? true : false;  
        if(result.unknownTime)
            result.birthDateTime = moment(result.birthDateTime).set({h: 12, m: 0, s:0, ms:0 }); // Approximate

        // Local Time
        result.localTime = (
            allowGMTDifference
                ? (
                    typeof values.localTime === 'boolean'
                        ? values.localTime  // Retain existing boolean value
                        : (values.localTime || []).includes('localTime') 
                )
                : true
        );

        // Remove optional fields
        if(!useDescription)     delete result.description;
        if(!useGender)          delete result.gender;
        if(!useIsUser)          delete result.isUser;
        if(!useRoddenRating)    delete result.roddenRating;
        if(!usePhoto)           delete result.photo;

        // Submit and set init values
        return Promise.resolve()
            .then(() => onSubmit(result))
            .then((errors) => {
                // if(isEmpty(errors))
                //    setInititialValues(result)
                return errors;
            });

    }, [allowGMTDifference, onSubmit, useDescription, useGender, useIsUser, usePhoto, useRoddenRating]);

    const handleGeoLocateClose      = React.useCallback( () => setGeoLocateOpen(false), []);
    const handleGeoLocateOpen       = React.useCallback( () => !disabled && setGeoLocateOpen(true), [disabled]);
    const handleOpenPhotoUploader   = React.useCallback( () => setOpenPhotoUploader(true), []);
    const handleClosePhotoUploader  = React.useCallback( () => setOpenPhotoUploader(false), []);

    const {mdUp,smUp}               = useBreakpointQueries()

    const PhotoSet                  = React.useCallback(({disabled, form, photo, ...props}) => (
        <Box flexShrink={1} pt={1}>
            <Box display="flex">
                <Box flexGrow={1} pr={2}>
                    <SubTitle>
                        {t('common.photo')}
                    </SubTitle>
                </Box>
                <Box style={{transform:'translatey(-5px)'}}>
                    { 
                        false && !disabled && mdUp && 
                        <Button startIcon={<BackupIcon/>} size="small" color="secondary" onClick={handleOpenPhotoUploader}> 
                            {t('common.upload')}
                        </Button>
                    }
                    { 
                        !disabled && photo &&
                        <RemoveWithConfirmationButton size="small" color="error" onClick={() => form.change('photo','')}> 
                            {t('common.clear')}
                        </RemoveWithConfirmationButton>
                    }
                </Box>
            </Box>
            <Box width="100%">
                <Box align="center" style={{width:'100%' ,maxWidth:200,margin:'auto',aspectRatio:'1.0'}}>
                    <Avatar 
                        src         = {photo} 
                        onClick     = {!disabled ? () => setOpenPhotoUploader(true) : undefined} 
                        style       = {!disabled ? {cursor:'pointer'} : {}}
                    />
                </Box>
            </Box>
        </Box>
    ),[handleOpenPhotoUploader, mdUp, t])

    return (
        <>
        {false && <JSONViewer src={initialValues} />}
        <Form
            debug                   = {debug}
            disabled                = {disabled}
            onSubmit                = {handleSubmit}
            onCancel                = {handleCancel}
            onReset                 = {handleReset}
            initialValues           = {initialValues}
            validate                = {validate}
            changeRequiredToSubmit  = {true}
            {...FormProps}
            render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values,...rest}) => {

                const handleDatePickerChange = (birthDateTime) => {
                    form.change('birthDateTime', birthDateTime);
                }
                const handleGeoLocateOk = (geo) => { 
                    Object.keys(geo).forEach(key => {
                        try{
                            let value = isNaN(geo[key]) ? geo[key] : parseFloat(geo[key]); 
                            form.change(key,value)
                        }catch(err){
                            //
                        }
                    }); 
                    handleGeoLocateClose(); 
                };
                const validKey = (keyCode) => (keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode === 8)
                const handleGeoKeyDown = (event) => {
                    if(disabled) return;
                    let {keyCode} = event
                    if (validKey(keyCode)){
                        event.preventDefault(); 
                        handleGeoLocateOpen();
                    }
                }
                const handleAboutClick = (event) => {
                    event.preventDefault();
                    setOpenAbout(true);
                }

                const unknownTime = (
                    typeof values.unknownTime === 'boolean' 
                        ? values.unknownTime 
                        : Boolean((values?.unknownTime || []).includes('unknownTime'))
                )

                const localTime = (
                    allowGMTDifference
                        ? (
                            typeof values.localTime === 'boolean' 
                                ? values.localTime 
                                : Boolean((values?.localTime || []).includes('localTime'))
                        )
                        : true
                )

                const birthLocationArgs = (geolookup = true) => ({
                    disabled    : disabled,
                    showError   : showErrorOnChange,
                    onClick     : geolookup ? handleGeoLocateOpen : null,
                    onKeyDown   : geolookup ? handleGeoKeyDown : null
                })

                const handlePhotoUploaderSubmit = (files, allFiles) => {
                    setOpenPhotoUploader(false);
                    let uploads = files.map(f => f?.meta?.fileUrl).filter(Boolean);
                    form.change('photo', uploads[0]);
                };

                const roddenRatings         = dataRoddenRating.filter(x => !x.unknownDate && x.unknownTime === unknownTime);
                const geolocated            = [values?.lat, values?.lng].map(value => typeof value === 'number' && !isNaN(value)).every(Boolean);

                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container>

                            {
                                false && 
                                <Grid item xs={12}>
                                    <JSONViewer src={values} />
                                </Grid>
                            }

                            {
                                (usePhoto || useMap) &&
                                <Grid item xs={12} sm={4} style={{display:'flex'}}>

                                    <Box display="flex" flexDirection="column" style={{position:'relative',width:'100%'}}>
                                        
                                        {
                                            usePhoto && smUp &&
                                            <PhotoSet disabled={disabled} photo={values.photo} form={form}/>
                                        }

                                        <PhotoMapContainer flexGrow={1}>
                                            
                                            {
                                                usePhoto && !smUp &&
                                                <Box width="40%">
                                                    <PhotoSet disabled={disabled} photo={values.photo} form={form}/>
                                                </Box>
                                            }

                                            {
                                                useMap &&
                                                <Box flexGrow={1}>
                                                    <Box display="flex" flexDirection="column" flexGrow={1} height="100%">
                                                        <Box>
                                                            <SubTitle>
                                                                {t('components.forms.natalDataForm.locationMap')}
                                                            </SubTitle>
                                                        </Box>
                                                        <SmallMapContainer 
                                                            ref         = {mapRef} 
                                                            flexGrow    = {1} 
                                                            onClick     = {!disabled ? handleGeoLocateOpen : undefined}
                                                            sx          = {{ cursor : disabled ? 'default' : 'pointer' }}
                                                        >
                                                            <Overlay sx={{ background : geolocated ? 'transparent' : theme.palette.action.disabledBackground }}>
                                                                {
                                                                    geolocated
                                                                        ? 
                                                                            (
                                                                                <GoogleMapsStatic 
                                                                                    lat     = {values.lat} 
                                                                                    lng     = {values.lng} 
                                                                                    width   = {width}
                                                                                    height  = {height}
                                                                                />
                                                                            )
                                                                        :   (
                                                                                <Box display="flex" flexDirection="column" height="100%">
                                                                                    <Box flexGrow={1}/>
                                                                                        <Typography align="center" variant="body2">
                                                                                            {t('components.forms.natalDataForm.geolocationRequired')}
                                                                                        </Typography>
                                                                                    <Box flexGrow={1}/>
                                                                                </Box>
                                                                        )
                                                                }
                                                            </Overlay>
                                                        </SmallMapContainer>
                                                    </Box>
                                                </Box>
                                            }
                                        </PhotoMapContainer>
                                    </Box>
                                </Grid>
                            }

                            <Grid component={ContentContainer} item xs={12} sm={usePhoto || useMap ? 8 : 12} spacing={4}>
                                
                                {
                                    useDescription &&
                                    <Grid component={ChildContainer} container item spacing={0} style={{paddingTop:theme.spacing(1)}}>
                                        <SubTitle disabled={disabled}>
                                            {t('components.forms.natalDataForm.summaryInformation')}
                                        </SubTitle>
                                        <Grid item xs={12}>
                                            <TextField 
                                                label           = {t('components.forms.natalDataForm.description')}
                                                name            = "description" 
                                                disabled        = {disabled} 
                                                showError       = {showErrorOnChange}
                                                helperText      = {t('components.forms.natalDataForm.descriptionHelperText')}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                                

                                <Grid component={ChildContainer} container item spacing={0}>
                                    <SubTitle disabled={disabled} sx={{mt:1}}> 
                                        {t('components.forms.natalDataForm.birthDateTime')}
                                    </SubTitle>
                                    <Grid item xs={12}>
                                        <Verbiage gutterBottom variant="body2">
                                            {t('components.forms.natalDataForm.birthDateTimeDescription')}
                                        </Verbiage>
                                        <div style={{width:'100%', marginTop:theme.spacing(-1)}}>
                                            <Checkboxes 
                                                name        = "unknownTime" 
                                                disabled    = {disabled}
                                                data        = {
                                                    [
                                                        {
                                                            label: (
                                                                <React.Fragment>
                                                                    <Typography component="div" /*color={unknownTime ? "error" : "textPrimary"}*/ variant="body2">
                                                                    
                                                                        {/*
                                                                            unknownTime 
                                                                                ? t('components.forms.natalDataForm.timeUnknown')
                                                                                : t('components.forms.natalDataForm.timeKnown')
                                                                        */
                                                                        } 
                                                                        {t('components.forms.natalDataForm.timeUnknown')}
                                                                        <About 
                                                                            tooltip = {t('components.forms.natalDataForm.aboutTimeOfBirth')}
                                                                            onClick = { handleAboutClick } 
                                                                            // color   = { unknownTime ? "error" : "inherit" } 
                                                                        />
                                                                    </Typography> 
                                                                </React.Fragment>
                                                            ), 
                                                            value : "unknownTime"
                                                        }
                                                    ]
                                                } 
                                            />
                                        </div>
                                    </Grid>

                                    {
                                        useRoddenRating && 
                                        <Grid item xs={12}>
                                            <Box display="flex">
                                                <Box flexGrow={1}>
                                                    <Select 
                                                        label           = {t('components.forms.natalDataForm.roddenRating')}
                                                        name            = "roddenRating" 
                                                        disabled        = { disabled } 
                                                        data            = { roddenRatings } 
                                                        showError       = { showErrorOnChange } 
                                                        inputLabelProps = {{ shrink : true }} 
                                                        helperText      = {t('components.forms.natalDataForm.roddenRatingHelperText')}
                                                    />
                                                </Box>
                                                <Box sx={{mt:2}}>
                                                    <RoddenRatingDialogButton Icon={HelpOutlineIcon}/>
                                                </Box>
                                            </Box>
                                            <FormSpy 
                                                subscription    = {{values: true}} 
                                                onChange        = {({values}) => { 
                                                    try {
                                                        const {unknownTime} = values;
                                                        if(Array.isArray(unknownTime)){
                                                            if(unknownTime.includes('unknownTime')){
                                                                form.change('roddenRating', dataRoddenRating.filter(d => d.unknownTime)[0]?.value || "")
                                                            }
                                                        }
                                                    } catch (err) {
                                                        console.error(err);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    }

                                    {
                                        allowGMTDifference && 
                                        <Grid item xs={12} sx={{textAlign:'right', display: 'flex', alignItems: 'center'}}>
                                            <Checkboxes 
                                                name        = "localTime" 
                                                disabled    = {disabled} 
                                                data        = {
                                                    [
                                                        {
                                                            label: (
                                                                <Typography component="div" variant="body2">
                                                                    {
                                                                        /*
                                                                        localTime 
                                                                            ? t('common.lmt')
                                                                            : t('common.gmt')
                                                                        */
                                                                    } 
                                                                    {
                                                                        true || localTime 
                                                                            ? t('common.lmtLong')
                                                                            : t('common.gmtLong')
                                                                    } 
                                                                </Typography> 
                                                            ), 
                                                            value : "localTime"
                                                        }
                                                    ]
                                                } 
                                            />
                                        </Grid>
                                    }

                                    <Grid item xs={12}>
                                        <DateTimeField
                                            name            = 'birthDateTime' 
                                            label           = {
                                                unknownTime 
                                                    ? t('components.forms.natalDataForm.dateOfBirth')
                                                    : t('components.forms.natalDataForm.dateAndTimeOfBirth')
                                            }
                                            disabled        = {disabled} 
                                            value           = {
                                                values['birthDateTime'] 
                                                    ? moment(values['birthDateTime']) 
                                                    : null
                                            }
                                            helperText      = {
                                                unknownTime 
                                                    ? t('components.forms.natalDataForm.provideDateOnly')
                                                    : (
                                                        localTime 
                                                            ? t('components.forms.natalDataForm.provideInLMT') 
                                                            : t('components.forms.natalDataForm.provideInGMT')
                                                    )
                                            }
                                            viewTime        = {!unknownTime}
                                            onChange        = {handleDatePickerChange}
                                            showError       = {showErrorOnChange} 
                                            usetimezone     = {false}
                                            disableFuture   = {false}
                                        />
                                    </Grid>

                                </Grid>

                                <Grid component={ChildContainer} item spacing={0}>
                                    <Box display="flex" sx={{width:"100%",mt:2}}>
                                        <Box flexGrow={1}>
                                            <SubTitle disabled={disabled} sx={{mt:1}}>
                                                {t('components.forms.natalDataForm.birthLocation')}
                                            </SubTitle>
                                        </Box>
                                        {
                                            useGeolocateButton &&
                                            <Box>
                                                <Button startIcon={<MapIcon/>} size="small" color="primary" variant="contained" {...birthLocationArgs(true)}>
                                                    {t('components.forms.natalDataForm.geolocate')}
                                                </Button>
                                            </Box>
                                        }
                                    </Box>
                                    <Box display="flex">
                                        <Box flexGrow={1}>
                                            <Grid container spacing={0}>
                                                {
                                                    useAddress &&
                                                    <Grid item xs={12}>
                                                        <TextField 
                                                            label       = {t('components.forms.natalDataForm.address')}
                                                            name        = "address"
                                                            {...birthLocationArgs(forceGeolookup)} 
                                                            inputProps  = {{ readOnly : forceGeolookup }}
                                                            helperText  = {t('components.forms.natalDataForm.addressHelperText')}
                                                        />
                                                    </Grid>
                                                }
                                                <Grid item container xs={12} spacing={2}>
                                                    <Grid item xs={6}>
                                                        <TextField 
                                                            label   = {t('components.forms.natalDataForm.latitude')}
                                                            name    = "lat" 
                                                            type    = 'number' 
                                                            {...birthLocationArgs(forceGeolookup)}
                                                            onChange = {(e) => {
                                                                handleGeoLocateOk({
                                                                    lng : values.lng,
                                                                    lat : e.target.value
                                                                })
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField 
                                                            label   = {t('components.forms.natalDataForm.longitude')}
                                                            name    = "lng" 
                                                            type    = 'number' 
                                                            {...birthLocationArgs(forceGeolookup)}
                                                            onChange = {(e) => {
                                                                handleGeoLocateOk({
                                                                    lat : values.lat,
                                                                    lng : e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        {
                                            false && 
                                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <FabLookupMap  size="large" color="primary" {...birthLocationArgs(true)}/>
                                            </Box>
                                        }
                                    </Box>
                                </Grid>
                                
                                    
                                {
                                    (useGender || useIsUser) && 
                                    <Grid component={ChildContainer} container item spacing={0}>
                                        <Grid item container xs={12} spacing={2}>
                                            <Grid item xs={12} sx={{mb:-2}}>
                                                <SubTitle disabled={disabled} sx={{mt:1}}> 
                                                    {t('components.forms.natalDataForm.otherInformation')}
                                                </SubTitle>
                                            </Grid>

                                            {
                                                useGender &&
                                                <Grid item xs={useIsUser ? 6 : 12} >
                                                    <Select label={t('components.forms.natalDataForm.gender')} name ="gender" disabled={disabled} data={dataGenders} inputLabelProps = {{ shrink : true }} />
                                                </Grid>
                                            }
                                            {
                                                useIsUser &&
                                                <Grid item xs={useGender ? 6 : 12} >
                                                    <Select 
                                                        label           = {t('components.forms.natalDataForm.isThisYou')}
                                                        name            = "isUser" 
                                                        disabled        = {disabled} 
                                                        data            = {dataIsUser} 
                                                        showError       = {showErrorOnChange} 
                                                        // helperText      = "Is this YOUR personal natal data?, ie is this you, or someone else?"
                                                        inputLabelProps = {{ shrink : true }} 
                                                    />
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                        <GeoLocate 
                            open        = {geoLocateOpen} 
                            onCancel    = {handleGeoLocateClose}
                            onOk        = {handleGeoLocateOk} 
                            lat         = {values.lat}
                            lng         = {values.lng}
                            address     = {values.address}
                        />

                        <DraggableDialog 
                            maxWidth            = "sm" 
                            open                = {openAbout} 
                            onClose             = {() => setOpenAbout(false)}
                            onOk                = {() => setOpenAbout(false)}
                            showButtonsCancel   = {false}
                            title               = {t('components.forms.natalDataForm.aboutBirthTime')}
                        >
                            <Verbiage>
                                {t('components.forms.natalDataForm.aboutBirthTimeDescription')}
                            </Verbiage>
                        </DraggableDialog>

                        <FileUploader 
                            title       = {t('components.forms.natalDataForm.uploadPhoto')}
                            open        = {openPhotoUploader} 
                            multiple    = {false}
                            onClose     = {handleClosePhotoUploader} 
                            onSubmit    = {handlePhotoUploaderSubmit}
                        />
                    </form>
                );
            }}
        />
        </>
    );
}

export default NatalDataForm;

