/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       17th January 2023

*******************************************************************************************/
import React                        from 'react';
import { useInView }                from 'react-intersection-observer';
import { Box, Grid }                from '@mui/material';
import AnalyticsIcon                from '@mui/icons-material/Analytics';
import PersonIcon                   from '@mui/icons-material/Person';
import CollectionsIcon              from '@mui/icons-material/Collections';
import PeopleIcon                   from '@mui/icons-material/People';
import CommentIcon                  from '@mui/icons-material/Comment';
import AdminPanelSettingsIcon       from '@mui/icons-material/AdminPanelSettings';
import { 
    PageContainer,
    LastQueried,
    ContainerWithFooter,
    ItemPaper,
    TabProvider,
    Tabs,
    TabPanel,
    VerticalSpaceContainer,
    VerticalGrowBox,
    JSONViewer,
    AdminBox
}                                   from 'components';
import { withTranslation }          from 'components/hoc';
import SkeletonTabs                 from 'components/skeletons/SkeletonTabs';
import {
    useNetwork,
    useUser
}                                   from 'contexts';
import {
    useSize,
    useBreakpointQueries
}                                   from 'hooks';     
import ExampleGraphics              from './ExampleGraphics';
import ExampleTitle                 from './ExampleTitle';
import ExampleBiographyTitle        from './ExampleBiographyTitle';
import ExampleBiographyContent      from './ExampleBiographyContent';
import ExampleBiographySkeleton     from './ExampleBiographySkeleton';
import ExampleDescriptor            from './ExampleDescriptor';
import ExamplePhotoAttribution      from './ExamplePhotoAttribution';
import ExampleRoddenRating          from './ExampleRoddenRating';
import ExampleChipsSocials          from './ExampleChipsSocials';
import ExampleSEO                   from './ExampleSEO';
import ExampleComments              from './ExampleComments';
import {
    ExampleAdminRawData, 
    ExampleAdminHoroscopeData 
}                                   from './ExampleAdminData';
import ExamplePhoto                 from './ExamplePhoto';
import ExamplePhotoContainerSticky  from './ExamplePhotoContainerSticky';
import ExamplePhotoContainer        from './ExamplePhotoContainer';
import ExamplePlaylist              from './ExamplePlaylist';
import ExampleContentful            from './ExampleConteintful';
import { 
    ExampleProvider, 
    useExample
}                                   from './ExampleContext';
import ExampleSabianSymbolSampler   from './ExampleSabianSymbolSampler';
import ExampleNakshatraSampler      from './ExampleNakshatraSampler';
import ExampleArchiveCrossSell      from './ExampleArchiveCrossSell';
import ExampleAdminInactiveNotice   from './ExampleAdminInactiveNotice';
import ExampleViewedTimes           from './ExampleViewedTimes';

const obj = {};

const TAB_COMMENTS      = false;
const TAB_ICON_POSITION = "start";

const ExampleBase = withTranslation( ({t, page = true, pageContainerProps = obj}) => {
    const {
        debug,
        status,
        working,
        queried,
        data,
        dataHoroscope
    }                                                   = useExample();
    const {isNetworkReady}                              = useNetwork();
    const { isAdmin }                                   = useUser();
    // Breakpoints
    const {smDown, mdUp}                                = useBreakpointQueries();
    const verticalGrowBoxRef                            = React.useRef(null);

    // Refs
    const radixRef1                                     = React.useRef();
    const radixRef2                                     = React.useRef();
    const {width:radixWidth1}                           = useSize(radixRef1);
    const {width:radixWidth2}                           = useSize(radixRef2);
    const {ref : isInViewRef, inView}                   = useInView({threshold:0});   

    const dataTabs                                      = React.useMemo(() => ([
        {
            label           : "Analysis",
            value           : 'analysis',
            icon            : <AnalyticsIcon />,
            iconPosition    : TAB_ICON_POSITION
        },
        {
            label           : "About",
            value           : 'about',
            icon            : <PersonIcon />,
            iconPosition    : TAB_ICON_POSITION
        },
        {
            label           : "Sabian",
            value           : "sabian",
            icon            : <CollectionsIcon />,
            iconPosition    : TAB_ICON_POSITION
        },
        {
            label           : "Nakshatra",
            value           : 'nakshatra',
            icon            : <CollectionsIcon />,
            iconPosition    : TAB_ICON_POSITION
        },
        TAB_COMMENTS 
            ? {
                label           : "Comments",
                value           : "comments",
                icon            : <CommentIcon/>,
                iconPosition    : TAB_ICON_POSITION
            }
            : undefined,
        {
            label           : "Related",
            value           : "related",
            icon            : <PeopleIcon />,
            iconPosition    : TAB_ICON_POSITION

        },
        {
            label           : "Admin",
            value           : "admin",
            hidden          : !(isAdmin && debug),
            icon            : <AdminPanelSettingsIcon />,
            iconPosition    : TAB_ICON_POSITION
        }
    ].filter(Boolean)),[isAdmin,debug]);

    const resetVerticalGrowBoxMinHeight = React.useCallback(() => {
        // console.log("Reset Vertical Grow Height")
        // console.log(verticalGrowBoxRef.current);
        if(verticalGrowBoxRef.current)
            verticalGrowBoxRef.current.resetMinHeight();
    },[])

    const playListProps = React.useMemo(() => ({
        onPlayIndexChange   : resetVerticalGrowBoxMinHeight,
        onPlayChange        : resetVerticalGrowBoxMinHeight,
        onChapterEntered    : resetVerticalGrowBoxMinHeight
    }),[resetVerticalGrowBoxMinHeight]);

    return ( 
        <PageContainer id="pageContainer" notFound = {status && status >= 400} loading={working} breadcrumbs={Boolean(page)} scrollToTop={Boolean(page)} {...pageContainerProps}>
            { 
                page && 
                <ExampleSEO /> 
            }
            <ContainerWithFooter 
                renderFooter = {
                    <LastQueried quantity={queried && data ? 1 : 0} timestamp={queried}/>
                } 
            >
                <ItemPaper sx={{minHeight:'100%'}}>
                    <ExampleAdminInactiveNotice sx={{mb:1}}/>
                    <Grid container>
                        {
                            !smDown &&
                            <Grid component={VerticalSpaceContainer} item xs={12} sm={4} md={3}>
                                <ExamplePhotoContainerSticky showTitle = {!inView}>
                                    <ExamplePhoto />
                                </ExamplePhotoContainerSticky>
                            </Grid>
                        }
                        <Grid component={VerticalSpaceContainer} item xs={12} sm={8} md={5} lg={6}>
                            <Box id="isInViewRef" ref={isInViewRef} sx={{m:0,height:0}}/>
                            {
                                isNetworkReady && queried &&
                                <>
                                    <ExampleTitle />
                                    <ExampleChipsSocials />
                                    <ExampleViewedTimes />
                                    <VerticalGrowBox ref={verticalGrowBoxRef}>
                                        <VerticalSpaceContainer>
                                            <TabProvider 
                                                syncWithQuery   = {true}
                                                data            = {dataTabs}
                                                onChange        = {resetVerticalGrowBoxMinHeight}
                                            >
                                                <Tabs />
                                                <TabPanel index={'analysis'}>
                                                    <ExampleDescriptor />
                                                    <ExampleRoddenRating />
                                                    <ExampleGraphics visible = {!mdUp} sticky = {false} width = {radixWidth2} />
                                                    <ExamplePlaylist playListProps = {playListProps} />
                                                </TabPanel>
                                                <TabPanel index={'about'}>
                                                    <ExamplePhotoContainer visible={smDown}>
                                                        <ExamplePhoto style = {{maxWidth:150}} />
                                                    </ExamplePhotoContainer>
                                                    <ExampleBiographyTitle />
                                                    <ExampleBiographyContent />
                                                    <ExampleContentful />
                                                    <ExamplePhotoAttribution visible={smDown} compact={false} />
                                                </TabPanel>
                                                <TabPanel index={'sabian'}>
                                                    <ExampleSabianSymbolSampler />
                                                </TabPanel>
                                                <TabPanel index={'nakshatra'}>
                                                    <ExampleNakshatraSampler />
                                                </TabPanel>
                                                <TabPanel index={'related'}>
                                                    <ExampleArchiveCrossSell />
                                                </TabPanel>
                                                {
                                                    TAB_COMMENTS &&
                                                    <TabPanel index={'comments'}>
                                                        <ExampleComments />
                                                    </TabPanel>
                                                }
                                                <TabPanel index={'admin'} hidden={!(isAdmin && debug)}>
                                                    <VerticalSpaceContainer>
                                                        <ExampleAdminRawData data = {data} />
                                                        <ExampleAdminHoroscopeData data = {dataHoroscope} />
                                                    </VerticalSpaceContainer>
                                                </TabPanel>
                                            </TabProvider>
                                            {
                                                !TAB_COMMENTS &&
                                                <ExampleComments />
                                            }
                                        </VerticalSpaceContainer>
                                    </VerticalGrowBox>
                                </>
                            }
                            {
                                (!isNetworkReady || !queried) &&
                                <VerticalSpaceContainer spacing={1}>
                                    <SkeletonTabs quantity={3} sx={{mb:2}}/>
                                    <ExampleBiographySkeleton />
                                </VerticalSpaceContainer>
                            }
                            <Box id="radixRef2" ref={radixRef2} sx={{m:0,height:0}}/>
                        </Grid>

                        <Grid component={VerticalSpaceContainer} item xs={12} md={4} lg={3}>
                            <ExampleGraphics visible = {mdUp} sticky = {true} width = {radixWidth1} />
                            <Box ref={radixRef1} />
                        </Grid>
                    </Grid>
                </ItemPaper>

                {
                    false && isAdmin && 
                    <AdminBox sx = {{ mt : 2 }}>
                        <JSONViewer src={data} />
                    </AdminBox>
                }
            </ContainerWithFooter>
        </PageContainer>
    );
});

export const Example = ({
    slug, 
    page                = true, 
    pageContainerProps  = obj, 
    force               = false, 
    adminMode           = false
}) => (
    <ExampleProvider slug={slug} force={force} adminMode={adminMode}>
        <ExampleBase page = {page} pageContainerProps = {pageContainerProps} />
    </ExampleProvider>
)

export default Example;