/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
ConfirmationIconButton
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       31st December 2024

*******************************************************************************************/
import React                        from 'react';
import { IconButton }               from 'components';
import { Confirmation }             from 'components/modals';

const noop = () => {}
const obj = {};

export const ConfirmationIconButton = React.forwardRef(({children, onClick : handleClickIn = noop, confirmationProps = obj,  ...props}, ref) => {
    const [open, setOpen]   = React.useState(false);
    const [event, setEvent] = React.useState(null);

    const handleClick = React.useCallback( (e) => {
        setEvent(e);
        setOpen(true)
    },[]);

    const handleCancel = React.useCallback( () => {
        setOpen(false)
    },[]);

    const handleOk = React.useCallback( () => {
        handleClickIn(event)
        setOpen(false)
    },[event, handleClickIn]);

    React.useEffect(() => {
        if(!open)
            setEvent(null);
    },[open])

    return (
        <React.Fragment>
            <IconButton ref={ref} {...props} onClick = {handleClick} >
                {children}
            </IconButton>
            <Confirmation
                {...confirmationProps}
                open        = { open }
                onOk        = { handleOk }
                onCancel    = { handleCancel }
            />
        </React.Fragment>
    )
});

export default ConfirmationIconButton;