/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Helmet Tag Organiser
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st December 2024

*******************************************************************************************/

import React            from 'react';
import { useLocation}   from "react-router-dom";
import debounce         from 'lodash/debounce';

const OBSERVER_ARGS = {
    childList: true,   // Watch for additions/removals of child nodes
    subtree: true,     // Watch all descendants of document.head
}

export const HelmetTagOrganizer = ({cache = undefined} = {}) => {

    const counter               = React.useRef(0);
    const location              = useLocation(); // Get the current location (URL)

    const observerRef           = React.useRef(null);
    const isReorderingRef       = React.useRef(false);

    const reorderTags           = React.useCallback(() => {

        if(counter.current >= 3)
            return;

        const head = document.querySelector('head');
            
        // Select all Helmet-managed tags or Open Graph (og:*) tags
        const relevantTags = head.querySelectorAll(
            '[data-react-helmet="true"]:not(style), meta[property^="og:"]'
        );

        // Move each selected tag to the top of the <head>
        relevantTags.forEach(tag => {
            head.prepend(tag); // Moves the tag to the very top
        });

        const mainSelectors = [
            'base',                             // <base> if present
            'title',                            // <title> tag, to be moved last
            'meta[name="description"]',         // Description meta
            'link',                             // <link> tags like favicons
            'meta[name="robots"]',              // Robots
        ];

        const ogSelectors = [
            'meta[property="og:updated_time"]', // Open Graph upodate time
            'meta[property="og:title"]',        // Open Graph title
            'meta[property="og:url"]',          // Open Graph url
            'meta[property="og:type"]',         // Open Graph type
            'meta[property="og:locale"]',       // Open Graph locale
            'meta[property="og:site_name"]',    // Open Graph site name
            'meta[property="og:description"]',  // Open Graph description
            'meta[property="og:image"]',        // Open Graph image
            'meta[property="og:image:url"]',    // Open Graph image url
            'meta[property="og:image:type"]',   // Open Graph image type
            'meta[property="og:image:height"]', // Open Graph image height
            'meta[property="og:image:width"]',  // Open Graph image width
        ];

        const twitterSelectors = [
            'meta[name="twitter:card"]',        // Twitter card type
            'meta[name="twitter:site"]',        // Twitter site username
            'meta[name="twitter:title"]',       // Twitter title
            'meta[name="twitter:description"]', // Twitter description
            'meta[name="twitter:image"]',       // Twitter image
            'meta[name="twitter:url"]',         // Twitter url
        ];

        // Define the priority order for important tags: title, base, meta, link
        const importantSelectors = [
            ...mainSelectors,                   // General Tags
            ...ogSelectors,                     // Open Graph Tags
            ...twitterSelectors                 // Twitter Selectors
        ];

        // Reverse the order for processing, so the last item (title) gets moved first
        importantSelectors.reverse().forEach(selector => {
            const tags = head.querySelectorAll(selector);
            tags.forEach(tag => {
                head.prepend(tag); // Move each tag to the top
            });
        });

        counter.current = counter.current + 1;

    },[])

    const disconnectAndReorderTags  = React.useCallback( () => {

        if (isReorderingRef.current) 
            return; // Prevent reordering if it's already in progress

        let disconnected = false;

        try {
            
            isReorderingRef.current = true;

            // Temporarily disconnect the observer during reordering
            if (observerRef.current) {
                observerRef.current.disconnect();
                disconnected = true;
            }

            reorderTags();

        } catch (err) {

            console.error(err);
               
        } finally {

            // After reordering, reconnect the observer
            if (observerRef.current && disconnected) {
                observerRef.current.observe(document.head,OBSERVER_ARGS);
            }

            // Reset the flag after the reordering is done
            setTimeout(() => {
                isReorderingRef.current = false;
            }, 0);
        }

    }, [reorderTags]);

    React.useEffect(() => {

        // No Cache Provided, Short Circuid
        if(!cache)
            return;

        // Ensure disconnectAndReorderTags is called after styles are rendered
        const onRender = () => {
            disconnectAndReorderTags();
        };

        // Hook into Emotion's cache onRender functionality
        cache.onRender = onRender;

        return () => {
            // Cleanup when the component unmounts or the cache changes
            delete cache.onRender;
        };

    }, [cache, disconnectAndReorderTags]);

    React.useLayoutEffect(() => {
        // Cache Provide3d, Short Circuid
        if(!cache)
            return;
    
        // Timout member
        let timeout;

        // Wait for the window to load fully
        const handleWindowLoad = () => {
            clearTimeout(timeout);
            timeout = setTimeout(disconnectAndReorderTags, 0);
        };
    
        if (document.readyState === 'loading') {
            // If the document is still loading, listen for the window load event
            window.addEventListener('load', handleWindowLoad);
        } else {
            // Already loaded; directly call the logic
            handleWindowLoad();
        }
    
        return () => {
            clearTimeout(timeout);
            window.removeEventListener('load', handleWindowLoad);
        };

    }, [cache, disconnectAndReorderTags]);

    // Listen for URL changes to reorder tags after each change
    React.useLayoutEffect(() => {

        if(counter >= 3)
            return;

        // Create a debounced version of disconnectAndReorderTags
        const debouncedReorderTags = debounce(disconnectAndReorderTags, 500); // Adjust debounce delay (in ms)

        // MutationObserver to watch for DOM changes
        observerRef.current = new MutationObserver(debouncedReorderTags);

        // Observe changes in the DOM subtree, including added or removed elements
        observerRef.current.observe(document.head,OBSERVER_ARGS);

        // Also handle location changes
        const handleLocationChange = () => {
            debouncedReorderTags();
        };

        // Listen to changes in location
        handleLocationChange();

        // Cleanup
        return () => {
            observerRef.current.disconnect(); // Disconnect the observer on cleanup
            debouncedReorderTags.cancel(); // Cancel any pending debounced calls
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.pathname, location?.search, counter]); // Watch pathname and search params for changes

    // Reset counter on page/search change
    React.useEffect(() => {
        counter.current = 0;
    },[location?.pathname, location?.search])

    return null; // No UI to render
};

export default HelmetTagOrganizer;