/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Locations
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd December 2020

*******************************************************************************************/
import Location from 'react-app-location';
import * as Yup from 'yup';

const RootLocation                                              = new Location(''); // Root

export const HomeLocation                                       = new Location(`${RootLocation.path}/`);
export const ApiLocation                                        = new Location(`${RootLocation.path}/api`);
export const HealthLocation                                     = new Location(`${RootLocation.path}/health`);
export const AboutLocation                                      = new Location(`${RootLocation.path}/about`);
export const FoundersLocation                                   = new Location(`${RootLocation.path}/founders`);
export const FAQLocation                                        = new Location(`${RootLocation.path}/faq`);
export const RadixLocation                                      = new Location(`${RootLocation.path}/radix`);

export const PreviewLocation                                    = new Location(`${RootLocation.path}/preview`);
export const PreviewReadingLocation                             = new Location(`${PreviewLocation.path}/reading`);
export const PreviewSignLocation                                = new Location(`${PreviewLocation.path}/sign`);
export const PreviewSignCelestialBodyLocation                   = new Location(`${PreviewSignLocation.path}/:name`, {name : Yup.string().oneOf(['sun','moon','venus']).required() });

export const ShopLocation                                       = new Location(`${RootLocation.path}/shop`);
export const ProductLocation                                    = new Location(`${ShopLocation.path}/product/:slug`, { slug: Yup.string().required() });

export const BlogLocation                                       = new Location(`${RootLocation.path}/blog`);
export const BlogPostLocation                                   = new Location(`${BlogLocation.path}/:slug`, { slug: Yup.string().required() });

export const ResourceCollectionLocation                         = new Location(`${RootLocation.path}/resource`);
export const BooksCollectionLocation                            = new Location(`${RootLocation.path}/books`);
export const BooksLocation                                      = new Location(`${BooksCollectionLocation.path}/:id`, { id: Yup.string().length(24).required() });
export const ResourceLocation                                   = new Location(`${ResourceCollectionLocation.path}/:id`, { id: Yup.string().length(24).required() });

export const ErrorLocation                                      = new Location(`${RootLocation.path}/error`);
export const ErrorAuthLocation                                  = new Location(`${ErrorLocation.path}/auth`);
export const ErrorSuspendedLocation                             = new Location(`${ErrorLocation.path}/suspended`);
export const ErrorBadRequestLocation                            = new Location(`${ErrorLocation.path}/badrequest`);
export const ErrorUnauthorizedLocation                          = new Location(`${ErrorLocation.path}/unauthorized`);
export const ErrorForbiddenLocation                             = new Location(`${ErrorLocation.path}/forbidden`);
export const ErrorNotFoundLocation                              = new Location(`${ErrorLocation.path}/notfound`);
export const ErrorInternalServerErrorLocation                   = new Location(`${ErrorLocation.path}/internalservererror`);

export const VerifyLocation                                     = new Location(`${RootLocation.path}/verify`);
export const VerifyEmailLocation                                = new Location(`${VerifyLocation.path}/email`);

export const AdminLocation                                      = new Location(`${RootLocation.path}/administration`);
export const AdminDashboardLocation                             = new Location(`${AdminLocation.path}/dashboard`);
export const AdminLibraryLocation                               = new Location(`${AdminLocation.path}/library`);
export const AdminProductsLocation                              = new Location(`${AdminLocation.path}/products`);
export const AdminCouponsLocation                               = new Location(`${AdminLocation.path}/coupons`);
export const AdminFeaturesLocation                              = new Location(`${AdminLocation.path}/astrological-features`);
export const AdminTicketsLocation                               = new Location(`${AdminLocation.path}/tickets`);
export const AdminDeliveriesLocation                            = new Location(`${AdminLocation.path}/deliveries`);
export const AdminOrdersLocation                                = new Location(`${AdminLocation.path}/orders`);
export const AdminUsersLocation                                 = new Location(`${AdminLocation.path}/users`);
export const AdminEmailsLocation                                = new Location(`${AdminLocation.path}/emails`);
export const AdminPlayGroundLocation                            = new Location(`${AdminLocation.path}/playground`);
export const AdminExamplesCollectionLocation                    = new Location(`${AdminLocation.path}/examples`);
export const AdminDalleLocation                                 = new Location(`${AdminLocation.path}/dalle`);
export const AdminResourcesLocation                             = new Location(`${AdminLocation.path}/resources`);
export const AdminCommentsLocation                              = new Location(`${AdminLocation.path}/comments`);
export const AdminReviewsLocation                               = new Location(`${AdminLocation.path}/reviews`);
// export const AdminOrdersLocation                                = new Location(`${AdminLocation.path}/orders`);
// export const AdminArchiveLocation                               = new Location(`${AdminExamplesCollectionLocation.path}/:slug`, { slug: Yup.string().required() });
export const AdminSymbolsLocation                               = new Location(`${AdminLocation.path}/symbols`);
export const AdminSymbolsPlaygroundLocation                     = new Location(`${AdminSymbolsLocation.path}/playground`);

export const AdminNakshatrasLocation                            = new Location(`${AdminLocation.path}/nakshatras`);

export const AdminEmailsLocation_TabHistory                     = new Location(`${AdminEmailsLocation.path}/history`);
export const AdminEmailsLocation_TabCompose                     = new Location(`${AdminEmailsLocation.path}/compose`);
export const AdminEmailsLocation_TabTemplates                   = new Location(`${AdminEmailsLocation.path}/templates`);

export const AdminFeaturesLocation_TabAspects                   = new Location(`${AdminFeaturesLocation.path}/aspects`);
export const AdminFeaturesLocation_TabHouses                    = new Location(`${AdminFeaturesLocation.path}/houses`);
export const AdminFeaturesLocation_TabCelestialBodies           = new Location(`${AdminFeaturesLocation.path}/celestialBodies`);
export const AdminFeaturesLocation_TabCelestialPoints           = new Location(`${AdminFeaturesLocation.path}/celestialPoints`);
export const AdminFeaturesLocation_TabAngles                    = new Location(`${AdminFeaturesLocation.path}/angles`);
export const AdminFeaturesLocation_TabSigns                     = new Location(`${AdminFeaturesLocation.path}/signs`);
export const AdminFeaturesLocation_TabNakshatras                = new Location(`${AdminFeaturesLocation.path}/nakshatras`);

export const UserLocation                                       = new Location(`${RootLocation.path}/user`);
export const UserProfileLocation                                = new Location(`${UserLocation.path}/profile/@:handle`, { handle: Yup.string().required() })

export const UserOrderCollectionLocation                        = new Location(`${UserLocation.path}/orders`);
export const UserOrderLocation                                  = new Location(`${UserOrderCollectionLocation.path}/:id`, { id: Yup.string().length(24).required() });
export const UserCartLocation                                   = new Location(`${UserLocation.path}/cart`);    
export const UserLibraryCollectionLocation                      = new Location(`${UserLocation.path}/library`);
export const UserLibraryLocation                                = new Location(`${UserLibraryCollectionLocation.path}/:id`, {id: Yup.string().length(24).required()});
export const UserContactLocation                                = new Location(`${UserLocation.path}/contact`);
export const UserSettingsLocation                               = new Location(`${UserLocation.path}/settings`);
export const UserTicketsLocation                                = new Location(`${UserLocation.path}/supportTickets`);
export const UserSettingsLocation_TabNatal                      = new Location(`${UserSettingsLocation.path}/natalData`);
export const UserSettingsLocation_TabHistory                    = new Location(`${UserSettingsLocation.path}/transactionHistory`);
export const UserSettingsLocation_TabPaymentMethods             = new Location(`${UserSettingsLocation.path}/paymentMethods`);
export const UserSettingsLocation_TabAddresses                  = new Location(`${UserSettingsLocation.path}/addresses`);
export const UserSettingsLocation_TabUserEmailPreferences       = new Location(`${UserSettingsLocation.path}/emailPreferences`);
export const UserSettingsLocation_TabUserProfile                = new Location(`${UserSettingsLocation.path}/userProfile`);

export const UserDeliveryCollectionLocation                     = new Location(`${UserLocation.path}/delivery`);
export const UserDeliveryLocation                               = new Location(`${UserDeliveryCollectionLocation.path}/:id`, { id: Yup.string().length(24).required() });

export const UserCheckoutLocation                               = new Location(`${UserCartLocation.path}/checkout`);

export const PolicyLocation                                     = new Location(`${RootLocation.path}/policy`);
export const PolicyCookiesLocation                              = new Location(`${PolicyLocation.path}/cookiePolicy`);
export const PolicyPrivacyLocation                              = new Location(`${PolicyLocation.path}/privacyPolicy`);
export const PolicyTermsLocation                                = new Location(`${PolicyLocation.path}/termsOfUse`);

export const ArchiveCollectionLocation                          = new Location(`${RootLocation.path}/archive`);
export const ExamplesCollectionLocation                         = new Location(`${RootLocation.path}/examples`)
export const ArchiveLocation                                    = new Location(`${ArchiveCollectionLocation.path}/:slug`, { slug: Yup.string().required() });
export const ExamplesLocation                                   = new Location(`${ExamplesCollectionLocation.path}/:slug`, { slug: Yup.string().required() });
