/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Admin - Admin Delivery Panel
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       31st December 2024

*******************************************************************************************/
import React                        from "react";
import {
    Badge, 
    Box,
    Typography,
    Tooltip
}                                   from "@mui/material";
import UpdateIcon                   from '@mui/icons-material/Update';
import { 
    useStateEphemeral,
    useAdminDeliveryFunctions 
}                                   from "hooks";
import {
    AdminBox,
    AlertMessageGroup,
    ConfirmationIconButton,
    LoadingData,
}                                   from "components"
import {
    ReleaseDeliveryButton,
    ReprocessDeliveryButton,
    CancelDeliveryButton,
    ResetDeliveryButton,
}                                   from "components/order/deliveries/components"
import { useAlert }                 from "contexts";

const obj = {};

export const AdminDeliveryPanel = ({
    deliveryId,
    component : Component   = AdminBox,
    componentProps          = obj,
    disabled                = false,
    allowExtendDelivery     = true,
    allowReprocessDelivery  = true,
    allowResetDelivery      = true,
    allowCancelDelivery     = true,
    allowReleaseDelivery    = true,
    ...props
} = {}) => {
    const {alert}                               = useAlert();
    const [messageSuccess,  setMessageSuccess]  = useStateEphemeral(undefined);
    const [messageError,    setMessageError]    = useStateEphemeral(undefined,5000);
    const {
        isUserValid,
        releaseDelivery,
        releaseDeliveryWorking,
        cancelDelivery,
        cancelDeliveryWorking,
        reprocessDelivery,
        reprocessDeliveryWorking,
        resetDelivery,
        resetDeliveryWorking,
        extendDelivery,
        extendDeliveryWorking
    }                               = useAdminDeliveryFunctions()
    const handleExtendDelivery = React.useCallback((deliveryId,amount,unit) => new Promise(resolve => { 
        extendDelivery(deliveryId, amount, unit)
            .then(result => {
                setMessageSuccess(`Delivery ${deliveryId} extended`);
                return result;
            })
            .then(resolve)
            .catch(({message}) => {
                setMessageError(message);
                resolve(false)
            })
    }),[extendDelivery, setMessageSuccess, setMessageError]);

    const handleReprocessDelivery = React.useCallback(deliveryId => new Promise(resolve => { 
        reprocessDelivery(deliveryId)
            .then(result => {
                setMessageSuccess(`Delivery ${deliveryId} reprocessed`);
                return result;
            })
            .then(resolve)
            .catch(({message}) => {
                setMessageError(message);
                resolve(false)
            })
    }),[reprocessDelivery, setMessageSuccess, setMessageError])

    const handleResetDelivery = React.useCallback(deliveryId => new Promise(resolve => { 
        resetDelivery(deliveryId)
            .then(result => {
                setMessageSuccess(`Delivery ${deliveryId} reset`);
                return result;
            })
            .then(resolve)
            .catch(({message}) => {
                setMessageError(message);
                resolve(false)
            })
    }),[resetDelivery, setMessageSuccess, setMessageError]);

    const handleCancelDelivery = React.useCallback(deliveryId => new Promise(resolve => { 
        cancelDelivery(deliveryId)
            .then(result => {
                setMessageSuccess(`Delivery ${deliveryId} cancelled`);
                return result;
            })
            .then(resolve)
            .catch(({message}) => {
                setMessageError(message);
                resolve(false)
            })
    }),[cancelDelivery, setMessageSuccess, setMessageError])

    const handleReleaseDelivery = React.useCallback(deliveryId => new Promise(resolve => { 
        releaseDelivery(deliveryId)
            .then(result => {
                setMessageSuccess(`Delivery ${deliveryId} released`);
                return result;
            })
            .then(resolve)
            .catch(({message}) => {
                setMessageError(message);
                resolve(false)
            })
    }),[releaseDelivery, setMessageError, setMessageSuccess])

    React.useEffect(() => {
        if(messageSuccess) 
            alert(messageSuccess,'success')
    },[alert, messageSuccess])

    React.useEffect(() => {
        if(messageError) 
            alert(messageError,'error')
    },[alert, messageError])
    
    // Invalid User, no DeliveryID
    if(!isUserValid || !deliveryId)
        return null;

    // No Options Selected
    if(![
        allowExtendDelivery,
        allowReprocessDelivery,
        allowResetDelivery,
        allowCancelDelivery,
        allowReleaseDelivery    
    ].some(Boolean))
        return null;

    return (
        <Component {...componentProps} {...props}> 
            {
                (messageSuccess || messageError) && 
                <Box sx={{mb:2}}>
                    <AlertMessageGroup 
                        success = {messageSuccess} 
                        error   = {messageError} 
                    />
                </Box>
            }

            <Box display="flex" sx={{"& > * + *" : {ml:3}}}>
                
                {
                    allowExtendDelivery && 
                    <Box display="flex">
                        {
                            [1,2,7,14,28].map((amount,ix) => (
                                <ConfirmationIconButton 
                                    key                 = {ix} 
                                    disabled            = {disabled || extendDeliveryWorking} 
                                    onClick             = {() => handleExtendDelivery(deliveryId, amount, 'days')}
                                    confirmationProps   = {{
                                        children : (
                                            <React.Fragment>
                                                <Typography gutterBottom>
                                                    Are you sure you want to extend the delivery by {amount}x days?
                                                </Typography>
                                                <Typography>
                                                    This cannot be undone.
                                                </Typography>
                                            </React.Fragment>
                                        )
                                    }}
                                >
                                    <Tooltip title={`Extend Delivery by ${amount}x day/s`} arrow>
                                        <Badge badgeContent={`+${amount}`} color="primary">
                                            <UpdateIcon />
                                        </Badge>
                                    </Tooltip>
                                </ConfirmationIconButton>
                            ))
                        }
                        {
                            extendDeliveryWorking &&
                            <Box sx={{ml:3}}>
                                <LoadingData />
                            </Box>
                        }
                    </Box>
                }
                <Box display="flex">
                    {
                        allowReprocessDelivery &&
                        <Box display="flex" alignItems="center"> 
                            <ReprocessDeliveryButton 
                                disabled    = {disabled || reprocessDeliveryWorking} 
                                deliveryId  = {deliveryId} 
                                onClick     = {handleReprocessDelivery}
                            />
                            {reprocessDeliveryWorking && <LoadingData />}
                        </Box>
                    }
                    {
                        allowResetDelivery && 
                        <Box display="flex" alignItems="center"> 
                            <ResetDeliveryButton 
                                disabled    = {disabled || resetDeliveryWorking} 
                                deliveryId  = {deliveryId} 
                                onClick     = {handleResetDelivery} 
                            />
                            {resetDeliveryWorking && <LoadingData />}
                        </Box>
                    }
                    {
                        allowReleaseDelivery &&
                        <Box display="flex" alignItems="center"> 
                            <ReleaseDeliveryButton
                                disabled    = {disabled || releaseDeliveryWorking} 
                                deliveryId  = {deliveryId}
                                onClick     = {handleReleaseDelivery}
                            />
                            {releaseDeliveryWorking && <LoadingData />}
                        </Box>
                    }
                    {
                        allowCancelDelivery &&
                        <Box display="flex" alignItems="center"> 
                            <CancelDeliveryButton
                                disabled    = {disabled || cancelDeliveryWorking} 
                                deliveryId  = {deliveryId}
                                onClick     = {handleCancelDelivery}
                            />
                            {cancelDeliveryWorking && <LoadingData />}
                        </Box>
                    }
                </Box>
            </Box>
        </Component>
    )
}

export default AdminDeliveryPanel;