
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
use Cancel Token hook
********************************************************************************************
https://dev.to/mostafaomar98/comment/1lc0g

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       27th February 2022

Universally Handled in axios interceptor

Usage:      

    const {cancelToken, isCancel} = useCancelToken();               // NOTE USE
    const someQuery = () => new Promise((resolve, reject) => {
        axios.get(`<SOME URL>`, { cancelToken })                    // NOTE USE
            .then(resolve)
            .catch(err => {
                if (err.isCancel) return resolve({});               // NOTE USE
                reject(err);
            })
    }

*******************************************************************************************/

import React        from 'react';
import {useAxios}   from './services';

export const useCancelToken = ({debug = false} = {}) => {

    const {axios}               = useAxios(); 

    // Seed the source
    const cancelTokenSourceRef  = React.useRef(axios.CancelToken.source());

    // Cancel Function
    const cancel = React.useCallback( 
        (reason = 'Request Actively Cancelled') => {
            
            if(debug) 
                console.log(`Cancelling: ${reason || ''}`)

            if(cancelTokenSourceRef.current)
                cancelTokenSourceRef.current?.cancel(reason || undefined);
            
            // Create a new source for future requests
            cancelTokenSourceRef.current = axios.CancelToken.source();
        },
        [axios.CancelToken, debug]
    )
  
    // Cancel on Unmount or cancel function change
    React.useEffect( () => {
        return () => {
            cancel('Unmounting'); // Cleanup
        }
    }, [cancel]);


    // Modfified isCancel function to handle edge case and the universal axios interceptor
    const isCancel = React.useCallback((err) => {
        try {
            if (err instanceof Error) {
                return axios.isCancel(err); // Fallback method
            }
            if (typeof err?.isCancel === 'boolean') {
                return err.isCancel;
            }
        } catch (err) {
            console.error(err);
        }
        return false;
    }, [axios]);

    return {
        cancelToken : cancelTokenSourceRef.current?.token,
        isCancel,
        cancel
    }
};

export default useCancelToken;