/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
TitleWithReadOnlyChip
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       10th June 2021

*******************************************************************************************/

import React                            from 'react';
import { Box, Typography }              from '@mui/material';
import { ColorChip }                    from './ColorChip';
import { useTranslation }               from 'contexts'; 

export const TitleWithReadOnlyChip = ({ title, readOnly, ...props }) => {
    const {t} = useTranslation();
    return (
        <Box sx={{width:'100%', py:0.5}}>
            <Typography variant="h5" {...props} >
                {title}
                {
                    readOnly && 
                    <Box component="span" sx={{pl:1}}>
                        <ColorChip size="small" label={t('common.readOnly')} color="secondary" />
                    </Box>
                }
            </Typography>
        </Box>
    )
}

export default TitleWithReadOnlyChip;