/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Pick Reading Variables
********************************************************************************************
Admin Only

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       12th November 2022

*******************************************************************************************/
import React                        from 'react';
import {
    Box,
    Typography
}                                   from '@mui/material';
import { 
    SelectProductForm,
    NatalDataForm,
    SelectHouseSystemForm,
    SelectZodiacForm
}                                   from 'components/forms';
import {
    RootContainer
}                                   from 'components';
import { withTranslation }          from './hoc';

const defaultNatalRecordData = {
    lat             : undefined,
    lng             : undefined,
    address         : undefined,
    birthDateTime   : undefined,
    unknownTime     : undefined,
    gender          : undefined
};

const defaultProductData = {
    id              : undefined,
};

const defaultZodiacData = {
    zodiac          : "tropical"
}

const defaultHouseSystemData = {
    houseSystem     : "auto"
}

const selectProductFormArgs = {
    FormProps : {
        submitText              : "Ok",
        disabledAfterSubmit     : false,
        showResetButton         : true,
        showCancelButton        : false,
        showAlerts              : false,
        ButtonProps             : {
            size : "small",
            style : {
                textTransform: 'capitalize'
            }
        }
    }
};

const selectHouseSystemFormArgs = {
    FormProps : {
        submitText              : "Ok",
        disabledAfterSubmit     : false,
        showResetButton         : true,
        showCancelButton        : false,
        showAlerts              : false,
        ButtonProps             : {
            size : "small",
            style : {
                textTransform: 'capitalize'
            }
        }
    }
};

const selectZodiacFormArgs = {
    FormProps : {
        submitText              : "Ok",
        disabledAfterSubmit     : false,
        showResetButton         : true,
        showCancelButton        : false,
        showAlerts              : false,
        ButtonProps             : {
            size : "small",
            style : {
                textTransform: 'capitalize'
            }
        }
    }
};

const natalRecordFormArgs = {
    usePhoto            : false,
    useDescription      : true,
    useGender           : false,
    useRoddenRating     : false,
    useIsUser           : false,
    useGeolocateButton  : false,
    useMap              : false,
    useAddress          : false,
    FormProps           : {
        submitText              : "Ok",
        disabledAfterSubmit     : false,
        showResetButton         : true,
        showCancelButton        : false,
        showAlerts              : false,
        ButtonProps             : {
            size : "small",
            style : {
                textTransform: 'capitalize'
            }
        }
    }
};

const noop = () => {};

const formDataDefault = {
    productData : defaultProductData,
    natalRecord : defaultNatalRecordData,
    houseSystem : defaultHouseSystemData,
    zodiac      : defaultZodiacData
};

export const PickReadingVariables = withTranslation( ({
    t,
    formData            = formDataDefault,
    onProductDataChange = noop,
    onHouseSystemChange = noop,
    onZodiacChange      = noop,
    onNatalRecordChange = noop,
    useProduct          = true,
    useNatalRecord      = true,
    useHouseSystem      = true,
    useZodiac           = true,
    ...props
}) => {

    const handleSubmitProductData = React.useCallback( (formData) => {
        onProductDataChange(formData);
    }, [onProductDataChange]);
    
    const handleSubmitHouseSystem = React.useCallback( (formData) => {
        onHouseSystemChange(formData);
    }, [onHouseSystemChange]);
    
    const handleSubmitZodiac = React.useCallback( (formData) => {
        onZodiacChange(formData);
    }, [onZodiacChange]);

    const handleSubmitNatalData = React.useCallback( (formData) => {
        onNatalRecordChange(formData);
    }, [onNatalRecordChange]);

    return (
        <RootContainer>
            <Typography variant='h5' component="h3">
                {t('common.variables')}
            </Typography>

            {   
                useProduct && 
                <Box id="selectPoductForm">
                    <SelectProductForm
                        formData    = {formData.productData}
                        usePhysical = {false}
                        useVirtual  = {true}
                        processor   = "NatalAnalysisCP"
                        onSubmit    = {handleSubmitProductData}
                        {...selectProductFormArgs}
                    />
                </Box>
            }
            
            {
                useNatalRecord && 
                <Box id="selectNatalDataForm" mt={0}>
                    <NatalDataForm 
                        formData        = {formData.natalRecord} 
                        onSubmit        = {handleSubmitNatalData}
                        {...natalRecordFormArgs}  
                    />
                </Box>
            }
            
            {
                (useHouseSystem || useZodiac) && 
                <Box display="flex">
                    {
                        useHouseSystem && 
                        <Box id="selectHouseSystemForm" flexGrow={1}>
                            <SelectHouseSystemForm 
                                formData = {formData.houseSystem}
                                onSubmit = {handleSubmitHouseSystem}
                                {...selectHouseSystemFormArgs}
                            />
                        </Box>
                    }   
                    { 
                        useZodiac && 
                        <Box id="selectZodiacForm" flexGrow={1} ml={1}>
                            <SelectZodiacForm 
                                formData = {formData.zodiac}
                                onSubmit = {handleSubmitZodiac}
                                {...selectZodiacFormArgs}
                            />
                        </Box>
                    }
                </Box>
            }
           
        </RootContainer>
    )
});

export default PickReadingVariables;