/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
View Product Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       17th April 2022

*******************************************************************************************/
import React                                from 'react';
import { Button }                           from '@mui/material';
import { ShoppingCart as ShoppingCartIcon } from '@mui/icons-material';
import { useHistory }                       from 'react-router-dom';
import {
    DraggableDialog,
    Product as ProductComponent,
}                                           from 'components';
import { useProduct }                       from 'contexts';
import { ProductLocation }                  from 'router/locations/Locations';

const defaultComponentProps = {
    size        : "small",
    variant     : "text",
    color       :  "secondary",
    startIcon   : <ShoppingCartIcon/>
}
const defaultDialogProps = {
}
const defaultProductProps = {
}

export const ViewProductButton = ({
    modal                   = false,
    productId : productIdIn = undefined, 
    component : Component   = Button, 
    componentProps          = defaultComponentProps,
    dialogProps             = defaultDialogProps,
    productProps            = defaultProductProps,
    children,
    ...props
}) => {

    const history                       = useHistory();
    const {data}                        = useProduct();
    const productId                     = React.useMemo(() => productIdIn, [productIdIn]);
    const [open, setOpen]               = React.useState(false);
    const handleProductOpen             = React.useCallback( () => setOpen(true), []);
    const handleProductClose            = React.useCallback( () => setOpen(false), []);
    
    const handleNavigateToProduct = React.useCallback((event) => {
        if (productId) {
            const slug = data.find(x => x.id === productId)?.slug;
            const url = ProductLocation.toUrl({ slug: slug || productId });
    
            if (event.shiftKey) {
                // Open in a new window
                window.open(url, '_blank', 'noopener,noreferrer');
            } else if (event.metaKey || event.ctrlKey) {
                // Open in a new tab
                window.open(url, '_blank');
            } else {
                // Navigate normally
                history.push(url);
            }
        }
    }, [data, history, productId]);
    
    if(!productIdIn)
        return null;

    return (
        <>
            <Component onClick = {modal ? handleProductOpen : handleNavigateToProduct} {...componentProps} {...props}>
                {children || "View Product"}
            </Component>
            <DraggableDialog 
                title               = "View Product"
                open                = {open} 
                onClose             = {handleProductClose} 
                onCancel            = {handleProductClose} 
                onOk                = {handleProductClose} 
                readOnly            = {false}
                fullScreen          = {true}
                showButtonsCancel   = {false}
                disableBackdropClick
                {...dialogProps}
            >
                <ProductComponent 
                    id                  = {productId} 
                    sticky              = {false}
                    disabled            = {false}
                    showImages          = {true} 
                    showPricing         = {true} 
                    showRawData         = {false} 
                    showCrossSell       = {false}
                    {...productProps}
                />
            </DraggableDialog>
        </>
    )
};

export default ViewProductButton;