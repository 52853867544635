
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Alert Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       2nd April 2022

*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';

// The Product Context 
const AlertContext = React.createContext(undefined);

// const EMPTY_MESSAGE = {severity : undefined, message: undefined};

const PERIOD = 5000;

// Alert Provider
const AlertProvider = ({children}) => {

    const timerRef                      = React.useRef(null);
    const [messages,    setMessages]    = React.useState([]);
    
    const alert                         = React.useCallback( (message, severity = 'info') => (
        setMessages( prev => ([
            ...prev, 
            { id: Date.now(), date : moment(), message, severity },
        ]))
    ), [setMessages]);

    const handleClose                   = React.useCallback( () => {
        setMessages(prev => prev.slice(1));  // Remove the first message
    }, []);

    const clear                         = React.useCallback(() => {
        setMessages([]);
    },[])

    // Remove
    React.useEffect(() => {
        if (messages.length > 0) {
            if (timerRef.current) {
                clearTimeout(timerRef.current);  // Clear previous timer
            }
            timerRef.current = setTimeout(handleClose, PERIOD);
            return () => (
                clearTimeout(timerRef.current)
            );
        }
    }, [handleClose, messages]);

    // Context values
    const value = {
        period  : PERIOD,
        messages,
        clear,
        handleClose,
        alert
    };

    return (
        <AlertContext.Provider value={value}>
            {children}
        </AlertContext.Provider>
    )
}

// Alert Consumer
const AlertConsumer = ({children}) => {
    return (
        <AlertContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('AlertConsumer must be used within AlertProvider');
                }
                return children(context)
            }}
        </AlertContext.Consumer>
    )
}

// useAlert Hook
const useAlert = () => {
    const context = React.useContext(AlertContext);
    if(context === undefined)
        throw new Error('useAlert must be used within AlertProvider');
    return context;
}

export {
    AlertProvider,
    AlertConsumer,
    useAlert
}