/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Admin - useAdminDeliveryFunctions
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       31st December 2024

*******************************************************************************************/
import React                from 'react';
import debounce             from 'lodash/debounce';
import moment               from 'moment';
import { 
    useNetwork,
    useUser
}                           from 'contexts';
import { useCancelToken }   from 'hooks';

const DEBOUNCE_UNTERVAL     = 250;
const BASE_API_URL          = '/api/admin/delivery';

export const useAdminDeliveryFunctions = () => {
    const { isAuthenticated, isAdmin, isSuspended }                     = useUser();
    const { cancelToken }                                               = useCancelToken();
    const { axios, isNetworkReady }                                     = useNetwork();
    const [ getDeliveriesWorking,       setGetDeliveriesWorking]        = React.useState(false);
    const [ getDeliveryWorking,         setGetDeliveryWorking ]         = React.useState(false);
    const [ releaseDeliveryWorking,     setReleaseDeliveryWorking]      = React.useState(false);
    const [ cancelDeliveryWorking,      setCancelDeliveryWorking]       = React.useState(false);
    const [ reprocessDeliveryWorking,   setReprocessDeliveryWorking]    = React.useState(false);
    const [ resetDeliveryWorking,       setResetDeliveryWorking]        = React.useState(false);
    const [ 
        getDeliveriesWorkRequiredWorking,       
        setGetDeliveriesWorkRequiredWorking
    ]                                                                   = React.useState(false);
    const [ extendDeliveryWorking,      setExtenDeliveryWorking]        = React.useState(false);
    const isUserValid                                                   = React.useMemo(() => isAuthenticated && isAdmin && !isSuspended, [isAdmin, isAuthenticated, isSuspended]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceGetDeliveriesFunction = React.useCallback(
        debounce(
            (deliveryStatusArray, dateRange, page, perPage, resolve, reject) => {
                const processDate = d => moment.isMoment(d) ? d.valueOf() : d;
                const [start, finish] = [dateRange.start, dateRange.finish].map(processDate);
                const ds = deliveryStatusArray.length
                    ? '&' + deliveryStatusArray.map(x => `deliveryStatus[]=${x}`).join('&')
                    : '';
                setGetDeliveriesWorking(true);
                axios.get(
                    `${BASE_API_URL}?page=${page}&perPage=${perPage}&start=${start || 0}&finish=${finish || Date.now()}${ds}`, 
                    { cancelToken }
                )
                .then(({ data, totalCount }) => {
                    resolve([data, totalCount]);  // Resolve with results
                })
                .catch(err => {
                    if (axios.isCancel(err)) {
                        console.log('Request cancelled.');
                        resolve([[], 0]);  // Resolve with empty data for cancel
                    } else {
                        reject(err);  // Reject on other errors
                    }
                })
                .finally(()=>{
                    setGetDeliveriesWorking(false);
                });
            }, 
            DEBOUNCE_UNTERVAL  // Debounce time
        ),  
        [axios, cancelToken]
    );

    // Wrapper to maintain promise API
    const getDeliveries = React.useCallback((deliveryStatusArray, dateRange, page, perPage) => new Promise((resolve, reject) => {
        if(isNetworkReady){
            debounceGetDeliveriesFunction(deliveryStatusArray, dateRange, page, perPage, resolve, reject)
        }else{
            reject(new Error('network not ready'));
        }
    }), [debounceGetDeliveriesFunction, isNetworkReady]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceGetDeliveriesWorkRequiredFunction = React.useCallback(
        debounce(
            (page, perPage, resolve, reject) => {
                setGetDeliveriesWorkRequiredWorking(true);
                axios.get(`${BASE_API_URL}/workRequired?page=${page}&perPage=${perPage}`, {cancelToken})
                    .then(({data,totalCount}) => [data,totalCount])
                    .then(resolve)
                    .catch(reject)
                    .finally(()=>{
                        setGetDeliveriesWorkRequiredWorking(false);
                    })
            }, 
            DEBOUNCE_UNTERVAL // Debounce time
        ),  
        [axios, cancelToken]
    );

    const getDeliveriesWorkRequired = React.useCallback((page, perPage) => new Promise((resolve,reject) => {
        if(isNetworkReady){
            debounceGetDeliveriesWorkRequiredFunction(page, perPage, resolve, reject)
        }else{
            reject(new Error('network not ready'));
        }
    }),[debounceGetDeliveriesWorkRequiredFunction, isNetworkReady]);

    const getDelivery = React.useCallback((deliveryId) => new Promise((resolve,reject) => {
        if(isNetworkReady && deliveryId){
            setGetDeliveryWorking(true);
            axios.get(`${BASE_API_URL}/${deliveryId}`, {cancelToken})
                .then(({data}) => data)
                .then(resolve)
                .catch(reject)
                .finally(()=>{
                    setGetDeliveryWorking(false);
                })
        }else{
            reject(new Error('network not ready or deliveryId not specified'));
        }
    }), [axios, cancelToken, isNetworkReady])

    const releaseDelivery = React.useCallback(deliveryId => new Promise((resolve,reject) => {
        if(isNetworkReady && deliveryId){
            setReleaseDeliveryWorking(true);
            axios.post(`${BASE_API_URL}/${deliveryId}/release`, {}, {cancelToken})
                .then(({data}) => data)
                .then(resolve)
                .catch(reject)
                .finally(() => {
                    setReleaseDeliveryWorking(false);
                })
        }else{
            reject(new Error('network not ready or deliveryId not specified'));
        }
    }),[axios, cancelToken, isNetworkReady]);

    const cancelDelivery = React.useCallback(deliveryId => new Promise((resolve,reject) => {
        if(isNetworkReady && deliveryId){
            setCancelDeliveryWorking(true);
            axios.post(`${BASE_API_URL}/${deliveryId}/cancel`, {}, {cancelToken})
                .then(({data}) => data)
                .then(resolve)
                .catch(reject)
                .finally(() => {
                    setCancelDeliveryWorking(false);
                })
        }else{
            reject(new Error('network not ready or deliveryId not specified'));
        }
    }),[axios, cancelToken, isNetworkReady])

    const reprocessDelivery = React.useCallback(deliveryId => new Promise((resolve,reject) => {
        if(isNetworkReady && deliveryId){
            setReprocessDeliveryWorking(true);
            axios.post(`${BASE_API_URL}/${deliveryId}/reprocess`, {}, {cancelToken})
                .then(({data}) => data)
                .then(resolve)
                .catch(reject)
                .finally(() => {
                    setReprocessDeliveryWorking(false);
                })
        }else{
            reject(new Error('network not ready or deliveryId not specified'));
        }
    }),[axios, cancelToken, isNetworkReady])

    const resetDelivery = React.useCallback(deliveryId => new Promise((resolve,reject) => {
        if(isNetworkReady && deliveryId){
            setResetDeliveryWorking(true);
            axios.post(`${BASE_API_URL}/${deliveryId}/reset`, {}, {cancelToken})
                .then(({data}) => data)
                .then(resolve)
                .catch(reject)
                .finally(() => {
                    setResetDeliveryWorking(false);
                })
        }else{
            reject(new Error('network not ready or deliveryId not specified'));
        }
    }),[axios, cancelToken, isNetworkReady]);

    const extendDelivery = React.useCallback((deliveryId, amount, unit = 'days') => new Promise((resolve,reject) => {
        if(isNetworkReady && deliveryId && Number.isFinite(amount)){
            setExtenDeliveryWorking(true);
            axios.post(`${BASE_API_URL}/${deliveryId}/extend?amount=${amount}&unit=${unit}`, {amount, unit}, {cancelToken})
                .then(({data}) => data)
                .then(resolve)
                .catch(reject)
                .finally(() => {
                    setExtenDeliveryWorking(false);
                })
        }else{
            reject(new Error('network not ready, deliveryId not specified or invalid amount'));
        }
    }),[axios, cancelToken, isNetworkReady]);

    return {
        isUserValid,
        getDeliveries,
        getDeliveriesWorking,
        getDeliveriesWorkRequired,
        getDeliveriesWorkRequiredWorking,
        getDelivery,
        getDeliveryWorking,
        releaseDelivery,
        releaseDeliveryWorking,
        cancelDelivery,
        cancelDeliveryWorking,
        reprocessDelivery,
        reprocessDeliveryWorking,
        resetDelivery,
        resetDeliveryWorking,
        extendDelivery,
        extendDeliveryWorking
    }
}

export default useAdminDeliveryFunctions;