/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Order
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com 
Date:       27th July 2021

*******************************************************************************************/
import React                            from 'react';
import { 
    styled, 
    Box, 
    Grid 
}                                       from '@mui/material';
import {
    RootContainer,
    Title,
    SkeletonFancyWaitingForNetwork,
}                                       from 'components';
import { withTranslation }              from 'components/hoc';
import NotFound                         from 'components/errors/NotFound';
import { 
    // usePageHeight,
    TrackIdProvider
}                                       from 'contexts';
import { 
    useOrderViewer,
    StatusMapProvider,
    OrderViewerProvider 
}                                       from './context';
import {
    InvoicesProvider
}                                       from './context';        
import OrderTable                       from './OrderTable';
import OrderPaymentHistory              from './OrderPaymentHistory';
import OrderTransactionTiles            from './OrderTransactionTiles';
import OrderProgress                    from './OrderProgress';
import OrderProgressSummary             from './OrderProgressSummary';
import OrderUserInputAlert              from './OrderUserInputAlert';
import OrderTaxInvoice                  from './OrderTaxInvoice';
import OrderTips                        from './OrderTips';
import OrderTickets                     from './OrderTickets';
import OrderPaymentCallToAction         from './OrderPaymentCallToAction';

const withContent = Component => styled(Component, {
    shouldForwardProp : (prop) => prop !== 'sticky'
})(({theme, sticky = false}) => ({
    ...(sticky && {
        position    : 'sticky', 
        top         : theme.spacing(2)
    }),
    '& > * + *' : {
        marginTop : theme.spacing(2)
    }
}));

const BoxContent = withContent(Box);

const OrderBase = withTranslation( ({ t, title = false }) => {
    // const { height }                            = usePageHeight();
    const { queried, data : order, orderId }    = useOrderViewer();
    const sideBarBox                            = React.useRef();

    // No Order
    if(!orderId)
        return null;

    return (
        <RootContainer id="order">
            {
                !queried && 
                <Box style={{aspectRatio:'0.71'}}>
                    <SkeletonFancyWaitingForNetwork />
                </Box>
            }
            { 
                (queried && !order) &&
                <NotFound>
                    { t('components.order.order.error404') }
                </NotFound>
            }
            {
                (queried && order) &&
                <React.Fragment>
                    {   
                        title && 
                        <Title> 
                            { t('components.order.order.orderSheet') }
                        </Title>
                    }
                    <OrderUserInputAlert />
                    <OrderTransactionTiles />
                    <Box mt={2}>
                        <Grid container direction="row-reverse">
                            <Grid component={RootContainer} item xs={12} md={8}>
                                <OrderPaymentCallToAction />
                                <OrderProgressSummary orderId={orderId}/>
                                {
                                    order?.shouldProcess &&
                                    <>
                                        {
                                            [true,false].map((quickLinks,ix) => (
                                                <OrderProgress 
                                                    key         = {ix} 
                                                    orderId     = {orderId} 
                                                    quickLinks  = {quickLinks} 
                                                />
                                            ))
                                        }
                                    </>
                                }
                                <OrderTable orderId={orderId}/>
                                <OrderTickets orderId={orderId}/>
                            </Grid>
                            <Grid item xs={12} md={4} ref={sideBarBox}>
                                <BoxContent sticky={true} >
                                    <OrderTips />
                                    <OrderPaymentHistory />
                                    <OrderTaxInvoice />
                                </BoxContent>
                            </Grid>
                        </Grid>
                    </Box>
                </React.Fragment>
            }

        </RootContainer>
    )
});

export const Order = ({orderId = undefined, ...props}) => (
    <OrderViewerProvider orderId = {orderId}>
        <StatusMapProvider>
            <TrackIdProvider>
                <InvoicesProvider>
                    <OrderBase {...props}/>
                </InvoicesProvider>
            </TrackIdProvider>
        </StatusMapProvider>
    </OrderViewerProvider>
)

export default Order;