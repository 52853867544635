/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Delivery Review Alert
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       15th October 2024

*******************************************************************************************/
import React                            from 'react';
import { Box }                    from '@mui/material';
import { 
    Button,
    FormAlert as Alert
}                                       from 'components';
import { DeliveryReview }               from './DeliveryReview';
import { DeliveryReviewModal }          from './DeliveryReviewModal';
import { withTranslation }              from 'components/hoc';

const noop = () => {};

export const DeliveryReviewAlert = withTranslation(({t, disabled = false, onClick : handleClick = noop, deliveryId = null, force = false}) => {
    if(!deliveryId)
        return null;
    return (
        <DeliveryReview 
            deliveryId  = {deliveryId} 
            render      = {({
                // deliveryId,
                queried, 
                working,
                isOwner, 
                isReviewed, 
                //review, 
                formData, 
                editReview,
                handleCreateOrEdit,
                handleEditStart,
                handleEditStop
            }) => {
                if(!force){
                    if(!queried)                return null;
                    if(!isOwner)                return null;
                    if(isOwner && isReviewed)   return null;
                }
                return (
                    <Box id="libraryReviewReminder">
                        <Alert 
                            severity    = "info"
                            action      = {
                                <Box>
                                    <Button 
                                        disabled    = {disabled}
                                        variant     = "contained" 
                                        size        = "small" 
                                        onClick     = { (e) => {
                                            handleClick(e);
                                            handleEditStart(e);
                                        }}>
                                        {t('components.library.libraryReviewReminder.button')}
                                    </Button>
                                </Box>
                            }
                        >
                            <strong>
                                {t('components.library.libraryReviewReminder.message')}
                            </strong>
                        </Alert>
                        <DeliveryReviewModal
                            working             = {working}
                            editReview          = {editReview} 
                            handleEditStop      = {handleEditStop}
                            handleCreateOrEdit  = {handleCreateOrEdit}
                            formData            = {formData}
                        />
                    </Box>
                )
            }
        }/>
    )
});

export default DeliveryReviewAlert;
