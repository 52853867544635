/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
LibraryDetails Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       16th October 2021

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import formatcoords                     from 'formatcoords';
import { noCase }                       from 'no-case';
import { capitalCase }                  from 'capital-case';
import _                                from 'lodash';
import {
    styled,
    Box,
    Typography,
    Table,
    TableContainer,
    TableRow,
    TableCell as TableCellMUI,
    TableHead,
    TableBody,
    AppBar,
}                                       from '@mui/material';
import {
    JSONViewer,
    GoogleMapsStatic,
    GoogleMapsStaticWithGeolocation,
    YesNoIcon,
    TabProvider,
    TabPanel as TabPanelBase,
    Tabs,
    RootContainer,
    ColorChip
}                                       from 'components';
import LibrarySubtitle                  from './LibrarySubtitle';
import LibraryEditableField             from './LibraryEditableField';
import { 
    useLibraryViewer,
    useLibrary,
    useTranslation
}                                       from 'contexts';

const DEBUG         = false;

const TableCell = (props) => (
    <TableCellMUI {...props} sx={{...props.sx,p:0}}/>
);

const TabPanel = (props) => (
    <TabPanelBase
        {...props} 
        sx = {{
            mt : 1, 
            '& > * + *' : { mt : 2 },
            minHeight : { md : 400, xs : 'unset' },
            ...props.sx
        }}
    />
);

const DynamicTypography = styled(Typography)(({theme}) => ({
    [theme.breakpoints.down('xl')] : {
        fontSize : '0.70rem'
    },
    [theme.breakpoints.down('lg')] : {
        fontSize : '0.65rem'
    },
    [theme.breakpoints.down('md')] : {
        fontSize : '0.60rem'
    }
}));

const Inclusions = ({value,birthTimeUnknown = false}) => {
    const birthTimeKnown = !birthTimeUnknown;
    switch(value){
        case true:
        case 'yes':
            return <YesNoIcon value={true}/>
        case false:
        case 'no':
            return <YesNoIcon value={false}/>
        case 'knownBirthTime':
            return <YesNoIcon value={birthTimeKnown}/>
        case 'unknownBirthTime':
            return <YesNoIcon value={birthTimeUnknown}/>
        default:
            return JSON.stringify(value);
    }
}

// Library Details
export const LibraryDetails = ({sensitive=false, ...props}) => {

    const {t}                                   = useTranslation();

    // Get library from Context
    const {refreshLibraryById}                  = useLibrary();
    const {
        data, 
        setData, 
        libraryId, 
        loading, 
        isOwner,
        updateLibrary : update
    }                                           = useLibraryViewer();

    // Extract Natal Recod
    const {natalRecord = undefined} = data?.metadata?.userFieldsData || {};

    // Extract Product Field Data
    const productFieldsData = _.omit((data ? data.metadata : {}).productFieldsData, ['files','releaseManual']);

    // Maps Component depending on Sensitive or Not
    const MapsComponent = sensitive ? GoogleMapsStatic : GoogleMapsStaticWithGeolocation

    const updateLibrary = React.useCallback( (data) => new Promise((resolve,reject) => {
        update(data)
            .then(data => {
                setData(data);
                refreshLibraryById(libraryId);
                resolve({});
            })
            .catch(reject)
    }), [libraryId, refreshLibraryById, setData, update]);

    // Render
    return (
        <RootContainer id='libraryDetails'>
            <Box>
                {
                    !libraryId && 
                    <Typography>
                        { t('components.library.libraryDetails.pickLibrary') }
                    </Typography>
                }
            </Box>
            {
                !loading &&
                <TabProvider data = {[
                    t('components.library.libraryDetails.variables'),
                    t('components.library.libraryDetails.inclusions') 
                ]}>
                    <AppBar elevation={0} position="static" color="transparent">
                        <Tabs/>
                    </AppBar>
                    <TabPanel index={0}>
                        {
                            natalRecord &&
                            <LibraryEditableField 
                                disabled        = {loading}
                                title           = { t('components.library.libraryDetails.nameDescription') }
                                name            = "metadata.userFieldsData.natalRecord.description"
                                value           = {natalRecord?.description}
                                onSubmit        = {updateLibrary}
                                editable        = {isOwner}
                                fieldFormProps  = {{
                                    minLength : 0,
                                    maxLength : 32
                                }}
                            />
                        }
                        {
                            natalRecord && 
                            <Box>
                                <LibrarySubtitle>
                                    { t('components.library.libraryDetails.birthLocation') }
                                </LibrarySubtitle>
                                <div style={{width:'100%',height:100}}>
                                    <MapsComponent containerprops={{style:{height:'100%'}}} lat={natalRecord.lat} lng={natalRecord.lng} zoom={12} width={250} height={250}/>
                                </div>
                                <Box pt={0.5}>
                                    <DynamicTypography variant="body2">
                                        {formatcoords(natalRecord.lat, natalRecord.lng).format(sensitive ? {decimalPlaces:1} : {})}
                                    </DynamicTypography>
                                    <DynamicTypography variant="body2">
                                        {
                                            !sensitive 
                                                ? natalRecord.address 
                                                : `[${ t('components.library.libraryDetails.adressSensitive') }]`
                                        }
                                    </DynamicTypography>
                                </Box>
                            </Box>
                        }
                        {
                            natalRecord &&
                            <Box>
                                <LibrarySubtitle>
                                    {t('components.library.libraryDetails.birthDateTime')}
                                </LibrarySubtitle>
                                {
                                    !sensitive && 
                                    <DynamicTypography component="div" variant="body2">
                                        {
                                            [
                                                moment.utc(natalRecord.birthDateTime).format('ll'),
                                                !natalRecord.unknownTime 
                                                    ? moment.utc(natalRecord.birthDateTime).format('LT') 
                                                    : undefined,
                                                natalRecord.localTime 
                                                    ? t('common.lmt')
                                                    : t('common.gmt')
                                            ].filter(Boolean).join(' ')
                                        } {
                                            natalRecord.unknownTime &&
                                            <ColorChip 
                                                label       = {t('common.timeUnknown')}
                                                size        = "small" 
                                                color       = "secondary" 
                                                style       = {{transform:'translatey(-5px)', padding:0,marginTop:5}}
                                            />
                                        }
                                    </DynamicTypography>
                                }
                                {
                                    sensitive && 
                                    <DynamicTypography variant="body2">
                                        [{t('components.library.libraryDetails.birthDateTimeSensitive')}]
                                    </DynamicTypography>
                                }
                            </Box>
                        }
                    </TabPanel>
                    
                    <TabPanel index={natalRecord ? 1 : 0}>

                        {
                            productFieldsData && 
                            <Box pt={1}>
                                <LibrarySubtitle>
                                    {t('components.library.libraryDetails.inclusionsVariables')}
                                </LibrarySubtitle>
                                <Typography variant="body2">
                                    {t('components.library.libraryDetails.inclusionsVariablesSummary')}
                                </Typography>
                            </Box>
                        }
                        {
                            productFieldsData && 
                            <Box>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell/>
                                                <TableCell>
                                                    {t('components.library.libraryDetails.table.description')}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {t('components.library.libraryDetails.table.inc')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                Object.keys(productFieldsData).map((k,ix)=>(
                                                    <TableRow key={ix}>
                                                        <TableCell>{ix + 1}</TableCell>
                                                        <TableCell>{capitalCase(noCase(k))}</TableCell>
                                                        <TableCell align="center">
                                                            <Inclusions 
                                                                value={productFieldsData[k]} 
                                                                unknownBirthTime={natalRecord.unknownTime}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        }
                    </TabPanel>
                </TabProvider>
            }
            {DEBUG && libraryId && 
                <JSONViewer src={data}/>
            }
        </RootContainer>
    )
}

export default LibraryDetails;