/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Admin - Deliveries Work Required
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th July 2023

*******************************************************************************************/
import React                        from 'react';
import moment                       from 'moment';
import { 
    useTheme,
    alpha,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    Tooltip,
    useMediaQuery,
    Typography
}                                   from '@mui/material';
import RedoIcon                     from '@mui/icons-material/Redo';
import LibraryBooksIcon             from '@mui/icons-material/LibraryBooks';
import NavigateNextIcon             from '@mui/icons-material/NavigateNext';
import WarningIcon                  from '@mui/icons-material/WarningAmber';
import AutoIcon                     from '@mui/icons-material/AutoAwesome';
import ManualIcon                   from '@mui/icons-material/Rule';
import {
    JSONViewer,
    ItemPaper,
    Paginated,
    PageContainerHeader,
    CountDown,
    CountDownOrTimestamp,
    CompletionBar,
    ObjectId,
    ViewUserButton,
    ViewProductButton,
    ViewOrderButton,
    Button,
    IconButton,
    EditLibraryButton,
    ViewDeliveryButton,
    AdminDeliveryPanel
}                                   from 'components';
import {
    FabRefresh
}                                   from 'components/fabs';
import { 
    useNetwork,
    useLocale,
    useLibraryViewer,
    MasterLibraryProvider,
    LibraryViewerProvider
}                                   from 'contexts';
import {
    useStateEphemeral,
    useClock,
    withClickHandler,
    useAdminDeliveryFunctions
}                                   from 'hooks';
import {
    TableRow, 
    TableCell,
    UserLibraryDialog
}                                   from 'components/order/deliveries/components';

const TableRowMod = withClickHandler(TableRow);

const noop = () => {}

const clamp = (value, min = 0, max = 1) => Math.max(min, Math.min(max, value));

function calcFracIncomplete(currentTime, targetTime, timeSpanDays = 7) {
    const diff              = moment(targetTime).diff(moment(currentTime));
    const fracComplete      = diff / (timeSpanDays * 24 * 60 * 60 * 1000);
    const fracIncomplete    = 1 - clamp(fracComplete);
    return fracIncomplete;
}

const Component = ({onChange : handleChange = noop, onLibraryIdChange : handleLibraryIdChange = noop, forceHeader = false}) => {
    const theme                                 = useTheme();
    const { now }                               = useClock();
    const { formatDateTime }                    = useLocale();
    const smDown                                = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [expanded,        setExpanded]        = React.useState({});
    const [page,            setPage]            = React.useState(0);
    const [perPage,         setPerPage]         = React.useState(5);
    const [totalCount,      setTotalCount]      = React.useState(0);
    const [data,            setData]            = React.useState([]);
    const [messageSuccess,  setMessageSuccess]  = useStateEphemeral(undefined);
    const [messageError,    setMessageError]    = useStateEphemeral(undefined,5000);
    const { socketUsers : socket }              = useNetwork();

    const {libraryId, setLibraryId}             = useLibraryViewer();
    const handleViewLibraryById                 = React.useCallback( (libraryId) => setLibraryId(libraryId), [setLibraryId]);
    const handleLibraryClose                    = React.useCallback( () => setLibraryId(undefined), [setLibraryId]);

    const resetData                             = React.useCallback( () => {
        setData([]);
        setTotalCount(0);
    }, []);

    const {
        isUserValid,
        getDeliveriesWorkRequired,
        getDeliveriesWorkRequiredWorking,
        reprocessDelivery,
        reprocessDeliveryWorking
    }                                           = useAdminDeliveryFunctions();

    const query = React.useCallback( () => {
        if(isUserValid){
            getDeliveriesWorkRequired(page,perPage)
                .then(([data,totalCount]) => {
                    setData(data);
                    setTotalCount(totalCount);
                    setMessageSuccess(`${totalCount} deliveries require immediate work`);
                })
                .catch(({message}) => {
                    setMessageError(message);
                    resetData();
                })
        }else{
            resetData();
        }
    },[isUserValid, getDeliveriesWorkRequired, page, perPage, setMessageSuccess, setMessageError, resetData])

    React.useEffect(query,[query]);

    React.useEffect(() => handleChange(data), [data, handleChange])
    React.useEffect(() => handleLibraryIdChange(libraryId), [libraryId, handleLibraryIdChange])

    const handleReprocessDelivery = React.useCallback(deliveryId => new Promise(resolve => { 
        reprocessDelivery(deliveryId)
            .then(result => {
                setMessageSuccess(`Delivery ${deliveryId} reprocessed`);
                return result;
            })
            .then(resolve)
            .catch(({message}) => {
                setMessageError(message);
                resolve(false)
            })
    }),[reprocessDelivery, setMessageSuccess, setMessageError])

    // Refresh on SocketIO Instruction
    React.useEffect(() => {
        if(socket){
            const refreshDelivery = ({deliveryId : id = undefined} = {}) => {
                if(data.find(d => id && d.id === id))
                    query()
            };
            socket.on('refresh_delivery', refreshDelivery)
            return () => {
                socket.off('refresh_delivery', refreshDelivery);
            }
        }
    },[data, socket, query])

    const hasData = Boolean(isUserValid && data?.length);

    if(!forceHeader && !hasData)
        return null

    return (
        <Box sx={{mb:2}}>
            <PageContainerHeader 
                title           = {"Work Required"}
                messageSuccess  = { messageSuccess } 
                messageError    = { messageError }     
                render          = {
                    <Box display="flex" sx={{mb:1}}>
                        <Box sx={{my:'auto'}}>
                            <FabRefresh 
                                tooltip     = "Refresh" 
                                loading     = {getDeliveriesWorkRequiredWorking} 
                                pulse       = {false} 
                                color       = "primary" 
                                aria-label  = "refresh" 
                                onClick     = {query}  
                                size        = "small"
                            />
                        </Box>
                    </Box>
                }
            />

            {
                hasData && 
                <ItemPaper>
                    <Paginated 
                        data                = {data}
                        page                = {page}
                        perPage             = {perPage}
                        onPerPageChange     = {setPerPage}
                        onPageChange        = {setPage}
                        externalPagination  = {true}
                        documentCount       = {totalCount}
                        showTop             = {false}
                        showBottom          = {true}
                        render              = {({data, page, perPage}) => {
                            return (
                                <>
                                    <TableContainer component={Box}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell header={true} />
                                                    <TableCell header={true} />
                                                    <TableCell header={true} align="center">                 
                                                        Actions
                                                    </TableCell>
                                                    <TableCell header={true} sx={{minWidth:75}} align="center">
                                                        Progress         
                                                    </TableCell>
                                                    <TableCell header={true} align="center" colSpan={3}>
                                                        Due              
                                                    </TableCell>
                                                    <TableCell header={true} align="left">
                                                        Ext.         
                                                    </TableCell>
                                                    <TableCell header={true} align="center">
                                                        User
                                                    </TableCell>
                                                    <TableCell header={true} align="center">
                                                        Delivery            
                                                    </TableCell>
                                                    <TableCell header={true} align="center">    
                                                        Order
                                                    </TableCell>
                                                    <TableCell header={true} align="center">    
                                                        Product
                                                    </TableCell>
                                                    <TableCell header={true} width="100%">    
                                                        Product Name
                                                    </TableCell>
                                                    <TableCell header={true} align="center" sx={{whiteSpace:'nowrap'}} >Chapters     </TableCell>
                                                    <TableCell header={true} align="center" sx={{whiteSpace:'nowrap'}} >Duration     </TableCell>
                                                    <TableCell header={true} align="center" sx={{whiteSpace:'nowrap'}} >Total        </TableCell>
                                                    <TableCell header={true} align="center" sx={{whiteSpace:'nowrap'}} >Done         </TableCell>
                                                    <TableCell header={true} align="center" sx={{whiteSpace:'nowrap'}} >Todo        </TableCell>
                                                    <TableCell header={true} align="left">
                                                        Created         
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    data.map(({id, order, product, library, releaseAfter, releaseAfterHistory = [], createdAt, releaseManual},ix) => {
                                                        const total                 = (library.files || []).length
                                                        const complete              = (library.files || []).reduce((acc,cur) => acc + (cur.file ? 1 : 0), 0);
                                                        const duration              = (library.files || []).reduce((acc,cur) => acc + (cur?.duration || 0), 0);
                                                        const chapters              = ([...new Set((library.files || []).map(x => x?.metadata?.chapter).filter(Boolean))] || []).length;
                                                        const incomplete            = total - complete; 
                                                        const pcntComplete          = total <= 0 ? 0 : 100 * complete / total;
                                                        const handleViewLibrary     = (e) => { if(e) e.stopPropagation(); handleViewLibraryById(library?.id); };
                                                        const handleReprocess       = (e) => { if(e) e.stopPropagation(); handleReprocessDelivery(id); };
                                                        const buttonArgs            = {disabled:  getDeliveriesWorkRequiredWorking || reprocessDeliveryWorking, size: "small" };
                                                        const viewArgs              = {color : "info",    onClick : handleViewLibrary}
                                                        const procArgs              = {color : "warning", onClick : handleReprocess}
                                                        const isExpanded            = expanded[id];
                                                        const handleToggleExpand    = () => setExpanded(prev => ({...prev,[id]:!Boolean(prev[id]) }));
                                                        const libraryOutstanding    = (library?.files || []).filter(f => Boolean(!f?.file));
                                                        const extensions            = (releaseAfterHistory.filter(({isOriginal = true}) => !isOriginal) || []).length;
                                                        const countDownProps        = {prefix:'Due',sx:{fontWeight:400}}

                                                        /*
                                                        const  handleScroll         = (e) => {
                                                            const {scrollHeight,scrollTop,clientHeight } = e.target;
                                                            const range = scrollHeight - clientHeight;
                                                            if(range !== 0){
                                                                const pcntComplete = Math.max(Math.min(100 * scrollTop / range ,100),0);
                                                                setPcnt( prev => ({...prev,[id]:pcntComplete}));
                                                            }
                                                        };
                                                        */

                                                        return (
                                                            <React.Fragment key={id}>
                                                                <TableRowMod key={id} onClick={handleToggleExpand} expanded={isExpanded} sx={{bgcolor : alpha(theme.palette.error.light, calcFracIncomplete(now, releaseAfter))}}>
                                                                    <TableCell>
                                                                        <IconButton size="small" onClick={e => {e.stopPropagation(); handleToggleExpand(e); }}>
                                                                            <NavigateNextIcon sx={{transform:`rotate(${isExpanded ? 90 : 0}deg)`,transition: '150ms ease all'}} />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                    <TableCell>{page*perPage + ix + 1}</TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            !smDown && 
                                                                            <Box display="flex" flexDirection="row">
                                                                                <Button variant="contained" startIcon={<LibraryBooksIcon/>} sx={{fontSize:'inherit', width:'100%', mx:0.5}} {...buttonArgs} {...viewArgs} >
                                                                                    View
                                                                                </Button>
                                                                                {
                                                                                    false && 
                                                                                    <Button variant="contained" startIcon={<RedoIcon/>} sx={{fontSize:'inherit', width:'100%', mx:0.5}} {...buttonArgs} {...procArgs}>
                                                                                        Proc
                                                                                    </Button>
                                                                                }
                                                                            </Box>
                                                                        }
                                                                        {
                                                                            smDown && 
                                                                            <Box display="flex" flexDirection="row">
                                                                                <IconButton {...buttonArgs} {...viewArgs} >
                                                                                    <Tooltip title="View Library">
                                                                                        <LibraryBooksIcon/>
                                                                                    </Tooltip>
                                                                                </IconButton>
                                                                                <IconButton {...buttonArgs} {...procArgs}>
                                                                                    <Tooltip title="Re-Process Delivery">
                                                                                        <RedoIcon />
                                                                                    </Tooltip>
                                                                                </IconButton>
                                                                            </Box>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{whiteSpace:'nowrap'}}>
                                                                        <CompletionBar pcntComplete={pcntComplete}/>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Tooltip 
                                                                            title = {
                                                                                releaseManual 
                                                                                    ? "Requires Manual Release" 
                                                                                    : "Relased Automatically"
                                                                                }
                                                                        >
                                                                            { 
                                                                                releaseManual 
                                                                                    ? <ManualIcon /> 
                                                                                    : <AutoIcon /> 
                                                                            }
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{whiteSpace:'nowrap'}}>
                                                                        <CountDownOrTimestamp 
                                                                            now             = {now} 
                                                                            to              = {releaseAfter} 
                                                                            countDownProps  = {countDownProps}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{whiteSpace:'nowrap'}}>
                                                                        {
                                                                            releaseManual &&
                                                                            <CountDown
                                                                                to              = {moment(releaseAfter)} 
                                                                                render          = {({finished}) => (
                                                                                    finished 
                                                                                        ? (
                                                                                            <Tooltip title="Overdue. Requires Manual Release">
                                                                                                <WarningIcon sx={{color:theme.palette.error.contrastText  }}/>
                                                                                            </Tooltip> 
                                                                                        )
                                                                                        : null
                                                                                )}
                                                                                {...countDownProps}
                                                                            />
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{whiteSpace:'nowrap'}}>
                                                                        {extensions ? `${extensions}x` : undefined}
                                                                    </TableCell>
                                                                    <TableCell align="center" onClick={e => e.stopPropagation()} width={0} sx={{whiteSpace:'nowrap'}} >
                                                                        <ViewUserButton id={order?.user?.id} emailMD5={order?.user?.emailMD5} name={order?.user?.name} disabled={getDeliveriesWorkRequiredWorking} scrollable={false}/>
                                                                    </TableCell>
                                                                    <TableCell align="center" onClick={e => e.stopPropagation()}>
                                                                        <ViewDeliveryButton deliveryId={id} size="small" color="primary" variant="contained" sx={{fontSize:'inherit'}}>
                                                                            <ObjectId allowCopy={false} value={id} sx={{color:'inherit',fontWeight:'inherit',fontSize:'inherit'}} />
                                                                        </ViewDeliveryButton>
                                                                    </TableCell>
                                                                    <TableCell align="center" onClick={e => e.stopPropagation()}>
                                                                        <ViewOrderButton orderId={order?.id} deliveryId={id} size="small" variant="contained" color={"primary"} sx={{fontWeight:'inherit',fontSize:'inherit'}}>
                                                                            <ObjectId allowCopy={false} value={order?.id} sx={{color:'inherit',fontWeight:'inherit',fontSize:'inherit'}} />
                                                                        </ViewOrderButton>
                                                                    </TableCell>
                                                                    <TableCell align="center" onClick={e => e.stopPropagation()}>
                                                                        <ViewProductButton productId={product?.id} size="small" variant="contained" modal={true} color={"primary"} sx={{fontWeight:'inherit',fontSize:'inherit'}}>
                                                                            <ObjectId allowCopy={false} value={product?.id} sx={{color:'inherit',fontWeight:'inherit',fontSize:'inherit'}} />
                                                                        </ViewProductButton>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {product?.name || "Product"}
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {chapters}x
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={{whiteSpace:'nowrap'}}>
                                                                        {
                                                                            moment.duration(duration,'seconds').format()
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{whiteSpace:'nowrap'}}>{total}x</TableCell>
                                                                    <TableCell align="center" sx={{whiteSpace:'nowrap'}}>{complete}x</TableCell>
                                                                    <TableCell align="center" sx={{whiteSpace:'nowrap'}}>{incomplete}x</TableCell>
                                                                
                                                                    <TableCell  align="center" width="fit-content"  sx={{whiteSpace:'nowrap'}}>
                                                                        {
                                                                            formatDateTime(moment(createdAt))
                                                                        }
                                                                    </TableCell>
                                                                </TableRowMod>
                                                                {
                                                                    isExpanded && 
                                                                    <TableRow id={`${id}-missing`}>
                                                                        <TableCell />
                                                                        <TableCell />
                                                                        <TableCell colSpan={999}>
                                                                            <Box display="flex" sx={{mt:2,mr:2}}>
                                                                                <Box>
                                                                                    <Typography variant="h5" gutterBottom>
                                                                                        Missing Library Entries ({incomplete}x)
                                                                                    </Typography>
                                                                                </Box>
                                                                                {
                                                                                    /*
                                                                                    false && 
                                                                                    <Box sx={{position:'relative'}}>
                                                                                        <LoadingBar transitionTime={0} loaderSpeed={0} progress = {isExpanded ? (pcnt[id] || 0) : 0} shadow={false} containerStyle={{position:'relative'}}/>
                                                                                    </Box>
                                                                                    */
                                                                                }
                                                                                <Box flexGrow={1} />
                                                                                <Box sx={{ml:4}}>
                                                                                    <AdminDeliveryPanel component={Box} deliveryId={id} disabled={getDeliveriesWorkRequiredWorking} />
                                                                                </Box>
                                                                            </Box>
                                                                            <TableContainer sx={{maxHeight:200,overflow:'scroll'}} /*onScroll={handleScroll}*/ >
                                                                                <Table sx={{mb:0.25}}>
                                                                                    <TableHead>
                                                                                        <TableRow> 
                                                                                            <TableCell header={true} width={25}/>
                                                                                            <TableCell header={true} align="center" width={50}>
                                                                                                ID
                                                                                            </TableCell>
                                                                                            <TableCell header={true} align="center" width={100}>
                                                                                                Actions
                                                                                            </TableCell>
                                                                                            <TableCell header={true} width={150}>
                                                                                                Chapter
                                                                                            </TableCell>
                                                                                            <TableCell header={true}>
                                                                                                Description
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {
                                                                                            libraryOutstanding.map(({_id, metadata, masterLibrary, description,...rest},ix) => {
                                                                                                const lastRow = ix >= libraryOutstanding.length - 1;
                                                                                                const {chapter,chapterTitle} = metadata
                                                                                                const showChapter = ix === 0 || chapter !== libraryOutstanding[ix-1]?.metadata?.chapter 
                                                                                                return (
                                                                                                    <TableRow key={_id}> 
                                                                                                        <TableCell lastRow={lastRow} align="right">
                                                                                                            {ix + 1}
                                                                                                        </TableCell>
                                                                                                        <TableCell lastRow={lastRow}>
                                                                                                            <ObjectId value={_id} sx={{color:'inherit',fontWeight:'inherit',fontSize:'inherit'}} />
                                                                                                        </TableCell>
                                                                                                        <TableCell lastRow={lastRow}>
                                                                                                            <MasterLibraryProvider>
                                                                                                                <EditLibraryButton libraryId={masterLibrary} ButtonProps={{...buttonArgs,color:'primary', variant:"contained", sx:{fontSize:'0.6rem', whiteSpace:'nowrap'}}}>
                                                                                                                    Edit Master
                                                                                                                </EditLibraryButton>
                                                                                                            </MasterLibraryProvider>
                                                                                                        </TableCell>
                                                                                                        <TableCell 
                                                                                                            lastRow = {lastRow} 
                                                                                                            align   = { showChapter ? "left" : "center" }
                                                                                                        >
                                                                                                            <Box
                                                                                                                sx = {{
                                                                                                                    display     : 'block',
                                                                                                                    width       : 150,
                                                                                                                    whiteSpace  : 'nowrap',
                                                                                                                    overflow    : 'hidden',
                                                                                                                    textOverflow: 'ellipsis'
                                                                                                                }} 
                                                                                                            >
                                                                                                                { showChapter ? chapterTitle : ' " ' }
                                                                                                            </Box>
                                                                                                        </TableCell>
                                                                                                        <TableCell lastRow={lastRow}>
                                                                                                            {description}
                                                                                                        </TableCell>
                                                                                                    </TableRow>
                                                                                                )
                                                                                            })}
                                                                                        
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {
                                        false && 
                                        <JSONViewer src={data} />
                                    }
                                </>
                            )
                        }}
                    />
                    <UserLibraryDialog 
                        libraryId   = {libraryId}
                        onClose     = {handleLibraryClose} 
                        onOk        = {handleLibraryClose} 
                    />
                </ItemPaper>
            }
        </Box>
    )
}

export const WorkRequired = (props) => {
    return (
        <LibraryViewerProvider forceScroll={false}>
          <Component {...props}/>
        </LibraryViewerProvider>
    )
}

export default WorkRequired;