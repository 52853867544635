
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
House Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       19th August 2021
*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';
import {useNetwork}             from './NetworkContext';
import {
    useStateEphemeral,
    useCancelToken
}                               from '../hooks';
import {debounce}               from '../functions';

// The Product Context 
const HouseContext = React.createContext(undefined);

const makeDefaultData = (house,description) => ({house,description});
const DEFAULT_DATA = [
    makeDefaultData( 1, "Physical appearance, traits and characteristics. Resourcefulness. Outlook and impressions. Ego. Beginnings and initiatives."),
    makeDefaultData( 2, "Material and immaterial things of certain value. Money. Possessions and acquisitions. Cultivation. Substance. Self-worth."),
    makeDefaultData( 3, "Communication. Distribution/generosity. Intelligence/development. Siblings. Locomotion and transportation. Ephemera."),
    makeDefaultData( 4, "Ancestry, heritage, roots. Foundation and environment. Mother or caretaker. Housing and the household. Neighborhood matters. Comfort, security. Tidiness. Pets."),
    makeDefaultData( 5, "Recreational and leisure activities. Things which make for enjoyment and entertainment. Games/gambling/risk. Romance and limerence. Children/fertility. Creative self-expression."),
    makeDefaultData( 6, "Routine tasks and duties. Skills or training acquired. Employment (job). Service performed for others. Strength, vitality. Wellness and healthcare. Courage."),
    makeDefaultData( 7, "Partnerships. Marriage and business matters. Diplomacy. Agreements, contracts and all things official. Equilibrium."),
    makeDefaultData( 8, "Cycles of deaths and rebirth. Sexual relationships and commitments of all kinds. Joint funds, finances. Other person's resource. Karma and debt (judgment). Regeneration. Self-transformation."),
    makeDefaultData( 9, "Travel and foreign affairs. Culture. Expansion. Law and ethics. Education/learning/knowledge. Philosophical interests, belief systems. Experience through exploration. Things long-term."),
    makeDefaultData(10, "Ambitions. Motivations. Career, achievements. Society and government. Father or authority. Notoriety. Advantage."),
    makeDefaultData(11, "Benefits from effort. Friends and acquaintances of like-minded attitudes. Belonging. Groups, communities, and associations. Charity. Connectedness/networking. Love. Wish fulfillment. Wealth."),
    makeDefaultData(12, "Privacy, refuge; seclusion and retreating. Clandestiny <-> Revelation. Intuition. Extremities, but also addictions. Luck, miracles. Releasing/relinquishing, healing, forgiveness, and peacefulness. Finality/completion/conclusion."),
];
const BASE_API_URL = '/api/astrology/house';

// House Provider
const HouseProvider = ({children}) => {

    const {
        axios, 
        socketUsers : socket
    }                                           = useNetwork();
    const {cancelToken }                        = useCancelToken();

    const [enabled, setEnabled]                 = React.useState(false);
    const [data,    setData]                    = React.useState([]);
    const [working, setWorking]                 = React.useState(false);
    const [queried, setQueried]                 = React.useState(undefined);

    // Error/Success Messages
    const   [messageSuccess,    setMessageSuccess]  = useStateEphemeral(undefined),
            [messageError,      setMessageError]    = useStateEphemeral(undefined,5000);

    // Clear Records
    const clear = React.useCallback(() => setData([]),[]);

    // GET
    const get = React.useCallback(() => new Promise((resolve,reject) => {
        setWorking(true);
        axios.get(BASE_API_URL, {cancelToken})
            .then(({data}) => {
                setMessageSuccess("Houses queried")
                return data
            })
            .then(resolve)
            .catch(err => {
                setMessageError(err.message);
                reject(err)
            })
            .finally(() => {
                setWorking(false);
            })
    }), [axios, cancelToken, setMessageError, setMessageSuccess]);

    const refresh = React.useCallback( (reset) => {
        setWorking(true);
        if(reset) 
            clear();
        get()
            .then(setData)
            .catch(clear)
            .finally(() => {
                setWorking(false);
                setQueried(moment());
            })
    },[clear, get])

    const deleteById = React.useCallback( (id,query=true) => new Promise((resolve,reject) => {            
        setWorking(true);
        axios.delete(`${BASE_API_URL}/${id}`, {cancelToken})
            .then(data => {
                setMessageSuccess(`Deleted house ${id}`);
                resolve({})
            })
            .catch((err) => {
                setMessageError(err?.message);
                reject(err);
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }), [axios, cancelToken, refresh, setMessageError, setMessageSuccess])

    const create = React.useCallback( ({house, description},query=true) => new Promise((resolve,reject) => {
        setWorking(true);
        axios.post(BASE_API_URL, {house, description}, {cancelToken})
            .then(() => {
                setMessageSuccess('Created house');
                resolve({})
            })
            .catch(err =>{
                setMessageError(err?.message);
                reject(err);
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }), [axios, cancelToken, refresh, setMessageError, setMessageSuccess])

    const postById = React.useCallback( ({_id, house, description},query=true) => new Promise((resolve,reject) => {
        setWorking(true);
        axios.post(`${BASE_API_URL}/${_id}`,{house,description}, {cancelToken})
            .then(() => {
                setMessageSuccess(`Updated house ${_id}`);
                resolve({})
            })
            .catch(err => {
                setMessageError(err?.message);
                reject(err);
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }), [axios, cancelToken, refresh, setMessageError, setMessageSuccess])

    // Refresh on authentication
    React.useEffect(() => {
        if(enabled)
            refresh();
    },[enabled, refresh])

    // Refresh on SocketIO Instruction
    React.useEffect(() => {
        if(enabled && socket){
            socket.on('refresh_houses', refresh)
            return () => {
                socket.off('refresh_houses', refresh);
            }
        }
    },[enabled, refresh, socket])

    const enable = React.useCallback( () => setEnabled(true) , []);

    // Context values
    const value = React.useMemo(() => ({
        data,
        enabled,
        setEnabled,
        enable,
        working,
        queried,
        refresh : debounce(refresh),
        create,
        postById,
        deleteById,
        messageSuccess,
        setMessageSuccess,
        messageError,
        setMessageError,
        DEFAULT_DATA,
        BASE_API_URL
    }), [create, data, deleteById, enable, enabled, messageError, messageSuccess, postById, queried, refresh, setMessageError, setMessageSuccess, working]);

    return (
        <HouseContext.Provider value={value}>
            {children}
        </HouseContext.Provider>
    )
}

// House Consumer
const HouseConsumer = ({children}) => {
    return (
        <HouseContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('HouseConsumer must be used within HouseProvider');
                }
                return children(context)
            }}
        </HouseContext.Consumer>
    )
}

// useHouse Hook
const useHouse = () => {
    const context = React.useContext(HouseContext);
    if(context === undefined)
        throw new Error('useHouse must be used within HouseProvider');
    return context;
}

export {
    HouseProvider,
    HouseConsumer,
    useHouse
}