/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
CommentsThread
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       23rd September 2022

*******************************************************************************************/
import React                            from 'react';
import Comments                         from './Comments';
import {
    useNetwork, 
    useUser, 
    useCommentsThread
}                                       from 'contexts';
import { useCancelToken }               from 'hooks';

const API_BASE_URL = '/api/user/commentGroup';

export const CommentsThread = ({
    reference                       = undefined,
    privateThread                   = true,
    ...props
}) => {
    const [parentId, setParentId]               = React.useState(undefined);
    const {axios, isNetworkReady }              = useNetwork();
    const {isAuthenticated}                     = useUser();
    const {cancelToken }                        = useCancelToken();
    const {setReference}                        = useCommentsThread();

    // Update Reference
    React.useEffect(() => setReference(reference), [reference, setReference]);

    React.useEffect(() => {

        if(!isNetworkReady)
            return setParentId(undefined)
        if(!reference)
            return setParentId(undefined)
        if(!isAuthenticated && privateThread)
            return setParentId(undefined)

        // Create or get
        axios.get(`${API_BASE_URL}/${reference}`, {cancelToken})
            .then((({data}) => data.id))
            .then(setParentId)
            .catch(err => {
                console.error(err)
                setParentId(undefined);
            })

    },[axios, cancelToken, isAuthenticated, isNetworkReady, privateThread, reference])

    if(!parentId) 
        return null;
        
    return (
        <Comments parentId={parentId} {...props} />
    )
}

export default CommentsThread;