/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Admin -- CelestialBodies
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       21st August 2021

*******************************************************************************************/
import React                            from 'react';
import {titleCase}                      from 'title-case';
import { Grid }                          from '@mui/material';
import {
    Form,
    JSONViewer,
    ColorChip
}                                       from 'components';
import { useTranslation }               from 'contexts';
import { 
    TextField,
    showErrorOnChange   as showError,
    Autocomplete
}                                       from 'mui-rff';
import {
    useCelestialBody
}                                       from 'contexts';

const noop  = () => {}
const obj   = {};
const defaultFormData = {
    id          : undefined,
    name        : '',
    description : '',
    rulers      : []
}

export const SignForm = ({
    formData                = defaultFormData,
    isEditing               = false,
    disabled                = false,
    onSubmit : handleSubmit = noop,
    onCancel : handleCancel = noop,
    FormProps               = obj,
}) => {
    
    const {
        data : celestialBodies
    }               = useCelestialBody();
    const {t}       = useTranslation();
    const validate  = React.useCallback( (values) => {
        let errors = {};
        let requiredFields  = ['name','description'];
        requiredFields.forEach(item => {
            if(values[item] === undefined || values[item] === '')
                errors[item] = errors[item] || t('components.forms.signForm.required');
        })
        return errors;
    }, [t]);

    // Initial Form Data
    const initialValues = React.useMemo(() => ({
        ...formData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [JSON.stringify(formData)])

    return (
        <>
            <Form
                debug           = {false}
                disabled        = {disabled}
                onSubmit        = {handleSubmit}
                onCancel        = {handleCancel}
                initialValues   = {initialValues}
                validate        = {validate}
                {...FormProps}
                render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container>
                                {
                                    [   
                                        { name: 'name',         xs:12 },
                                        { name: 'description',  xs:12 }
                                    ]
                                        .map(({name,xs},ix) => {
                                            const extra = ix > 0 
                                                ? {multiline:true, minRows:1, maxRows:10, disabled} 
                                                : {disabled : isEditing || disabled, type : "text" };
                                            return (
                                                <Grid key={`text${ix}`} item xs={xs}>
                                                    <TextField 
                                                        name        = {name} 
                                                        label       = { t(`components.forms.signForm.${name}`) }
                                                        value       = {values[name]}
                                                        {...extra}
                                                        showError   = {showError}
                                                    />
                                                </Grid>
                                            )
                                        })
                                }
                                <Grid item xs={12}>
                                    <Autocomplete
                                        disabled                = {disabled} 
                                        label                   = { t('components.forms.signForm.rulers') }
                                        name                    = "rulers"
                                        options                 = {celestialBodies.map(x => x._id)}
                                        value                   = {values.rulers}
                                        getOptionValue          = {option => option}
                                        getOptionLabel          = {option => (
                                            titleCase(celestialBodies.find(x => x._id === option)?.name || '') || 
                                            t('components.forms.signForm.none')
                                        )}
                                        isOptionEqualToValue    = {(option,value) => option === value}
                                        disableCloseOnSelect    = {false}
                                        textFieldProps          = {{
                                            InputLabelProps : {shrink :true}
                                        }}
                                        renderTags              = {(value, getTagProps) =>
                                            (value || []).map((option, index) => (
                                                <ColorChip
                                                    {...getTagProps({ index })}
                                                    color   = "primary"
                                                    size    = "small"
                                                    label   = {
                                                        titleCase(celestialBodies.find(x => x._id === option)?.name || '') || 
                                                        t('components.forms.signForm.none')
                                                    } 
                                                />
                                            ))
                                        }
                                        autoHighlight
                                        autoSelect
                                        autoComplete
                                        multiple
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    );
                }}
            />
            {
                false && 
                <JSONViewer src={celestialBodies}/>
            }
        </>
    );
}

export default SignForm;