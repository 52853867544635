/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Order Progress
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       5th August 2021

*******************************************************************************************/
import React                            from 'react';
import { useHistory, useLocation }      from 'react-router-dom';
import { 
    styled, 
    alpha, 
    Box, 
    Typography
}                                       from '@mui/material';
import {
    Title,
    JSONViewer,
    AdminBox,
    NoDataPrompt,
    Button,
    RootContainer,
    ItemPaper,
    OrderDeliveryProgress,
}                                       from 'components';
import { withTranslation }              from 'components/hoc';
import {
    useUser,
    useTrackId
}                                       from 'contexts';
import { 
    useStatusMap,
    useOrderViewer 
}                                       from './context';
import { 
    useQueryParam, 
    StringParam,
}                                       from 'hooks/useQueryParams';
import { useBreakpointQueries }         from 'hooks';

const DEBUG                     = false;
const DELIVERY_QUERY_PARAM_NAME = 'deliveryId'
const ORDER_TABLE_ID            = 'orderTable';
const SUPPORT_ID                = 'support';

const ContentContainer = styled(Box,{
    shouldForwardProp : (prop) => prop !== 'cancelled'
})(({theme, cancelled = false}) => ({
    background : cancelled 
        ? alpha(theme.palette.error.light,0.5) 
        : 'inheri',
    '& > * + *' : {
        marginTop : theme.spacing(2)
    }
}));

export const OrderProgress = withTranslation( ({
    t : tBase, 
    quickLinks = false, 
    ...props
}) => {
    const [deliveryId, setDeliveryId]                   = useQueryParam(DELIVERY_QUERY_PARAM_NAME, StringParam);
    const location                                      = useLocation();
    const history                                       = useHistory();
    const {
        data,   // Order 
        orderId, 
        isOwner,
        deliveries
    }                                                   = useOrderViewer();
    const {trackId, setTrackId}                         = useTrackId();
    const {isAdmin,isAuthenticated}                     = useUser();
    const {mdDown}                                      = useBreakpointQueries();
    const {statusMap,statusMapNonEmpty,hasNonEmpty}     = useStatusMap();

    const t = React.useCallback( suffix => tBase(`components.order.orderProgress.${suffix}`),[tBase]);

    const scrollToID = React.useCallback((id) => {
        if(id){
            const el = document.getElementById(id);
            if(el) 
                el.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
            return true;
        }
        return false;
    },[])

    const scrollToDelivery = React.useCallback((deliveryId) => {
        if(scrollToID(deliveryId)){
            setTrackId(deliveryId)
            setDeliveryId(undefined);
        }
    },[scrollToID, setDeliveryId, setTrackId])

    // clear last scroll after a reasonable time
    React.useEffect(()=>{
        if(trackId) 
            scrollToDelivery(trackId);
    },[trackId, scrollToDelivery, setTrackId])

    // Scroll to library hash
    React.useEffect(() => {
        if(isAuthenticated && (deliveries || []).length > 0 && deliveryId){
            const timeout = setTimeout(() => scrollToDelivery(deliveryId), 500);
            return () => {
                clearTimeout(timeout);
            }
        }
    },[isAuthenticated, deliveries, location.search, history, scrollToDelivery, deliveryId])

    const hasOrderTable = Boolean(document.getElementById( ORDER_TABLE_ID ));
    const hasSupport    = Boolean(document.getElementById( SUPPORT_ID ));

    if(!data || !data?.shouldProcess || !orderId)
        return null
    
    return (
        <RootContainer component={ItemPaper} id={"orderprogress"}>
            {
                quickLinks &&
                <ContentContainer>
                    <Box>
                        <Title variant="h5">
                            {t('quickLinks')}
                        </Title>
                    </Box>
                    {
                        (hasNonEmpty || hasOrderTable) && 
                        <Box mt={0}>
                            <Typography gutterBottom>
                                {t('navigateToTheFollowing')}
                            </Typography>
                            <Box display="flex" flexDirection={mdDown ? 'column' : 'row'} sx={{alignItems: 'left'}}>   
                                {   
                                    hasNonEmpty && 
                                    statusMapNonEmpty.map(({id,quantity}) => (
                                        <Button key={id} variant="contained" size="small" color="secondary" onClick={() => scrollToID(id)} sx={{whiteSpace:'nowrap',mr:1,mt:1}}>
                                            {t(`${id}.title`)} ({quantity}x)
                                        </Button>
                                    ))
                                }
                                {
                                    hasOrderTable && 
                                    <Button variant="contained" size="small" color="secondary" onClick={() => scrollToID(ORDER_TABLE_ID)} sx={{whiteSpace:'nowrap',mr:1,mt:1}}>
                                        {t('orderTable')}
                                    </Button>
                                }
                                {   
                                    hasSupport && 
                                    <Button variant="contained"  size="small" color="secondary" onClick={() => scrollToID(SUPPORT_ID)} sx={{mr:1,mt:1}}>
                                        {t('support')}
                                    </Button>
                                }
                            </Box>
                        </Box>
                    }

                    {
                        (!hasOrderTable && !hasNonEmpty) && 
                        <Box p={2} align="center">
                            <NoDataPrompt>
                                {t('noQuickLinks')}
                            </NoDataPrompt>
                        </Box>
                    }
                </ContentContainer>
            }
            
            {   
                !quickLinks && 
                <ContentContainer id={'deliveries'} >
                    {
                        statusMap
                            .filter(x => !x?.empty) // Exlude empty
                            .map(({id, deliveriesSubset}, ix) => (
                                <ContentContainer p={2} id={id} key={ix} cancelled = {id === 'cancelled'} sx={{m:-2}}>
                                    <Box>
                                        <Title variant="h5">
                                            {t(`${id}.title`)}
                                        </Title>
                                        <Typography gutterBottom>
                                            {t(`${id}.subtitle`)}
                                        </Typography>
                                    </Box>
                                    <ContentContainer> 
                                        {
                                            deliveriesSubset.map(delivery => (
                                                <Box key={delivery?.id} id={delivery?.id}> {/* FOR SCROLL TO DELIVERY ID*/}
                                                    <OrderDeliveryProgress 
                                                        delivery            = {delivery}
                                                        isOwner             = {isOwner}
                                                    />
                                                    {false && <JSONViewer src={delivery} />}
                                                </Box>
                                            ))
                                        }
                                        {
                                            DEBUG && isAdmin && deliveriesSubset.length > 0 &&
                                            <AdminBox>
                                                <JSONViewer src={deliveriesSubset}/>
                                            </AdminBox>
                                        }
                                    </ContentContainer>
                                </ContentContainer>
                            )
                        )
                    }
                </ContentContainer>
            }
        </RootContainer>
    )
})

export default OrderProgress;