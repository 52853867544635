
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Celestial Body Context
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       21st August 2021

*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';
import {useNetwork}             from './NetworkContext';
import {
    useStateEphemeral,
    useCancelToken,
}                               from '../hooks';
import {debounce}               from '../functions';

// The Product Context 
const CelestialBodyContext = React.createContext(undefined);

const makeDefaultData = (name,description) => ({name,description});
const DEFAULT_DATA = [
    makeDefaultData('sun',      'The Sun, the giver of life, represents our conscious mind in Astrology. It represents our will to live and our creative life force'),
    makeDefaultData('moon',     'The Moon, in Astrology, is the ruler of Cancer. The Moon represents our deepest personal needs, our basic habits and reactions, and our unconscious'),
    makeDefaultData('mercury',  'Mercury, Mercury Symbol Glyphthe messenger of the gods, is the ruler of Gemini and Virgo. Mercury is the messenger in Astrology as it is in Mythology.'),
    makeDefaultData('venus',    'Venus is the Goddess of Love. In Astrology, Venus has dual rulership over Libra and Taurus. As a result, Venus represents two main areas of our life: love and money.'),
    makeDefaultData('mars',     'Mars, the God of War, is the ruler of Aries. In astrology, Mars is the planet of energy, action, and desire.'),
    makeDefaultData('jupiter',  'Jupiter, the King of the Gods, is the ruler of Sagittarius. In Astrology, Jupiter is a planet of plenty'),
    makeDefaultData('saturn',   'Saturn, is the ruler of Capricorn. In Greek Mythology, Cronus was one of the Titans, and the father of Zeus. Cronus ate his children to prevent himself from being dethroned as the King of the Gods. That is, until his wife, Rhea, tricked him into swallowing a stone when Zeus was born.'),
    makeDefaultData('uranus',   'Uranus, God of the sky and the heavens, is the ruler of Aquarius. In Astrology, the energies of Uranus are electric and crammed with change. Uranus is forward-looking. It balks at tradition and celebrates originality and individuality. Uranus is associated with technology, innovation, discovery, and all that is progressive.'),
    makeDefaultData('neptune',  'Neptune, God of the Sea, is the ruler of Pisces. In astrology, Neptune is considered a planet of inspiration, dreams, psychic receptivity, illusion, and confusion. Neptune rules spirituality, and all things subtle. A youthful, and sometimes naive, spirit characterizes those with a strong placement of Neptune in their natal charts.'),
    makeDefaultData('pluto',    'Pluto, God of the Underworld, is the ruler of Scorpio. (In Greek mythology, the corresponding god was Hades). In Astrology, the energies of Pluto are transforming. Pluto represents subconscious forces, ruling all that is “below the surface”.'),
    makeDefaultData('chiron',   'Chiron is a comet with a unique and erratic orbit. In the natal chart, Chiron is symbolized by the “wounded healer.” It represents our deepest wound and our efforts to heal the wound.'),
    // makeDefaultData('sirius',   'Fixed star Sirius is of the nature of Jupiter and Mars (high ambition, pride, love of power, grandeur of view.) It gives honor, renown, wealth, ardor, faithfulness, devotion, passion and resentment, and makes its natives custodians, curators and guardians'),
]

const BASE_API_URL = '/api/astrology/celestialBody';

// CelestialBody Provider
const CelestialBodyProvider = ({children}) => {

    const {
        axios, 
        socketUsers : socket
    }                                               = useNetwork();
    const {cancelToken}                             = useCancelToken();

    const [enabled, setEnabled]                     = React.useState();
    const [data,    setData]                        = React.useState([]);
    const [working, setWorking]                     = React.useState(false);
    const [queried, setQueried]                     = React.useState(undefined);
   
    // Error/Success Messages
    const   [messageSuccess,    setMessageSuccess]  = useStateEphemeral(undefined),
            [messageError,      setMessageError]    = useStateEphemeral(undefined,5000);

    // Clear Records
    const clear = React.useCallback(() => setData([]),[]);

    // GET
    const get = React.useCallback(() => new Promise((resolve,reject) => {
        setWorking(true);
        axios.get(BASE_API_URL,{cancelToken})
            .then(({data}) => {
                setMessageSuccess("Celestial bodies queried")
                return data
            })
            .then(resolve)
            .catch(err => {
                setMessageError(err.message);
                reject(err)
            })
            .finally(() => {
                setWorking(false);
            })
    }),[axios, cancelToken, setMessageError, setMessageSuccess]);

    const refresh = React.useCallback( (reset) => {
        setWorking(true);
        if(reset) 
            clear();
        get()
            .then(setData)
            .catch(clear)
            .finally(() => {
                setWorking(false);
                setQueried(moment());
            })
    },[clear, get])

    const create = React.useCallback( ({name, description}, query=true) => new Promise((resolve,reject) => {
        setWorking(true);
        axios.post(BASE_API_URL, {name, description}, {cancelToken})
            .then(() => {
                setMessageSuccess('Created celestial body');
                resolve({})
            })
            .catch(err => {
                setMessageError(err.message);
                reject(err);
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }),[axios, cancelToken, refresh, setMessageError, setMessageSuccess])

    const deleteById = React.useCallback( (id,query=true) => new Promise((resolve,reject) => {            
        setWorking(true);
        axios.delete(`${BASE_API_URL}/${id}`, {cancelToken})
            .then(data => {
                setMessageSuccess(`Deleted celestial body ${id}`);
                resolve({})
            })
            .catch(err => {
                setMessageError(err.message);
                reject(err);
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }), [axios, cancelToken, refresh, setMessageError, setMessageSuccess])

    const postById = React.useCallback( ({_id, name, old, description}, query=true) => new Promise((resolve,reject) => {
        setWorking(true);
        axios.post(`${BASE_API_URL}/${_id}`, {name, old, description}, {cancelToken})
            .then(() => {
                setMessageSuccess(`Updated celestial body ${_id}`);
                resolve({})
            })
            .catch(err => {
                setMessageError(err.message);
                reject(err);
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }), [axios, cancelToken, refresh, setMessageError, setMessageSuccess])

    // Refresh on authentication
    React.useEffect(() => {
        if(enabled)
            refresh();
    },[enabled, refresh])

    // Refresh on SocketIO Instruction
    React.useEffect(() => {
        if(enabled && socket){
            socket.on('refresh_celestial_bodies', refresh)
            return () => {
                socket.off('refresh_celestial_bodies', refresh);
            }
        }
    },[enabled, refresh, socket]);

    const enable = React.useCallback( () => setEnabled(true) , []);

    // Context values
    const value = React.useMemo(() => ({
        data,
        enabled,
        setEnabled,
        enable,
        working,
        queried,
        refresh : debounce(refresh),
        create,
        postById,
        deleteById,
        messageSuccess,
        setMessageSuccess,
        messageError,
        setMessageError,
        DEFAULT_DATA,
        BASE_API_URL
    }), [create, data, deleteById, enable, enabled, messageError, messageSuccess, postById, queried, refresh, setMessageError, setMessageSuccess, working]);

    return (
        <CelestialBodyContext.Provider value={value}>
            {children}
        </CelestialBodyContext.Provider>
    )
}

// CelestialBody Consumer
const CelestialBodyConsumer = ({children}) => {
    return (
        <CelestialBodyContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('CelestialBodyConsumer must be used within CelestialBodyProvider');
                }
                return children(context)
            }}
        </CelestialBodyContext.Consumer>
    )
}

// useCelestialBody Hook
const useCelestialBody = () => {
    const context = React.useContext(CelestialBodyContext);
    if(context === undefined)
        throw new Error('useCelestialBody must be used within CelestialBodyProvider');
    return context;
}

export {
    CelestialBodyProvider,
    CelestialBodyConsumer,
    useCelestialBody
}