/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Day/Night Auto Theme
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th June 2021

*******************************************************************************************/

import React                from 'react';
import moment               from 'moment';
import {
    styled,
    Box,
    Snackbar    as SnackbarMUI,
    Alert       as AlertMUI
}                           from '@mui/material';
import { 
    useAlert,
    useLocale
}                           from 'contexts';
import {withTranslation}    from './hoc'

const MAX_Z_INDEX   = 2147483647;
const ANCHOR_ORIGIN = {vertical:'bottom', horizontal:'left'};
const noop          = () => {}

const Container = styled(Box)({
    maxWidth : '50vw'
});

const Snackbar = styled(SnackbarMUI)({
});

const SnackbarCustom = withTranslation(({
    t, 
    data : {
        id          = undefined,
        date        = undefined, 
        message     = undefined, 
        severity    = 'info', 
        remaining   = 0
    } = {}, 
    onClose : handleClose   = noop, 
    autoHideDuration        = 6000, 
    ...rest
}) => {
    const {formatDateTime}          = useLocale();
    const [isHovered, setIsHovered] = React.useState(false);
    const timeStamp                 = React.useMemo(() => (
        moment.isMoment(date) 
            ? formatDateTime(date)
            : undefined
    ), [date, formatDateTime]);
    const handleMouseEnter          = () => {
        setIsHovered(true);
    };
    const handleMouseLeave          = () => {
        setIsHovered(false);
    };

    if(!message)
        return null;

    return (
        <Snackbar 
            id                  = {id} 
            open                = {true} 
            autoHideDuration    = {autoHideDuration} 
            onClose             = {handleClose} 
            anchorOrigin        = {ANCHOR_ORIGIN} 
            {...rest} 
            onMouseEnter        = {handleMouseEnter}
            onMouseLeave        = {handleMouseLeave}
            sx                  = {{
                zIndex: isHovered ? MAX_Z_INDEX : MAX_Z_INDEX,  // Increase zIndex on hover
                ...rest?.sx
            }}
        >
            <Container>
                <AlertMUI elevation={6} variant="filled" severity={severity} onClose = {handleClose} >
                    <Box>
                        {
                            timeStamp && 
                            <Box>
                                {timeStamp}
                            </Box>
                        }
                        <Box>
                             {message} 
                        </Box>
                        {
                            Boolean(remaining) &&
                            <Box align="right">
                                {t("components.alertSnackbar.snackbarCustom.remainingToGo", {remaining})}
                            </Box>
                        }
                    </Box>
                </AlertMUI>
            </Container>
        </Snackbar>
    );
});

export const AlertSnackbar = () => {
    const {
        messages, 
        handleClose, 
        period
    } = useAlert();

    const data  = React.useMemo(() => {
        return (
            !messages?.length
                ? undefined
                : {
                    ...messages[0],
                    remaining : messages.length - 1
                }
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(messages)])

    if(!data) 
        return null;

    // Render
    return (
        <SnackbarCustom
            data                = {data}
            onClose             = {handleClose} 
            autoHideDuration    = {period}
        />
    );
};

export default AlertSnackbar;