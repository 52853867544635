/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Generate Reading
********************************************************************************************
Admin Only

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th October 2022

*******************************************************************************************/
import React                        from 'react';
import { titleCase }                from 'title-case';
import { 
    pick, 
    isEqual, 
    isEmpty, 
    isNull, 
    isUndefined
}                                   from 'lodash';
import moment                       from 'moment';
import { 
    Link,
}                                   from 'react-router-dom'
import {
    styled,
    useTheme,
    Box,
    Checkbox,
    Grid,
    Paper as PaperMUI,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow as TableRowMUI,
    TableCell as TableCellMUI,
    TableContainer,
    IconButton,
    alpha
}                                   from '@mui/material';
import HourglassBottomIcon          from '@mui/icons-material/HourglassBottom';
import DataObjectIcon               from '@mui/icons-material/DataObject';
import TimelapseIcon                from '@mui/icons-material/Timelapse';
import DownloadIcon                 from '@mui/icons-material/CloudDownload';
import FileCopyIcon                 from '@mui/icons-material/FileCopy';
import BuildIcon                    from '@mui/icons-material/Build';
import ManageHistoryIcon            from '@mui/icons-material/ManageHistory';
import CheckIcon                    from '@mui/icons-material/CheckCircleOutline';
import PlayArrowIcon                from '@mui/icons-material/PlayArrow';
import PauseIcon                    from '@mui/icons-material/Pause';
import UndoIcon                     from '@mui/icons-material/Undo';
import MergeTypeIcon                from '@mui/icons-material/MergeType';
import CheckBoxIcon                 from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon     from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon    from '@mui/icons-material/IndeterminateCheckBox';
import ReportProblemIcon            from '@mui/icons-material/ReportProblem';
import ShoppingBasketIcon           from '@mui/icons-material/ShoppingBasket';
import SaveIcon                     from '@mui/icons-material/Save';
import DeleteIcon                   from '@mui/icons-material/Delete';
import AutoDeleteIcon               from '@mui/icons-material/AutoDelete';
import MoreHorizIcon                from '@mui/icons-material/MoreHoriz';
import { AdminLibraryLocation }     from 'router/locations';
import { 
    RootContainer,
    Button,
    ConfirmationButton,
    RefreshIcon,
    FormAlert,
    Radix,
    ProductCard,
    JSONViewer,
    SkeletonProductCard as SkeletonProductCardRaw,
    NatalSummary,
    SkeletonPlaylist as SkeletonPlayListRaw,
    CopyField,
    NotFound,
    PickReadingVariables,
    EditLibraryButton,
    ColorChip,
    SkeletonFancy,
    CompletionBar,
    TabProvider,
    Tabs,
    TabPanel,
    VerticalSpaceContainer,
    Paginated,
    ContainerWithFooter,
    LastQueried,
    FabRefresh,
    PageContainerHeader,
    ColorChip as Chip,
    ObjectId,
    DraggableDialog,
    ViewProductButton,
    CountDownOrTimestamp,
    FileIcon,
    NoData
}                                   from 'components';
import {LibraryJinkePlayer}         from 'components/library/LibraryJinkePlayer';
import { 
    useNatalData, 
    useProduct,
    useNetwork,
    useUser,
    MasterLibraryProvider,
    useAlert
}                                   from 'contexts';
import { 
    useStateEphemeral, 
    useSize,
    useCancelToken
}                                   from 'hooks';
import {
    withFeatureTypography
}                                   from 'components/hoc'
import { withTranslation }          from './hoc';

const API_BASE_URL = "/api/admin/reading";

const SkeletonProductCard   = withFeatureTypography(SkeletonProductCardRaw);
const SkeletonPlaylist      = withFeatureTypography(SkeletonPlayListRaw);

const SelectProductReminder = withTranslation( ({t}) => (
    <Typography align="center">
        {t('components.generateReading.selectProductPrompt')}
    </Typography>
));

const SelectPlaylistReminder = withTranslation( ({t}) => (
    <Typography align="center">
        {t('components.generateReading.selectPlaylistPrompt')}
    </Typography>
));

const prettySeconds = (seconds,trim = true) => (
    moment
        .duration(seconds, "seconds")
        .format("hh:mm:ss", { trim })
);

const defaultNatalRecordData = {
    lat             : undefined,
    lng             : undefined,
    address         : undefined,
    birthDateTime   : undefined,
    unknownTime     : undefined,
    localTime       : undefined,
    gender          : undefined,
    roddenRating    : 'AA'
};

const defaultProductData = {
    id              : undefined,
};

const defaultZodiacData = {
    zodiac          : "tropical"
}

const defaultHouseSystemData = {
    houseSystem     : "auto"
}


const TableRow = styled(TableRowMUI,{
    shouldForwardProp : prop => prop !== 'current' && prop !== 'hasFile' && prop !== 'failed' && prop !== "completed" && prop !== "working" && prop !== 'deleting'
})(({theme, current = false, hasFile = false, failed = false, completed = false,  working = false, deleting = false}) => ({
    ...(hasFile && {
        '&:hover' : {
            background : [alpha(theme.palette.secondary.main,0.15),'!important']
        },
    }),
    ...(current && {
        background : alpha(theme.palette.secondary.main,0.25),
        '&:hover' : {
            background : [alpha(theme.palette.secondary.main,0.35),'!important']
        }
    }),
    ...(failed && {
        background : alpha(theme.palette.error.main,0.25),
        '&:hover' : {
            background : [alpha(theme.palette.error.main,0.35),'!important']
        }
    }),
    ...(deleting && {
        background : alpha(theme.palette.error.main,0.50),
        '&:hover' : {
            background : [alpha(theme.palette.error.main,0.55),'!important']
        }
    }),
    ...(false && completed && {
        background : alpha(theme.palette.success.main,0.25),
        '&:hover' : {
            background : [alpha(theme.palette.success.main,0.35),'!important']
        }
    }),
    ...(working && {
        background : alpha(theme.palette.warning.main,0.25),
        '&:hover' : {
            background : [alpha(theme.palette.warning.main,0.35),'!important']
        }
    })
}))

const StickyContainer = styled(Box,{
    shouldForwardProp : prop => prop !== 'stickySpacing' && prop !== 'sticky'
})(({theme, stickySpacing = 2, sticky = false}) => ({
    ...(sticky && {
        position    : 'sticky',
        top         : theme.spacing(stickySpacing)
    })
}))

const TableCell = styled(TableCellMUI)(({theme}) => ({
    padding         : theme.spacing(0.25),
    paddingLeft     : theme.spacing(0.50),
    paddingRight    : theme.spacing(0.50),
    fontSize        : 12
}))

const HeaderContent = styled(Typography)({
    fontWeight : 800
})

const Paper = styled(PaperMUI)(({theme}) => ({
    border          : `1px solid ${theme.palette.divider}`,
    padding         : theme.spacing(1),
    height          : '100%',
}))

const MYAuto = styled(Box)({
    marginTop       :'auto',
    marginBottom    :'auto'
})

const noop = () => {};

const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'K', 'M', 'G', 'T'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))} ${sizes[i]}`;
};

const FileSizeDisplay = ({ size }) => {
    if(!Number.isFinite(size) || size < 0)
        return null;
    return formatFileSize(size);
};

const getFileExtension = (fileName) => {
    const cleanFileName = fileName.split('?')[0];
    const parts = cleanFileName.split('.');
    return parts.length > 1 ? parts.pop() : '';
};

const ViewRawData = ({data, component : Component = Button, children, ...props}) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen    = () => setOpen(true);
    const handleClose   = () => setOpen(false); 
    return (
        <React.Fragment>
            <Component {...props} onClick={handleOpen}>
                {children}
            </Component>
            {
                data && 
                <DraggableDialog 
                    title       = {"Raw Data"} 
                    open        = {open} 
                    onClose     = {handleClose} 
                    onCancel    = {handleClose} 
                    showButtons = {false}
                    maxWidth    = "sm"
                    fullWidth   = {true}
                >
                    <Box sx={{minHeight:400,width:'100%'}}>
                        <JSONViewer src={data} />
                    </Box>
                </DraggableDialog>
            }
        </React.Fragment>
    )
}

const GenerateReadingHistory = () => {
    const {isAuthenticated, isAdmin}            = useUser();
    const {axios, socketUsers : socket}         = useNetwork();
    const {cancelToken}                         = useCancelToken();
    const [messageSuccess,  setMessageSuccess]  = useStateEphemeral(undefined);
    const [messageError,    setMessageError]    = useStateEphemeral(undefined);
    const [data,            setData]            = React.useState([]);
    const [totalQuantity,   setTotalQuantity]   = React.useState(0);
    const [working,         setWorking]         = React.useState(false);
    const [queried,         setQueried]         = React.useState(undefined);
    const [perPage,         setPerPage]         = React.useState(10);
    const [page,            setPage]            = React.useState(0);
    const [progress,        setProgress]        = React.useState({});
    const [deleting,        setDeleting]        = React.useState({});

    const clear = React.useCallback(() => {
        setData([]);
        setTotalQuantity(0)
        setQueried(undefined);
        setDeleting({});
    },[])

    const query = React.useCallback(() => {
        if(isAuthenticated && isAdmin){
            setWorking(true);
            setDeleting({});
            axios.get(`${API_BASE_URL}/stitch?page=${page}&perPage=${perPage}`, { cancelToken } )
                .then(response => {
                    console.log(response)
                    return response;
                })
                .then(({data,totalQuantity = 0}) => {
                    setData(data)
                    setTotalQuantity(totalQuantity)
                    setMessageSuccess("Stitch History Queried Successfully")
                })
                .catch(err => {
                    clear();
                    setMessageError(err?.message)
                })
                .finally(() => {
                    setQueried(moment());
                    setTimeout(() => {
                        setWorking(false);
                    },1000)
                })
        }else{
            clear();
        }
    },[axios, cancelToken, clear, isAdmin, isAuthenticated, page, perPage, setMessageError, setMessageSuccess]);

    const deleteJob = React.useCallback(async (jobId) => {
        if(jobId){
            let success = false;
            setDeleting(prev => ({...prev,[jobId]:true}))
            setWorking(true);
            axios.delete(`${API_BASE_URL}/stitch/${jobId}`, {cancelToken})
                .then(() => {
                    success = true;
                    setMessageSuccess(`Deleted JobID ${jobId}`)
                    query();
                })
                .catch(err => {
                    setMessageError(err?.message)
                })
                .finally(() => {
                    setTimeout(() => {
                        setDeleting(prev => ({...prev,[jobId]:false}))
                        if(success)
                            setData(prev => prev.filter(d => d._id !== jobId)); // Temporarily remove
                        setWorking(false);
                    },1000)
                })
        }else{
            setMessageError(`Deleting Job requires a valid JobID`);
        }
    },[axios, cancelToken, query, setMessageError, setMessageSuccess])

    // Query on Load
    React.useEffect(query, [query]);

    // Listen to Updates
    React.useEffect(() => {
        if(socket && isAuthenticated && isAdmin){
            const handleStitchingJobPorgress = ({jobId,completePcnt}) => {
                setProgress(prev => ({...prev,[jobId]:completePcnt}));
            }
            socket.on('admin_refresh_stitching_jobs',query);
            socket.on('admin_stitching_job_progress',handleStitchingJobPorgress);
            return () => {
                socket.off('admin_refresh_stitching_jobs',query);
                socket.off('admin_stitching_job_progress',handleStitchingJobPorgress);
            }
        }
    },[isAdmin, isAuthenticated, query, socket])

    return (
        <VerticalSpaceContainer component={Paper}>
            {
                working && !queried &&
                <Box sx={{aspectRatio:2}}>
                    <SkeletonFancy />
                </Box>
            }
            { 
                (!working || queried) &&
                <ContainerWithFooter
                    renderFooter    = { <LastQueried quantity={totalQuantity} timestamp={queried}/> }
                    renderHeader = {
                        <PageContainerHeader 
                            title           = "Stitching Job History"
                            titleProps      = {{variant:'h5',component:'h5'}}
                            messageSuccess  = { messageSuccess } 
                            messageError    = { messageError } 
                            render          = {
                                <FabRefresh loading={working} pulse={false} color="primary" onClick={query} size="small"/>
                            }
                        />
                    }
                >
                    <Paginated
                        data                = {data}
                        perPage             = {perPage}
                        page                = {page}
                        onPageChange        = {setPage}
                        onPerPageChange     = {setPerPage}
                        documentCount       = {totalQuantity}
                        externalPagination  = {true}
                        render              = {({data}) => (
                            <RootContainer sx={{position : 'relative'}}>
                                {
                                    queried && totalQuantity <= 0 &&
                                    <NoData sx={{height:'33vh'}}>
                                        No Stitching Job History
                                    </NoData>
                                }
                                { 
                                    (!queried || totalQuantity > 0) &&
                                    <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell />
                                                        <TableCell align="center">     
                                                            Job
                                                        </TableCell>
                                                        <TableCell sx={{minWidth:250}}>
                                                            Natal Record
                                                        </TableCell>
                                                        <TableCell colSpan={2}>
                                                            Product
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            House
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            Zodiac
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <AutoDeleteIcon sx={{fontSize:14}}/>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <FileCopyIcon sx={{fontSize:14}}/>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TimelapseIcon sx={{fontSize:14}}/>
                                                        </TableCell>
                                                        <TableCell align="center" colSpan={2}>
                                                            <SaveIcon sx={{fontSize:14}}/>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <HourglassBottomIcon sx={{fontSize:14}} /> 
                                                        </TableCell>
                                                        <TableCell align="center">   
                                                            <DownloadIcon sx={{fontSize:14}}/>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <MoreHorizIcon sx={{fontSize:14}} />  
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        data.map(({_id, status, files = [], shortUrl, fileName, duration, metadata, expiresAt, size},ix) => (
                                                            <TableRow key={ix} deleting = {Boolean(deleting[_id])} working={status === 'working'} failed={status === 'failed'} completed={status === 'completed'}>
                                                                <TableCell>
                                                                    {page*perPage + ix + 1}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <ObjectId allowCopy={true} value={_id}/>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        metadata?.natalRecord &&
                                                                        <NatalSummary
                                                                            lat             = {metadata?.natalRecord?.lat} 
                                                                            lng             = {metadata?.natalRecord?.lng} 
                                                                            address         = {metadata?.natalRecord?.address} 
                                                                            unknownTime     = {metadata?.natalRecord?.unknownTime} 
                                                                            birthDateTime   = {metadata?.natalRecord?.birthDateTime}
                                                                            localTime       = {typeof metadata?.natalRecord?.localTime === 'boolean' ? metadata?.natalRecord?.localTime : true}
                                                                            description     = {metadata?.natalRecord?.description}
                                                                        />
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {metadata?.product?.name}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        (metadata?.product?._id || metadata?.product) &&
                                                                        <ViewProductButton component={IconButton} modal={false} productId={metadata?.product?._id || metadata?.product} color="primary" variant="contained" size="small">
                                                                            <ShoppingBasketIcon />
                                                                        </ViewProductButton>
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        metadata?.houseSystem && 
                                                                        <Chip label={titleCase(metadata?.houseSystem)} size="small" color="primary"/>
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        metadata?.zodiac && 
                                                                        <Chip label={titleCase(metadata?.zodiac)} size="small" color="primary"/>
                                                                    }
                                                                </TableCell>
                                                                <TableCell sx={{whiteSpace:'nowrap',fontSize:12}}>
                                                                    {
                                                                        expiresAt &&
                                                                        <CountDownOrTimestamp to={expiresAt} countDownProps={{prefix:'', sx:{fontWeight:400}}}/>
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {files?.length || 0}x
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        status === 'completed' && Number.isFinite(duration) && 
                                                                        moment.duration(duration,'seconds').format(undefined) 
                                                                    }
                                                                </TableCell>
                                                                <TableCell sx={{whiteSpace:'nowrap'}} align="right">
                                                                    {
                                                                        status === 'completed' &&
                                                                        <FileSizeDisplay size={size} />
                                                                    }
                                                                </TableCell>
                                                                <TableCell sx={{whiteSpace:'nowrap',width:25}}>
                                                                    {
                                                                        status === 'completed' &&
                                                                        <FileIcon extension={getFileExtension(fileName)} />
                                                                    }
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Chip 
                                                                        label   = {
                                                                            titleCase(
                                                                                {'completed':'done'}[status] || status
                                                                            )
                                                                        } 
                                                                        size    = "small"
                                                                        color   = {{'working':'warning','failed':'error','completed':'success'}[status] || 'default'}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        status === 'working' && Object.keys(progress).includes(_id) &&
                                                                        <CompletionBar pcntComplete={progress[_id]}/>
                                                                    }
                                                                    {
                                                                        status === 'completed' && shortUrl &&
                                                                        <a href={shortUrl} target="_blank" rel="noopener noreferrer">
                                                                            {shortUrl.slice(-6)}
                                                                        </a>
                                                                    }
                                                                </TableCell>
                                                                <TableCell sx={{whiteSpace:'nowrap'}}>
                                                                    <ViewRawData component={IconButton} data={data[ix]} color="primary" size="small">
                                                                        <DataObjectIcon sx={{fontSize:14}}/>
                                                                    </ViewRawData>
                                                                    <IconButton disabled={working} onClick={() => deleteJob(_id)} size="small">
                                                                        <DeleteIcon color="error" sx={{fontSize:14}}/>
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                    </TableContainer>
                                }
                                {false && <JSONViewer src={data}/>}
                            </RootContainer>
                        )}
                    />
                </ContainerWithFooter>
            }
        </VerticalSpaceContainer>
    )
}

const PlayList = withTranslation( ({t, disabled = false, data, onChange = noop, onChangeFile = noop, onClick = noop, currentTime=0, playing=false, playingItem=undefined, chapterName = "Chapter", prefix=undefined, ...props}) => {
    
    const dataGrouped   = React.useMemo(
        () => data.reduce((acc,cur,ix) => {
            const chap  = cur?.metadata?.chapter || "No Chapter"
            acc[chap]   = [...(acc[chap] || []), {...cur,originalIndex:ix} ];
            return acc;
        },{}),
        [data]
    );
    const handleChangeSelected = React.useCallback( 
        (originalIndices,value) => {
            originalIndices = !Array.isArray(originalIndices) ? [originalIndices] : originalIndices;
            if(!originalIndices.length) 
                return;
            const newData = data.map((d,ix) => {
                return (originalIndices.includes(ix)) 
                    ? {...d, selected : Boolean(value)} 
                    : d;
            })
            onChange(newData);
        }, 
        [data, onChange]
    );

    return (
        <RootContainer>
            {
                Object.entries(dataGrouped).map(([key,value],ix) => {
                    const someChecked   = value.map(x => x.selected).some(Boolean);
                    const allChecked    = value.map(x => x.selected).every(Boolean);
                    const allIndices    = value.map(x => x.originalIndex);
                    return (
                        <Box key={ix}>
                            {chapterName} {[value[0]?.metadata?.chapter || (ix + 1), value[0]?.metadata?.chapterTitle].filter(Boolean).join(' - ') }
                            <TableContainer >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell  >
                                                <Checkbox 
                                                    disabled            = {disabled}
                                                    onClick             = {(e) => {
                                                        e.stopPropagation();
                                                        handleChangeSelected(allIndices, !someChecked ? true : false);
                                                    }}
                                                    checked             = {someChecked} 
                                                    indeterminate       = {someChecked && !allChecked} 
                                                    checkedIcon         = {<CheckBoxIcon fontSize="medium"/>}
                                                    icon                = {<CheckBoxOutlineBlankIcon fontSize="medium"/>}
                                                    indeterminateIcon   = {<IndeterminateCheckBoxIcon fontSize="medium"/>}
                                                />
                                            </TableCell>
                                            <TableCell width="100%">
                                                <HeaderContent>
                                                    { t('common.topic') }
                                                </HeaderContent>
                                            </TableCell>
                                            <TableCell style={{minWidth:100}} align="right">
                                                <HeaderContent>
                                                    { t('common.duration') }
                                                </HeaderContent>
                                            </TableCell>
                                            <TableCell align="right">
                                                { t('common.actions') }
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            value.map(({file,duration,name,description,metadata,selected,originalIndex, masterLibrary},ixB) => {

                                                const hasFile   = Boolean(file);
                                                const isCurrent = file && file === playingItem?.file;

                                                return (
                                                    <TableRow 
                                                        key     = {`${ix}-${ixB}`} 
                                                        onClick = {() => {
                                                            if(!file) return;
                                                            isCurrent 
                                                                ? onClick()
                                                                : onChangeFile(file);
                                                        }} 
                                                        hover       = {Boolean(file)}
                                                        style       = {{cursor : file ? 'pointer' : 'default'}}
                                                        hasFile     = {hasFile}
                                                        current     = {hasFile && isCurrent}
                                                    >
                                                        <TableCell style={{minWidth:40}} align="right">
                                                            <Typography>
                                                                {prefix}{ix+1}.{ixB + 1}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Checkbox 
                                                                disabled            = {disabled}
                                                                checked             = {selected} 
                                                                onClick             = {(e) => {
                                                                    e.stopPropagation();
                                                                    handleChangeSelected(originalIndex,!selected);
                                                                }}
                                                                checkedIcon         = {<CheckBoxIcon fontSize="medium"/>}
                                                                icon                = {<CheckBoxOutlineBlankIcon fontSize="medium"/>}
                                                                indeterminateIcon   = {<IndeterminateCheckBoxIcon fontSize="medium"/>}
                                                            />
                                                        </TableCell>
                                                        <TableCell width="100%">
                                                            <Typography component="div">
                                                                {description}{" "} 
                                                                {isCurrent
                                                                    ? <> 
                                                                        { 
                                                                            playing 
                                                                                ? <PlayArrowIcon    color="secondary" sx={{transform :'translatey(3px)', fontSize : 12}} /> 
                                                                                : <PauseIcon        color="secondary" sx={{transform :'translatey(3px)', fontSize : 12}} />
                                                                        }
                                                                    </> 
                                                                    : null
                                                                }
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell style={{minWidth:75}} align="right">
                                                            <Typography component="div">
                                                                {!isNaN(duration) ? prettySeconds(duration) : duration} {!file && <ReportProblemIcon sx={{color:'error.main'}}/> }
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell onClick={e => e.stopPropagation()} style={{minWidth:75}} align="right">
                                                            <EditLibraryButton libraryId={masterLibrary} />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    )
                })
            }
        </RootContainer>
    )
});

const MasterLibraryLink = withTranslation( ({t}) => (
    <Link to={AdminLibraryLocation.path} style={{color:'inherit'}}>
        { t('common.masterLibrary') }
    </Link>
))

const defaultFormData = {
    productData : defaultProductData,
    natalRecord : defaultNatalRecordData,
    houseSystem : defaultHouseSystemData,
    zodiac      : defaultZodiacData
};

export const GenerateReading = withTranslation( ({
    t,
    formData                    = defaultFormData,
    result      : resultIn      = undefined,
    allowStitching              = true,
    auto                        = true,
    onWorking   : handleWorking = noop, 
    onChange    : handleChange  = noop,
    stickySpacing               = 2
}) => {
    
    const {alert,clear:clearAlerts}             = useAlert();
    const theme                                 = useTheme();
    const {data:nd,queried:ndQueried}           = useNatalData()
    const {data:pd,queried:pdQueried}           = useProduct();
    const {axios,socketUsers:socket}            = useNetwork();
    const {isAuthenticated, isAdmin}            = useUser();
    const {cancelToken}                         = useCancelToken();
    const [error, setError]                     = useStateEphemeral(undefined,5000);
    const ref                                   = React.useRef();
    const {width}                               = useSize(ref);
    const jobIdRef                              = React.useRef(null);
    const [jobProgress, setJobProgress]         = React.useState(0);

    const productDataRef                        = React.useRef(null);
    const [productData,     setProductData]     = React.useState( formData.productData );
    const natalRecordRef                        = React.useRef(null);
    const [natalRecord,     setNatalRecord]     = React.useState( formData.natalRecord );

    const [houseSystem,     setHouseSystem]     = React.useState( formData.houseSystem );
    const [zodiac,          setZodiac]          = React.useState( formData.zodiac );

    const [instance,        setInstance]        = React.useState(undefined);
    const [working,         setWorking]         = React.useState(false);
    const [result,          setResult]          = React.useState(resultIn);
    const [playList,        setPlayList]        = React.useState([]);
    const [playing,         setPlaying]         = React.useState(false);
    const [stitching,       setStitching]       = React.useState(false);
    const [playListIndex,   setPlayListIndex]   = React.useState(undefined);
    const [currentTime,     setCurrentTime]     = React.useState(0);
    const [chapters,        setChapters]        = React.useState(() => []);
    const [appendices,      setAppendices]      = React.useState(() => []);
    const [downloadUrl,     setDownloadUrl]     = React.useState(undefined);
    const [fileName,        setFileName]        = React.useState(undefined);
    const hasPlaylist                           = React.useMemo(() => Array.isArray(playList) && playList.length > 0, [playList])

    // Reset Result and Signed URL
    const handleClear = React.useCallback( () => {
        jobIdRef.current = null;
        setResult(undefined);
        setDownloadUrl(undefined);
        setJobProgress(0);
    }, []);
    
    const handleSubmitProductData               = React.useCallback( (formData) => {
        setProductData(  prev => ({...prev,...formData}))
        handleClear();
    }, [handleClear]);
    const handleSubmitHouseSystem               = React.useCallback( (formData) => {
        setHouseSystem(  prev => ({...prev,...formData}))
        handleClear();
    }, [handleClear]);
    const handleSubmitZodiac                    = React.useCallback( (formData) => {
        setZodiac(       prev => ({...prev,...formData}))
        handleClear();
    }, [handleClear]);
    const handleSubmitNatalRecord                 = React.useCallback( (formData) => {
        setNatalRecord(  prev => ({...prev,...formData}))
        handleClear();
    }, [handleClear]);

    // When Working
    React.useEffect(() => (
        handleWorking(working)
    ), [handleWorking, working]);

    // If update natal data
    React.useEffect(() => {
        if(ndQueried){
            const proposed = pick((nd || []).find(x => x.isUser), Object.keys(defaultNatalRecordData));
            if(proposed && !isEqual(proposed,defaultNatalRecordData) && !natalRecordRef.current){
                natalRecordRef.current = proposed;
                setNatalRecord(proposed)
            }
        }
    },[nd, natalRecord, ndQueried])

    // If update product data
    React.useEffect(() => {
        if(pdQueried){
            const proposed = pick((pd || [])[0],  Object.keys(defaultProductData));
            if(proposed && !isEqual(proposed,defaultProductData) && !productDataRef.current){
                productDataRef.current = proposed
                setProductData(proposed)
            }
        }
    },[pd, productData, pdQueried]);

    React.useEffect(() => {
        try{
            const proposed = [
                'DoB',
                moment.utc(natalRecord?.birthDateTime).format('yyyyMMDD-HHmmss'),
                '-',
                natalRecord?.unknownTime ? 'BTUnknown' : 'BTKnown',
                '-',
                'Geo',
                parseFloat(Math.abs(natalRecord.lat)).toFixed(2).replace('.','p'), natalRecord.lat >= 0 ? 'N' : 'S',
                parseFloat(Math.abs(natalRecord.lng)).toFixed(2).replace('.','p'), natalRecord.lng >= 0 ? 'E' : 'W',

                '-',
                'System',titleCase(houseSystem.houseSystem),
                '-',
                "Zodiac",titleCase(zodiac.zodiac),
                '.mp3'
            ].join('')
            setFileName(proposed);
        }catch(err){
            setFileName(undefined);
        }
    },[productData,natalRecord,houseSystem,zodiac])

    const handleSubmit = React.useCallback( () => {
        setResult(undefined)
        setWorking(true);
        const userInput = {
            ...houseSystem,
            ...zodiac,
            natalRecord,
        };
        axios.post(
            API_BASE_URL, 
            { productId : productData.id, userInput : userInput },
            { cancelToken }
        )
            .then(({data}) => setResult(data))
            .catch(err => {
                const {message,errors} = err;
                setError(message + " " + (!isEmpty(errors) ? JSON.stringify(errors) : ""))
                console.error(err)
            })
            .finally(() => {
                setWorking(false);
            })

    },[axios, cancelToken, houseSystem, zodiac, natalRecord, productData.id, setError]);


    const getJobResult = React.useCallback(async (jobId) => {
        try {

            // Fetch the result once the job is completed
            const res = await axios.get(`${API_BASE_URL}/stitch/${jobId}/result`, {cancelToken});
            if (res.status === 200) {
                const {shortUrl, signedUrl} = res.data;
                setDownloadUrl(shortUrl || signedUrl);
            } else {
                throw new Error(`Error fetching job result: ${res?.message}`);
            }

            clearAlerts();
            alert(`Stitching JobID: ${jobId} has COMPLETED`,'success');

        } catch (err) {
            setError(err?.message);
            alert(err?.message,'errors')
        } finally {
            setStitching(false);
        }
    },[alert, axios, cancelToken, clearAlerts, setError]);

    const checkJobStatus = React.useCallback(async (jobId, timeoutMilliseconds) => {

        let polling, timeout, interval = 5000;

        const clear = () => {
            clearInterval(polling);
            clearTimeout(timeout);
        }
        const complete = () => {
            clear();
            jobIdRef.current = null;
            setJobProgress(100);
            setStitching(false);
        }
        const fail = () => {
            clear();
            jobIdRef.current = null;
            setJobProgress(0)
            setStitching(false);
        }

        // Timeout
        if(Number.isFinite(timeoutMilliseconds) && timeoutMilliseconds > 0)
            timeout = setTimeout(() => clearInterval(polling), timeoutMilliseconds);
            
        // Polling loop: check the job status at regular intervals
        polling = setInterval(async () => {

            try {

                // Poll the status endpoint with the cancel token from the hook
                const res = await axios.get(`${API_BASE_URL}/stitch/${jobId}/status`, { cancelToken });

                if (res.status === 200) {

                    const { status } = res.data;

                    // Clear polling and interval if not working
                    if(status !== 'working')
                        clear();

                    // Now Handle the 3 cases.
                    if(status === 'working'){

                        alert(`Stitching JobID: ${jobId} is WORKING`,'info');

                    } else if (status === 'completed') {

                        complete();
                        
                        await getJobResult(jobId);

                    } else if (status === 'failed') {
                        throw new Error(`Stitching JobID: ${jobId} has FAILED`);
                    }
                    
                } else {
                    throw new Error(`Error fetching job status: ${res?.message}`)
                }

            } catch ({message}) {
                fail();
                clearAlerts();
                setError(message);
                alert(message, 'error');
            }
        }, interval);

    }, [alert, axios, cancelToken, clearAlerts, getJobResult, setError]);

    const handleStitch = React.useCallback(async () => {

        try{

            setStitching(true);
            setDownloadUrl(undefined);
            setJobProgress(0);
            
            const files             = [...chapters,...appendices].filter(x => x.selected && x.file).map(x => x.file);
            const fileNameDefault   = "output.mp3"

            // Post the files to stitch and extract the data and headers
            const res               = await axios.post(
                `${API_BASE_URL}/stitch`, 
                { 
                    files           : files, 
                    fileName        : fileName || fileNameDefault,
                    metadata        : {
                        ...houseSystem,
                        ...zodiac,
                        natalRecord,
                        product : productData.id
                    }
                }, 
                { 
                    cancelToken,
                    responseType    : "application/json", 
                }
            );

            if (res.status === 202) {
                jobIdRef.current = res.data.jobId;
                await checkJobStatus(jobIdRef.current, 30 * 60 * 1000); // 30 Minute hard timeout
            } else {
                console.error('Error starting job:', res.data.message);
            }
        }
        catch(err){
            setError(err.message)
            console.error(err);
            setStitching(false);
        }
    },[appendices, axios, cancelToken, chapters, checkJobStatus, fileName, houseSystem, natalRecord, productData.id, setError, zodiac]);

    React.useEffect(() => {
        if(!productData?.id)            return;
        if(!houseSystem?.houseSystem)   return;
        if(!zodiac?.zodiac)             return;
        if(!natalRecord)                return;
        if(Object.keys(defaultNatalRecordData).map(
            (key) => isNull(natalRecord[key]) || isUndefined(natalRecord[key])
        ).some(Boolean)) return;

        // Om submit
        if(auto) handleSubmit();
    },[houseSystem?.houseSystem, zodiac?.zodiac, natalRecord, handleSubmit, productData?.id, auto])

    // Call Reset when Critical Things Change
    /*
    React.useEffect(() => {
        setResult(resultIn || undefined);
        setDownloadUrl(undefined);
    }, [houseSystem, natalRecord, productData, resultIn, zodiac])
    */

    React.useEffect(()=>{
        if(result || downloadUrl){
            let files = [
                ...(result?.files || []), 
                downloadUrl 
                    ? {
                        file        : downloadUrl,
                        name        : 'Stitched Audio',
                        description : 'Stitched Audio'
                    } 
                    : undefined
            ].filter(Boolean)
            const pl = (files || [])
                .map(({file,name,description}, ix) => ({ix, file, name, description}))
                .filter(d => Boolean(d.file))
            setPlayList(pl);
        }else{
            setPlayList([])
        }

    },[downloadUrl, result])

    // Play if Stopped or Stop if Playing
    const handlePlayToggle = React.useCallback(() => {
        try{
            if(!instance) throw new Error('No Instance');
            playing ? instance.pause() : instance.play();
        }catch(err){
            // silent
        }
    },[instance, playing]);

    const handleAudioPlay       = React.useCallback( () => setPlaying(true),    []);
    const handleAudioPause      = React.useCallback( () => setPlaying(false),   []);
    const handleAudioEnded      = React.useCallback( () => setPlaying(false),   []);
    const handleAudioAbort      = React.useCallback( () => setPlaying(false),   []);
    const handlePlayIndexChange = React.useCallback( (playIndex) => setPlayListIndex(playIndex), []);
    const handleAudioProgress   = React.useCallback( (progress) => {
        let {currentTime,playIndex,paused,ended} = progress;
        setCurrentTime(currentTime);
        setPlayListIndex(playIndex);
        setPlaying(!paused && !ended);
    }, []);

    const handleChangeFile      = React.useCallback( (file) => {
        if(file){
            const newIndex = playList.findIndex(x => x.file === file)
            setPlayListIndex(newIndex);
        }
    }, [playList]);

    const handleAudioListChange = React.useCallback( () => {
        
    }, []);

    // Chapters and Appendices
    React.useEffect(() => {
        const newChapters = (result?.files || []).filter(x => !x?.metadata?.appendix).map(x => ({...x,selected:true}));
        setChapters(prev => !isEqual(prev,newChapters) ? newChapters : prev);
        const newAppendices = (result?.files || []).filter(x =>  x?.metadata?.appendix).map(x => ({...x,selected:false}))
        setAppendices(prev => !isEqual(prev,newAppendices) ? newAppendices : prev);
    },[result?.files])

    // When Result Changes
    React.useEffect(() => (
        handleChange({
            result : {
                ...result,
                files : [
                    ...chapters.filter(x => x.selected), 
                    ...appendices.filter(x => x.selected)
                ],
            },
            formData : {
                productData,
                natalRecord,
                houseSystem,
                zodiac
            },
            downloadUrl
        })
    ), [handleChange, downloadUrl, result, chapters, appendices, productData, natalRecord, houseSystem, zodiac]);

    const quantitySelected = React.useMemo(() => (
        chapters.filter(x => x.selected).length + appendices.filter(x => x.selected).length
    ), [appendices, chapters]);

    // Memoised common playlist arguments
    const playListArgs = React.useMemo(() => ({
        playing         : playing,
        currentTime     : currentTime,
        playingItem     : playList[playListIndex],
        disabled        : working || stitching,
        onClick         : handlePlayToggle,
        onChangeFile    : handleChangeFile,
    }),[currentTime, handleChangeFile, handlePlayToggle, playList, playListIndex, playing, stitching, working])

    // Determine if Has Files or Not
    const hasFiles      = React.useMemo(() => (
        Boolean(result && (result?.files || []).map(x => x.file).filter(Boolean).length > 0)
    ),[result]);

    // Determine Quantity Missing
    const qtyMissing    = React.useMemo(() => (
        result 
            ? (result?.files || []).map(x => !x.file).filter(Boolean).length 
            : 0
    ),[result]); 

    // Is there any missing
    const hasMissing    = React.useMemo(() => qtyMissing > 0, [qtyMissing]);

    React.useEffect(() => {
        if(socket){
            const handleStitchingJobPorgress = ({jobId,completePcnt}) => {
                console.log("admin_stitching_job_progress",jobId,completePcnt);
                if(jobIdRef.current && jobId === jobIdRef.current){
                    setJobProgress(completePcnt);
                }
            }
            socket.on('admin_stitching_job_progress',handleStitchingJobPorgress);
            return () => {
                socket.off('admin_stitching_job_progress', handleStitchingJobPorgress);
            }
        }
    },[socket])

    return (
        <>
            {
                (!isAuthenticated || !isAdmin) && 
                <React.Fragment>
                    {
                        (ndQueried && pdQueried)
                            ? <NotFound> { t('components.generateReading.noPermission') } </NotFound>
                            : <Box sx={{aspectRatio:'2'}}> <SkeletonFancy /> </Box>
                    }
                </React.Fragment>
            }
            {
                isAuthenticated && isAdmin &&
                <Grid container>
                    <Grid item xs={12} sm={12} md={5} lg={4}>
                        <StickyContainer stickySpacing={stickySpacing}>
                            <Paper sx={{/*height:'60vh', */ overflow:'scroll', border : theme => `1px solid ${theme.palette.divider}`, p : 1 }}>
                                <PickReadingVariables
                                    formData    = {{
                                        productData,
                                        natalRecord,
                                        houseSystem,
                                        zodiac
                                    }}
                                    onProductDataChange = {handleSubmitProductData}
                                    onNatalRecordChange = {handleSubmitNatalRecord}
                                    onHouseSystemChange = {handleSubmitHouseSystem}
                                    onZodiacChange      = {handleSubmitZodiac}
                                />
                            </Paper>
                        </StickyContainer>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={8} style={{height:'100%', /*overflow:'scroll',*/ display:"flex",flexDirection:'column'}}>
                        
                        <RootContainer>

                            <TabProvider
                                syncWithQuery   = {true}
                                data = {[
                                    {
                                        label           : `Generate`, 
                                        hidden          : false, 
                                        value           : 'generate',
                                        icon            : <BuildIcon />,
                                        iconPosition    : 'start'
                                    },
                                    {
                                        label           : `History`, 
                                        hidden          : false, 
                                        value           : 'history',
                                        icon            : <ManageHistoryIcon />,
                                        iconPosition    : 'start'
                                    },
                                ]}
                            >
                            
                                <Tabs />

                                <TabPanel index={'history'}>
                                    <GenerateReadingHistory />
                                </TabPanel>

                                <TabPanel index={'generate'}>
                                    <Paper>
                                        <Grid container direction="row-reverse">
                                            {
                                                error &&
                                                <Grid item xs={12}>
                                                    <FormAlert severity="error">
                                                        {error}
                                                    </FormAlert>
                                                </Grid>
                                            }
                                            <Grid item xs={12} md={3}>
                                                <Typography variant='h5' component="h3">
                                                    {t('common.product')}
                                                </Typography>
                                                <Box style={{zoom:'0.5'}}>
                                                    {
                                                        productData?.id 
                                                            ? <ProductCard actionsHeight={0} productId={productData?.id} showDescription={false} showAddToCart={false}/>
                                                            : <SkeletonProductCard typographyComponent={SelectProductReminder}/>
                                                    }
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                <Box display="flex" flexDirection="column" height="100%">
                                                    <Box flexGrow={1}>
                                                        <Box display="flex">
                                                            <Box flexGrow={1}>
                                                                <Typography variant='h5' component="h2">
                                                                    {t('common.natalData')}
                                                                </Typography>
                                                            </Box>
                                                            <Box style={{transform:'translatey(4px)'}}>
                                                                <Typography component="div" gutterBottom>
                                                                    {t('common.houseSystem')}: <ColorChip size="small" color="primary" label={houseSystem.houseSystem}/> {t('common.zodiac')}: <ColorChip size="small" color="primary" label={zodiac.zodiac}/>
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <NatalSummary
                                                            lat             = {natalRecord?.lat} 
                                                            lng             = {natalRecord?.lng} 
                                                            address         = {natalRecord?.address} 
                                                            unknownTime     = {natalRecord?.unknownTime} 
                                                            birthDateTime   = {natalRecord?.birthDateTime}
                                                            localTime       = {typeof natalRecord?.localTime === 'boolean' ? natalRecord?.localTime : true}
                                                            description     = {natalRecord?.description}
                                                        />
                                                    </Box>  
                                                    {
                                                        allowStitching && fileName &&
                                                        <Box pt={2}>
                                                            <Typography variant='h5' component="h2">
                                                                {t('common.outputFile')}
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                {fileName}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    <Box display={"flex"}>
                                                        <Box flexGrow={1} align="right" mt={2} style={{color:theme.palette.warning.main}}>
                                                            {
                                                                allowStitching && result &&
                                                                <ConfirmationButton 
                                                                    startIcon   = {(working || stitching) ? <RefreshIcon loading={true}/> : <MergeTypeIcon/>} 
                                                                    disabled    = {Boolean(stitching || working || !isAuthenticated || !hasPlaylist)} 
                                                                    color       = "inherit" 
                                                                    variant     = "text" 
                                                                    onClick     = {handleStitch}
                                                                >
                                                                    {
                                                                        hasPlaylist 
                                                                            ? t('common.stitch')
                                                                            : t('common.nothingToStitch')
                                                                    }
                                                                </ConfirmationButton>
                                                            }
                                                        </Box>
                                                        <Box align="right" mt={2} ml={2} style={{color:theme.palette.error.main}}>
                                                            <Button 
                                                                startIcon   = {<UndoIcon/>}
                                                                disabled    = {Boolean(stitching || working || !isAuthenticated || !result)} 
                                                                color       = "inherit" 
                                                                variant     = "text" 
                                                                onClick     = {handleClear}
                                                            >
                                                                {t('common.clearReset')}
                                                            </Button>
                                                        </Box>
                                                        <Box align="right" mt={2} ml={2}>
                                                            <Button 
                                                                startIcon   = {working ? <RefreshIcon loading={true}/> : <CheckIcon />} 
                                                                disabled    = {Boolean(stitching || working || !isAuthenticated)} 
                                                                color       = "primary" 
                                                                variant     = "contained" 
                                                                onClick     = {handleSubmit}
                                                            >
                                                                { 
                                                                    working 
                                                                        ? t('common.working')
                                                                        : t('common.generate')
                                                                }
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Paper>

                                    {
                                        (!true || stitching || downloadUrl) && 
                                        <Paper>
                                            <Typography variant='h5' component="h2">
                                                {t('components.generateReading.stitchingProgress')}
                                            </Typography>
                                            {
                                                (!true || (stitching && !downloadUrl)) &&
                                                <Box sx={{mt:1,mb:2}}>
                                                    <FormAlert severity="warning">
                                                        { t('components.generateReading.stitchingTakesTime') }
                                                    </FormAlert>
                                                </Box>
                                            }
                                            {
                                                downloadUrl &&
                                                <Typography gutterBottom>
                                                    {t('components.generateReading.stitchingProgressComplete')}
                                                </Typography>
                                            }
                                            <CompletionBar pcntComplete={downloadUrl ? 100 : jobProgress}/>
                                        </Paper>
                                    }

                                    {
                                        downloadUrl &&
                                        <Paper>
                                            <Typography variant='h5' component="h2">
                                                {t('components.generateReading.signedDownloadUrl')}
                                            </Typography>
                                            <Typography gutterBottom>
                                                {t('components.generateReading.signedDownloadUrlDescription', {expireDays:7,purgeDays:30})}
                                            </Typography>
                                            <CopyField value={downloadUrl}/>
                                        </Paper>
                                    }

                                    <Paper style={{minHeight:400}}>
                                        <Grid container>
                                            {
                                                result && (!hasFiles || hasMissing) && 
                                                <Grid item xs={12} sx={{'& > * + *' : {mt : 1}}}>
                                                    {
                                                        !hasFiles && 
                                                        <FormAlert severity="error">
                                                            {t('components.generateReading.errorNoFiles')} (<MasterLibraryLink/>)
                                                        </FormAlert>
                                                    }
                                                    {
                                                        hasMissing &&
                                                        <FormAlert severity="warning">
                                                            {t('components.generateReading.warningMissingFiles', {quantity : qtyMissing })} (<MasterLibraryLink/>)
                                                        </FormAlert>
                                                    }
                                                </Grid>
                                            }
                                            <Grid item xs={12} lg={4}>
                                                <StickyContainer ref={ref} style={{maxWidth:400,margin:'auto'}}>
                                                    <Radix 
                                                        id      = "radix"
                                                        width   = {width}
                                                        data    = {result?.horoscopeData}
                                                    />
                                                </StickyContainer>
                                            </Grid>
                                            {   
                                                result && 
                                                <Grid item xs={12} lg={8}>
                                                    <RootContainer>
                                                        <RootContainer display="flex" flexDirection="row">
                                                            <MYAuto>
                                                                <Typography variant='h6'>
                                                                    {t('common.result')}
                                                                </Typography>
                                                            </MYAuto>
                                                            {
                                                                hasPlaylist && 
                                                                <MYAuto>
                                                                    {
                                                                        playing 
                                                                            ? <PlayArrowIcon color="secondary" style={{transform:'translatey(3px)'}}/>
                                                                            : <PauseIcon color="secondary" style={{transform:'translatey(3px)'}} />
                                                                    }
                                                                </MYAuto>
                                                            }
                                                            {
                                                                hasPlaylist && playing &&
                                                                <MYAuto>
                                                                    <Typography component="span" variant="body2">
                                                                        {prettySeconds(currentTime,false)}
                                                                    </Typography>
                                                                </MYAuto>
                                                            }
                                                            <Box flexGrow={1}/>
                                                            <MYAuto>
                                                                <Typography component="span" variant="body2">
                                                                    {t('components.generateReading.topicsSelectedQuantity',{quantity:quantitySelected})}
                                                                </Typography>
                                                            </MYAuto>
                                                        </RootContainer>

                                                        <MasterLibraryProvider>
                                                            
                                                            {/* Chapters Playlist */}
                                                            <PlayList 
                                                                data        = {chapters} 
                                                                onChange    = {setChapters} 
                                                                {...playListArgs} 
                                                                chapterName = {t('common.chapter')} 
                                                            />

                                                            {/* Appendices Playlist */}
                                                            <PlayList 
                                                                data        = {appendices} 
                                                                onChange    = {setAppendices} 
                                                                {...playListArgs}
                                                                prefix      = {'A'} 
                                                                chapterName = {t('common.appendix')} 
                                                            />

                                                        </MasterLibraryProvider>

                                                        {
                                                            false && 
                                                            <Box fontSize={'0.65rem'}>
                                                                <JSONViewer src={result}/>
                                                            </Box>
                                                        }
                                                    </RootContainer>
                                                </Grid>
                                            }

                                            {
                                                !result && 
                                                <Box p={2} width="100%">
                                                    <SkeletonPlaylist typographyComponent={SelectPlaylistReminder}/>
                                                </Box>
                                            }
                                        </Grid>
                                    </Paper>

                                </TabPanel>

                            </TabProvider>
                        </RootContainer>
                    </Grid>
                </Grid>
            }
            {   
                hasPlaylist &&
                <LibraryJinkePlayer 
                    hidden                  = { false }
                    playList                = { playList }
                    playListIndex           = { playListIndex }
                    onAudioInstanceChange   = { setInstance }
                    onAudioPlay             = { handleAudioPlay}  
                    onAudioPause            = { handleAudioPause }
                    onAudioEnded            = { handleAudioEnded }
                    onAudioAbort            = { handleAudioAbort}   
                    onPlayIndexChange       = { handlePlayIndexChange }
                    onAudioListChange       = { handleAudioListChange }
                    onAudioProgress         = { handleAudioProgress }
                />
            }
        </>
    )
});

export default GenerateReading;
